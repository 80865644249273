import React, { useEffect, useState } from 'react'
import { Container, StepsWrapper, StyledProgress } from './styles'

interface Props {
  total: number
  done: number
  rewardAt: number
}

const CourseProgress: React.FC<Props> = ({ total, done, rewardAt }) => {
  const [percent, setPercent] = useState(0)

  useEffect(() => {
    setPercent(Math.ceil((done * 100) / total))
  }, [done, total])

  return (
    <Container>
      <StepsWrapper>
        {done}/{total} concluído
        <strong>{percent}%</strong>
      </StepsWrapper>
      
      <StyledProgress amountFilled={percent} rewardAt={rewardAt} />
    </Container>
  )
}

export default CourseProgress
