import { makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { ReactNode } from 'react'

export const DataTableCell = withStyles(() => ({
  root: {
    fontStyle: 'normal',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    color: '#5C5C5C',
    border: 'none',
  },
  head: {
    backgroundColor: 'white',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '0.625rem',
    lineHeight: '1rem',
    letterSpacing: '0.09375rem',
    textTransform: 'uppercase',
    color: '#A2A2A2',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

export const DataTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#FAFAFA',
    },
  },
}))(TableRow)

const useStyles = makeStyles({
  container: {
    marginTop: '2rem',
    
  },  
  table: {
    minWidth: '100%',
    
  },
  link: {
    float: 'right',
    marginRight: '1rem',
    marginTop: '2rem'
  }
})

interface Props {
  children: ReactNode
  link?: string
}

export const DataTableHead = ({ children }: Props) => <TableHead>{children}</TableHead>
export const DataTableBody = ({ children }: Props) => <TableBody>{children}</TableBody>

export const DataTable: React.FC<Props> = ({ children, link }) => {
  const classes = useStyles()

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} aria-label="customized table">
        {children}
      </Table>
    </TableContainer>
  )
}
