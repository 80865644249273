import React from 'react'
import File from 'assets/icons/file.svg'
// import Button from 'components/new/Button'
// import Question from 'components/new/Question'
// import { Classes } from 'pages/CourseView'
import {
  Container,
  DivFile
} from './styles'

// interface Props {
//   test: Classes
//   showResults?: boolean
//   handleSubmitTest: () => void
// }

// const CourseTest: React.FC<Props> = ({
//   test,
//   showResults,
//   handleSubmitTest,
// }) => {
//   const { questions } = test

//   if (!questions?.length) return <p>Sem perguntas para este módulo</p>

//   return (
//     <Container>
//       {showResults && (
//         <ResultBlock>
//           <div></div>
//           <Right>
//             <h3>Muito Bom!</h3>
//             <strong>Você acertou 5/6 questões.</strong>
//             <p>
//               Navegue pelo menu abaixo para visualizar as respostas corretas de
//               cada questão.
//             </p>
//             <Button>Iniciar Módulo 2</Button>
//           </Right>
//         </ResultBlock>
//       )}
//       {/* Implementar a paginação quando ela estiver concluída. (O Renan está fazendo o componente) */}
//       <QuestionsWrapper>
//         {questions.map(question => (
//           <Question
//             title={question.title}
//             description={question.description}
//             options={question.options}
//             showResults={showResults}
//           />
//         ))}
//       </QuestionsWrapper>

//       <StyledButton onClick={handleSubmitTest}>Finalizar Teste</StyledButton>
//     </Container>
//   )
// }

interface Props {
  name?: string,
  description?: string,
  pdfLink?: any,
}

const CourseTest: React.FC<Props> = ({ name, description, pdfLink }) => {

  return (
    <Container>
      <h1>{name}</h1>
      <p>{description}</p>
      <DivFile>
        <a href={pdfLink} target="_blank" rel="noopener noreferrer">
          <img src={File} alt="Icon" />
          <p>{name}</p>
        </a>
      </DivFile>
    </Container>
  )
}

export default CourseTest
