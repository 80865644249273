import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const To = styled(Link)`
  margin: 0 4px;
  font-size: 15;
  font-weight: normal;
`
export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0 8px;

  > svg {
    margin: 0 15px;
  }
`

interface Props {
  active: boolean
}
export const BreadcrumbItem = styled.span<Props>`
  color: ${props => (props.active ? '#4F4F4F' : 'var(--main)')};
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
`
