import React, { useState, useEffect } from 'react'
import {
  ProductCard,
  ProductImg,
  Content,
  ContentTitle,
  ContentCenter,
  Icon,
  Value,
  Label,
  FlexContainer,
  ButtonAcess,
  Subcard,
  ProductLink,
  OptionsBtn
} from './style'
import ThreeDotMenu from 'assets/icons/3dotmenu-icon.svg'
import StudentIcon from 'assets/icons/student-icon.svg'
import VideoCamIcon from 'assets/icons/videocam-icon.svg'
import StarIcon from 'assets/icons/star-icon.svg'
import Rating from '@material-ui/lab/Rating'
import api from 'services/api'
import {
  displayErrorNotification,
  displaySucessNotification,
} from 'utils/displayNotification'
import { Dropdown } from 'antd'

interface Product {
  product: any
}

const Product: React.FC<Product> = ({ product }) => {
  const [productId, setProductId] = React.useState(null)
  const [data, setData] = useState<any>({})

  
  const handleRate = async (value: number) => {
    try {
      let data = {
        product_id: productId,
        rate: value,
      }
      await api.post('avaliation-product', data)
      displaySucessNotification(
        'Curso avaliado com sucesso, recarregando a pagina...',
      )
      setTimeout(() => window.location.reload(true), 800)
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const acessCourse = (e: any) => {
    sessionStorage.clear()
    sessionStorage.setItem('course', e)
  }

  const storage = async(productId: any, producerId: any, checkoutId: any) =>{
    let response = await api.get('profile')

    let data = {
      producer_id: producerId,
      student_id: response.data.user.id,
      product_id: productId,
      checkout_id: checkoutId
    }
    setData(data)
  }

  useEffect(() => {
    sessionStorage.clear()
  }, [])

  const handleCancel = async() => {
    try {
      let response = await api.post('/request-chargeback', data)
        displaySucessNotification(
        response.data.message
      )
    } catch (error) {
        displayErrorNotification(error.response.data.message)
    }
  }

  const dropdownMenu = (
    <Subcard>
      <ProductLink onClick={() => handleCancel()}>
        Solicitar cancelamento
      </ProductLink>
    </Subcard>
  )

  return (
    <>
      {product.map((item: any) => (
        <ProductCard>
          <ProductImg src={item.product_photo} />

          <Content>
            <ContentTitle>
              <h1>{item.product_name}</h1>
              <h2>{item.producer_name}</h2>
            </ContentTitle>

            <ContentCenter>
              <section>
                <FlexContainer>
                  <Icon src={StudentIcon} />
                  <Value>{Math.round(item.porcent_complete)}%</Value>
                </FlexContainer>

                <p>Concluído</p>
              </section>

              <section>
                <FlexContainer>
                  <Icon src={VideoCamIcon} style={{ width: '1.265625rem' }} />
                  <Value>{item.time_video}</Value>
                </FlexContainer>

                <Label>de vídeo</Label>
              </section>

              <section>
                <FlexContainer>
                  <Icon src={StarIcon} />
                  <Rating
                    name="customized-empty"
                    value={item.avaliation}
                    precision={0.1}
                    onChange={(event, value) => {
                      if (value) {
                        handleRate(value)
                        
                      }
                    }}
                    onClick={() => {
                      setProductId(item.product_id)
                    }}
                  />
                </FlexContainer>

                <Label>Avalie e o curso</Label>
              </section>

              <section style={{width: '200px'}}>
                <ButtonAcess
                  to="/course"
                  onClick={() => acessCourse(item.product_id)}
                >
                  Acessar o curso
                </ButtonAcess>
              </section>
            </ContentCenter>
            
          </Content>
          <Dropdown overlay={dropdownMenu}>
            <OptionsBtn>
              <img
                src={ThreeDotMenu}
                alt=""
              />
            </OptionsBtn>
          </Dropdown>
        </ProductCard>
      ))}
    </>
  )
}

export default Product
