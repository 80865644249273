/*
Aqui mostrará todos os afiliados, mostrando sua foto, nome, em qual produto ele está, 
suas vendas, seu tipo e quantas afiliações ele tem.
*/

import React from 'react'
import MenuIcon from '../../../../assets/icons/3dotmenu-icon.svg';
import ArrowIcon from '../../../../components/ArrowIcon'
import AffiliatePhoto from './affiliate-img.svg'
import { Dropdown } from 'antd'
import { ImgContainer } from '../../../../components/DataTable/style'
import {
  DataTableRow,
  DataTableCell,
} from '../../../../components/DataTable'
import { MenuCard } from './style'

const dropdownMenu = (
  <MenuCard>
    <p>Enviar mensagem pelo Whatsapp</p>
    <ArrowIcon />
  </MenuCard>
)

const data = {
  img: AffiliatePhoto,
  name: 'Luiza Santos Pereira',
  affiliateProduct: '(2) Como Economizar para o Futuro; Grande Inve...',
  sales: 56,
  type: 'Pessoa Física',
  affiliations: 4
}

const Affiliate = (/*{ affiliate }*/) => {

  return (
    <DataTableRow>
      <DataTableCell component="th" scope="row">
        <ImgContainer>
          <img src={data.img} alt="" />
        </ImgContainer>
      </DataTableCell>
      <DataTableCell>{data.name}</DataTableCell>
      <DataTableCell>{data.affiliateProduct}</DataTableCell>
      <DataTableCell>
        {data.sales}
      </DataTableCell>
      <DataTableCell>
        {data.type}
      </DataTableCell>
      <DataTableCell>
        {data.affiliations}
      </DataTableCell>
      <Dropdown overlay={dropdownMenu}>
        <DataTableCell style={{ cursor: 'pointer' }}>
          <img src={MenuIcon} alt="" />
        </DataTableCell>
      </Dropdown>
    </DataTableRow>
  )
}

export default Affiliate