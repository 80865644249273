import styled from 'styled-components'

export const ContainerInputSearch = styled.div`
  width: 500.45px;
  height: 40px;
  border-radius: 45px;
  background: #ffffff;
  display: flex;
  justify-content: start;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  align-items: center;
`
