import TableRow from '@material-ui/core/TableRow'
import { FormHandles } from '@unform/core'
import { Dropdown } from 'antd'
import ThreeDotMenu from 'assets/icons/3dotmenu-icon.svg'
import {
  DataTable,



  DataTableBody, DataTableCell,
  DataTableHead, DataTableRow
} from 'components/DataTable'
import Form from 'components/new/Form'
import Modal from 'components/new_components/Modal'
import React, { useEffect, useRef, useState } from 'react'
import api from 'services/api'
import { displayErrorNotification, displaySucessNotification } from 'utils/displayNotification'
import NewSubCategory from '../../../../pages/BackOffice/Config/newSubCategory'
import Input from '../../../Form/Input'
import { InputGroup } from '../../../Form/Input/style'
import { Button, OptionsBtn, ProductLink, Subcard } from './style'


interface Categories {
  categories: {
    id: string
    name: string
    is_active: boolean
  }
};

const Categories: React.FC<Categories> = ({ categories }) => {
  const {
    id,
    name
  } = categories

  const [data, setData] = useState(name || '')
  const [edit, setEdit] = useState(false)

  const formRef = useRef<FormHandles>(null)

  const handleDelete = async (id: any) => {
    try {
      await api.delete(`/delete-category/${id}`)
      displaySucessNotification('Categoria apagada com sucesso! Sua página irá atualizar.')

      setTimeout(() => window.location.reload(true), 250)
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const handleEdit = async () => {
    try {
      await api.put(`/update-category/${id}`, { name: data })
      displaySucessNotification('Categoria editada com sucesso! Sua página irá atualizar.')

      setTimeout(() => window.location.reload(true), 250)
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const [subcategories, setSubcategories] = useState([''])
  const handleSubCategories = async () => {
    try {
      let {data: subCategories} = await api.get(`/subcategories/${id}`)
      setSubcategories(subCategories)
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  useEffect(() => {
    handleSubCategories()
  }, [handleSubCategories])

  const textModal = (
    <>
      <DataTable>
        <DataTableHead>
          <DataTableRow>
            <DataTableCell>Nome da subcategoria</DataTableCell>
          </DataTableRow>
        </DataTableHead>
        <DataTableBody>
          {subcategories.map((item: any) => (
          <>
          <DataTableCell 
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            {item.name}
          </DataTableCell>
          </>
            ))}
        </DataTableBody>
        <DataTableCell><NewSubCategory category_id={id} /></DataTableCell>
      </DataTable>
    </>
  )

  const dropdownMenu = (
    <Subcard>
      <ProductLink onClick={() => setEdit(true)}>Editar</ProductLink>
      <ProductLink onClick={() => handleDelete(id)}><span style={{ color: 'red' }}>Apagar</span></ProductLink>
    </Subcard>
  )

  return (
    <>
      <DataTableRow>
        {
          edit ? (
            <td>
              <Form ref={formRef} onSubmit={handleEdit}>
                <TableRow>
                  <InputGroup>
                    <Input type="text" name="category" onChange={(e: any) => setData(e.target.value)} placeholder="Editar categoria" value={data} />
                    <legend>Novo nome da categoria</legend>
                  </InputGroup>
                  <Button type="submit">✓</Button>
                </TableRow>
              </Form>
            </td>
          ) : (
              <>
                <DataTableCell>{name}<Modal title={'Subcategorias'} text={textModal} iconOpen={false} btnText={'Subcategorias'}></Modal></DataTableCell>
                <Dropdown overlay={dropdownMenu}>
                  <OptionsBtn>
                    <img className='' src={ThreeDotMenu} alt="" />
                  </OptionsBtn>
                </Dropdown>
              </>
            )
        }

      </DataTableRow>
    </>
  )
}

export default Categories