import React from 'react'
import { FormContainer } from './style'

interface Props {
  ref?: any
  onSubmit?: any
}

const Form: React.FC<Props> = ({ ref, children, onSubmit, ...rest }) => {
  return (
    <FormContainer ref={ref} onSubmit={onSubmit} {...rest}>
      {children}
    </FormContainer>
  )
}

export default Form