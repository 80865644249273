/*
Nessa página é a parte funcional da criação de um co-produtor para a criação de curso.
*/

import { Modal, Skeleton } from 'antd'
import Input from 'components/new/Input'
import Check from 'components/new/Input/Checkbox'
import Select from 'components/new/Input/Select'
import Text from 'components/new/Input/Text'
import React, { useContext, useEffect, useState } from 'react'
import api from 'services/api'
import {
  displayErrorNotification,
  displaySucessNotification
} from 'utils/displayNotification'
import displayYupErrorNotification from 'utils/displayYupErrorNotification'
import * as Yup from 'yup'
import { NewProductContext } from '../../../NewProductContext'
import { Button, CoProducerBtn, InputContainer } from './style'

interface CoProducer {
  co_producer_email: string
  comission: string
  contract: any
  comission_percentage: string
}

const createNewCoProducer = (): CoProducer => ({
  co_producer_email: '',
  contract: {},
  comission_percentage: '',
  comission: 'SELL_PRODUCTOR',
})

const CoProducerRegister: React.FC = ({ ...rest }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [contracts, setContracts] = useState<any>(null)
  const [newProductData, setNewProductData] = useContext(NewProductContext)
  const [coProducer, setCoProducer] = useState<CoProducer>(createNewCoProducer)
  const [contractLabelValue, setContractLabelValue] = useState<any>(null)

  const showModal = (e: any) => {
    e.preventDefault()

    if (hasCoProducer()) {
      return
    } else {
      setVisible(true)
    }
  }

  const hideModal = () => {
    setVisible(false)
    reset()
  }

  const getContracts = async () => {
    let { data } = await api.get('/contracts')
    setContracts(data)
  }

  const handleEmail = (evt: any) => {
    evt.preventDefault()

    setCoProducer({
      ...coProducer,
      co_producer_email: evt.target.value,
    })
  }

  const handleComissionPercentage = (evt: any) => {
    let prop = evt.target.id.toString()
    let stringValue = evt.target.value.toString().replace(',', '.')
    let value = parseFloat(stringValue)

    if (value > 100) {
      value = 100
    }

    setCoProducer({
      ...coProducer,
      [prop]: value,
    })
  }

  const handleContract = ({ label, value }: any) => {
    setCoProducer({
      ...coProducer,
      contract: { id: value, name: label },
    })

    setContractLabelValue({ label, value })

    setNewProductData({
      ...newProductData,
      contractName: label,
    })
  }

  const hasCoProducer = () => {
    if (newProductData.co_producers.length === 1) {
      displayErrorNotification('Você já tem um Co-produtor cadastrado.')
      return true
    }

    return false
  }

  const reset = () => {
    setCoProducer(createNewCoProducer())
    setContractLabelValue(null)
  }

  /*const handleComission = (evt: any) => {
    setCoProducer({
      ...coProducer,
      comission: { id: evt.target.value }
    })
  }*/

  const setNewCoProducer = async () => {
    await api.get(
      `/is-email-valid/${coProducer.co_producer_email}`,
    )
    .then(async(res)=>{
      const { data } = res
      try {
        const schema = Yup.object().shape({
          contract: Yup.object().shape({
            id: Yup.number().required('Selecione o tempo de contrato.'),
            name: Yup.string().required('Selecione o tempo de contrato.'),
          }),
          comission_percentage: Yup.number().required(
            'Digite a porcentagem do co-produtor',
          ),
        })
  
        await schema.validate(coProducer)
      } catch (err) {
        displayYupErrorNotification('Algum erro no preenchimento dos dados!')
        return
      }
  
      if (data) {
        setNewProductData({
          ...newProductData,
          co_producers: [coProducer, ...newProductData.co_producers],
        })
  
        reset()
  
        displaySucessNotification('Co-produtor cadastrado com sucesso!')
  
        hideModal()
      } else {
        displayErrorNotification('O email do co-produtor não está cadastrado.')
      }
    })
    .catch((err)=>{
      displayErrorNotification('Algum erro no preenchimento dos dados!')
    })


  }

  useEffect(() => {
    reset()
    getContracts()
  }, [])

  let comissions = [{ id: 'SELL_PRODUCTOR', name: 'Vendas do produtor' }]

  return (
    <>
      <CoProducerBtn onClick={showModal}>Adicionar Co-produtor</CoProducerBtn>

      <Modal
        title="Informações do Co-produtor"
        visible={visible}
        closable={true}
        onCancel={hideModal}
        footer={[
          <Button
            type="button"
            className="cancel"
            onClick={hideModal}
            value="Cancelar"
          />,
          <Button
            type='button'
            className="submit"
            onClick={setNewCoProducer}
            value="Adicionar Co-produtor"
          />,
        ]}
        {...rest}
      >
        <Input title="Email do Co-produtor">
          <Text
            placeholder={'joao_produtor@email.com'}
            name="co_producer_email"
            type="email"
            onBlur={handleEmail}
            onChange={handleEmail}
            value={coProducer.co_producer_email}
          />
        </Input>

        <Input title="Tempo de contrato">
          <Select
            name="contract"
            placeholder={'Selecione o tempo de contrato para o co-produtor'}
            options={contracts}
            onChange={handleContract}
            value={contractLabelValue}
          />
        </Input>

        <InputContainer>
          <h1>De quais vendas você deseja receber comissão?</h1>
          <section className="content">
            {!comissions ? (
              <Skeleton.Button active />
            ) : (
              <Check name="comission" options={comissions} noBorder checked />
            )}
          </section>
        </InputContainer>

        <Input
          title="Porcentagem da comissão (%)"
          style={{ marginTop: '2rem' }}
        >
          <Text
            name="comission_percentage"
            placeholder={'10'}
            type="number"
            onChange={handleComissionPercentage}
            onBlur={handleComissionPercentage}
            value={coProducer.comission_percentage}
          />
        </Input>
      </Modal>
    </>
  )
}

export default CoProducerRegister
