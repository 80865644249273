/*
Aqui é onde se cria um módulo.
*/

import React, { ChangeEvent, useState, useContext } from 'react'
import { Modal } from 'antd'
import { ModalButton } from '../../shared-styles'
import { Form } from '@unform/web'
import Input from 'components/new/Input'
import Text from 'components/new/Input/Text'
import Button from 'components/new/Button'
import { EditProductContext } from '../../../EditProductContext'

interface NewModule {
  name: string
  sequence: number
  classes: []
  tests: []
}

const NewModuleModal: React.FC = () => {
  const [moduleName, setModuleName] = useState<string>('')
  const [visible, setVisible] = useState<boolean>(false)
  const [editProductData, setEditProductData] = useContext(EditProductContext)

  const handleName = (evt: ChangeEvent<HTMLInputElement>) => {
    setModuleName(capitalize(evt.target.value))
  }

  const handleNewModule = () => {
    let newSequence = editProductData.modules.length + 1

    let newModule: NewModule = {
      name: moduleName,
      sequence: newSequence,
      classes: [],
      tests: [],
    }

    setEditProductData({
      ...editProductData,
      modules: [...editProductData.modules, newModule],
    })

    setModuleName('')
    setVisible(false)
  }

  const capitalize = (e: any) => {
    return e.charAt(0).toUpperCase() + e.slice(1)
  }

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        style={{ margin: '2.5rem 0 5rem' }}
      >
        <span>+</span>
        Novo módulo
      </Button>

      <Modal
        title="Adicionar novo módulo"
        visible={visible}
        closable={false}
        onCancel={() => setVisible(false)}
        footer={[
          <ModalButton type="button" onClick={() => setVisible(false)}>
            Cancelar
          </ModalButton>,

          <ModalButton className="submit" onClick={handleNewModule}>
            Adicionar novo módulo
          </ModalButton>,
        ]}
      >
        <Form onSubmit={() => { }}>
          <Input title="Nome do módulo">
            <Text
              name="name"
              placeholder="Digite o nome do módulo"
              value={moduleName}
              onChange={handleName}
              maxLength={100}
            />
          </Input>
        </Form>
      </Modal>
    </>
  )
}

export default NewModuleModal