import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Breadcrumbs } from '@material-ui/core/'
import { Card } from 'components/CardContainer'

export const Title = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  color: #898989;
  margin: 1.52625rem 0;

  span {
    color: var(--main);
  }
`

export const Breadcrumb = styled(Breadcrumbs)`
  &&& {
    margin: 2.345rem 0;
  }
`

export const Link = styled(NavLink)`
  color: ${({ color }) => color ? color : 'var(--main)'};

  &:hover {
    color: var(--main);
  }
`

export const Header = styled.header`
  display: grid;
  grid-template-columns: 0.15fr 1fr;
  grid-column-gap: 2vw;
  @media screen and (max-width: 767px){
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`

export const Subheader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 1.77625rem auto 1.043125rem;

  h1 {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.015625rem;
    color: #898989;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    flex: 2.86;
  }

  section {
    color: #A2A2A2;
    display: flex;
    flex: 2;

    &:not(:first-child),
    &:not(:last-child) {
      margin-left: 3rem;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.025rem;
      margin-left: 0.35rem;
    }
  }
`

export const NewProductBtn = styled(Link)`
  grid-column: 1;
  width: 133px;
  height: 44px;
  padding: 0.4375rem 1.18875rem;
  color: #FFFFFF;
  box-sizing: border-box;
  border-radius: 3rem;
  background-color: var(--main);
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  border: none;
  outline: none;
  display: flex;
  align-items: center;

  span {
    margin-right: 0.41375rem;
    font-size: 1.34375rem;
  }

  &:hover {
    color: #FFFFFF;
    background-color: #e6d627;
  }
  @media screen and (max-width: 767px){
    margin-top: 16px
  }
`

export const Button = styled.button`
    border: 1px solid var(--main);
    box-sizing: border-box;
    border-radius: 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: var(--main);
    background: transparent;
    width: 150px;
    height: 36px;
    cursor: pointer;
    transition: all 0.3s;
    outline: none;
    &:hover{
        border: 1px solid #5C5C5C;
        color: #5C5C5C; 
    }
`

export const SearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-column: 2;
    align-items: center;

  
  @media screen and (max-width: 767px){
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .ant-radio-button-wrapper{
    padding: 0px 12px;
  }
`

export const ProductList = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SmallIcon = styled.img`
  width: 0.875rem;
  height: 0.875rem;
`

export const NoProductMsg = styled.h1`
  text-align: center;
  font-size: 1.25rem;
  margin-top: 3rem;
  color: #292929;
`

export const ErrorScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 1rem;
    color: red;
  }
`

export const CardContainer = styled(Card)`
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  margin-bottom: 5.52475vh;
  @media screen and (max-width: 767px){
    flex-direction: column;
  }
`

export const Content = styled.section`
  width: 84.3%;
  margin: 25px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px){
    flex-direction: column;
    width: 85%;

  }
  .responsiveOptionBtn{
    align-self: flex-start;
    @media screen and (max-width: 767px){
    align-self: flex-end;

    }
  }
  
`

export const Icon = styled.img`
  width: auto;
  height: 1.34125rem;
  margin-right: 0.625rem;
`

export const Flex = styled.div`
  display: flex;
`
export const FlexContainer = styled(Flex)`
  align-items: center;
`

export const ContentCenter = styled(Flex)`
  margin: auto;
  width: 70.16875%;
  justify-content: space-around;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    padding: 20px;
  }@media screen and (max-width: 402px) {
    flex-wrap: wrap;
    padding: 20px;
    width: 100%;
  }
`

export const Value = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin: 0;
  letter-spacing: 0.009375rem;

  color: #5C5C5C;
`

export const Label = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  margin-left: 2rem;
  color: #A2A2A2;
`