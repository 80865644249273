import { Dropdown, Pagination } from 'antd'
import { Card } from 'components/CardContainer'
import { Subcard } from 'pages/Profile/shared-style'
import styled from 'styled-components'

export const FilterDropdown = styled<any>(Dropdown)`
  button {
    position: absolute;
    right: 15px;
    top: 5px;
  }
`
export const SelectForm = styled.div`
    select{
        margin-left: 10px;
        width: 95%;
        border: none;
        background: white;
        margin-bottom: 5px;
        height: 30px;
        outline: none;
    }
`
export const FilterCard = styled(Subcard)`
  width: 26.765625rem;
  height: 10.24625rem;
  flex-wrap: wrap;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.625rem 0 1.18125rem;
    
    p {
      width: max-content;
      margin: 0;
    }

    &:first-child {
      border-bottom: 1px solid #E6E6E6;
    }
  }
`
export const FooterPagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const StyledPagination = styled(Pagination)`
  margin: 1rem auto 2.5rem;

  > .ant-pagination-item-active {
    border-color: var(--main);

    > a {
      color: var(--main);
    }

    &&&:hover {
      border-color: var(--main);
      color: var(--main);
    }
  }
`
export const Head = styled.div`
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  &&& .ant-radio-wrapper{
    width: 100%;
    color: #5C5C5C;
    margin-bottom: 10px;
    display: flex;
  }
  &&& .ant-radio p{
    margin: 0;
  }
`
export const NoStudentMsg = styled.h1`
  text-align: center;
  font-size: 1.25rem;
  margin-top: 3rem;
  color: #292929;
  width: 100%;

`
export const ContainerTable = styled(Card)`
  height: 100%;
  width: 100%;
  .title{
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #5C5C5C;
  }
`
export const SearchContainer = styled.div`
  grid-column: 1;
  margin-right: 0.8125rem;
`