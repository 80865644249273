import styled from 'styled-components'
import ReactSelect from 'react-select'

export const Container = styled(ReactSelect)`
  width: calc(100% - 0.9375rem);
  padding-left: 0.5rem;
  padding-bottom: 0.25rem;
  margin-top: -0.5rem;

  &&& *:not(.css-26l3qy-menu) {
    border: none;
    box-shadow: none;
    background: none;
    cursor: pointer;
  }

  &&& .css-9gakcf-option,
  &&& .react-select__option {
    color: #292929 !important;
    cursor: pointer;
  }

  &&& .css-9gakcf-option {
    background: #d7d7d7 !important;
  }

  &&& .css-1n7v3ny-option {
    background: #e8e8e8 !important;
  }
`