import { FormControlLabel, Radio, TablePagination } from '@material-ui/core'
import { notification } from 'antd'
import SimpleLineChart from 'components/Financial/Graphic'
import FinancialResume from 'components/Financial/ResumFinan'
import TableSells from 'components/Financial/TableSells'
import Form from 'components/new/Form'
import Input from 'components/new/Input'
import Select from 'components/new/Input/Select'
import Search from 'components/new/Search'
import Button from 'components/new_components/button'
import React, { useCallback, useEffect, useState } from 'react'
import api from 'services/api'
import ColorsList from 'utils/colorsList'
import {
  FilterContainer, Flex, FlexContainer,
  GraphContainer,
  HeaderTable, StyledRadio, SubTitle,CardTitle,
  TableContainer
} from './style'

const notificationStyle = {
  right: '1.875rem',
  width: '16.25rem',
  borderRadius: '0.625rem'
}

const gera_cor = () => {
  let hexadecimais = '0123456789ABCDEF';
  let cor = '#';

  // Pega um número aleatório no array acima
  for (var i = 0; i < 6; i++) {
    //E concatena à variável cor
    cor += hexadecimais[Math.floor(Math.random() * 16)];
  }
  return cor;
}

const Financial = () => {
  const [selles, setSelles] = useState<any>([])
  const [totalSelles, setTotalSelles] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [paymentStatusState, setPaymentStatusState] = useState([{}]);
  const [search, setSearch] = useState('produto');


  const [produto, setProduto] = useState('')
  const [products, setProducts] = useState<any>(null)
  const [comprador, setComprador] = useState('')
  const [method, setMethod] = useState('')
  const [status, setStatus] = useState('')

  const [lengthItems, setLengthItems] = useState<any>([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const [colunm, setColunm] = useState('')
  const [order, setOrder] = useState('DESC')

  const [graphContent, setGraphContent] = useState<Curso[]>([])
  const [graphLabel, setGraphLabel] = useState<Array<String>>([])

  const [paymentMethods, setPaymentMethods] = useState([{}]);
  const paymentMethod = async () => {
    setPaymentMethods([{
      id: '',
      name: 'Todos'
    },{
      id: 0,
      name: 'Credito'
    }, {
      id: 1,
      name: 'Boleto'
    }, {
      id: 2,
      name: 'Cortesia'
    }, {
      id: 3,
      name: 'Paypal'
    }])
  }
  const paymentStatus = async () => {
    setPaymentStatusState([{
      id: '',
      name: 'Todos'
    },{
      id: 'PENDING',
      name: 'Pendente'
    }, {
      id: 'PAID',
      name: 'Pago'
    }, {
      id: 'REFUND',
      name: 'Estornado'
    }, {
      id: 'CANCELED',
      name: 'Cancelado'
    }, {
      id: 'EXPIRED',
      name: 'Expirado'
    }])
  }
  const getProducts = async () => {
    let { data } = await api.get('/list-all-by-producer-products')
    setProducts(data.records)
  }
  const filters = useCallback(async () => {
    try {
      let { data } = await api.get(`/latest-sales?orderColunm=${colunm}&order=${order}&page_index=${page}&page_size=${rowsPerPage}&produto=${produto}&costume=${comprador}&payment_method=${method}&payment_status=${status}`)
      setSelles(data.records)
      setLengthItems(data.total_records)
    } catch (error) {
      console.log('Tente novamente')
    }
  }, [colunm, comprador, method, order, page, produto, rowsPerPage, status])

  const searchProduto = async (v: any) => {
    await setProduto(v)
  }

  const searchComprador = async (v: any) => {
    await setComprador(v)
  }

  const tableOrderFilter = async (colunm: string, order: string) => {
    try {
      setOrder(order)
      setColunm(colunm)
      let { data } = await api.get(`/latest-sales?orderColunm=${colunm}&order=${order}&page_index=${page}&page_size=${rowsPerPage}&produto=${produto}&costume=${comprador}&payment_method=${method}&payment_status=${status}`)
      setSelles(data.records)
      setLengthItems(data.total_records)
    } catch (error) {
      console.log('Tente novamente')
    }
  }

  const handleChangePage = async (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    let response = await api.get(`/latest-sales?orderColunm=${colunm}&order=${order}&page_index=${newPage}&page_size=${rowsPerPage}&produto=${produto}&costume=${comprador}&payment_method=${method}&payment_status=${status}`)
    setSelles(response.data.records)
    setPage(newPage)
  };
  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPage(0)
    setRowsPerPage(parseInt(event.target.value, 10))
    let response = await api.get(`/latest-sales?orderColunm=${colunm}&order=${order}&page_index=${page}&page_size=${parseInt(event.target.value, 10)}&produto=${produto}&costume=${comprador}&payment_method=${method}&payment_status=${status}`)
    setSelles(response.data.records)
  };

  const handleMethod = async (v: any) => {
    console.log(v.value)
    setMethod(v.value)
  }

  const handleStatus = async (v: any) => {
    console.log(v.value)

    setStatus(v.value)
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch((event.target as HTMLInputElement).value);
  };

  interface Curso {
    label: string,
    data: [number, number, number, number, number, number, number, number, number, number, number, number],
    fill: boolean,
    backgroundColor?: string,
    borderColor: string
    pointBackgroundColor: string
    pointHoverBackgroundColor: string
    lineTension: number
  }

  interface ReportItem {
    product_id: number,
    product_name: string,
    payment_method: string,
    payment_status: string,
    created_at: Date,
    amount: number
  }

  const handleGraph = useCallback(async () => {
    try {


      const response = await api.get('/profile')
      const dataResp = await api.post('/report-generate-report-no-buffer', { producer_id: response.data.user.id })

      let cursos: Array<Curso> = []
      let labels: Array<string> = []
      await dataResp.data.data.map((item: ReportItem) => {

        let possuiCurso = 0;
        const date = new Date(item.created_at)
        const mes = date.getMonth()

        if (item.payment_status === "Pago") {
          cursos.map((cursoItem: Curso) => {

            if (cursoItem.label === item.product_name) {
              cursoItem.data[mes]++;
              possuiCurso++;
            }
          })
        }
        if (possuiCurso === 0) {
          if (item.payment_status === "Pago") {
            cursos.push(
            // para teste
            //   {
            //   label: 'a',
            //   data: [10, 15, 2, 50, 84, 21, 8, 45, 64, 98, 12, 45],
            //   fill: (cursos.length > 0) ? false : true,
            //   backgroundColor: '#84a846',
            //   borderColor: '#84a846',
            //   pointBackgroundColor: '#fff',
            //   pointHoverBackgroundColor: '#f5f5f5',
            //   lineTension: 0
            // },
            {
              label: item.product_name,
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              fill: (cursos.length > 0) ? false : true,
              backgroundColor: (ColorsList[cursos.length] ? ColorsList[cursos.length] : gera_cor()) + "90",
              borderColor: ColorsList[cursos.length] ? ColorsList[cursos.length] : gera_cor(),
              pointBackgroundColor: '#fff',
              pointHoverBackgroundColor: '#f5f5f5',
              lineTension: 0
            })
          }
        }

      })

      setGraphContent(cursos);
      setGraphLabel(labels);

    } catch (err) {
      console.log({ Message: "Erro", err })
    }

  }, [])

  useEffect(() => {
    const handleValues = async () => {
      try {
        let { data } = await api.get('/latest-sales', {
          params: { page_index: pageNumber },
        })
        setSelles(data.records)
        setPageNumber(data.page_index)
        setPageSize(data.page_size)
        setTotalSelles(data.total_students)

      } catch (err) {
        notification.error({
          message: err.response.data.message,
          placement: 'bottomRight',
          style: notificationStyle
        })
      }
    }
    paymentMethod()
    paymentStatus()
    handleGraph()
    getProducts()
    filters()
    // handleValues()

  }, [filters, handleGraph, pageNumber])

  return (
    <>
      

      <FlexContainer>
        <FinancialResume col={4} />
        <GraphContainer>
          <CardTitle>Graficos Financeiros</CardTitle>
          <SimpleLineChart
            labels={["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]}
            content={graphContent} />
        </GraphContainer>
      </FlexContainer>
      <TableContainer>
        <FilterContainer>
          <p className="title">
            Útimas vendas
                </p>
          <Form onSubmit={() => console.log('website will remember this')}>
            <HeaderTable>
              <StyledRadio style={{ display: 'flex', flexDirection: 'column' }} aria-label="pesquisa" name="pesquisa" value={search} onChange={handleChange}>
                <FormControlLabel value="comprador" control={<Radio color="primary" />} label="Comprador" />
                <FormControlLabel value="produto" control={<Radio />} label="Produto" />
              </StyledRadio>
              <div>
                <Button onClick={() => filters()}>Filtrar</Button>
              </div>
            </HeaderTable>
            {search === 'produto' ?
              // <Search
              //   placeholder="Busque pelo nome do produto"
              //   handleSearch={(e: any) => searchProduto(e.target.value)}
              // /> 
              <Input title="Produto">
                <Select
                  options={products}
                  name="product"
                  placeholder="Selecione o produto"
                  onChange={(e: any) => { searchProduto(e.label) }}
                />
              </Input>
              : search === 'comprador' ?
                <Search
                  placeholder="Busque pelo nome do comprador"
                  handleSearch={(e: any) => searchComprador(e.target.value)}
                /> : false
            }
            <Flex>
              <Input width={'280px'} title="Método de pagamento">
                <Select
                  options={paymentMethods}
                  name="paymentMethod"
                  placeholder="Selecione o método"
                  onChange={(e: any) => handleMethod(e)}
                />
              </Input>
              <Input width={'280px'} title="Status de pagamento">
                <Select
                  options={paymentStatusState}
                  name="paymentStatus"
                  placeholder="Selecione o status"
                  onChange={(e: any) => handleStatus(e)}
                />
              </Input>
            </Flex>
          </Form>
        </FilterContainer>

        <TableSells products={selles} tableOrderFilter={tableOrderFilter} />
        <TablePagination
          component="div"
          count={lengthItems}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>


    </>
  )
}

export default Financial