import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  background-color: #e6e6e6;
  border-radius: 10px;
  height: 10px;
  width: 100%;
`

export const Fill = styled.div<{ amount: number }>`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  width: ${props => `${props.amount}%`};
  background-color: var(--main);
  border-radius: 10px;
`

export const Trophy = styled.div`
  position: absolute;
  display: flex;
  aligin-items: center;
  justify-content: center;
  left: 70%;
  top: 50%;
  transform: translateY(-50%);
  width: 42.6px;
  height: 42.6px;
  border-radius: 50%;
  background: #D8C410;
`
