import React, { ReactNode } from 'react'
import { SearchContainer, Input } from './style'
import SearchIcon from 'assets/icons/search-icon.svg'

type SearchProps = {
  callbackSearch?: any,
  children?: ReactNode,
  placeholder: string
  handleSearch?: any
}

const Search = ({ callbackSearch, children, placeholder, handleSearch }: SearchProps) => {


  return (
    <SearchContainer>
      <img src={SearchIcon} alt="Ícone de busca" />
      <Input
        onChange={handleSearch}
        placeholder={placeholder} />
      {
        children ? children : ''
      }
    </SearchContainer>
  )
}

export default Search