/*
Nessa página, você poderá gerar o relatório em uma tabela xls (excel) das suas vendas.
*/

import React from 'react'
import { Row } from 'antd'
import ReportsList from 'containers/ReportsList'
import Header from 'components/HeaderPromotor'
import { Title } from './style'
import { Container, FlexContainer } from 'GlobalStyles'
import Footer from 'containers/Footer'

const Reports = () => {
  return (
    <>
    
    <Container>
      <Header SearchHeader />
      <FlexContainer>

      </FlexContainer>
      <Row style={{ marginTop: '23px' }}>
        <Title>Relatórios</Title>
      </Row>
      <Row>
        <ReportsList />
      </Row>
    </Container>
    <Footer />
    </>
  )
}

export default Reports