import styled from 'styled-components'
// import { Subcard } from 'pages/Profile/shared-style'

/*
export const MenuCard = styled(Subcard)`
  width: 21.925625rem;
  height: 4.945625rem;
  justify-content: space-between;
  cursor: pointer;

  img {
    transform: rotate(-90deg);
  }
` */

export const Subcard = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.1.5625rem;
  color: #5c5c5c;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border: 0.0625rem solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 0.625rem;
  padding: 0.5rem;

  & a:nth-child(2) {
    border-top: 0.0625rem solid #e0e0e0;
    border-bottom: 0.0625rem solid #e0e0e0;
  }

  & a:nth-child(3) {
    border-bottom: 0.0625rem solid #e0e0e0;
  }
`

export const Href = styled.a`
  font-size: 0.9rem;
  color: #292929;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  padding: 1rem;

  &:hover {
    color: var(--main);
  }
`