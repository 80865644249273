import React, { useEffect, useState, useRef, useCallback } from 'react'
import api from 'services/api'
import DoughnutChart from 'components/Financial/ProductResume'
import { TableContainer, FlexContainer, Container, Msg, ContainerDown, ContentForm, Register, ReportInfo } from './style'
import Select from 'components/new/Input/Select'
import Input from 'components/new/Input'
import { AiOutlineSearch, AiOutlineDownload } from "react-icons/ai"
import { SubmitHandler } from '@unform/core'
import * as Yup from 'yup'
import displayYupErrorNotification from 'utils/displayYupErrorNotification'
import { CircularProgress } from '@material-ui/core/'
import Button from 'components/new_components/button'
import { ContactPhoneSharp } from '@material-ui/icons'
import { report } from 'process'

interface FormData {
  product: any
}

interface Relatory {
  totalSells: number
  totalAmount: number
  totalProducerNet: number
  totalCardItems: number
  totalCardPaidItems: number
  totalCardRefundedItems: number
  totalCardExpiredItems: number
  totalBilletItems: number
  totalBilletPaidItems: number
  totalBilletRefundedItems: number
  totalBilletExpiredItems: number
  totalBilletPendingItems: number
  totalFreeItems: number
  totalBumpItems: number
  totalUpsellSells: number
  financialResume: {
    pending: {
      amount: number,
      count: number,
    },
    paid: {
      amount: number,
      count: number,
    },
    canceled: {
      amount: number,
      count: number,
    },
    refunded: {
      amount: number,
      count: number,
    }
  }
}

interface ReceivableItem {
  id: string,
  resource: string,
  status: string,
  recipient: string,
  transaction: string,
  split_rule: string,
  installment: string,
  liable: boolean,
  amount: string,
  gross_amount: string,
  anticipation_fee: string,
  paid_at: string,
  created_at: string,
  transaction_created_at: string,
  canceled_at: string,
  expected_on: string,
  authorization_code: string,
  id_original_receivable: boolean,
  prepaid: boolean
}

const ReportsList = () => {
  const [reports] = useState<any>([])
  const [id, setId] = useState<any>()
  const [products, setProducts] = useState<any>(null)
  const [selectedProduct, setSelectedProduct] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [productId, setProductId] = useState<number>(0)
  const [productName, setProductName] = useState<string>('')
  const [selectedReport, setSelectedReport] = useState<any>(null)

  const [infosRelatory, setInfosRelatory] = useState<Relatory>({
    totalSells: 0,
    totalAmount: 0,
    totalProducerNet: 0,
    totalCardItems: 0,
    totalCardPaidItems: 0,
    totalCardRefundedItems: 0,
    totalCardExpiredItems: 0,
    totalBilletItems: 0,
    totalBilletPaidItems: 0,
    totalBilletRefundedItems: 0,
    totalBilletExpiredItems: 0,
    totalBilletPendingItems: 0,
    totalFreeItems: 0,
    totalBumpItems: 0,
    totalUpsellSells: 0,
    financialResume: {
      pending: {
        amount: 0.0,
        count: 0,
      },
      paid: {
        amount: 0.0,
        count: 0,
      },
      canceled: {
        amount: 0.0,
        count: 0,
      },
      refunded: {
        amount: 0.0,
        count: 0,
      }
    }
  })

  const reportsList = async () => {
    try {
      let id = await api.get('profile')
      setId(id.data.user.id)
    } catch (error) { }
  }
  interface ProductItem{
    avaliation: number
    currentstatus: string
    description: string
    is_active: boolean
    name: string
    photo: string
    product_id: number
    time_video: number
    total_sells: number
    total_students: number
  }
  const getProducerProducts = async () => {
    let response = await api.get(`/list-all-by-producer-products`)

    const produtos = response.data?.records.map((item:ProductItem) => {

      item['id'] = item.product_id
      return item
    })
    if(produtos){
      setProducts(produtos)
    }
  }

  const handleProducts = async ({value, label}: any) => {
    setProductId(value)
    setProductName(label)
    const obj = {
      totalSells: 0,
      totalAmount: 0,
      totalProducerNet: 0,
      totalCardItems: 0,
      totalCardPaidItems: 0,
      totalCardRefundedItems: 0,
      totalCardExpiredItems: 0,
      totalBilletItems: 0,
      totalBilletPaidItems: 0,
      totalBilletRefundedItems: 0,
      totalBilletExpiredItems: 0,
      totalBilletPendingItems: 0,
      totalFreeItems: 0,
      totalBumpItems: 0,
      totalUpsellSells: 0,
      financialResume: {
        pending: {
          amount: 0.0,
          count: 0,
        },
        paid: {
          amount: 0.0,
          count: 0,
        },
        canceled: {
          amount: 0.0,
          count: 0,
        },
        refunded: {
          amount: 0.0,
          count: 0,
        }
      }
    }

    setLoading(true)
    const {data: reports} = await api.get(`/report-resume-financial/${value}`)
    
    reports.forEach((checkout: any) => {
      checkout.receivables.map((receivable: ReceivableItem) => {

        obj.financialResume[receivable.status]['count']++;
        obj.financialResume[receivable.status]['amount'] = obj.financialResume[receivable.status]['amount'] + parseFloat(receivable.amount)
      })
    })


    obj['totalSells'] = reports.length
    reports.forEach((value: any) => {
      
      switch (value.payment_method) {
        case 0:
          value.payment_method = 'Credito'
          break;
        case 1:
          value.payment_method = 'Boleto'
          break;
        case 2:
          value.payment_method = 'Cortesia'
          break;
      
        default:
          value.payment_method = 'Paypal'
          break;
      }



      obj['totalAmount'] = obj.totalAmount + value.original_amount

      value.transactions.map((transaction: any) => {
        obj['totalProducerNet'] = obj.totalProducerNet + parseFloat(transaction.productor_amount ? transaction.productor_amount : 0 )
      })


      if(value.bump_name){
        obj['totalBumpItems'] = obj.totalBumpItems + 1
      }

        obj['totalUpsellSells'] = value.upsell_sells
      
      if (value.payment_method === 'Credito') {
        obj['totalCardItems'] = obj.totalCardItems + 1
        switch (value.payment_status) {
          case 'PAID':
            obj['totalCardPaidItems'] = obj.totalCardPaidItems + 1
            break;
          case 'REFUNDED':
            obj['totalCardRefundedItems'] = obj.totalCardRefundedItems + 1
            break;
          case 'EXPIRED':
            obj['totalCardExpiredItems'] = obj.totalCardExpiredItems + 1
            break;
          default:
            break;
        }
      } else if (value.payment_method === 'Boleto') {
        obj['totalBilletItems'] = obj.totalBilletItems + 1

        switch (value.payment_status) {
          case 'PAID':
            obj['totalBilletPaidItems'] = obj.totalBilletPaidItems + 1
            break;
          case 'REFUNDED':
            obj['totalBilletRefundedItems'] = obj.totalBilletRefundedItems + 1
            break;
          case 'EXPIRED':
            obj['totalBilletExpiredItems'] = obj.totalBilletExpiredItems + 1
            break;
          case 'PENDING':
            obj['totalBilletPendingItems'] = obj.totalBilletPendingItems + 1
            break;
          default:
            break;
        }
      } else if (value.payment_method === 'Cortesia') {
        obj['totalFreeItems'] = obj.totalFreeItems + 1

      }
    })

    setSelectedReport(reports)
    setInfosRelatory(obj)
    handleVisible()
    setLoading(false)
  }

  const generateRelatory = async () => {
    let newData = {
      producer_id: id,
    }

    const { data: { data } } = await api.post(`/report-generate-report/?produto=${productName}`, newData)

    var byteArray = new Uint8Array(data.data);
    var a = window.document.createElement('a');

    a.href = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
    a.download = `relatorio-financeiro-${Date.now()}.xlsx`;

    document.body.appendChild(a)
    a.click();

    document.body.removeChild(a)
  }
  const handleVisible = () => {
    loading ? setIsVisible(false) : setIsVisible(true)
  }

  const download = async () => {
    await api.get(`list-checkout-report-excel/${id}`, { responseType: 'blob' }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'relatorio.xls') //or any other extension
      document.body.appendChild(link)
      link.click()
    })
  }

  useEffect(() => {
    getProducerProducts()
    reportsList()
    
  }, [])
  
  return (
    <>
      <FlexContainer>
        <TableContainer>
          <h3>Relatório por Produto</h3>
          <div style={{ display: 'inline' }}>
            <ContentForm>
              <Input title="Produto a selecionar">
                <Select
                  options={products}
                  name="product"
                  placeholder="Selecione o produto"
                  onChange={handleProducts}
                  defaultValue={selectedProduct?.product}
                  id="selectForm"
                />
              </Input>

              {loading ? (
                <CircularProgress color="inherit" size={'1.1rem'} />
              ) : (
                  <></>
                )
              }
            </ContentForm>
          </div>

          {isVisible ?
            <ReportInfo>
              <br/>
              <section>
              <h1 className="productName">{productName}</h1>
                <h3>Total de Vendas: <span className="totalNumber">{infosRelatory.totalSells}</span></h3>
              </section>
              <div className="boxSells">
                <h3 className="paymentMethod">Faturamento: <span className="totalNumber">R$ {(infosRelatory.totalAmount).toFixed(2)}</span></h3>
                <section>
                <p><span className="subTotalNumber">{infosRelatory.financialResume.paid.count}</span><br></br>Parcelas recebidas</p>
                <p><span className="subTotalNumber">R$ {(infosRelatory.financialResume.paid.amount).toFixed(2)}</span><br></br>Já Depositado</p>
                <p><span className="subTotalNumber">R$ {(infosRelatory.totalProducerNet).toFixed(2)}</span><br></br>Total líquido produtor</p>
                </section>
              </div>
              <br/>
              <div className="boxSells">
                <h3 className="paymentMethod">À Receber: <span className="totalNumber">R$ {(infosRelatory.financialResume.pending.amount).toFixed(2)}</span></h3>
                <section>
                <p><span className="subTotalNumber">{infosRelatory.financialResume.pending.count}</span><br></br>Parcelas à receber</p>
                <p><span className="subTotalNumber">{infosRelatory.financialResume.canceled.count}</span><br></br>Parcelas canceladas</p>
                <p><span className="subTotalNumber">{infosRelatory.financialResume.refunded.count}</span><br></br>Parcelas estornadas</p>
                </section>
              </div>
              <br/>
              <div className="boxSells">
                <h3 className="paymentMethod">Cartão: <span className="totalNumber">{infosRelatory.totalCardItems}</span> vendas</h3>
                <section>
                <p><span className="subTotalNumber">{infosRelatory.totalCardExpiredItems}</span><br></br>Expirados</p>
                <p><span className="subTotalNumber">{infosRelatory.totalCardRefundedItems}</span><br></br>Estornados</p>
                <p><span className="subTotalNumber">{infosRelatory.totalCardPaidItems}</span><br></br>Pagos</p>
                </section>
              </div>
              <br/>
              <div className="boxSells">
                <h3 className="paymentMethod">Boleto: <span className="totalNumber">{infosRelatory.totalBilletItems}</span> vendas</h3>
                <section>
                <p><span className="subTotalNumber">{infosRelatory.totalBilletExpiredItems}</span><br></br>Expirados</p>
                <p><span className="subTotalNumber">{infosRelatory.totalBilletRefundedItems}</span><br></br>Estornados</p>
                <p><span className="subTotalNumber">{infosRelatory.totalBilletPaidItems}</span><br></br>Pagos</p>
                <p><span className="subTotalNumber">{infosRelatory.totalBilletPendingItems}</span><br></br>Pendentes</p>
                </section>
              </div>
              <br/>
              <div className="boxSells">
                <h3 className="paymentMethod">Order Bump: <span className="totalNumber">{infosRelatory.totalBumpItems}</span> vendas</h3>
              </div>
              <br/>
              <div className="boxSells">
                <h3 className="paymentMethod">UpSell: <span className="totalNumber">{infosRelatory.totalUpsellSells}</span> vendas</h3>
              </div>
              <br/>
              <div className="boxSells">
                <h3 className="paymentMethod">Cortesias: <span className="totalNumber">{infosRelatory.totalFreeItems}</span></h3>
              </div>
              <br /><br />
                <Button onClick={generateRelatory} style={{ float: 'right' }}><AiOutlineDownload />Baixar relatório</Button>
              
              </ReportInfo>
            : null}

        </TableContainer>
        <TableContainer>
          <h3>Relatório de todos produtos</h3>
          <p>
            Clique no botão "Download" para gerar a planilha de relatórios.
            <br />
            Você precisa ter o Microsoft Excel para abrir a planilha.
          </p>
          <Button onClick={() => download()}>Download</Button>

        </TableContainer>

      </FlexContainer>
      <Container>
        {/* <TableContainer>
        <DoughnutChart labels={["Curso"]} content={graphContent} titleText="Relátorio de Total de Vendas" />
      </TableContainer> */}

      </Container>
      {/* {reports && totalProducts > pageSize && (
        <StyledPagination
          defaultCurrent={pageNumber + 1}
          total={totalProducts}
          onChange={handlePageNumber}
        />
      )} */}

      {!reports ? (
        <Msg>
          <h3>Não tem nenhum relatorio ainda...</h3>
        </Msg>
      ) : null}
    </>
  )
}

export default ReportsList
