/*
Aqui fica o cartão do produto onde o aluno vê, contendo a foto do curso, nome do curso
e o autor do curso.

*/

import ArrowIcon from 'components/ArrowIcon'
import React from 'react'
import { AiFillStar } from 'react-icons/ai'
import displayMonetaryValue from 'utils/displayMonetaryValue'
import {
  AuthorImage,
  AuthorName, CardImageFooter,




  ProductAuthor, ProductAvaliation, ProductCardContainer,





  ProductCardDetailsContainer, ProductCardImageContainer,
  ProductImg,








  ProductLink, ProductName, ProductPrice
} from './style'

interface Product {
  product_id: number
  product_name: string
  product_photo: string
  product_value: number
  product_rate: number
  teacher_name: string
  teacher_last_name: string
  teacher_photo: string
  avaliation: number
}

const ProductCard = (product: Product): JSX.Element => (
  <ProductCardContainer key={product.product_id}>
    <ProductCardImageContainer>
      <ProductImg image={product.product_photo} />

      <CardImageFooter>
        <ProductAvaliation>
          {product.avaliation ? product.avaliation : '-'}
          <AiFillStar size={15} />
        </ProductAvaliation>
        <ProductPrice>
          {displayMonetaryValue(product.product_value)}
        </ProductPrice>
      </CardImageFooter>
    </ProductCardImageContainer>

    <ProductCardDetailsContainer>
      <ProductName>{product.product_name}</ProductName>

      <ProductAuthor>
        <AuthorImage image={product.teacher_photo} />
        <AuthorName>
          {`${product.teacher_name}`}
        </AuthorName>
      </ProductAuthor>

      <ProductLink
        target="_blank"
        href={`https://checkout.skycode.com.br/?product_id=${product.product_id}`}
      >
        Comprar
        <ArrowIcon rotateDeg={-90} />
      </ProductLink>
    </ProductCardDetailsContainer>
  </ProductCardContainer>
)

export default ProductCard
