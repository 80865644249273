import React from 'react';
import Header from '../../components/HeaderPromotor/index';

interface Props {
    Search: boolean
  }

const HeaderContainer: React.FC<Props> = ({Search}) =>{
    return(
        <>
            {Search === true 
                ?   
                    <Header SearchHeader={true} />
                :
                    <Header SearchHeader={false} />
            }
        </>
    )
}

export default HeaderContainer;