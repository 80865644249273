import React from 'react'
import {
    ConquestCard,
    NoSelect
} from './style'

interface Props{
    img: string
    nivel: string
    description: string
}

const ConquestList: React.FC<Props> = ({img, nivel, description}) =>{


    return(

                        <>
                            <ConquestCard>
                                <img src={img} alt=""/>
                                <strong>
                                    {nivel}
                                </strong>
                                    {description}
                            </ConquestCard>
                            <NoSelect>
                                <input type="checkbox" id="1"/>
                                <label htmlFor="1">Não mostrar no perfil</label>
                            </NoSelect>
                        </>

    )
}

export default ConquestList;