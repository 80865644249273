/*
Aqui fica a página de quando o usuário esquece a senha e deseja recuperar a conta
*/

import { FormHandles, SubmitHandler } from '@unform/core'
import { notification } from 'antd'
import React, { useRef, useState } from 'react'
import { displayErrorNotification } from 'utils/displayNotification'
import * as Yup from 'yup'
import {
  Container,
  FormContainer,
  MainContainer,
  PrimaryButton
} from '../../components/Form/Container'
import { StyledLink } from '../../components/Link'
import Logo from '../../components/Logo'
import api from '../../services/api'
import history from '../../routes/history'

const notificationStyle = {
  right: '1.875rem',
  width: '16.25rem',
  borderRadius: '0.625rem',
}


const ValidateEmail = () => {
  const [loading, setLoading] = useState(false)
  const formRef = useRef<FormHandles>(null)

    const handleVerified = () => {
      setLoading(true)

      try {
        //await api.post('/validate-email', body)

        notification.success({
          message: 'Seu e-mail foi verificado com sucesso!',
          description:
            'Obrigado por confirmar seu e-mail! Estamos te redirecionando para a página de login ;)',
          placement: 'bottomRight',
          style: notificationStyle
        })
        history.push('/login')

        setLoading(false)
      } catch (err) {
        const validationErrors: any = {}

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })

          if (formRef && formRef.current)
            formRef.current.setErrors(validationErrors)
            
            setLoading(false)
            return
        }

        displayErrorNotification(err.response.data.message)
        setLoading(false)
      }
    }

  //handleVerified()

  return (
    <MainContainer>
      <Container>
        <Logo />
        <FormContainer style={{ marginTop: '3rem' }}>
          <h1 style={{ color:'#FFF', fontSize: 18, textAlign:'center', padding: 12 }} >Seu e-mail foi confirmado! Agora você pode acessar nossa plataforma :)</h1>
          <StyledLink to='/login'>
            <PrimaryButton>
              Voltar para Login
            </PrimaryButton>
          </StyledLink>
        </FormContainer>
      </Container>
    </MainContainer>
  )
}

export default ValidateEmail
