import React from 'react'
import MenuIcon from 'assets/icons/3dotmenu-icon.svg'
import Alert from 'assets/icons/alert.svg'
import Swal from 'sweetalert2'
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import Moment from 'react-moment';
import { Dropdown } from 'antd'
import api from 'services/api'
import {
  DataTableRow,
  DataTableCell,
} from 'components/DataTable'
import { Subcard, Href } from './style'
import {
  displayErrorNotification,
  displaySucessNotification
} from 'utils/displayNotification'

interface Student {
  student: {
    product_name: string
    amount: any
    bump_name: string
    bump_discount: number
    bump_value: number
    lead_name: string
    lead_email: string
    lead_cell_phone: string
    payment_method: number
    payment_status: string
    created_at: string
    product_sku: string
    active: number
    supplier_order: string
    market_place_shipping: string
    id: number
    user_cell_phone: string
    user_email: string
    user_name: string,
    co_producer_cell_phone: string
    co_producer_name: string
    co_producer_last_name: string
  }
}

const Student: React.FC<Student> = ({ student }) => {
  const {
    product_name,
    amount,
    bump_name,
    bump_discount,
    bump_value,
    lead_name,
    lead_cell_phone,
    lead_email,
    payment_method,
    payment_status,
    created_at,
    product_sku,
    supplier_order,
    market_place_shipping,
    active,
    id,
    user_cell_phone,
    user_email,
    user_name,
    co_producer_cell_phone,
    co_producer_name,
    co_producer_last_name
  } = student

  // const handleDate = () =>{

  //   let value = new Date(created_at)
  //   value.Moment().format("MMM Do YY")


  //   console.log(value)
  // }

  // useEffect(() => {
  //   handleDate()
  // }, [])

  const paymentStatus = () => {
    switch (payment_status) {
      case 'PENDING': return 'Pendente';
      case 'PAID': return 'Pago';
      case 'EXPIRED': return 'Expirado';
      case 'REFUND': return 'Estornado';
      case 'CANCELED': return 'Cancelado';
    }
  }

  const paymentMethod = () => {
    switch (payment_method) {
      case 0: return 'Cartão';
      case 1: return 'Boleto';
      case 2: return 'Cortesia';
      case 3: return 'Paypal';
    }
  }

  const handleOrder = async () => {
    try {
      const isOrder = await api.get(`get-order/${id}`)

      if(isOrder.data.isOrder) {
        var orders: any = [];
        
        isOrder.data.supplierOrder.map((el: any) => (
          orders.push(el.order_number)
        ))
        
        Swal.fire({
          title: 'Deseja criar um novo pedido?',
          html: `Já existe(m) pedido(s) para a venda - ${id} </br></br> ${orders.join('/')}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33', 
          confirmButtonText: 'Yes!'
        }).then(async (result) => {
          if(result.value){
            const response = await api.get(`generate-order/${id}`, { responseType: 'blob' })

            /*
            const blob = new Blob([response.data], { type: 'image/jpg' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${id}.jpg`;
            link.click();
            */
            
            displaySucessNotification(
              'Pedido gerado com sucesso.',
            )
          }
        })
      }else{
        const response = await api.get(`generate-order/${id}`, { responseType: 'blob' })

        /*
        const blob = new Blob([response.data], { type: 'image/jpg' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `pedido.jpg`;
        link.click();
        */
        
        displaySucessNotification(
          'Pedido gerado com sucesso.',
        )
      }
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const handleFinalizeOrder = async () => {
    try {
      await api.get(`finalize-order/${id}`)
      
      displaySucessNotification(
        'Pedido finalizado com sucesso.',
      )
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const handlePixPayment = async () => {
    try {
      // await api.get(`finalize-order/${id}`)

      console.log(id)
      
      displaySucessNotification(
        'Transferência realizada com sucesso.',
      )
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const handleShipping = async () => {
    try {
      const response = await api.get(`get-shipping/${id}`, { responseType: 'blob' })

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `etiqueta.pdf`;
      link.click();
         
      displaySucessNotification(
        'Etiqueta gerada com sucesso.',
      )
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const handleDownloadOrderShipping = async () => {
    try {
      const response = await api.get(`get-download-order-shipping/${id}`, { responseType: 'blob' })

      const blob = new Blob([response.data], { type: 'aoctet/stream' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${id}.zip`;
      link.click();
      
      displaySucessNotification(
        'Download executado com sucesso.',
      )
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const dropdownMenu = (
    <Subcard>
      <>
        <Href onClick={() => {
          window.open(`https://wa.me/55${co_producer_cell_phone.replace(/[^0-9.]/g, '')}?text=${encodeURI('Olá, segue mais uma nova venda, muito obrigado.')}`, '_blank')
        }}>
          Enviar mensagem pelo Whatsapp
        </Href>
      </>
      <>
        <Href onClick={() => handleOrder()}>
          Gerar pedido
        </Href>
      </>
      <>
        <Href onClick={() => handleShipping()}>
          Gerar etiqueta
        </Href>
      </>
      <>
        <Href onClick={() => handleFinalizeOrder()}>
          Finalizar pedido
        </Href>
      </>
      <>
        <Href onClick={() => handlePixPayment()}>
          Transferir pagamento para o fornecedor (Via PIX)
        </Href>
      </>
      <>
        <Href onClick={() => handleDownloadOrderShipping()}>
          Download pedido/etiqueta
        </Href>
      </>
    </Subcard>
  )
  return (
    <>
      <DataTableRow >
        <DataTableCell component="th" scope="row">
          {id}
          { /* payment_status === "PENDING" ? <img src={Alert} alt="alerta" /> : <> </> */ }
        </DataTableCell>
        <DataTableCell >{product_name}</DataTableCell>
        <DataTableCell>R$ {amount}</DataTableCell>
        <DataTableCell>
          {user_name}
        </DataTableCell>
        <DataTableCell>
          {user_email}
        </DataTableCell>
        <DataTableCell>
          {lead_cell_phone === null ? "Não cadastrado" : lead_cell_phone}
        </DataTableCell>
        <DataTableCell>
          {
            paymentMethod()
          }
        </DataTableCell>
        <DataTableCell>
          {
            paymentStatus()
          }
        </DataTableCell>
        <DataTableCell>
          {
            supplier_order ? 
            <Checkbox checked={ true } style={{ color: '#45c166', fontWeight: 'bold' }}/> 
            :
            <Checkbox checked={ false } disabled /> 
          }
        </DataTableCell>
        <DataTableCell>
          {
            market_place_shipping ? 
            <Checkbox checked={ true } style={{ color: '#45c166', fontWeight: 'bold' }}/> 
            :
            <Checkbox checked={ false } disabled /> 
          }
        </DataTableCell>
        <DataTableCell>
          {
            active ? 
            <Checkbox checked={ true } style={{ color: '#45c166', fontWeight: 'bold' }}/> 
            :
            <Checkbox checked={ false } disabled /> 
          }
        </DataTableCell>
        <DataTableCell>
          {co_producer_name} {co_producer_last_name} 
        </DataTableCell>
        <DataTableCell>
          <Moment date={created_at} format="DD/MM/YYYY" />
        </DataTableCell>
        <Dropdown overlay={dropdownMenu}>
          <DataTableCell style={{ cursor: 'pointer' }}>
            <img src={MenuIcon} alt="" />
          </DataTableCell>
        </Dropdown>
      </DataTableRow>
      {
        bump_name ? (
          <DataTableRow style={{ backgroundColor: '#e9f8ce', color: '#000', fontWeight: 'bold' }}>
            <DataTableCell component="th" scope="row">
              OrderBump
              </DataTableCell>
            <DataTableCell >{bump_name}</DataTableCell>
            <DataTableCell>R$ {bump_value - (bump_value * (bump_discount / 100))}</DataTableCell>
            <DataTableCell>
              -
            </DataTableCell>
            <DataTableCell>
              -
            </DataTableCell>
            <DataTableCell>
              -
            </DataTableCell>
            <DataTableCell>
              -
            </DataTableCell>
            <DataTableCell>
              -
            </DataTableCell>
            <DataTableCell>
              -
            </DataTableCell>
          </DataTableRow>
        ) : (<></>)
      }
    </>
  )
}

export default Student