import React, { ReactNode } from 'react'
import { FilterButton } from './style'
import { IoIosArrowDown } from 'react-icons/io'

type FilterProps = {
  children?: ReactNode,
  onClick?: any
}

const Filter = ({ children, onClick }: FilterProps) => {
  return (
    <FilterButton onClick={onClick ? onClick : (e) => e.preventDefault()}>
      {children}
      <IoIosArrowDown size={17.33} />
    </FilterButton>
  )
}

export default Filter