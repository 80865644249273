import React from 'react'
import {
    Complement,
    TxtComplement,
} from './style'
import Progress from 'assets/icons/trending.svg'
import Cam from 'assets/icons/videocam.svg'

interface Props {
    active: any
    hours: any
}

const ProgressCard: React.FC<Props> = ({active, hours}) => {


    return (
        <>
            <Complement>
                <div>
                    <TxtComplement>
                        <img src={Progress} alt="" />
                        {active}
                    </TxtComplement>
                    Alunos ativos
                </div>
                    <div>
                    <TxtComplement>
                        <img src={Cam} alt="" />
                        {hours}
                    </TxtComplement>
                    Aulas cadastradas na plataforma
                </div>
            </Complement>
        </>

    )
}

export default ProgressCard