import React, { useState, useEffect } from 'react'
import { Container } from 'GlobalStyles'
import Steps from './Steps'
import { Link, Breadcrumb } from './style'
import HeaderPromotor from 'components/HeaderPromotor'
import { ViewProductContext } from './ViewProduct'
import api from 'services/api'
import { useLocation } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core/'

interface ViewProductData {
  id: number

  name: string
  photo?: string
  updated_photo: boolean
  category: { value: number; label: string }
  sub_category: { value: number; label: string }
  description: string

  value: number
  quantity: number
  currency: { id: number }
  currencySymbol: string
  repayment: { id: number }
  payments: { payment_method: number }[]
  hasInstallment: boolean
  installments: []
  interest_to_client: boolean
  co_producers?: []

  activeOrderBump?: boolean
  order_bump?: { value: number; label: string }
  discountBump?: number
  descriptionBump?: string
  activeOrderSell?: boolean
  order_sell?: { value: number; label: string }
  discountSell?: number
  descriptionSell?: string

  modules?: []
  release_date?: string

  finalJSON: {}
}

const createValueLabelForSelectInput = (id: number, name: string) => ({
  value: id,
  label: name,
})

const createBumpSellsObject = () => ({
  description: null,
  discount: null,
  product_tenant: {
    id: null,
    name: null,
  },
})

const constructInstallments = (el: any) => {
  if (!el.installment) return

  return {
    id: el.installment.id,
  }
}

const createFinalJSON = (data: any) => ({
  id: data.id,
  name: data.name,
  description: data.description,
  category: { id: data.category.id },
  photo: data.photo,
  updated_photo: false,
  currency: { id: data.currency.id },
  payments: data.payments.map((pay: any) => ({
    payment_method: pay.payment_method.id,
  })),
  installments: data.installments.map((installment: any) =>
    constructInstallments(installment),
  ),
  value: data.value,
  interest_to_client: data.interest_to_client,
  co_producers: data.co_producers,
  bumps: data.bumps,
  quantity: data.quantity,
  upsells: data.upsells,
  modules: data.modules,
  release_date: data.release_date,
})

const ViewProduct: React.FC = () => {
  const [editProductData, setEditProductData] = useState<ViewProductData>()
  const { state } = useLocation()
  const { product_id }: any = state

  const getProductData = async () => {
    let { data } = await api.get(`/get-product/${product_id}`)
    let bumps = data.bumps[0] ? data.bumps[0] : createBumpSellsObject()
    let upsells = data.upsells[0] ? data.upsells[0] : createBumpSellsObject()
    let formatedData = {
      ...data,
      category: createValueLabelForSelectInput(
        data.category.id,
        data.category.name,
      ),
      payments: data.payments.map((payment: any) => ({
        payment_method: payment.payment_method.id,
      })),
      currencySymbol: 'BRL',
      order_bump: createValueLabelForSelectInput(
        bumps.product_tenant.id,
        bumps.product_tenant.name,
      ),
      descriptionBump: bumps.description,
      discountBump: bumps.discount,
      order_sell: createValueLabelForSelectInput(
        upsells.product_tenant.id,
        upsells.product_tenant.name,
      ),
      descriptionSell: upsells.description,
      discountSell: upsells.discount,
      release_date: data.release_date ? new Date(data.release_date) : null,

      finalJSON: createFinalJSON(data),
    }

    setEditProductData(formatedData)
  }

  useEffect(() => {
    getProductData()
  }, [])

  return (
    <Container style={{ marginBottom: '2rem' }}>
      <HeaderPromotor SearchHeader />

      <Breadcrumb separator="→" aria-label="breadcrumb">
        <Link to="/BackOffice">Produtos</Link>
        <Link color="#BCBCBC" to="/products/view">
          Visualizar produto
        </Link>
      </Breadcrumb>

      {!editProductData ? (
        <CircularProgress color="inherit" style={{ margin: '10% 50%' }} />
      ) : (
        <ViewProductContext.Provider
          value={[editProductData, setEditProductData]}
        >
          <Steps />
        </ViewProductContext.Provider>
      )}
    </Container>
  )
}

export default ViewProduct
