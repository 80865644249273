import React from 'react';
import { MenuLink } from './styles'
import { HomeOutlined, ShopOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'

const StudentMobileView = () => {

    return (
        <>
            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
                <MenuLink.Item key="1" icon={<HomeOutlined />}>
                    <Link to="/"></Link>
                </MenuLink.Item>
                <MenuLink.Item key="2" icon={<ShopOutlined />}>
                    <Link to="/products"></Link>
                </MenuLink.Item>
            </Menu>
        </>
    )

}

export default StudentMobileView