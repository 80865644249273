import styled from 'styled-components'
import { Subcard } from 'pages/Profile/shared-style'

export const MenuCard = styled(Subcard)`
  width: 21.925625rem;
  justify-content: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  img {
    transform: rotate(-90deg);
  }
  div{
    display: flex;
    justify-content: space-between;
  }
`

export const Photo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`
export const ContainerLoad = styled.div`
  width: 300px;
  height: 100px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 9px 5px 20px #eeeeee, 0px 2px 5px #e4e4e4;
  display: flex;
  align-items: center;
  justify-content: center;

    position: absolute;
    right: 0;
`

export const Inative = styled.div`
  color: #DCDCDF;
`