
import React from 'react';
import {
    CardTopAffiliate,
    DadosAfilliate,
} from './style'

interface Props {
    photo?: string
    name?: string
    sales?: string
  }

const TopAffiliate: React.FC<Props> = ({photo, name, sales}) => {

    return (
        <>

            <CardTopAffiliate>
                <img src={photo} alt="Afiliado" />
                <DadosAfilliate>
                    {name}
                  <p>{sales} vendas</p>
                </DadosAfilliate>
            </CardTopAffiliate>


        </>
    )
}

export default TopAffiliate
