import React from 'react'
import { Container } from './style'

interface Props {
  dark?: boolean
}

const Footer: React.FC<Props> = ({ dark = false }) => {
  const now = new Date()

  return (
    <Container dark={dark}>
      @ Copyright {now.getFullYear()} Skycode. Todos os direitos reservados.
    </Container>
  )
}

export default Footer
