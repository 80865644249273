import React, { useRef, useEffect } from 'react'
import { OptionTypeBase, Props as SelectProps } from 'react-select'
import { useField } from '@unform/core'
import { Container } from './style'

interface Props extends SelectProps<OptionTypeBase> {
  name: string
  options?: any
  defaultValue?: any
  disabled?: boolean
}

const Select: React.FC<Props> = ({
  name,
  defaultValue = null,
  options = null,
  disabled,
  ...rest
}) => {
  const selectRef = useRef(null)
  const { fieldName, registerField, error } = useField(name)

  const mapOptionsToValueLabel = () =>
    options.map((option: any) => ({
      value: option.id,
      label: option.name,
    }))

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (!ref.state.value) {
          return null
        }
        return ref.state.value.value
      },
    })
  }, [fieldName, registerField])

  return (
    <>
      <Container
        ref={selectRef}
        isSearchable={false}
        isLoading={options === null}
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Nenhuma opção'}
        classNamePrefix="react-select"
        options={options ? mapOptionsToValueLabel() : []}
        defaultValue={defaultValue}
        disabled={disabled}
        {...rest}
      />
      { error && <span className="error">{error}</span>}
    </>
  )
}

export default Select
