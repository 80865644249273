import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  Container,
  FormContainer,
  MainContainer,
  PolicyPrivacy,
} from '../../components/Form/Container'
import Logo from '../../components/Logo'
import {
  Flex, StyledNav,
} from './style'

const PrivacyPolicy = () => {
  return (
    <MainContainer>
      <Container>
        <Logo />

        <StyledNav>
          <NavLink activeClassName="active" to="/register">
            Criar conta
          </NavLink>
          <NavLink activeClassName="active" to="/login">
            Acessar conta
          </NavLink>
          <NavLink activeClassName="active" to="/privacyPolicy">
            Política de privacidade
          </NavLink>
        </StyledNav>

        <FormContainer>
          <Flex>
            <PolicyPrivacy>
              <h3>
                Política de Privacidade
              </h3> 
              <p>
                Esta Política de Privacidade descreve como a Skycode coleta, usa e compartilha informações pessoais de usuários do site www.web.skycode.com.br e integra os 
                termos e condições gerais de uso do site www.web.skycode.com.br, devidamente inscrita no CNPJ sob o nº 32.500.331/0001-87 (doravante referido como "Skycode"). 
                Ao usar o Site, você concorda com as práticas descritas nesta política.
              </p>
              <p>
                O presente documento foi elaborado em conformidade com a Lei Geral de Proteçâo de Dados Pessoais (Lei 13.709/18), 
                o Marco Civil da Internet (Lei 12.965/14) (e o Regulamento da UE n. 2016/6790). Ainda, 
                o documento poderá ser atualizado em decorrência de eventual atualização normativa, razão pela qual se convida o usuário a consultar periodicamente esta seção.
              </p>
              <h3>
                Informações Coletadas
              </h3>
              <p>
                Quando você acessa ou interage com o Site, podemos coletar as seguintes informações pessoais:
              </p>
              <p>
                <li>Nome</li>
                <li>Número de telefone</li>
                <li>E-mail</li>
                <li>Endereço</li>
              </p>
              <p>Essas informações são coletadas apenas quando fornecidas voluntariamente pelos usuários, por exemplo, ao preencher formulários de contato ou fazer pedidos.</p>
              <h3>Uso das Informações</h3>
              <p>As informações coletadas são utilizadas para os seguintes propósitos:</p>
              <p>
                <li>Para fornecer e personalizar os serviços oferecidos pelo Site.</li>
                <li>Para entrar em contato com os usuários, responder às suas perguntas e fornecer suporte.</li>
                <li>Para processar pedidos e fornecer informações sobre o status dos mesmos.</li>
                <li>Para enviar comunicações de marketing, caso o usuário tenha consentido com isso.</li>
                <li>Para cumprir obrigações legais, como a geração de etiquetas e notas fiscais.</li>
              </p>
              <h3>Compartilhamento de Informações</h3>
              <p>Podemos compartilhar informações pessoais com terceiros apenas nas seguintes circunstâncias:</p>
              <p>
                <li>Com prestadores de serviços que nos ajudam a operar o Site ou fornecer serviços relacionados, como processamento de pedidos, envio de e-mails ou geração de notas fiscais. Esses prestadores de serviços têm acesso limitado às informações necessárias para realizar suas funções e são obrigados a proteger a confidencialidade dessas informações.</li>
                <li>Quando exigido por lei ou acreditarmos de boa fé que tal ação é necessária para estar em conformidade com uma obrigação legal, proteger e defender nossos direitos legais ou investigar possíveis fraudes ou violações de segurança.</li>
              </p>
              <h3>Seus Direitos de Privacidade</h3>
              <p>
                Os usuários têm o direito de solicitar acesso, corrigir ou excluir suas informações pessoais. Se desejar exercer esses direitos ou tiver alguma dúvida sobre nossa política de privacidade, entre em contato conosco pelo e-mail <b>contato@skycode.com.br</b>.
              </p>
              <h3>Cookies e Tecnologias Semelhantes</h3>
              <p>
                O Site pode usar cookies e tecnologias semelhantes para melhorar a experiência do usuário, rastrear o uso do Site e coletar informações adicionais. Os usuários podem configurar seus navegadores para recusar todos os cookies ou para indicar quando um cookie está sendo enviado.
              </p>
              <h3>Alterações nesta Política de Privacidade</h3>
              <p>
                Esta Política de Privacidade está sujeita a alterações. Quaisquer alterações serão publicadas nesta página e a data da última atualização será modificada. Encorajamos os usuários a revisar periodicamente esta página para se manterem informados sobre como estamos protegendo as informações pessoais coletadas.
              </p>
              <h3>Contato</h3>
              <p>
                Se você tiver alguma dúvida ou preocupação sobre esta Política de Privacidade, entre em contato conosco pelo e-mail <b>contato@skycode.com.br</b>.
                Última atualização: 17/01/2024.
              </p>
            </PolicyPrivacy>
          </Flex>
        </FormContainer>
      </Container>

    </MainContainer>
  )
}

export default PrivacyPolicy