import { Card, Menu } from 'antd';
import React, { useEffect, useCallback } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import api from 'services/api';
import iconDown from '../../assets/icons/keyboard_arrow_down-white-24dp.svg';
import icon from '../../assets/icons/menu-24px.svg';
import student from '../../assets/images/aluno.jpg';
import background1 from '../../assets/images/back1.png';
import background from '../../assets/images/background.jpg';
import background2 from '../../assets/images/backMain.jpg';
import logo from '../../assets/images/logo.png';
import producer from '../../assets/images/produtor.jpg';
import {
    CardContainer, Content,
    DropdownResponsivo, EntrarBtn,
    Frame,
    Heading,
    Image, ListOptions,
    Main, StyledButton,
    StyledCard, StyledMenu, SubscribeBtn,
    TextoApresenta,
    TextoCard, Title,
    TopNav
} from './style';

const { Meta } = Card;

const ConfirmEmail = () =>{
    const history = useHistory()
    const {token} = useParams();

    const verifyEmail = useCallback(async () => {
        const result = await api.get(`/confirm-email/${token}`)
         
        if(result.status === 204){
             history.push('/login')
         }else{
             
             history.push('/register')
        }
     }, [history, token]) 


    useEffect(() => {
        verifyEmail()
    },[verifyEmail])

    const menu = (
        <StyledMenu>
          <Menu.Item><a href='#quemSomos'><li>Quem Somos</li></a></Menu.Item>
          <Menu.Item><a href='#queFazemos'><li>O Que Fazemos</li></a></Menu.Item>
          {/* <Menu.Item><a href='#provas'><li>Quem Usa</li></a></Menu.Item> */}
          <hr style={{width: '75%', margin: '4px auto',}}/>
          <Menu.Item><Link to='/login'><EntrarBtn>Entrar</EntrarBtn></Link></Menu.Item>
        </StyledMenu>
      );
      
    return(
        <>
        <TopNav>
            <a href='#home'><img height='50px' alt='logo' src={logo} /></a>
            <ListOptions>
                <a href='#quemSomos'><li>Quem Somos</li></a>
                <a href='#queFazemos'><li>O Que Fazemos</li></a>
                {/* <a href='#provas'><li>Quem Usa</li></a> */}
                <Link to='/login'><EntrarBtn>Entrar</EntrarBtn></Link>
            </ListOptions>
            <DropdownResponsivo overlay={menu} placement="bottomLeft" trigger={['click','hover']}>
                <img src={icon} alt="lgo"/>
            </DropdownResponsivo>
        </TopNav>
        <Main>
            <Frame id='home' background={`url(${background})`}>
                <Content>
                    <Heading>
                        <h1>Sistema Exponencial XGrow</h1>
                        <h2>Plataforma flexível focada no desenvolvimento online, guiada através de um conceito intuitivo e focado em alta performance.</h2>
                        <Link to='/register'><SubscribeBtn>Inscrever-se</SubscribeBtn></Link>
                    </Heading>
                    <a href='#quemSomos'><span style={{padding: 12}}><img src={iconDown} alt=""/></span></a>
                </Content>
            </Frame>
            
            <Frame id='quemSomos' background={`url(${background1})`} >
                <Content>
                    <Frame className='greenPoly' background=''>
                        <Frame className='poly' background=''>
                            <TextoApresenta>
                                <p>Acreditamos que todo crescimento é fruto do conhecimento em ação.

                                Queremos ver pessoas tendo um crescimento exponencial, entrando em ação 
                                e obtendo os resultados desejados, sejam eles financeiros, intelectuais, 
                                ou quaisquer outros relacionados a desenvolvimento.
                                </p>
                            </TextoApresenta>
                        </Frame>
                    </Frame>
                </Content>
            </Frame>
            <Frame id='queFazemos' background={`url(${background2})`} >
                <Content>
                    <Frame className='greenPoly2' background=''>
                        <Frame className='poly2' background=''>
                            <TextoApresenta className='texto2'>
                                <p>
                                    Embasados nos princípios cristãos, como honestidade, transformação, contribuição,
                                    crescimento e exponencialidade, desenvolvemos a XGrow para proporcionar a melhor 
                                    experiência para os usuários, uma plataforma flexível que busca atingir as principais 
                                    necessidades quando se trata de desenvolvimento online, seja ele: business, financeiro,
                                    intelectual ou network. Tudo isso através de um conceito intuitivo e focado em alta performance.
                                </p>
                            </TextoApresenta>
                        </Frame>
                    </Frame>
                </Content>
            </Frame>
                       
            <Frame id='provas' background={`url(${background})`} >
                <Content className='cards' >
                <Title color='#FFF'>Nosso ecossistema</Title>
                    <CardContainer>
                        
                        <StyledCard
                            cover={
                                <Image
                                    alt="example"
                                    src={producer}
                                    
                                />}
                            >
                            <Meta
                           
                            title="O produtor"
                            description={
                                <>
                                <TextoCard>Responsável por criar e disponibilizar aulas, o produtor é a peça chave da plataforma.</TextoCard>
                                <TextoCard>Procuramos garantir a total performance para os produtores, que desejam subir suas aulas de forma 
                                dinâmica, intuitiva, rápida e independente, para que assim, seu foco se detenha apenas em gerar 
                                conteúdo para seus alunos.
                                </TextoCard>
                                </>
                                }
                                />
                            <Link to='/register'>
                                <StyledButton type="primary" size={'large'}>
                                    Quero me tornar um produtor!
                                </StyledButton>
                            </Link>
                        </StyledCard>
                        <StyledCard
                            cover={
                                <Image
                                    alt="example"
                                    src={student}
                                />}
                            >
                            <Meta
                            title="O aluno"
                            description={<TextoCard>Ao buscar de uma melhor experiência de aprendizado online, muitos alunos estão 
                                constantemente buscando plataformas para suprir sua vontade de aprender e fazer network. 
                                Por isso, nosso foco é garantir sempre: uma experiência única e a certeza do pertencimento
                                a uma comunidade dinâmica e focada em alta performance.</TextoCard>}
                                />
                                <Link to='/register'>
                                    <StyledButton type="primary" size={'large'}>
                                        Quero me tornar um aluno!
                                    </StyledButton>
                                </Link>
                        </StyledCard>
                    </CardContainer>
                </Content>
            </Frame>

            
          
        </Main>
        </>
    );
    
}

export default ConfirmEmail

