/*
Aqui é a página principal do produtor
*/

import Card from 'components/Card'
import Button from 'components/new_components/button'
import StyledCard from 'components/new_components/Card'
import React, { useEffect, useState } from 'react'
import { AiFillStar, AiOutlineEyeInvisible, AiOutlineCodeSandbox, AiOutlineTeam, AiOutlineDollar, AiOutlineUser, AiOutlineProfile, AiOutlineRise, AiOutlineRight, AiOutlineTool, AiOutlineDown } from 'react-icons/ai'
import { FaChevronRight } from 'react-icons/fa'
import { BlurComponent, CardBody, ContainerCards, ContainerContent, ContainerAfiliados, EmBreve, RelContent, RelatoriosCard } from './styles'

interface Props {
  dataBruto: any,
  dataLiquido: any,
  dataSaque: any,
  dataCount: any,
  activeStudents: any,
  hoursVideo: any,
  rating: any,
  completedStudents: any,
  totalStudents: any,

}

const HomePage: React.FC<Props> = ({ rating, dataBruto, dataLiquido, dataSaque, dataCount, activeStudents, hoursVideo, completedStudents, totalStudents }) => {

  const [bruto, setBruto] = useState(dataBruto);
  const [liquido, setLiquido] = useState(dataLiquido);
  const [saque, setSaque] = useState(dataSaque);
  const [blur, setBlur] = useState(true)

  const handleVisibleChange = async () => {
    if (!blur) {
      setBlur(true)
    } else {
      setBlur(false)
    }
  }



  useEffect(() => {
    const init = async () => {
      if (dataBruto === null) {
        setBruto('R$ 0,00')
      } else {
        setBruto(dataBruto)
      }
      if (dataLiquido === null) {
        setLiquido('R$ 0,00')
      } else {
        setLiquido(dataLiquido)
      }
      if (dataSaque === null) {
        setSaque('R$ 0,00')
      } else {
        setSaque(dataSaque)
      }
    }
    init()
  }, [rating, dataBruto, dataLiquido, dataSaque, dataCount, activeStudents, hoursVideo])


  return (
    <>
      <ContainerCards>
        <Card
          cardIcon={<AiOutlineDollar style={{ fontSize: 24, color: '#888' }} />}
          titulo="Financeiro"
          icon={<AiOutlineEyeInvisible color="var(--main)" size={30} />}
          onCh={() => handleVisibleChange()}
          url={"/financial"}
        >
          <BlurComponent blur={blur}>
            <ContainerContent>
              <p>{liquido}</p>
              <p>Valor Liquido</p>
            </ContainerContent>
            <ContainerContent>
              <p>{saque}</p>
              <p>Para saque</p>
            </ContainerContent>
          </BlurComponent>
        </Card>
        <Card
          cardIcon={<AiOutlineCodeSandbox style={{ fontSize: 24, color: '#888' }} />}
          value={dataCount}
          titulo="Produtos"
          url={"/products"}>
          <CardBody>
            <ContainerContent>
              <p>{hoursVideo}</p>
              <p>de vídeo</p>
            </ContainerContent>
            <ContainerContent>
              <p>
                {rating}
                <AiFillStar
                  color="#5C5C5C"
                  size={22}
                  style={{ marginLeft: 5 }}
                />
              </p>
              <p>Avaliação Média</p>
            </ContainerContent>
          </CardBody>
        </Card>
        <Card
          cardIcon={<AiOutlineUser style={{ fontSize: 24, color: '#888' }} />}
          titulo="Alunos"
          url={"/student-producer"}
          value={totalStudents}>
          <CardBody>
            <ContainerContent>
              <p>{completedStudents.toFixed(0)}%</p>
              <p>Concluiram</p>
            </ContainerContent>
            <ContainerContent>
              <p>{activeStudents.toFixed(0)}%</p>
              <p>Ativos</p>
            </ContainerContent>
          </CardBody>
        </Card>
        <Card
          cardIcon={<AiOutlineTeam style={{ fontSize: 24, color: '#888' }} />}
          titulo="Afiliados"
          value="0">

          <EmBreve><AiOutlineTool style={{ marginRight: 8 }} />Em breve</EmBreve>
          {/* <ContainerAfiliados>
            <AiOutlineTeam
            style={{
              fontSize: 56,
              color: 'var(--main)'
            }}
            />
            <ContainerContent style={{ textAlign: 'left', marginLeft: 16}} className="container_afiliado_2">
              <p style={{ color:'var(--main)' }}>0 Novas</p>
              <p style={{ color:'var(--main-highlight)' }}>
                solicitações
                <FaChevronRight
                  color="var(--main)"
                  size={15}
                  style={{ marginLeft: 7, marginTop: 3 }}
                />
              </p>
            </ContainerContent>
          </ContainerAfiliados> */}

        </Card>
        <Card
          cardIcon={<AiOutlineProfile style={{ fontSize: 24, color: '#888' }} />}
          titulo="Relatórios"
          value=""
          url={"/reports"}
        >
          <RelatoriosCard>
            <RelContent>
              <AiOutlineProfile /><p>Gastos por lançamento</p>
              <FaChevronRight
                color="var(--main)"
                size={15}
                style={{ marginLeft: 40 }}
              />
            </RelContent>

            <RelContent>
              <AiOutlineProfile /><p>Análise de recompra</p>
              <FaChevronRight
                color="var(--main)"
                size={15}
                style={{ marginLeft: 60 }}
              />
            </RelContent>
          </RelatoriosCard>
        </Card>
        <Card
          cardIcon={<AiOutlineRise style={{ fontSize: 24, color: '#888' }} />}
          titulo="Analytics"
          value=""
        >
          <EmBreve><AiOutlineTool style={{ marginRight: 8 }} />Em breve</EmBreve>
        </Card>
        
      </ContainerCards>
      
    </>
  )
}

export default HomePage
