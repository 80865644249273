import styled from 'styled-components'
import { Card } from 'components/CardContainer'
import Button from 'components/new/Button'

export const ContainerView = styled(Card)`
  margin-top: 20px;
`

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width:992px){
    flex-direction: column
  }
`
export const MainContainer = styled.div`
  margin: auto;
  margin-top: 2.2556rem;
  width: calc(100% - 9rem);
  min-height: 100vh;
  padding: 1rem;

  @media (min-width: 1330px) {
    padding: 0;
  }
  @media (max-width: 1085px) {
    margin: 0;
    width: 100%;
  }
`
export const Container = styled.div`
  margin: 16px;

  @media (max-width: 1085px) {
    margin: 0;
    width: 100%;
  }
`

export const Main = styled.div`
  @media screen and (max-width: 952px){
    width: 100%;
  }
  @media screen and (max-width: 600px){
    width: 100%;
  }
`
export const Msg = styled.div`
  margin-top: 40px;
  border-radius: 20px;
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #292929;

  h2{
    color: white;
  }
`

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
export const HeaderCurso = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Name = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Sidebar = styled.div`
  margin: 0 16px;
  @media screen and (max-width: 992px){
    width: 90%;
    margin: 24px auto;
  }
  .ant-collapse-content>.ant-collapse-content-box{
    padding: 0;
  }
`

export const SelectedClass = styled.div`
    background-color: #ebebeb;
    width: 100%;
    height: auto;
    padding: 12px;
`

export const Status = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;

  h3 {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #5c5c5c;
    text-align: center;
    margin-bottom: 10px;
    width: 100%;

  }
  @media screen and (max-width: 992px){
    width: 100%;
  }
`

export const Lessons = styled.ul`
  list-style: none;
  padding: 0;

  strong{
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    margin: 0;
    height:10px;
  }
  li {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: left;
    height: auto;
    margin: 0;
    background-color: #f7f7f7;
    margin-bottom: 4px;

    h1{
      margin: 0;
      padding-right: 30px;
    }
  }

  &&& .ant-collapse-content-box:first-child{
    border-top: 1px solid #d9d9d9;
  }

  &&& .ant-collapse-header{
    padding-right: 50px;
  }
`
export const ControlButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  color: var(--main);
  cursor: pointer;
  transition: 200ms;
  outline: 0;

  &:hover {
    opacity: 0.7;
  }

  svg {
    font-size: 22px;
    margin-right: 10px;
    margin-left: 10px;
  }
`
export const ViewLast = styled.p`
  height: auto;
  width: 100%;
  height: auto;
  padding: 12px;
  margin: 0;

`

export const TestsView = styled.p`
  font-weight: 400;
  height: auto;
  width: 100%;
  height: auto;
  padding: 12px;
  margin: 0;
`

export const StyledButton = styled(Button)`
  margin: 5px auto;
`