/*
Aqui é o componente módulo em si, onde mostra todas as aulas/testes criados, assim como
tem um botão para criar novas aulas e testes.
*/

import React, { useState, useContext } from 'react'
import {
  Container,
  CollapseContainer,
  StyledCollapse,
  StyledPanel,
  InsertModuleMenu,
  ClassTestContainer,
  Classes,
  Tests,
} from './style'
import Button from 'components/new/Button'
import ArrowIcon from 'components/new/ArrowIcon'
import EditIcon from 'assets/icons/edit-icon.svg'
import DeleteIcon from 'assets/icons/delete-icon.svg'
import { Dropdown } from 'antd'
import { EditProductContext } from '../../../EditProductContext'
import ClassPreviewModal from '../ClassPreviewModal'
import TestPreviewModal from '../TestPreviewModal'
import NewClassModal from '../NewClassModal'
import NewTestModal from '../NewTestModal'
import EditClassModal from '../EditClassModal'
import EditModuleModal from '../EditModuleModal'
import EditTestModal from '../EditTestModal'
import api from 'services/api'
import apiVideoUploader from 'services/api-video-upload'
import {
  displayErrorNotification,
  displaySucessNotification,
} from 'utils/displayNotification'
import classes from '*.module.css'

interface Module {
  id: number
  name: string
  sequence: number
  classes: []
  tests: []
}

interface Class {
  id: number
  name: string
  sequence: number
  video_link: string
  video_key: string
  description: string
}

interface Test {
  id: number
  sequence: number
  name: string
  time: number
  description: string
  pdf_link?: File
}

interface Props {
  module: Module
}

const Module: React.FC<Props> = ({ module }) => {
  const [editProductData, setEditProductData] = useContext(EditProductContext)
  const [classPreview, setClassPreview] = useState<boolean>(false)
  const [testPreview, setTestPreview] = useState<boolean>(false)
  const [video_key, setVideo_key] = useState<string>('')

  const [showNewClassModal, setShowNewClassModal] = useState<boolean>(false)
  const [showNewTestModal, setShowNewTestModal] = useState<boolean>(false)

  const [showEditClassModal, setShowEditClassModal] = useState<boolean>(false)
  const [editClassModal, setEditClassModal] = useState<any>({})

  const [showEditTestModal, setShowEditTestModal] = useState<boolean>(false)
  const [editTestModal, setEditTestModal] = useState<any>({})

  const [showEditModuleModal, setShowEditModuleModal] = useState<boolean>(false)
  const [, setModuleToEdit] = useState<Module>()

  const insertMenu = (
    <InsertModuleMenu>
      <div onClick={() => setShowNewClassModal(true)}>
        <p>Nova Aula</p>
        <ArrowIcon rotateDeg={-90} />
      </div>
      <div onClick={() => setShowNewTestModal(true)}>
        <p>Novo Teste</p>
        <ArrowIcon rotateDeg={-90} />
      </div>
    </InsertModuleMenu>
  )

  const handleEditModuleName = (module: Module) => {
    setShowEditModuleModal(true)
    setModuleToEdit(module)
  }

  const handleDeleteModule = async (moduleID: number, sequence: number) => {
    try {
      if (!moduleID) {
        let newModules = editProductData.modules.filter(
          (module: Module) => module.sequence !== sequence,
        )

        setEditProductData({
          ...editProductData,
          modules: newModules,
        })
      } else {
        let response = await api.delete(`/remove-module/${moduleID}`)

        let newModules = editProductData.modules.filter(
          (module: Module) => module.sequence !== sequence,
        )

        setEditProductData({
          ...editProductData,
          modules: newModules,
        })

        displaySucessNotification(response.data.message)
      }
    } catch (err) {
      displayErrorNotification(err.response.data.message)
      return
    }
  }

  const handleEditClass = async (
    classe: any,
  ) => {
    setShowEditClassModal(true)
    setEditClassModal(classe)
  }

  const handleEditTest = async (
    test: any,
  ) => {
    setShowEditTestModal(true)
    setEditTestModal(test)
  }

  const handleDeleteClass = async (
    moduleSequence: number,
    classSequence: number,
    classID: number,
    video_key: string,
  ) => {
    let newModules = editProductData.modules.map((module: Module) => {
      if (module.sequence === moduleSequence) {
        let newClasses = module.classes.filter(
          (classe: Class) => (classe.sequence !== classSequence) || (classe.id !== classID),
        )

        return {
          ...module,
          classes: newClasses,
        }
      } else {
        return module
      }
    })

    setEditProductData({
      ...editProductData,
      modules: newModules,
    })

    try {
      if (!classID) return

      let response = await api.delete(`/remove-class/${classID}`)
      await apiVideoUploader.delete(`/delete/${video_key}`).then((res) => {
        console.log('Conteúdo da Amazon removido com sucesso!')
      })
      displaySucessNotification(response.data.message)
    } catch (err) {
      displayErrorNotification(err.response.data.message)
      return
    }
  }

  const handleDeleteTest = async (
    moduleSequence: number,
    testSequence: number,
    testID: number,
  ) => {
    let newModules = editProductData.modules.map((module: Module) => {
      if (module.sequence === moduleSequence) {
        let newTests = module.tests.filter(
          (test: Test) => (test.sequence !== testSequence) || (test.id !== testID),
        )

        return {
          ...module,
          tests: newTests,
        }
      } else {
        return module
      }
    })

    setEditProductData({
      ...editProductData,
      modules: newModules,
    })

    try {
      if (!testID) return

      let response = await api.delete(`/remove-test/${testID}`)
      displaySucessNotification(response.data.message)
    } catch (err) {
      displayErrorNotification(err.response.data.message)
      return
    }
  }

  const [selectedClass, setSelectedClass] = useState<Class>()
  const handleClassPreview = (module: Module, classSequence: number) => {
    let selectedClass = module.classes.find(
      (classe: Class) => classe.sequence === classSequence,
    )

    setSelectedClass(selectedClass)
    setClassPreview(!classPreview)
  }

  const [selectedTest, setSelectedTest] = useState<Test>()
  const handleTestPreview = (module: Module, testSequence: number) => {
    let selectedTest = module.tests.find(
      (test: Test) => test.sequence === testSequence,
    )

    setTestPreview(!testPreview)
    setSelectedTest(selectedTest)
  }

  return (
    <Container>
      <CollapseContainer>
        <StyledCollapse>
          <input
            type="image"
            onClick={() => handleEditModuleName(module)}
            src={EditIcon}
            alt=""
          />
          <input
            type="image"
            onClick={() => handleDeleteModule(module.id, module.sequence)}
            src={DeleteIcon}
            alt=""
          />
          <StyledPanel
            className="style-header"
            header={module.name}
            key={module.sequence}
          >
            <Dropdown overlay={insertMenu}>
              <Button arrowIcon width="7rem">
                Inserir
              </Button>
            </Dropdown>

            <ClassTestContainer>
              <Classes>
                <h2>Aulas</h2>
                <section>
                  {module.classes.map((classe: Class) => (
                    <div key={classe.sequence}>
                      <h1
                        onClick={() =>
                          handleClassPreview(module, classe.sequence)
                        }
                      >
                        {classe.name}
                      </h1>
                      <input
                        type="image"
                        onClick={() => handleEditClass(classe)}
                        src={EditIcon}
                        alt=""
                      />
                      <input
                        type="image"
                        onClick={() =>
                          handleDeleteClass(
                            module.sequence,
                            classe.sequence,
                            classe.id,
                            classe.video_key,
                          )
                        }
                        src={DeleteIcon}
                        alt=""
                      />
                    </div>
                  ))}
                </section>

                <ClassPreviewModal
                  module={module}
                  classe={selectedClass ? selectedClass : {}}
                  visible={classPreview}
                  onCancel={() => setClassPreview(!classPreview)}
                />
              </Classes>

              <Tests>
                <h2>Testes</h2>
                <section>
                  {module.tests.map((test: Test) => (
                    <div>
                      <h1
                        onClick={() => handleTestPreview(module, test.sequence)}
                      >
                        {test.name}
                      </h1>
                      <input
                        type="image"
                        onClick={() => handleEditTest(test)}
                        src={EditIcon}
                        alt=""
                      />
                      <input
                        type="image"
                        onClick={() =>
                          handleDeleteTest(
                            module.sequence,
                            test.sequence,
                            test.id,
                          )
                        }
                        src={DeleteIcon}
                        alt=""
                      />
                    </div>
                  ))}
                </section>

                <TestPreviewModal
                  test={selectedTest ? selectedTest : {}}
                  visible={testPreview}
                  onCancel={() => setTestPreview(!testPreview)}
                />
              </Tests>
            </ClassTestContainer>
          </StyledPanel>
        </StyledCollapse>


      </CollapseContainer>

      <EditModuleModal
        visible={showEditModuleModal}
        module={module}
        onCancel={() => setShowEditModuleModal(false)}
      />

      <EditClassModal
        visible={showEditClassModal}
        classe={editClassModal}
        moduleID={module.sequence}
        onCancel={() => setShowEditClassModal(false)}
      />

      <EditTestModal
        visible={showEditTestModal}
        test={editTestModal}
        moduleID={module.sequence}
        onCancel={() => setShowEditTestModal(false)}
      />

      <NewClassModal
        visible={showNewClassModal}
        moduleID={module.sequence}
        onCancel={() => setShowNewClassModal(false)}
        video_key={video_key}
      />

      <NewTestModal
        visible={showNewTestModal}
        moduleID={module.sequence}
        onCancel={() => setShowNewTestModal(false)}
      />
    </Container>
  )
}

export default Module