/*
2º Passo da criação de produto:
Aqui fica os passos de: preço, tipo da moeda, adição de co-produtor, prazo de reembolso,
formas de pagamento, opção de ativar o parcelamento máximo disponível e a opção de repassar
os juros para o cliente (caso tenha).
*/

import { SubmitHandler } from '@unform/core'
import { Skeleton } from 'antd'
import ArrowIcon from 'components/ArrowIcon'
import Info from 'components/Financial/Info'
import HeaderNewProduct from 'components/new/HeaderNewProduct'
import Input from 'components/new/Input'
import Check from 'components/new/Input/Checkbox'
import CurrencyInput from 'components/new/Input/CurrencyInput'
import Radios from 'components/new/Input/Radio'
import StyledSwitch from 'components/new/Switch'
import React, {
  useCallback, useContext, useEffect,
  useRef, useState
} from 'react'
import api from 'services/api'
import displayYupErrorNotification from 'utils/displayYupErrorNotification'
import * as Yup from 'yup'
import { StepContext } from '../../../StepContext'
import { NewProductContext } from '../../NewProductContext'
import CoProducerList from './CoProducerList'
import CoProducerRegister from './CoProducerRegister'
import {
  ActionBtn,

  ContentContainer,
  ContentFooter,
  Flex, FormContainer,
  InputContainer,


  MainContainer,



  NoCoProducer, SwitchContainer
} from './style'

interface FormData {
  currency: string
  repayments: string
  payments: any[]
  installments: any[]
  value: number
  interestToClient: boolean
  co_producers: any[]
}

const PriceInfo: React.FC = () => {
  const { handlePreview, handleNext } = useContext(StepContext)
  const [newProductData, setNewProductData] = useContext(NewProductContext)

  const [currencies, setCurrencies] = useState<any>(null)
  const getCurrencies = async () => {
    let { data } = await api.get('/currencies')
    setCurrencies(data)
  }

  const [repayments, setRepayments] = useState<any>(null)
  const getRepayments = async () => {
    let { data } = await api.get('/repayments')
    setRepayments(data)
  }

  const [paymentMethods, setPaymentMethods] = useState<any>(null)
  const getPaymentMethods = async () => {
    let { data } = await api.get('/payments-methods')
    setPaymentMethods(data)
  }

  const [installments, setInstallments] = useState<any>(null)
  const getInstallments = async (value?: number) => {
    let { data } = await api.get(`/installments/${value}`)
    setInstallments(data)
  }

  const handleInterest = () => {
    setNewProductData({
      ...newProductData,
      interestToClient: !newProductData.interestToClient,
    })
  }

  const handleHasInstallment = () => {
    setNewProductData({
      ...newProductData,
      hasInstallment: !newProductData.hasInstallment,
    })
  }

  const [interestAndInstallment, setInterestAndInstallment] = useState(false)
  const handleInterestAndInstallment = (evt: any) => {
    if (evt.target.id === 'Cartão de crédito') {
      setInterestAndInstallment(!interestAndInstallment)
      setNewProductData({
        ...newProductData,
        hasInstallment: false,
        interestToClient: false,
      })
    }

    return
  }

  const [moneyValue, setMoneyValue] = useState(0)
  const handleProductValue = useCallback((value: number) => {
    let convertedValue = value / 100

    getInstallments(convertedValue)

    setMoneyValue(value)
  }, [])

  const handleCurrencySymbol = (evt: any) => {
    let currency = evt.target.id

    switch (currency) {
      case 'DOLAR':
        setNewProductData({
          ...newProductData,
          // value: convertProductValue(newProductData.value, 'USD'),
          currencySymbol: 'USD',
        })
        return
      case 'EURO':
        setNewProductData({
          ...newProductData,
          currencySymbol: 'EUR',
        })
        return
      case 'LIBRA':
        setNewProductData({
          ...newProductData,
          currencySymbol: 'GBP',
        })
        return
      default:
        setNewProductData({
          ...newProductData,
          currencySymbol: 'BRL',
        })
        return
    }
  }

  const handleCoProducerDelete = (id: number) => {
    let newCoProducers = newProductData.co_producers.filter(
      (co_producer: any) => co_producer.id !== id,
    )

    setNewProductData({
      ...newProductData,
      co_producers: newCoProducers,
    })
  }

  const [userOptions, setUserOptions] = useState<any>({
    currencies: '1',
    repayments: '1',
    payments: ['1', '2', '4'],
  })

  const setLocalData = useCallback(() => {
    setUserOptions({
      currencies: newProductData.currency.id.toString(),
      repayments: newProductData.repayment.id.toString(),
      payments: newProductData.payments.map((payment: any) =>
        payment.payment_method.toString(),
      ),
    })

    let credCard = newProductData.payments.find(
      (pay: any) => pay.payment_method === 1,
    )

    if (!credCard) setInterestAndInstallment(true)
    handleProductValue(newProductData.value * 100)
  },[handleProductValue, newProductData.currency.id, newProductData.payments, newProductData.repayment.id, newProductData.value])

  useEffect(() => {
    if (newProductData?.currency) {
      setLocalData()
    }

    getCurrencies()
    getRepayments()
    getPaymentMethods()
    getInstallments(newProductData.value || 0)
  }, [newProductData, setLocalData])

  const formRef = useRef<SubmitHandler>(null)

  const handleSubmit: SubmitHandler<FormData> = async data => {
    try {
      const schema = Yup.object().shape({
        currency: Yup.string().required(),
        repayment: Yup.string(),
        payments: Yup.array()
          .of(Yup.string())
          .required('Escolha os tipos de pagamento.'),
        value: Yup.number()
          .min(5, 'Valor mínimo de 5,00.')
          .required('Adicione um valor ao seu produto'),

        interestToClient: Yup.boolean(),
      })

      let dataWithValue = {
        ...data,
        value: moneyValue / 100,
      }

      await schema.validate(dataWithValue, {
        abortEarly: false,
      })

      let co_producer = newProductData.co_producers[0]
        ? [
          {
            co_producer_email:
              newProductData.co_producers[0].co_producer_email,
            contract: { id: newProductData.co_producers[0].contract.id },
            comission: newProductData.co_producers[0].comission,
            comission_percentage:
              newProductData.co_producers[0].comission_percentage,
          },
        ]
        : []

      let newData = {
        currency: { id: parseInt(data.currency) },
        repayment: { id: parseInt(data.repayments) },
        payments: data.payments.map((payment: any) => ({
          payment_method: parseInt(payment),
        })),
        installments: newProductData.hasInstallment
          ? [{ installment: parseInt(data.installments[0]) }]
          : [],
        value: moneyValue / 100,
        interest_to_client: newProductData.interestToClient,
        co_producers: co_producer,
      }

      setNewProductData({
        ...newProductData,
        ...newData,
      })

      sessionStorage.setItem('PriceInfo', JSON.stringify(newData))
      handleNext()
    } catch (err) {
      displayYupErrorNotification(err)
    }
  }

  return (
    <MainContainer>
      <ContentContainer>
        <HeaderNewProduct
          title="2 - Informações de Preço"
          subtitle="Defina a precificação e formas de pagamento."
        />

        <FormContainer ref={formRef} onSubmit={handleSubmit}>
          <Flex>
            <Input title={'Preço do Produto'} width={'200px'}>
              <CurrencyInput
                value={moneyValue}
                currency={newProductData.currencySymbol}
                onValueChange={handleProductValue}
              />
            </Input>

          </Flex>

          <InputContainer>
            <h1>Tipo de Moeda</h1>
            <section className="content">
              {!currencies ? (
                <Skeleton.Button active />
              ) : (
                <>
                  {/* <Currency /> */}
                  <Radios
                    name="currency"
                    options={currencies}
                    defaultValue={userOptions.currencies}
                    onChange={handleCurrencySymbol}
                  />
                  </>
                )}
            </section>
          </InputContainer>

          <InputContainer>
            <div style={{ display: "flex", alignItems: 'baseline', justifyContent: 'space-between' }}>
              <h1>Co-produtor</h1>
              <CoProducerRegister />
            </div>
            <section className="content">
              {newProductData.co_producers &&
                newProductData.co_producers.length === 0 ? (
                  <NoCoProducer>Nenhum co-produtor cadastrado</NoCoProducer>
                ) : (
                  <CoProducerList
                    co_producers={newProductData.co_producers}
                    onDelete={handleCoProducerDelete}
                  />
                )}
            </section>
          </InputContainer>

          <InputContainer>
            <h1>Prazo de Reembolso</h1>
            <section className="content">
              {!repayments ? (
                <Skeleton.Button active />
              ) : (
                  <Radios
                    name="repayments"
                    options={repayments}
                    defaultValue={userOptions.repayments}
                  />
                )}
            </section>
          </InputContainer>

          <InputContainer>
            <h1>Formas de Pagamento</h1>
            <section className="content">
              {!paymentMethods ? (
                <Skeleton.Button active />
              ) : (
                  <Check
                    name="payments"
                    options={paymentMethods}
                    defaultValue={userOptions.payments}
                    onChange={handleInterestAndInstallment}
                  />
                )}
            </section>
          </InputContainer>

          <InputContainer>
            <SwitchContainer>
              {installments? 
              <StyledSwitch
                onChange={handleHasInstallment}
                checked={newProductData.hasInstallment}
                disabled={interestAndInstallment}
              /> :
              null}
              <p>Parcelamento máximo disponível</p>
              {installments? 
              null :
              <Info InfoText='As opções só aparecerão após o valor do curso ser inserido.' />}
            </SwitchContainer>

            <section className="content">
              {!installments ? (
                <Skeleton.Button />
              ) : (
                  <Check
                    name="installments"
                    options={installments}
                    checked={newProductData.hasInstallment}
                    disabled={!newProductData.hasInstallment}
                  />
                )}
            </section>
          </InputContainer>

          <SwitchContainer>
            {installments? 
              <>
              <StyledSwitch
              onChange={handleInterest}
              checked={newProductData.interestToClient}
              disabled={interestAndInstallment}
              />
              <p>Repassar juros para o cliente, se houver?</p>
              </>
              :
              null}
            
           
          </SwitchContainer>

          <ContentFooter>
            <ActionBtn onClick={handlePreview}>
              <ArrowIcon rotateDeg={90} color="var(--main)" />
              <p>Voltar</p>
            </ActionBtn>

            <ActionBtn type="submit" className="next-btn">
              <p>Próximo</p>
              <ArrowIcon rotateDeg={-90} color="var(--main)" />
            </ActionBtn>
          </ContentFooter>
        </FormContainer>
      </ContentContainer>
    </MainContainer>
  )
}

export default PriceInfo
