import React from 'react'
import CourseTest from 'containers/CourseTest'
import CourseVideo from 'containers/CourseVideo'
// import { CourseState, Classes, Module } from 'pages/CourseView'


// interface Props {
//   handleLessonClick: (classes: Classes) => void
//   handleSubmitTest: () => void
//   modules: Module[]
// }

// const CourseViewContent: React.FC<CourseState & Props> = ({
//   currentLesson,
//   currentModule,
//   modules,
//   prev,
//   next,
//   showResults,
//   handleLessonClick,
//   handleSubmitTest,
// }) => {
//   function definePrevNextButtonTitle(prevNext: Classes | null) {
//     if (prevNext) {
//       const module = modules.find(m => m.sequence === prevNext.module_id)
//       return prevNext.shouldAssumeModuleName ? module?.name : prevNext.name
//     }
//   }

//   return (
//     <Container>
//       <Header>
//         <ControlButton
//           prev
//           visible={!!prev}
//           onClick={() => {
//             if (prev) handleLessonClick(prev)
//           }}
//         >
//           <FiChevronLeft />
//           {definePrevNextButtonTitle(prev)}
//         </ControlButton>

//         <strong>{currentLesson?.name}</strong>

//         <ControlButton
//           next
//           visible={!!next}
//           onClick={() => {
//             if (next) handleLessonClick(next)
//           }}
//         >
//           {definePrevNextButtonTitle(next)}
//           <FiChevronRight />
//         </ControlButton>
//       </Header>


//         <CourseVideo classes={currentLesson} />
//     </Container>
//   )<h1>Teste</h1>

interface Props {
  next?: any
  prev?: any
  videoLink?: any
  name?: any
  description?: any
  type?: string
  pdfLink?: any
  conclusion?: any
  idVideo?: any
  linkArchive?: any
  view?: any
}
const CourseViewContent: React.FC<Props> = ({ linkArchive, videoLink, name, description, prev, next, type, pdfLink, conclusion, idVideo, view }) => {
  return (
    <>

      {type === 'classes' ?
        <CourseVideo videoLink={videoLink} name={name} description={description} conclusion={conclusion} idVideo={idVideo} linkArchive={linkArchive} view={view} />
        :
        type === 'tests' ?
          <CourseTest name={name} description={description} pdfLink={pdfLink} />
          : null
      }
    </>
  )
}

export default CourseViewContent