import React from "react";
import {
  DataTable,
  DataTableHead,
  DataTableRow,
  DataTableCell,
  DataTableBody,
} from 'components/DataTable'
import {
  ContainerTable
} from './style'
import Installments from './Installments'
import { CircularProgress } from '@material-ui/core/'

interface Installment {
  installment: any
}

const SkeletonTable: React.FC<Installment> = ({ installment }) => {

  return (
    <ContainerTable>
      <DataTable>
        <DataTableHead>
          <DataTableRow>
            <DataTableCell>Parcelas</DataTableCell>
            <DataTableCell>Quantidade</DataTableCell>
          </DataTableRow>
        </DataTableHead>

        <DataTableBody>

          {
            !installment ? (
              <CircularProgress color="inherit" />
            ) : (
                installment && installment.map((product: any) => (
                  <Installments installment={product} key={product.id} />
                ))
              )
          }
        </DataTableBody>
      </DataTable>
    </ContainerTable>
  );
};

export default SkeletonTable;
