import React from 'react'
import ImageInput from '../ImgFormData'
import { Form } from '@unform/web'
import { StyledModal } from './style'

interface Props {
  visible: boolean
  handleOk: any,
  handleCancel: any
}

const ImgModal: React.FC<Props> = ({
  visible,
  handleOk,
  handleCancel,
  ...rest
}) => {
  const handleSubmit = (data: any) => {
    
  }

  return (
    <>
      <StyledModal
        title="Inserir Imagem"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        {...rest}
      >
        <Form onSubmit={handleSubmit}>
          <ImageInput name="productImg" />
        </Form>
      </StyledModal>
    </>
  )  
}

export default ImgModal