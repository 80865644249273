import { Card, Menu } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import iconDown from '../../assets/icons/keyboard_arrow_down-white-24dp.svg'
import icon from '../../assets/icons/menu-24px.svg'
import student from '../../assets/images/aluno.jpg'
import background1 from '../../assets/images/back1.png'
import background from '../../assets/images/background.jpg'
import background2 from '../../assets/images/backMain.jpg'
import logo from '../../assets/images/logo.png'
import producer from '../../assets/images/produtor.jpg'
import {
  CardContainer,
  Content,
  DropdownResponsivo,
  EntrarBtn,
  Frame,
  Heading,
  Image,
  ListOptions,
  Main,
  StyledButton,
  StyledCard,
  StyledMenu,
  SubscribeBtn,
  TextoApresenta,
  TextoCard,
  Title,
  TopNav,
} from './style'

const { Meta } = Card

const Landing = () => {
  const menu = (
    <StyledMenu>
      <Menu.Item>
        <a href="#quemSomos">
          <li>Quem Somos</li>
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href="#queFazemos">
          <li>O Que Fazemos</li>
        </a>
      </Menu.Item>
      {/* <Menu.Item><a href='#provas'><li>Quem Usa</li></a></Menu.Item> */}
      <hr style={{ width: '75%', margin: '4px auto' }} />
      <Menu.Item>
        <Link to="/login">
          <EntrarBtn>Entrar</EntrarBtn>
        </Link>
      </Menu.Item>
    </StyledMenu>
  )

  return (
    <>
      <TopNav>
        <a href="#home">
          <img height="50px" alt="logo" src={logo} />
        </a>
        <ListOptions>
          <a href="#quemSomos">
            <li>Quem Somos</li>
          </a>
          <a href="#queFazemos">
            <li>O Que Fazemos</li>
          </a>
          {/* <a href='#provas'><li>Quem Usa</li></a> */}
          <Link to="/login">
            <EntrarBtn>Entrar</EntrarBtn>
          </Link>
        </ListOptions>
        <DropdownResponsivo
          overlay={menu}
          placement="bottomLeft"
          trigger={['click', 'hover']}
        >
          <img src={icon} alt="lgo" />
        </DropdownResponsivo>
      </TopNav>
      <Main>
        <Frame id="home" background={`url(${background})`}>
          <Content>
            <Heading>
              <h1>Skycode</h1>
              <h2>
                Mussum Ipsum, cacilds vidis litro abertis. Paisis, filhis,
                espiritis santis. Aenean aliquam molestie leo, vitae iaculis
                nisl. Não sou faixa preta cumpadi, sou preto inteiris, inteiris.
                Mé faiz elementum girarzis, nisi eros vermeio.
              </h2>
              <Link to="/register">
                <SubscribeBtn>Inscrever-se</SubscribeBtn>
              </Link>
            </Heading>
            <a href="#quemSomos">
              <span style={{ padding: 12 }}>
                <img src={iconDown} alt="" />
              </span>
            </a>
          </Content>
        </Frame>

        <Frame id="quemSomos" background={`url(${background1})`}>
          <Content>
            <Frame className="greenPoly" background="">
              <Frame className="poly" background="">
                <TextoApresenta>
                  <p>
                    Mussum Ipsum, cacilds vidis litro abertis. Si u mundo tá
                    muito paradis? Toma um mé que o mundo vai girarzis! Copo
                    furadis é disculpa de bebadis, arcu quam euismod magna. Tá
                    deprimidis, eu conheço uma cachacis que pode alegrar sua
                    vidis. Vehicula non. Ut sed ex eros. Vivamus sit amet nibh
                    non tellus tristique interdum.
                    <br />
                    <br />
                    Viva Forevis aptent taciti sociosqu ad litora torquent. Per
                    aumento de cachacis, eu reclamis. Interagi no mé, cursus
                    quis, vehicula ac nisi. Leite de capivaris, leite de mula
                    manquis sem cabeça.
                  </p>
                </TextoApresenta>
              </Frame>
            </Frame>
          </Content>
        </Frame>
        <Frame id="queFazemos" background={`url(${background2})`}>
          <Content>
            <Frame className="greenPoly2" background="">
              <Frame className="poly2" background="">
                <TextoApresenta className="texto2">
                  <p>
                    Embasados nos princípios cristãos, como honestidade,
                    transformação, contribuição, crescimento e exponencialidade,
                    desenvolvemos a XGrow para proporcionar a melhor experiência
                    para os usuários, uma plataforma flexível que busca atingir
                    as principais necessidades quando se trata de
                    desenvolvimento online, seja ele: business, financeiro,
                    intelectual ou network. Tudo isso através de um conceito
                    intuitivo e focado em alta performance.
                  </p>
                </TextoApresenta>
              </Frame>
            </Frame>
          </Content>
        </Frame>

        <Frame id="provas" background={`url(${background})`}>
          <Content className="cards">
            <Title color="#FFF">Nosso ecossistema</Title>
            <CardContainer>
              <StyledCard cover={<Image alt="example" src={producer} />}>
                <Meta
                  title="X"
                  description={
                    <>
                      <TextoCard>
                        Mussum Ipsum, cacilds vidis litro abertis. Si u mundo tá
                        muito paradis? Toma um mé que o mundo vai girarzis! Copo
                        furadis é disculpa de bebadis, arcu quam euismod magna.
                        Tá deprimidis, eu conheço uma cachacis que pode alegrar
                        sua vidis. Vehicula non. Ut sed ex eros. Vivamus sit
                        amet nibh non tellus tristique interdum.
                      </TextoCard>
                    </>
                  }
                />
                <Link to="/register">
                  <StyledButton type="primary" size={'large'}>
                    Cadastrar
                  </StyledButton>
                </Link>
              </StyledCard>
              <StyledCard cover={<Image alt="example" src={student} />}>
                <Meta
                  title="Y"
                  description={
                    <TextoCard>
                      Mussum Ipsum, cacilds vidis litro abertis. Si u mundo tá
                      muito paradis? Toma um mé que o mundo vai girarzis! Copo
                      furadis é disculpa de bebadis, arcu quam euismod magna. Tá
                      deprimidis, eu conheço uma cachacis que pode alegrar sua
                      vidis. Vehicula non. Ut sed ex eros. Vivamus sit amet nibh
                      non tellus tristique interdum.
                    </TextoCard>
                  }
                />
                <Link to="/register">
                  <StyledButton type="primary" size={'large'}>
                    Cadastrar
                  </StyledButton>
                </Link>
              </StyledCard>
            </CardContainer>
          </Content>
        </Frame>
      </Main>
    </>
  )
}

export default Landing
