import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import StyledButton from 'components/new_components/button';
import { AiFillAlert, AiOutlineAlert, AiOutlineClose, AiOutlineWarning } from 'react-icons/ai';


interface ModalOptions{
  icon?: any
  okBtn?: any
  btnOpen?: boolean
  btnText?: any
  iconOpen?: boolean
  title: any
  text: any
}

function getModalStyle() {
  return {
    left: '50%',
    top: '50%',
    marginLeft: '-150px',
    marginTop: '-150px',
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 300,
      height: 300,
      backgroundColor: '#fff',
      borderRadius: '8px',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    headerModal:{
      display: 'flex',
      justifyContent: 'space-between',
      'h3':{
        padding: 8,
      }
    },
    contentModal:{
      flex: 1,
      padding: '20px',

    },
    footerModal:{
      textAlign: 'end',
    },
    close:{
      fontSize: '24px',
      color: 'red',
      padding: '2px',
      cursor: 'pointer',
    },
    icon: {
      fontSize: 32,
    },
    heading: {
      display: 'flex',
      alignItems: 'center',
    }
  }),
);

export default function StyledModal(options: ModalOptions) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className={classes.headerModal}>
        <div className={classes.heading}>
          <span className={classes.icon}>{options.icon? options.icon : false}</span>
          <h3 style={{marginLeft: 8}}>{options.title}</h3>
        </div>
        <AiOutlineClose onClick={handleClose} className={classes.close}/>
      </div>

      <div className={classes.contentModal}>
        <p>{options.text}</p>
      </div>

      <div className={classes.footerModal}>
        <StyledButton onClick={handleClose}>Ok</StyledButton>
      </div>
    </div>
  );

  return (
    <div>
      {options.iconOpen === false ? false :
      <AiOutlineWarning onClick={handleOpen} style={{fontSize: '48px', padding: '8px', color: 'var(--secondary)'}} />
      }
      {options.btnOpen === false ? false :
      <StyledButton onClick={handleOpen}>
        {options.btnText? options.btnText : 'Abrir Modal' }
      </StyledButton>
      }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}