import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import LayoutDefault from '../../components/LayoutDefault'
import { isAuthenticated } from '../../utils/login'

const PrivateRoute = ({ component, ...rest }: any) => {
  const routeComponent = (props: any) =>
    isAuthenticated() ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={{ pathname: '/login' }} />
    )
  return (
    <LayoutDefault>
      <Route {...rest} render={routeComponent} />
    </LayoutDefault>
  )
}

export default PrivateRoute
