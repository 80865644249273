import React from 'react'
import MenuIcon from 'assets/icons/3dotmenu-icon.svg'
import ArrowIcon from 'components/ArrowIcon'
import { Dropdown } from 'antd'
import { ImgContainer } from 'components/DataTable/style'
import {
  DataTableRow,
  DataTableCell,
} from 'components/DataTable'
import { MenuCard } from './style'
import Perfil from 'assets/perfil.png'

interface Student {
  supplier: {
    img: any
    productor_name: string
    productor_email: string
    productor_pix_key: string
    productor_business_website: string
    product_name: string
    productor_cell_phone: string
    porcentage_complete: number
    last_interation: number
  }
}

const Student: React.FC<Student> = ({ supplier }) => {
  const {
    img,
    productor_name,
    productor_email,
    productor_business_website,
    productor_cell_phone,
    productor_pix_key,
    // porcentage_complete,
    // last_interation,
    // product_name
  } = supplier

  const dropdownMenu = (
    <MenuCard onClick={() => {
      if (productor_cell_phone)
        window.open(`https://wa.me/55${productor_cell_phone.replace(/[^0-9.]/g, '')}`, '_blank')
    }}>
      {productor_cell_phone ? 'Enviar mensagem pelo Whatsapp' : 'Não possui número de telefone cadastrado'}
      <ArrowIcon />
    </MenuCard>
  )

  return (
    <DataTableRow>
      <DataTableCell component="th" scope="row">
        <ImgContainer>
          <img src={img ? img : Perfil} alt='' />
        </ImgContainer>
      </DataTableCell>
      <DataTableCell>{productor_name}</DataTableCell>
      <DataTableCell>{productor_email}</DataTableCell>
      <DataTableCell style={{ cursor: 'pointer' }} onClick={() => {
        window.open(`${productor_business_website}`, '_blank')
      }}>{productor_business_website}</DataTableCell>
      <DataTableCell>{productor_pix_key}</DataTableCell>
      <DataTableCell>
        {productor_cell_phone}
      </DataTableCell>      
      {/* 
      <DataTableCell>
        {last_interation ? last_interation : 0}
      </DataTableCell>
      */}
      <Dropdown overlay={dropdownMenu}>
        <DataTableCell style={{ cursor: 'pointer' }}>
          <img src={MenuIcon} alt="" />
        </DataTableCell>
      </Dropdown>
    </DataTableRow>
  )
}

export default Student