import styled from 'styled-components'
import { Menu } from 'antd'


export const MenuLink = styled(Menu)`

li span{
  
  @media screen and (max-width: 600px){
    margin: 0;
  }
}
  
  
  .anticon-shop{
    color: white;
  }
  &&& .ant-menu-item a{
    color: white;
  }

  &&& .ant-menu-item .anticon{
    color: white;
  }

  &&& .ant-menu-item-active a{
    color: #898989;
  }

  &&& .ant-menu-item-active .anticon{
    color: #898989;
  }

  &&& .ant-menu-item-selected{
    background: black;
  }
  &&& .ant-menu-item-selected a{
    color: var(--main);
  }

  &&& .ant-menu-item-selected .anticon{
    color: var(--main);
  }
  

`

export const Container_log = styled.div`
  position: absolute;
  width: 150.51px;
  height: 55.09px;
  left: 28px;
  top: 18px;
  img {
    width: 180.51px;
  }
`

export const Dot2 = styled.span`
  height: 50px;
  width: 50px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 55px;
  top: 18px;
  z-index: 10000000;
`
