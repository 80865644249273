import { Container, Body, Title } from './styles'
import HeaderPromotor from '../../components/HeaderPromotor'
import api from 'services/api'
import React, { useState, useEffect } from 'react'
import {  Row, Col } from 'antd'
import Table from 'components/Financial/Cancels'
import Footer from 'containers/Footer'

const ReversalPayment = () => {
  const [idProfile, setIdProfile] = useState<any>()

  const profile = async () => {
    let response = await api.get('profile')
    setIdProfile(response.data.user.id)
  }

  useEffect(() => {
    profile()
  }, [idProfile])

  return (
    <>
      <Container>
        <HeaderPromotor SearchHeader={true} />
        <Title>
          Solicitações de estornos
        </Title>
        <Body>
          <Row>
            {/* <Col span={6}>
              <InfoCancel />
            </Col> */}
            <Col span={24}>
              <Table/>
            </Col>
          </Row>
        </Body>
      </Container>
      <Footer />
    </>
  )
}

export default ReversalPayment
