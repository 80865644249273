import React, {useEffect, useState} from 'react'
import { FaChevronRight } from 'react-icons/fa'
import {
    CardContainer,
    Title,
    SubTitle,
    HeaderConquest,
    SelectAll,
    ContainerConquest,
} from './style'
import api from 'services/mock'
import Personal from 'containers/Personal'
import StudentBasic from 'containers/StudentBasicInfo'
import ListConquest from 'components/ProfileStudent/ConquestList'
interface propsUser{
    picture: any
    nivel: string
    description: string
}

const Conquest = () =>{
    const [ conqueste, setConqueste ] = useState<Array<propsUser>>([])
    const [ studentBasic, setStudentBasic ] = useState<Boolean>(false)
    const [ personal, setPersonal ] = useState<Boolean>(false)

    useEffect(() => {
        api.getConquest()
          .then((data: any) => {
            setConqueste(data.conquest)
          })
      }, [])

      const handleStudent = () =>{
        setStudentBasic(true)
    }
    const handlePersonal = () =>{
        setPersonal(true)
    }

    return(
        <>
            {personal === true 
                ?
                <Personal />

                :
            
                studentBasic === true 
                
                ?
                <StudentBasic />
                :
                    <main>
                        <SubTitle>
                                    <p onClick={() => handleStudent()}>Perfil</p>
                                    <FaChevronRight color="#898989" size={12} style={{ marginLeft: 5, marginRight: 5 }} />
                                    Conquista
                                    <FaChevronRight color="#898989" size={12} style={{ marginLeft: 5, marginRight: 5 }} />
                                    <p onClick={() => handlePersonal()}>Dados Pessoais</p>
                                </SubTitle>
                        <CardContainer>
                            <HeaderConquest>
                                <Title>
                                    Conquistas
                                </Title>
                                <SelectAll>
                                    Selecionar todos
                                </SelectAll>
                            </HeaderConquest>
                            <ContainerConquest>
                                { conqueste.map((item: any) => (
                                    <>
                                    <ListConquest img={item.picture} nivel={item.nivel} description={item.description} />
                                    </>
                                ))}
                            </ContainerConquest>
                        </CardContainer>
                    </main>
                }
        </>
    )
}

export default Conquest;