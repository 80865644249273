import React from 'react'
import Overview from './Overview'
import MyStudents from './MyStudents'
import { Title, FlexContainer } from '../shared-style'
import { Container } from '../../../GlobalStyles'
import Header from '../../../components/HeaderPromotor/index'
import Footer from 'containers/Footer'

const Suppliers = () => {
  return (
    <>
      <Container>
        <Header SearchHeader={true} />
        <Title>Fornecedores</Title>

        {/*
        <FlexContainer>
          <Overview />
          <Community />
        </FlexContainer>
        */}

        <MyStudents />
      </Container>
      <Footer />
    </>
  )
}

export default Suppliers
