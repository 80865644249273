import styled from 'styled-components'

export const FormContainer = styled.div`
  flex: 1;
  margin-right: 9.61813rem;
`

export const ProductInfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: 11.875rem;
`

export const ProductInfoContent = styled.div``

export const ProductInfoImg = styled.div`
  width: 14.9375rem;
  height: 9.875rem;
  margin-right: 1.8375rem;
  background-color: #E6E6E6;
`

export const ProductInfoTitle = styled.h1`
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.625rem;
  letter-spacing: 0.009375rem;
  color: #5C5C5C;
  width: 15rem;  
`

export const ProductInfoText = styled.p`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  color: #A2A2A2;

  &:last-child {
    margin-top: 2.45rem;
  }
`

export const PaymentContent = styled.section`
  width: 100%;
`

export const Price = styled.h1`
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.1875rem;
  letter-spacing: 0.009375rem;
  color: #292929;
`

export const PriceAlternative = styled.p`
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.009375rem;
  color: #898989;
`

export const AcceptedCards = styled.div`
  display: flex;
  justify-content: space-between;
`