
import React from 'react'
import { Container } from '../../GlobalStyles'

const h1Style = {
    fontSize: '60px',
    color: '#292929',
    flex: 1,
    marginTop: '20%',
    
}

const NotFound = () => {
  return (
    <Container style={{ textAlign:'center', width: '100vw', height: '100vh', display: 'flex'}}>
      <h1 style={h1Style}>Página não encontrada :(</h1>
    </Container>
  )
}

export default NotFound