import React, { useState, useEffect } from 'react'
import {
  Container,
  LogoutButton,
  UserName,
  ProfilePic,
  SettingButton,
  Footer,
  StyledLink,
} from './style'
import { logout } from 'utils/login'
import api from 'services/api'

interface Props {
  photo?: string
  role?: string
}


const MiniProfile: React.FC<Props> = ({ photo, role }) => {
  const [name, setName] = useState<any>()



  const getUser = async () => {

    let response = await api.get("profile");

    if (response.data.user.nickname === null || response.data.user.nickname === '') {
      setName(response.data.user.name)
    } else {
      setName(response.data.user.nickname)
    }

  }

  useEffect(() => {
    getUser()
  }, [])

  return (
    <Container>
      <ProfilePic>
        <img src={photo} alt="Foto de perfil" />
      </ProfilePic>

      <UserName>{name}</UserName>

      <StyledLink to="/student">
        <SettingButton>
          <img className="icon" src="" alt="" />
          <p>Configurações</p>
        </SettingButton>
      </StyledLink>

      <Footer>
        <LogoutButton onClick={logout}>Sair</LogoutButton>
      </Footer>
    </Container>
  )
}

export default MiniProfile
