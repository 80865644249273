import React from "react";
import Menu from "../Menu";

const LayoutDefault = ({ children }: any) => {
  return (
    <>
      <Menu children={children} />
      {/* {children} */}
    </>
  );
};

export default LayoutDefault;
