/*
Passo 4 da edição de produto:
Adição de módulos, aulas e testes.
*/

import React, { useState, useContext } from 'react'
import {
  ContentFooter,
  ActionBtn,
  MainContainer,
  ContentContainer,
} from '../shared-styles'
import { ModuleList, CompleteEdit, DateInput, NoModulesMsg } from './style'
import history from 'routes/history'
import api from 'services/api'
import HeaderNewProduct from 'components/new/HeaderNewProduct'
import { Form } from '@unform/web'
import Input from 'components/new/Input'
import ArrowIcon from 'components/new/ArrowIcon'
import { CircularProgress } from '@material-ui/core/'
import { StepContext } from '../../../StepContext'
import { EditProductContext } from '../../EditProductContext'
import {
  displayErrorNotification,
  displaySucessNotification,
} from 'utils/displayNotification'
import NewModuleModal from './NewModuleModal'
import Module from './Module'

const ContentRegistration: React.FC = () => {
  const [editProductData] = useContext(EditProductContext)
  const [loading, setLoading] = useState<boolean>(false)
  const { handlePreview } = useContext(StepContext)

  const sleep = (milliseconds: any) => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true)
      }, milliseconds)
    })
  }

  const handleSubmit = async (data: any) => {
    setLoading(true)

    let release_date: any = data.release_date

    let finalJSON = {
      ...editProductData.finalJSON,
      release_date,
      modules: editProductData.modules ? editProductData.modules : [],
    }

    // TODO: IMG
    finalJSON.photo = JSON.stringify(finalJSON.photo)

    console.log(finalJSON)

    try {
      await api.put(`/update-product/${editProductData.id}`, finalJSON)
      displaySucessNotification('Produto editado com sucesso!')
      history.push('/products')
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    } finally {
      setLoading(false)
    }

    setLoading(false)
  }

  return (
    <MainContainer>
      <ContentContainer>
        <HeaderNewProduct
          title="4 - Cadastro de Conteúdo"
          subtitle="Adicione módulos, aulas e testes na trilha de ensino do seu produto."
        />

        <ModuleList>
          {editProductData.modules.length === 0 ? (
            <NoModulesMsg>Nenhum módulo cadastrado.</NoModulesMsg>
          ) : (
            editProductData.modules.map((module: any) => (
              <Module module={module} />
            ))
          )}
        </ModuleList>

        <NewModuleModal />

        <Form onSubmit={handleSubmit}>
          <Input
            title="Data de lançamento"
            style={{ width: '200px', margin: '3rem 0', padding: '16px' }}
          >
            <DateInput
              name={'release_date'}
              placeholderText="-- / -- / --"
              defaultValue={editProductData.release_date}
            />
          </Input>

          <ContentFooter>
            <ActionBtn onClick={handlePreview} type="button">
              <ArrowIcon rotateDeg={90} color="var(--main)" />
              <p>Voltar</p>
            </ActionBtn>

            <CompleteEdit type="submit">
              {loading ? (
                <CircularProgress color="inherit" size={'1.1rem'} />
              ) : (
                <p>Concluir edição</p>
              )}
            </CompleteEdit>
          </ContentFooter>
        </Form>
      </ContentContainer>
    </MainContainer>
  )
}

export default ContentRegistration
