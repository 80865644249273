/*
Nessa página você terá a visão geral do estudante de quando ele está fazendo o curso,
gerenciamento de quando a próxima aula deve ser chamada (sequência de aulas), progresso de curso,
trilha de conteúdos, dentre outros.
*/

import React, { useState, useEffect } from 'react'
import Breadcrumb from 'components/new/BreadCrumb'
import CourseProgress from 'components/new/CourseProgress'
import Concluded from 'components/new/Concluded'
import CourseViewContent from 'containers/CourseViewContent'
import api from 'services/api'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { displaySucessNotification } from 'utils/displayNotification'
import Button from 'components/new_components/button'
import {
  StyledContainer,
  Main,
  Header,
  HeaderCurso,
  Sidebar,
  Status,
  Lessons,
  ContainerView,
  ControlButton,
  Msg,
  TestsView,
  ViewLast,
  Name,
  StyledButton,
  SelectedClass,
  MainContainer,
  Container
} from './styles'
import { Collapse } from 'antd'
import Footer from 'containers/Footer'
import Info from 'components/Financial/Info'
import HeaderPromotor from 'components/HeaderPromotor'


const { Panel } = Collapse

interface PropsClasses {
  id: number | undefined
  sequence: string | undefined
  description: string | undefined
  video_link: string | undefined
  name: string | undefined
}

const CourseView = () => {
  const [nameProduct, setNameProduct] = useState<any>()
  const [classes, setClasses] = useState<any>()
  const [tests, setTests] = useState<any>()
  const [pdfLink, setPdfLink] = useState<any>()
  const [numberLessons, setNumberLessons] = useState<any>()
  const [videoLink, setVideoLink] = useState<string>('')
  const [nameClass, setNameClass] = useState<any>()
  const [descriptionClass, setDescriptionClass] = useState<any>()
  const [modules, setModules] = useState<any>([])
  const [typeClass, setTypeClass] = useState<string>('classes')
  const [afterClass, setAfterClass] = useState<any>()
  const [afterSequence, setAfterSequence] = useState<any>()
  const [afterModule, setAfterModule] = useState<any>()
  const [afterType, setAfterType] = useState<any>()
  const [lastClass, setLastCLass] = useState<any>()
  const [lastSequence, setLastSequence] = useState<any>()
  const [lastModule, setLastModule] = useState<any>()
  const [lastType, setLastType] = useState<any>()
  const [noModule, setNoModule] = useState<boolean>(false)
  const [conlusionId, setConclusionId] = useState<any>()
  const [moduleDefault, setModuleDefault] = useState<any>()
  const [progress, setProgress] = useState<any>()
  const [archive, setArchive] = useState<any>()
  const [conclusion, setConclusion] = useState<any>(false)

  const [view, setView] = useState(true)

  const init = async () => {
    let number = sessionStorage.getItem('course')
    let response = await api.get(`/get-course/${number}`)
    setNumberLessons(response.data.total_class_in_course)
    setProgress(response.data.progress)
    let totalClasses: any = []
    if (response.data.product.modules.length === 0) {
      setNoModule(true)
    } else {
      if (response.data.product.modules) {
        response.data.product.modules.map((item: any) =>
          totalClasses.push(item.classes),
        )

        let totalTests: any = []
        response.data.product.modules.map((item: any) =>
          totalTests.push(item.tests),
        )

        let valueTests = 0
        totalTests.map((item: any) => item.map((classes: any) => valueTests++))
        let valueClasses = 0
        totalClasses.map((item: any) =>
          item.map((classes: any) => valueClasses++),
        )
        setArchive(response.data.product.modules[0].classes[0].attachment)
        setModuleDefault(response.data.product.modules[0].id)
        setConclusionId(response.data.product.modules[0].classes[0].id)
        setModules(response.data.product.modules)
        // setNumberLessons(valueTests + valueClasses)
        setNameProduct(response.data.product.name)
        setClasses(totalClasses)
        setTests(totalTests)
        setVideoLink(totalClasses[0][0].video_link)
        setDescriptionClass(totalClasses[0][0].description)
        setNameClass(totalClasses[0][0].name)
        if (totalClasses[0][1]) {
          setAfterClass(totalClasses[0][1].name)
          setAfterSequence(2)
          setAfterModule(1)
          setAfterType('classes')
        } else {
          setAfterClass(false)
        }
      }
    }
  }

  useEffect(() => {
    init()
  }, [])

  const [atualModule, setAtualModule] = useState<any>(1)
  const [atualClass, setAtualClass] = useState<any>(0)

  const handleClasses = (module: any, id: any) => {
    setAtualModule(module)
    setAtualClass(id + 1)
    setTypeClass('classes')
    setVideoLink(classes[module - 1][id].video_link)
    setDescriptionClass(classes[module - 1][id].description)
    setNameClass(classes[module - 1][id].name)
    setConclusionId(classes[module - 1][id].id)
    setArchive(classes[module - 1][id].attachment)

    let max = classes[module - 1].length

    if (id <= max) {
      // verificação se tem aula antes do atual
      if (classes[module - 1] && classes[module - 1][id - 2]) {
        setLastCLass(classes[module - 1][id - 2].name)
        setLastSequence(classes[module - 1][id - 2].sequence)
        setLastModule(module)
        setLastType('classes')
      } else {
        setLastCLass(false)
      }

      // verificação se tem aula depois do atual
      if (classes[module - 1] && classes[module - 1][id]) {
        setAfterClass(classes[module - 1][id].name)
        setAfterSequence(classes[module - 1][id].sequence)
        setAfterModule(module)
        setAfterType('classes')
      } else {
        setAfterClass(false)
        // verificar se tem tests depois da aula
        if (tests[module - 1] && tests[module - 1][0]) {
          setAfterClass(tests[module - 1][0].name)
          setAfterSequence(tests[module - 1][0].sequence)
          setAfterModule(module)
          setAfterType('tests')
        } else {
          setAfterClass(false)
        }
      }
    }
  }
  const [atualTest, setAtualTest] = useState<any>(2)

  const handleTests = (module: any, id: any) => {

    setAtualTest(id)
    setTypeClass('tests')
    setPdfLink(tests[module - 1][id].pdf_link)
    setDescriptionClass(tests[module - 1][id].description)
    setNameClass(tests[module - 1][id].name)
  }

  const handleSteps = (module: any, id: any, type: any) => {
    if (type === 'classes') {
      handleClasses(module, id)
    } else {
      handleTests(module, id)
    }
  }

  const aproved = async (e: any) => {
    if (e.played >= 0.98) {
      let body = {
        product_class_id: conlusionId,
      }

      await api.post('/course-progress', body)
        .then((res: any) => res)
    }
    if (e.played > 0.99) {
      let number = sessionStorage.getItem('course')
      let response = await api.get(`/get-course/${number}`)
      setNumberLessons(response.data.total_class_in_course)
      setProgress(response.data.progress)
      // displaySucessNotification('Aula finalizada, passe para próxima aula')

      // setTimeout(() => {
      //   displaySucessNotification('Iniciando a proxima aula')

      //   if (response.data.total_class_in_course === response.data.progress) {
      //     setConclusion(true)
      //   }
      //   if (
      //     classes[atualModule - 1][atualClass]
      //   ) {
      //     handleClasses(atualModule, atualClass)
      //   } else if (tests[atualModule - 1] && tests[atualModule - 1][0]) {
      //     handleTests(atualModule, 0)
      //   } else if (classes[atualModule] && classes[atualModule][0]) {
      //     handleClasses(atualModule + 1, 0)
      //   } else if (
      //     tests[atualModule - 1] &&
      //     tests[atualModule - 1][atualTest]
      //   ) {
      //     handleTests(atualModule, atualTest)
      //   } else if (tests[atualModule + 1] && tests[atualModule + 1][0]) {
      //     handleTests(atualModule + 1, 0)
      //   }
      // }, 1000)
    }
  }

  const aprovedTeste = async () => {
    let body = {
      product_class_id: conlusionId,
    }

    await api.post('/course-progress', body)

    let number = sessionStorage.getItem('course')
    let response = await api.get(`/get-course/${number}`)
    setNumberLessons(response.data.total_class_in_course)
    setProgress(response.data.progress)
    displaySucessNotification('Teste finalizado, passe para próxima aula')
    // setTimeout(() => {
    //   

    //   if (response.data.total_class_in_course === response.data.progress) {
    //     setConclusion(true)
    //   }
    //   if (tests[atualModule - 1] && tests[atualModule - 1][atualTest + 1]) {
    //     handleTests(atualModule, atualTest + 1)
    //   } else if (tests[atualModule] && tests[atualModule][atualTest]) {
    //     handleTests(atualModule, atualTest)
    //   } else if (classes[atualModule]) {
    //     handleClasses(atualModule + 1, atualClass)
    //   } else if (classes[atualModule - 1]) {
    //     handleClasses(atualModule, 0)
    //   }
    // }, 1000)

  }

  const handleView = () => {
    setView(!view)
  }

  return (
    <>
      <Container>
        <div style={{ margin: '24px 32px' }}>
          <HeaderPromotor SearchHeader />
        </div>
        <StyledContainer>
          <Main>
            <Header>
              <Breadcrumb
                items={[
                  {
                    name: 'Produtos',
                    active: false,
                    link: '/products',
                  },
                  {
                    name: nameProduct,
                    active: true,
                    link: '/course',
                  },
                ]}
              />
              <CourseProgress
                total={numberLessons}
                done={progress ? progress : '0'}
                rewardAt={70}
              />
            </Header>
            <>
              {!noModule ? (
                <ContainerView>
                  {/* <HeaderCurso>
                    {!lastClass ? (
                      <div style={{width: '70px'}}></div>
                    ) : (
                      <ControlButton
                        onClick={() =>
                          handleSteps(lastModule, lastSequence, lastType)
                        }
                      >
                        <FiChevronLeft />
                        {lastClass}
                      </ControlButton>
                    )}
                    <Name>
                      <strong>{nameClass}</strong>
                      <Info InfoText='Para uma melhor experiência, se estiver usando celular, rotacione seu dispositivo :)' />
                    </Name>

                    {!afterClass ? (
                      <div style={{width: '70px'}}></div>
                    ) : (
                      <ControlButton
                        onClick={() =>
                          handleSteps(afterModule, afterSequence, afterType)
                        }
                      >
                        {afterClass}
                        <FiChevronRight />
                      </ControlButton>
                    )}
                  </HeaderCurso> */}
                  {typeClass === 'classes' ? (
                    <>
                      <CourseViewContent
                        videoLink={`https://cors-anywhere.herokuapp.com/${videoLink}`}
                        name={nameClass}
                        description={descriptionClass}
                        type={typeClass}
                        conclusion={(e: any) => aproved(e)}
                        idVideo={conlusionId}
                        linkArchive={archive}
                        view={handleView}
                      />
                    </>
                  ) : typeClass === 'tests' ? (
                    <>
                      <CourseViewContent
                        pdfLink={pdfLink}
                        name={nameClass}
                        description={descriptionClass}
                        type={typeClass}
                      />
                      <StyledButton onClick={() => aprovedTeste()}>Finalizar</StyledButton>
                    </>
                  ) : null}
                </ContainerView>
              ) : (
                  <>
                    <Msg>
                      <h2>Sem aula por enquanto</h2>
                    </Msg>
                  </>
                )}
            </>
          </Main>
          {view ?
            <Sidebar>
              <Status>
                <h3>Trilha de ensino</h3>
                <Lessons>
                  {modules &&
                    modules.map((item: any) => (
                      <Collapse
                        key={item.id}
                        defaultActiveKey={[String(moduleDefault)]}
                        expandIconPosition="right"
                        bordered={false}
                      >
                        <Panel header={item.name} key={item.id}>
                          {item.classes.map((_classes: any, index: number) => (
                            <li
                              key={index}
                              onClick={() => {
                                handleClasses(item.sequence, index)
                              }}
                            >
                              {_classes.name === nameClass ? (
                                <SelectedClass>{_classes.name}</SelectedClass>
                              ) : _classes.is_complete === true ? (
                                <ViewLast>{_classes.name}</ViewLast>
                              ) : (
                                    <ViewLast>{_classes.name}</ViewLast>
                                  )}
                            </li>
                          ))}

                          {item.tests.map((_tests: any, index: number) => (
                            <li
                              key={index}

                              onClick={() =>
                                handleTests(item.sequence, index)
                              }
                            >
                              {_tests.name === nameClass ? (
                                <SelectedClass><b>Teste:</b> {_tests.name}</SelectedClass>
                              ) : (
                                  <TestsView><b>Teste:</b> {_tests.name}</TestsView>
                                )}
                            </li>
                          ))}
                        </Panel>
                      </Collapse>
                    ))}
                </Lessons>
              </Status>
            </Sidebar>
            :
            null
          }
        </StyledContainer>
      </Container>
      <Footer />
    </>
  )
}

export default CourseView
