import styled from 'styled-components'
import { Card } from 'components/CardContainer'
import Input from 'components/new/Input'
import { Link } from 'react-router-dom'

export const CardContainer = styled(Card)`
    margin-top: 40px;
    width: 100%;
    padding: 40px;
    margin-bottom: 40px;
    border-top-left-radius: 0;
    @media screen and (max-width: 767px){
        padding: 0;
    }
`
export const StyledLink = styled(Link)`
    color: #9e9e9e;
    font-size: 14px;
    text-decoration: none;
    :hover{
        color: #9e9e9e;
    }
`
export const HeaderConquest = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const Title = styled.p`
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #5C5C5C;
    padding:20px;
`
export const SubTitle = styled.div`
    margin-top: 10px;
    width:100%;
    text-align:left;
    color: #898989;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;

    p{
        margin: 0;
        cursor: pointer;
    }
`
export const FlexAdress = styled.div`
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    div{
        width: 100%;
        height: auto;
    }


    fieldset{
            margin-bottom: 10px;
    }
    p{
        font-size: 13px;
        color: #A2A2A2;
        padding-left: 15px;
        margin: 0;
    }
`
export const FlexAcount = styled.div`
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    div{
        width: 100%;
        height: auto;
    }

    fieldset{
            margin-bottom: 10px;
    }
    p{
        font-size: 13px;
        color: #A2A2A2;
        padding-left: 15px;
        margin: 0;
    }
`
export const ContainerForm = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`
export const EditInput = styled(Input)`
`
export const SaveButton = styled.button`
    cursor: pointer;
    background: transparent;
    margin-top: 80px;
    width: 150px;
    height: 36px;
    text-align: center;
    border: 1px solid #C0C0C0;
    border-radius: 48px;
    color: #C0C0C0;
    transition: all 0.3s;
    outline: none;
    &:hover{
        border: 1px solid var(--main);
        color: white;
        background-color: var(--main);

    }
`
export const SelectForm = styled.div`
    select{
        margin-left: 10px;
        width: 95%;
        border: none;
        background: white;
        margin-bottom: 5px;
        height: 30px;
    }
`
export const FormEnd = styled.div`
    width: 100%;
    margin-left: 12px;
    button{
        margin-top: 20px;
    }
    @media screen and (max-width: 767px){
        width: 100%;
        margin: 0;
        padding: 12px
    }
`
export const Tabs = styled.div`
    display: flex;
    position: relative;
    
    top: -70px;
    left: -39px;
    @media screen and (max-width: 767px){
        left: -2px;
        top: -30px;
    }
`
export const FicharActive = styled.div`
    width: 120px;
    height: 30px;
    border: 1px solid #d9d9d9;
    border-bottom: none;
    position: relative;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #ffff;
    padding-top: 4px;
`
export const FicharInative = styled.button`
    width: 120px;
    height: 30px;
    border: 1px solid #d9d9d9;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #ffff;
    padding-top: 4px;
    cursor: pointer;
    outline: none;

    :hover{
        border: 1px solid var(--main-shade);
    }
`
export const FormEdit = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;
    max-width: 200px;

    

`

export const Button = styled.button`
    width: auto;
    padding: 0px 20px;
    margin: 8px;
    color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 3rem;
    background-color: var(--main);
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.015625rem;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 36px;
    margin-top: 64px;


    &:hover {
    color: #FFFFFF;
    background-color: var(--main-highlight);
    }
`
export const FooterButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`