import Affiliates from 'pages/Affiliates'
import Analytics from 'pages/Analytics'
import Productors from 'pages/BackOffice/Productors'
import Config from 'pages/Config'
import CourseView from 'pages/CourseView'
import Financial from 'pages/Financial'
import MarketPlace from 'pages/MarketPlace'
// TODO: Por que estamos usando numero nos nomes das paginas
import HomePage from 'pages/Home'
import StudentView from 'pages/Home/Producer/StudentView'
import Products from 'pages/Products'
import EditProduct from 'pages/Products/EditProduct'
import NewProduct from 'pages/Products/NewProduct'
import Profile from 'pages/Profile'
import ProducerView from 'pages/Profile/ProducerView'
import Suppliers from 'pages/Profile/Suppliers'
import Reports from 'pages/Reports'
import ReversalPayment from 'pages/ReversalPayment'
import ValidateEmail from 'pages/ValidateEmail'
import React from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import BackOffice from '../pages/BackOffice'
import BackOfficeConfig from '../pages/BackOffice/Config'
import ViewProduct from '../pages/BackOffice/ViewProduct'
import CalendarPage from '../pages/Calendar'
import ForgotPassword from '../pages/ForgotPassword'
import Landing from '../pages/Landing'
import Login from '../pages/Login'
import ConfirmEmail from '../pages/ConfirmEmail'
import NotFound from '../pages/NotFound'
import Register from '../pages/Register'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import StudentCheckout from '../pages/StudentCheckout'
import GuestRoute from './guest'
import history from './history'
import PrivateRoute from './private'
import Personal from 'pages/Profile/Personal'

const Routes: React.FC = () => (
  <Router history={history}>
    <Switch>
      <Route path="/404" component={NotFound} />
      <PrivateRoute exact path="/" component={HomePage} />
      <PrivateRoute exact path="/market" component={StudentView} />
      <PrivateRoute exact path="/market/checkout" component={StudentCheckout} />
      <PrivateRoute exact path="/financial" component={Financial} />
      <PrivateRoute exact path="/marketPlace" component={MarketPlace} />
      <PrivateRoute exact path="/products" component={Products} />
      <PrivateRoute exact path="/course" component={CourseView} />
      <PrivateRoute exact path="/products/new" component={NewProduct} />
      <PrivateRoute exact path="/products/edit" component={EditProduct} />
      <PrivateRoute exact path="/products/view" component={ViewProduct} />
      <PrivateRoute exact path="/student" component={Profile} />
      <PrivateRoute exact path="/personal" component={Personal} />
      <PrivateRoute exact path="/student-producer" component={ProducerView} />
      <PrivateRoute exact path="/suppliers" component={Suppliers} />
      <PrivateRoute exact path="/affiliates" component={Affiliates} />
      <PrivateRoute exact path="/reports" component={Reports} />
      <PrivateRoute exact path="/analytics" component={Analytics} />
      <PrivateRoute exact path="/calendar" component={CalendarPage} />
      <PrivateRoute exact path="/cancel" component={ReversalPayment} />
      <PrivateRoute exact path="/config" component={Config} />
      <PrivateRoute exact path="/BackOffice" component={BackOffice} />
      <PrivateRoute
        exact
        path="/BackOfficeConfig"
        component={BackOfficeConfig}
      />
      <PrivateRoute
        exact
        path="/backOffice/productors"
        component={Productors}
      />
      <GuestRoute exact path="/home" component={Landing} />
      <GuestRoute exact path="/login" component={Login} />
      <Route path="/confirm-email/:token">
        <ConfirmEmail />
      </Route>
      <GuestRoute exact path="/register" component={Register} />
      <GuestRoute exact path="/privacyPolicy" component={PrivacyPolicy} />
      <GuestRoute exact path="/forgot" component={ForgotPassword} />
      <GuestRoute exact path="/validate" component={ValidateEmail} />

      <Redirect to="/404" />
    </Switch>
  </Router>
)
export default Routes
