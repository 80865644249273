import styled from 'styled-components'
import { Modal } from 'antd'

export const StyledModal = styled(Modal)`
  &&& .ant-modal-footer > .ant-btn:hover {
    border-color: #292929;
    color: #292929;
  }
  &&& .ant-modal-footer > .ant-btn-primary {
    background: var(--main);
    border-color: var(--main);
  }
  &&& .ant-modal-footer > .ant-btn-primary:hover {
    border-color: #92ce0f !important;
    background-color: #92ce0f;
    color: whitesmoke !important;
  }
`