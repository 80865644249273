import styled from 'styled-components'
import Form from 'components/new/Form'
import Textarea from 'components/new/Input/Textarea'


export const InputTextAre = styled(Textarea)`
`
export const DivOrder = styled.div`
  width: 70%;
`

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
  width: 18.75rem;
  margin-top: 3.125rem;

  input {
    margin-right: 0.9rem;
  }

  p {
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.015625rem;
    color: #5c5c5c;
    margin: 0;
  }
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;

  & input[type='image'] {
    margin-top: -2.2%;
    outline: none;
  }
`

export const ContentForm = styled(Form)`
  width: 100%;
  margin-bottom: 3rem;
`
export const Aprove = styled.button`
  bottom: 0.4rem;
  right: 5.25rem;
  padding: 0.425rem 1.25rem;
  outline: none;
  background: green;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
  }
`

export const Refuse = styled.button`
  bottom: 0.4rem;
  right: 5.25rem;
  padding: 0.425rem 1.25rem;
  outline: none;
  background: red;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
  }
`

interface Props {
  display: any;
}

export const Overlay = styled.div<Props>`
  position: fixed;
  display: ${ props => props.display }; 
  width: 100%; 
  height: 100%; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2; 
`
export const Modal = styled.div`
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: black;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);

  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

  min-height: 30%;
  width: 50%;

  p {
    margin: 3px;
    padding: 0;
    color: #000;
    font-size: 2rem;
  }

` 

export const Button = styled.button`
  float: right;

  margin: 4px;

  border-radius: 48px;
  border-color: #009640;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.25px;
  color: white;
  background: #009640;
  width: 150px;
  height: 36px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover{
    background: #0e0e;
    border-color: #0e0e;
  }
`