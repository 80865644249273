import styled from 'styled-components'
import { Layout } from 'antd'


export const Container = styled.div`
   .menu {
    background: 'black';
    margin-top: 20px;
  }
  
`

export const Container_log = styled.div`
  position: absolute;
  width: 150.51px;
  height: 55.09px;
  left: 28px;
  top: 18px;
  img {
    width: 180.51px;
  }
`
export const ContainerImage = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
`

export const SiderEdit = styled(Layout)`

  .ant-layout-sider-children{
    overflow: hidden;
    position: fixed;
    // overflow-y: scroll;
    @media (max-height: 388px) {
      overflow: scroll;
      
    }
  }
  .calls {
    background-color: 'black';
    position: relative;
    height: 100vh;

    @media (max-width: 600px) {
      display: none;
    }
    
    
  }
  /* ------------ Temporário ------------*/
  .header{
    display:none;
    .logo {
      width: 100%;
      height: auto;
      float: left;
      img{
        width: 48px;
        height: 48px;
      }
    }/* Works on Chrome/Edge/Safari */
      
    
    @media (max-width: 600px) {
      
      display: flex;
      position: fixed;
      width: 100%;
      z-index: 999;
      background-color: #000;
      overflow-x: scroll;
      overflow-y: hidden;
      justify-content: space-between;
      padding: 0 8px;
      overflow-x: scroll;
      .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, 
      .ant-menu.ant-menu-dark .ant-menu-sub{
      background-color: #000;
      }
      .ant-menu-submenu-title>span{
        font-size: 28px;
      }
      .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
        background-color: #00000019;
      }
      li.ant-menu-item{
          background-color:#000;
        }
      
    }
    /* ------------------------- */
  }
  
`

export const Dot = styled.span`
  height: 50px;
  width: 50px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: visibility 10s, opacity 10s linear;

  position: absolute;
  left: 170px;
  top: 18px;
  z-index: 10000000;
`

export const Dot2 = styled.span`
  height: 50px;
  width: 50px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 55px;
  top: 18px;
  z-index: 10000000;
`
