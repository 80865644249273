import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'

// import { Container } from './styles';

const SelectMonth: React.FC = () => {
  
  const today = new Date(Date.now())
  const mesAtual = today.getMonth() + 1
  const anoAtual = today.getFullYear()

  const [mes, setMes] = useState(mesAtual)
  const [ano, setAno] = useState(anoAtual)
  
  const getMonth = () => {
    return {selectedMonth: mes}
  }

  const handleMonth = (mesAtual: number, increase: boolean) => {
    if(increase){
      if(mesAtual === 12){
        return setMes(1)
      }else{
        return setMes(mesAtual + 1)
      }
    }else{
      if(mesAtual === 1){
        return setMes(12)
      }else{
        return setMes(mesAtual - 1)
      }
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    > 
    <Button onClick={() => handleMonth(mes, false)}>
      <FaChevronLeft color="#81BD03" size={15}  />
    </Button>
      <select placeholder='Selecione um Mês' defaultValue={mes} style={{ margin: 0, marginLeft: 17, color: '#81BD03' }}  name="month-selector" id="month-selector">
        <option value={1}  selected={(mes === 1 ) ? true : false } >Janeiro</option>
        <option value={2}  selected={(mes === 2 ) ? true : false } >Fevereiro</option>
        <option value={3}  selected={(mes === 3 ) ? true : false } >Março</option>
        <option value={4}  selected={(mes === 4 ) ? true : false } >Abril</option>
        <option value={5}  selected={(mes === 5 ) ? true : false } >Maio</option>
        <option value={6}  selected={(mes === 6 ) ? true : false } >Junho</option>
        <option value={7}  selected={(mes === 7 ) ? true : false } >Julho</option>
        <option value={8}  selected={(mes === 8 ) ? true : false } >Agosto</option>
        <option value={9}  selected={(mes === 9 ) ? true : false } >Setembro</option>
        <option value={10} selected={(mes === 10 ) ? true : false } >Outubro</option>
        <option value={11} selected={(mes === 11 ) ? true : false } >Novembro</option>
        <option value={12} selected={(mes === 12 ) ? true : false } >Dezembro</option>
      </select>

      <select placeholder='Selecione um Ano' defaultValue={ano} style={{ margin: 0, marginLeft: 17, color: '#81BD03' }}  name="year-selector" id="year-selector">
        <option value={2020} selected={(ano === 2020 ) ? true : false}  >2020</option>
        <option value={2021} selected={(ano === 2021 ) ? true : false}  >2021</option>
        <option value={2022} selected={(ano === 2022 ) ? true : false}  >2022</option>
        <option value={2023} selected={(ano === 2023 ) ? true : false}  >2023</option>
      </select>
      <Button onClick={() => handleMonth(mes, true)}>
      <FaChevronRight color="#81BD03" size={15} style={{ marginLeft: 17 }} />
      </Button>
    </div>
  )
}

export default SelectMonth
