import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Breadcrumbs } from '@material-ui/core/'
import { Pagination } from 'antd'

export const Title = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  color: #898989;
  width: 100%;
  margin: 1.52625rem 0;
  span {
    color: var(--main);
  }
`
export const FormEdit = styled.div`
    margin: 10px auto;
    display: flex;
    justify-content: space-around;
    max-width: 250px;
    div{
      margin: 0 4px;
      width: auto;
    }
    label{
      padding-left: 4px;
    }
`

export const Breadcrumb = styled(Breadcrumbs)`
* {
  justify-content: center;
}
  &&& {
    margin: 2.345rem 0;
  }
`

export const Link = styled(NavLink)`
  color: ${({ color }) => color ? color : 'var(--main)'};

  &:hover {
    color: var(--main);
  }
`

export const Header = styled.header`
  display: grid;
  grid-template-columns: 0.15fr 1fr;
  grid-column-gap: 2vw;
  @media screen and (max-width: 842px){
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`

export const Subheader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 1.77625rem auto 1.043125rem;

  h1 {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.015625rem;
    color: #898989;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    flex: 2.86;

  }

  section {
    color: #a2a2a2;
    display: flex;
    flex: 2;

    

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.025rem;
      margin-left: 0.35rem;
    }
    
  }
  @media screen and (max-width: 767px){
      flex-direction: column;
      align-items: center;
    }
`


export const SearchContainer = styled.div`
  grid-column: 2;
  @media screen and (max-width: 767px){
    width: 100%;
    margin-bottom: 8px;
  }
`

export const ProductList = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SmallIcon = styled.img`
  width: 0.875rem;
  height: 0.875rem;
`

export const NoProductMsg = styled.h1`
  text-align: center;
  font-size: 1.25rem;
  margin-top: 2rem;
  color: #292929;
`


export const IconDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
  justify-content: center;
`
export const StyledPagination = styled(Pagination)`
  margin: 1rem 0 2.5rem;

  > .ant-pagination-item-active {
    border-color: var(--main);

    > a {
      color: var(--main);
    }

    &&&:hover {
      border-color: var(--main);
      color: var(--main);
    }
  }
`
export const GoToBtn = styled(Link)`
  grid-column: 1;
  width: max-content;
  padding: 0.4375rem 1.18875rem;
  color: #ffffff;
  box-sizing: border-box;
  border-radius: 3rem;
  background-color: var(--main);
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    margin-right: 0.41375rem;
    font-size: 1.34375rem;
  }

  &:hover {
    color: #ffffff;
    background-color: var(--main-highlight);
  }
`
export const NewProductBtn = styled.div`
  grid-column: 1;
  width: 145px;
  padding: 0.4375rem 1.18875rem;
  color: #ffffff;
  box-sizing: border-box;
  border-radius: 3rem;
  background-color: var(--main);
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    margin-right: 0.41375rem;
    font-size: 1.34375rem;
  }

  &:hover {
    color: #ffffff;
    background-color: var(--main-highlight);
  }
`