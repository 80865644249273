import styled from 'styled-components'

export const StyledNav = styled.nav`
  margin: 0 auto 3rem;

  a {
    padding: 1rem;
    border-bottom: 0.125rem solid lightgray;

    &:hover {
      color: var(--main);
    }
  }

  a.active {
    border-bottom: 0.125rem solid var(--main);
    color: var(--main);
  }
`
