import styled from 'styled-components'

interface Props {
  dark?: boolean
}

export const Container = styled.div<Props>`
  margin-top: 20px;
  padding-left: 20px;
  width: 100%;
  height: 50px;
  bottom: 0%;
  background: ${({ dark }) => (dark ? `#292929` : `#F8F8F8`)};
  color: ${({ dark }) => (dark ? `#C0C0C0` : `#5C5C5C`)};
  display: flex;
  align-items: center;
`
