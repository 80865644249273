import React, { useCallback, useEffect, useState } from 'react'
import MenuIcon from 'assets/icons/3dotmenu-icon.svg'
import ArrowIcon from 'components/ArrowIcon'
import { Dropdown } from 'antd'
import { ImgContainer } from 'components/DataTable/style'
import {
  DataTableRow,
  DataTableCell,
} from 'components/DataTable'
import { MenuCard } from './style'
import Perfil from 'assets/perfil.png'
import api from 'services/api'
import Button from 'components/new_components/button' 
import { AiOutlineDelete, AiOutlineRedo, AiOutlineRest } from 'react-icons/ai'
import { displaySucessNotification } from 'utils/displayNotification'
import { CircularProgress } from '@material-ui/core'

interface Subscription {
  subscription: {
    id: number
    product_name: string
    lead_name: string
    lead_email: string
    lead_cell_phone: string
    amount: number
    status: string
    created_at: Date
    subscription_id: string
  }
}

const Subscription: React.FC<Subscription> = ({ subscription }) => {
  const {
    id,
    product_name,
    lead_name,
    lead_email,
    lead_cell_phone,
    amount,
    status,
    created_at,
    subscription_id,
  } = subscription
  const [loading, setLoanding] = useState<any>(true)
  const [ currentStatus, setCurrentStatus] = useState('')

  const getStatus = useCallback(() =>{
    setCurrentStatus(status)
  },[status])

  const [ data, setData] = useState(Date)
  const [ hora, setHora] = useState(Date)
  const handleDate = (created_at: Date)=>{
    const date = new Date(created_at).toLocaleDateString();
    const time = new Date(created_at).toLocaleTimeString();
    setData(date)
    setHora(time)
  }

  const deleteSubscription = async()=>{
    setLoanding(false)
    await api.post(`/delete-subscriptions/${id}/${subscription_id}`)
    setLoanding(true)
    displaySucessNotification('Assinatura deletada com sucesso! Reinicie a página para ver as alterações.')
  }
  const reactivateSubscription = async()=>{
    setLoanding(false)
    await api.post(`/reactive-subscriptions/${id}/${subscription_id}`)
    setLoanding(true)
    displaySucessNotification('Assinatura reativada com sucesso! Reinicie a página para ver as alterações.')
  }
  const suspendSubscription = async()=>{
    setLoanding(false)
    await api.post(`/cancel-subscriptions/${id}/${subscription_id}`)
    setLoanding(true)
    displaySucessNotification('Assinatura suspensa com sucesso! Reinicie a página para ver as alterações.')
  }
  
  useEffect(()=>{
    handleDate(created_at)
    getStatus()
    console.log(currentStatus)
  },[created_at, currentStatus, getStatus])

  return (
    <DataTableRow>
      <DataTableCell>{id}</DataTableCell>
      <DataTableCell>{product_name}</DataTableCell>
      <DataTableCell>{lead_name}</DataTableCell>
      <DataTableCell>{lead_email}</DataTableCell>
      <DataTableCell>{lead_cell_phone}</DataTableCell>
      <DataTableCell>{status === 'ACTIVE'? 'Ativo' : status === 'CANCELLED'? 'Suspenso' : 'Inativo' }</DataTableCell>
      <DataTableCell>
        {data + ' | ' + hora}
      </DataTableCell>
      <DataTableCell>
        <strong style={{fontFamily: 'monospace', fontSize: 18}}>
          R$ {amount}
        </strong>
      </DataTableCell>
      <DataTableCell>
        <Button icon={<AiOutlineDelete/>} style={{backgroundColor: '#dc3545', fontSize:28}} onClick={deleteSubscription} />
      </DataTableCell>
      <DataTableCell>
        {status === 'CANCELLED'?
        
          !loading ? (
            <CircularProgress color="inherit" />
          )
            :
            <Button icon={<AiOutlineRedo/>} style={{backgroundColor: '#17a2b8', fontSize:28}} onClick={reactivateSubscription} />
        :
          !loading ? (
            <CircularProgress color="inherit" />
          )
            :
          <Button icon={<AiOutlineRest/>} style={{backgroundColor: '#ffc107', fontSize:28}} onClick={suspendSubscription} />
        }
      </DataTableCell>

    </DataTableRow>
  )
}

export default Subscription