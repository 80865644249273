import styled from 'styled-components'

export const ContainerNotifications = styled.div`
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;

  p{
    padding-top: 12px;
    display:inline-block;
  }
  @media screen and (max-width: 886px){
    margin-bottom: 12px;
  }
`
