import data from './data.json'

export default {
  getReports,
  getUsers,
  getConquest,
  getBank
}

function getReports() {
  return Promise.resolve(data)
}

function getUsers() {
  return Promise.resolve(data)
}
function getConquest() {
  return Promise.resolve(data)
}

function getBank() {
  return Promise.resolve(data)
}

