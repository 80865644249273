import styled from 'styled-components'
import VideoImage from 'assets/images/fakevideo.svg'

export const DivImg = styled.div`
  width: 100%;
  height: 480px;
  background-image: url(${VideoImage});
  padding: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: repeat-y;
  background-size: cover;

  iframe{
    border: none;
    width: 960px;
    height: 540px;
  }
`
export const Container = styled.div`
  margin-top: 25px;

  > img {
    width: 100%;
  }

  > p {
    margin-top: 32px;
    letter-spacing: 0.25px;
    color: #898989;
  }
`
export const ComplementaryMaterial = styled.div`
  margin: 50px 0;

  > h3 {
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #4f4f4f;
    margin-bottom: 25px;
  }
`
export const ContainerButtons = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
`
export const AnotationButton = styled.button`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 40px;
  padding: 10px 25px;
  background: var(--main);
  border-radius: 35px;
  border: 1px solid var(--main);
  color: black;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    opacity: 0.8;
    color: var(--main);
    background: white;
  }

`

export const DownloadButton = styled.a`
  display: flex;
  align-items: center;
  width: fit-content;

  padding: 5px 10px;

  border-radius: 35px;
  border: 1px solid var(--main);

  color: var(--main);

  &:hover {
    opacity: 0.8;
    color: var(--main);
  }

  svg {
    margin-left: 8px;
  }
`

export const CommentsWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  h3 {
    width: 100%;
    text-align: center;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #4f4f4f;
    margin-bottom: 25px;
  }
`

