import React, { ReactNode } from 'react'
import { Container, Title } from './style'

interface Props {
  children?: ReactNode
  width?: string
  height?: string
  title?: string
  style?: object
}

const Input: React.FC<Props> = ({
  children,
  width = '100%',
  height = 'max-content',
  title,
  style
}) => {
  return (
    <Container style={style} width={width} height={height}>
      {children}
      <Title>{title}</Title>
    </Container>
  )
}

export default Input
