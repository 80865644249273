import { CircularProgress } from '@material-ui/core/'
import { Dropdown } from 'antd'
import Button from 'components/Button'
import { CardTitle } from 'components/CardContainer'
import {
  DataTable,



  DataTableBody, DataTableCell, DataTableHead,
  DataTableRow
} from 'components/DataTable'
import RangeSlider from 'components/RangeSlider'
import Search from 'components/Search'
import React, { useCallback, useEffect, useState } from 'react'
import { AiOutlineDownload } from 'react-icons/ai'
import api from 'services/api'
import Subscription from './Subscription'
import {
  ButtonActive, ButtonInative, DownloadCard,
  FilterCard, FlexContainer, FooterPagination, Header, MyStudentsCard,



  NoStudentMsg, SearchContainer, StyledPagination,TableContainer
} from './style'

const Subscriptions: React.FC = () => {

  const [subscriptions, setSubscriptions] = useState<any>([])

  
  const getSubscriptions = useCallback(async()=>{
    
    await api.get('/get-subscriptions')
    .then((res)=>{
      if (res.data.records === undefined) {
        setSubscriptions({})
      } else {
        setSubscriptions(res.data.records)
      }
    })
  },[])

  useEffect(() => {
    getSubscriptions()
  }, [getSubscriptions])

  return (
    <>
      <MyStudentsCard>
        <CardTitle>Assinaturas</CardTitle>

          <DataTable>
            <DataTableHead>
              <DataTableRow>
                <DataTableCell>*</DataTableCell>
                <DataTableCell>Produto</DataTableCell>
                <DataTableCell>Comprador</DataTableCell>
                <DataTableCell>Email</DataTableCell>
                <DataTableCell>N. de Telefone</DataTableCell>
                <DataTableCell>Status</DataTableCell>
                <DataTableCell>Data de criação</DataTableCell>
                <DataTableCell>Valor</DataTableCell>
                <DataTableCell></DataTableCell>
                <DataTableCell></DataTableCell>
              </DataTableRow>
            </DataTableHead>
          
            <DataTableBody>
              {!subscriptions ? (
                <CircularProgress color="inherit" style={{ margin: '0 50%' }} />
              ) : subscriptions.length === 0 ? (
                <NoStudentMsg>Você não tem nenhuma transferência :/</NoStudentMsg>
              ) : (
                    subscriptions.map((subscription: any, index: number) => (
                      <Subscription subscription={subscription} key={index} />
                    ))
                  )}

              
            </DataTableBody>
          
          </DataTable>
       
        
      </MyStudentsCard>
    </>
  )
}

export default Subscriptions
