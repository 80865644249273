import React, { ReactNode } from 'react'
import { SearchContainer, Input } from './style'
import SearchIcon from '../../assets/icons/search-icon.svg'

interface SearchProps {
  callbackSearch?: any
  children?: ReactNode
  placeholder: string
  handleSearch?: any
  value?: any
}

const Search: React.FC<SearchProps> = ({
  callbackSearch,
  children,
  placeholder,
  handleSearch,
  value
}) => {



  return (
    <SearchContainer>
      <img src={SearchIcon} alt="Ícone de busca" />
      <Input
        onChange={handleSearch}
        value={value}
        placeholder={placeholder} />
      {
        children ? children : ''
      }
    </SearchContainer>
  )
}

export default Search