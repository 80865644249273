import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'

const createSwitch = (color: string) => {
  return withStyles({
    switchBase: {
      color: 'silver',
      '&$checked': {
        color: color,
      },
      '&$checked + $track': {
        backgroundColor: color,
      }
    },

    checked: {},
    track: {},
  })(Switch)
}

interface Props {
  color?: string
  onChange?: any
  checked?: boolean
  disabled?: boolean
}

const StyledSwitch: React.FC<Props> = ({
  color = '#82DB02',
  onChange,
  checked = false,
  disabled = false,
  ...rest
}) => {
  const MySwitch = createSwitch(color)

  const handleChange = () => {
    onChange()
  }

  return <MySwitch disabled={disabled} name="checked" checked={checked} onClick={handleChange} {...rest} />
}

export default StyledSwitch
