import { CardTitle } from 'components/CardContainer'
import React, { useEffect, useState } from 'react'
import api from 'services/api'
import { Label, Value } from '../../shared-style'
import { Container, Content, Info, OverviewCard } from './style'

interface Overview {
  total_students: number
  started: number
  not_started: number
  concluded: number
  active_in_the_past: number
}

const Overview: React.FC = () => {
  const initial_state: Overview = {
    total_students: 0,
    started: 0,
    not_started: 0,
    concluded: 0,
    active_in_the_past: 0,
  }
  const [overview, setOverview] = useState<Overview>(initial_state)

  const getOverview = async () => {
    const { data } = await api.get('/overview')
    setOverview(data)
  }

  useEffect(() => {
    getOverview()
  }, [])

  return (
    <OverviewCard>
      <CardTitle>Visão geral</CardTitle>

      <Container>
        <Content>
          <Info>
            <Value>{overview.total_students ? overview.total_students : 0}</Value>
            <Label>Alunos</Label>
          </Info>
       

       
        
       
          <Info>
            <Value>{overview.started ? overview.started : 0}%</Value>
            <Label>Iniciaram</Label>
          </Info>

          <Info>
            <Value>{overview.not_started ? overview.not_started : 0}%</Value>
            <Label>Não iniciaram</Label>
          </Info>

          <Info>
            <Value>{overview.concluded ? overview.concluded : 0}%</Value>
            <Label>Concluiram</Label>
          </Info>
        

       
          <Info>
            <Value>{overview.active_in_the_past ? overview.active_in_the_past : 0}%</Value>
            <Label>Ativos nos últimos 7 dias</Label>
          </Info>
       
        </Content>
      </Container>
    </OverviewCard>
  )
}

export default Overview
