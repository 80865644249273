import React, { useEffect, useState } from 'react'
import {
  Container,
  TitleContainer,
  SearchAffiliates,
  ListTypes,
  Results,
  ListCard,
} from './styles'

import InputSearch from '../../components/InputSearch'
import CardAfilliate from '../../components/Afilliate/index'
import api from 'services/mock/index'

const ContainerAlifiadosEmAlta = () => {
  const [ affiliate, setAffiliate ] = useState([])

  useEffect(() => {
    api.getReports()
      .then((data: any) => {
        setAffiliate(data.affiliate)
      })
  }, [])

  return (
    <>
      <Container>
        <TitleContainer>
          Afiliados em Alta no mercado
        </TitleContainer>
        <SearchAffiliates>
          <InputSearch Label={"Busque por afiliados no mercado"}/>
          <ListTypes>
            <li>
              Finanças
            </li>
            <li>
              Saúde
            </li>
            <li>
              Desenvolvimento Pessoal
            </li>
            <li>
              Ecologia
            </li>
            <li>
              ...
            </li>
          </ListTypes>
        </SearchAffiliates>
        <Results>
          {affiliate.map((affiliate: any) => (
            <ListCard>
              <CardAfilliate photo={affiliate.photo} name={affiliate.name} sales={affiliate.sales} type={affiliate.type} />
            </ListCard>
          ))}
        </Results>
      </Container>
      
    </>
  )
}

export default ContainerAlifiadosEmAlta