/*
FUTURA página com gráficos visuais, CRM, etc
*/

import React from 'react'
import { Container } from 'GlobalStyles'
import Footer from 'containers/Footer'

const h1Style = {
  fontSize: '60px',
  color: '#292929',
}

const Analytics = () => {
  return (
    <Container>
      <h1 style={h1Style}>Analytics em construção :)</h1>
      <Footer />
    </Container>
  )
}

export default Analytics
