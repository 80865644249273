import styled from 'styled-components'

export const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.2556rem;
`

export const Title = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.1.5625rem;
  color: #898989;
  margin-bottom: 1.4481rem;
  margin-top: 1.4481rem;
`

export const Value = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.625rem;
  letter-spacing: 0.009375rem;
  margin-bottom: 0.25rem;

  color: #5C5C5C;
`

export const Label = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  color: #A2A2A2;
`

export const Subcard = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.1.5625rem;
  color: #5C5C5C;
  display: flex;
  background: #FFFFFF;
  border: 0.0625rem solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 0.625rem;
  padding: 12px;
`
