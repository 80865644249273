import React, { useState, useEffect, useRef, useContext } from 'react'
import { SubmitHandler } from '@unform/core'
import {
  ContentFooter,
  ActionBtn,
  MainContainer,
  ContentContainer,
} from '../shared-styles'
import {
  ContentForm,
  InputsSection,
  ImgContainer,
  ImgSection,
  ProductImg,
  UploadButton,
} from './style'
import Textarea from 'components/new/Input/Textarea'
import Text from 'components/new/Input/Text'
import Select from 'components/new/Input/Select'
import Input from 'components/new/Input'
import HeaderNewProduct from 'components/new/HeaderNewProduct'
import UploadIcon from 'assets/icons/uploadIcon.svg'
import ArrowIcon from 'components/ArrowIcon'
import api from 'services/api'
import ImgModal from './ImgModal'
import { StepContext } from '../../../StepContext'
import { EditProductContext } from '../../EditProductContext'
import * as Yup from 'yup'
import displayYupErrorNotification from 'utils/displayYupErrorNotification'

const textAreaHeight = '11.0625rem'

interface FormData {
  name: string
  category: any
  sub_category: any
  description: string
  update_photo: boolean
}

const BasicInfo: React.FC = () => {
  const [categories, setCategories] = useState<any>(null)
  const [subCategories, setSubcategories] = useState<any>(null)
  const { handleNext } = useContext(StepContext)
  const [editProductData, setEditProductData] = useContext(EditProductContext)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [photoProduct, setPhotoProduct] = useState(null)
  const [hasUpdatedPhoto, setHasUpdatedPhoto] = useState<boolean>(false)

  const getCategories = async () => {
    let { data } = await api.get('/categories')
    setCategories(data)

    getSubcategory()
  }

  const getSubcategory = async () => {
    let { data } = await api.get(
      `/subcategories/${editProductData.category.value}`,
    )
    setSubcategories(data)
  }

  const handleTextValue = (evt: any) => {
    setEditProductData({
      ...editProductData,
      [evt.target.id]: evt.target.value,
    })
  }

  const handleCategory = ({ value, label }: any) => {
    setSelectedCategory(value)

    setEditProductData({
      ...editProductData,
      category: { value, label },
    })
  }

  const handleSubcategory = ({ value, label }: any) => {
    setEditProductData({
      ...editProductData,
      sub_category: { value, label },
    })
  }

  const handlePhoto = (evt: any) => {
    let storagePhoto: any = sessionStorage.getItem('photo')

    setEditProductData({
      ...editProductData,
      photo: storagePhoto,
    })

    setPhotoProduct(storagePhoto)
    setHasUpdatedPhoto(true)
    setVisible(false)
  }

  const [visible, setVisible] = useState<boolean>(false)
  const showModal = () => setVisible(true)
  const closeModal = () => setVisible(false)

  useEffect(() => {
    getCategories()
  }, [selectedCategory])

  const formRef = useRef<SubmitHandler>(null)

  const handleSubmit: SubmitHandler<FormData> = async data => {
    try {
      const schema = Yup.object().shape({
        name: Yup.string()
          .required('Adicione um nome para o seu produto')
          .min(2, 'O nome precisa de no mínimo, 2 caracteres'),
        category: Yup.number().nullable().required('Selecione uma categoria.'),
        sub_category: Yup.number()
          .nullable()
          .required('Selecione uma subcategoria.'),
        description: Yup.string().required(
          'Adicione uma descrição para o seu produto',
        ),
      })

      await schema.validate(data, {
        abortEarly: false,
      })

      let newData = {
        name: data.name,
        description: data.description,
        category: { id: data.category },
        sub_category: { id: data.sub_category },
        photo: hasUpdatedPhoto ? photoProduct : editProductData.photo,
        updated_photo: hasUpdatedPhoto,
      }

      setEditProductData({
        ...editProductData,
        finalJSON: {
          ...newData,
        },
      })
      handleNext()
    } catch (err) {
      displayYupErrorNotification(err)
    }
  }

  return (
    <MainContainer>
      <ContentContainer>
        <HeaderNewProduct
          title="1 - Informações Básicas"
          subtitle="Esse conteúdo será a primeira impressão do seu produto no mercado!"
        />
        <ContentForm ref={formRef} onSubmit={handleSubmit}>
          <ImgSection>
            <ImgContainer>
              <ImgModal
                handleCancel={closeModal}
                handleOk={handlePhoto}
                visible={visible}
              />

              <UploadButton
                onClick={showModal}
                type="button"
                opacity={editProductData.photo ? '0' : '1'}
              >
                <img src={UploadIcon} alt="" />
                Inserir Imagem
              </UploadButton>

              <ProductImg
                display={editProductData.photo ? 'flex' : 'none'}
                image={
                  editProductData.photo ? editProductData.photo : photoProduct
                }
              />
            </ImgContainer>

            <p>A Imagem deve ter a resolução mínima de 300 x 300 Pixels</p>
            <p>Deixe os detalhes para escrever nos campos ao lado.</p>
          </ImgSection>

          <InputsSection>
            <Input title="Nome do produto">
              <Text
                name="name"
                placeholder="Escreva o nome do produto"
                value={editProductData.name}
                onChange={handleTextValue}
              />
            </Input>

            <Input title="Categoria">
              <Select
                options={categories}
                name="category"
                placeholder="Selecione a categoria do produto"
                onChange={handleCategory}
                defaultValue={editProductData.category}
              />
            </Input>

            <Input title="Subcategoria">
              <Select
                isDisabled={editProductData?.category ? false : true}
                name="sub_category"
                placeholder="Selecione a subcategoria do produto"
                options={subCategories}
                onChange={handleSubcategory}
                defaultValue={editProductData.sub_category}
              />
            </Input>

            <Textarea
              name="description"
              title="Breve descrição"
              placeholder="Essa descrição ajudará a vender o seu produto!"
              height={textAreaHeight}
              valueInput={editProductData.description}
              onChangeInput={handleTextValue}
            />
          </InputsSection>

          <ContentFooter>
            <ActionBtn className="next-btn" type="submit">
              <p>Próximo</p>
              <ArrowIcon rotateDeg={-90} color="#0c69ff" />
            </ActionBtn>
          </ContentFooter>
        </ContentForm>
      </ContentContainer>
    </MainContainer>
  )
}

export default BasicInfo