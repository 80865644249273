import styled from 'styled-components'
import Form from 'components/new/Form'

export const DivOrder = styled.div`
  width: 200px;
`

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
  width: 18.75rem;
  margin-top: 3.125rem;

  input {
    margin-right: 0.9rem;
  }

  p {
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.015625rem;
    color: #5c5c5c;
    margin: 0;
  }
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 2rem 0;
  flex-wrap: wrap;

  & input[type='image'] {
    margin-top: -2.2%;
    outline: none;
  }
`

export const ContentForm = styled(Form)`
  width: 100%;
  margin-bottom: 3rem;
`
