import React, { useState } from 'react'
import MenuIcon from 'assets/icons/3dotmenu-icon.svg'
import ArrowIcon from 'components/ArrowIcon'
import { Dropdown } from 'antd'
import { DataTableRow, DataTableCell } from 'components/DataTable'
import { MenuCard, Photo, ContainerLoad, Inative } from './style'
import api from 'services/api'
import {
  displayErrorNotification,
  displaySucessNotification,
} from 'utils/displayNotification'
import Perfil from 'assets/perfil.png'
import { CircularProgress } from '@material-ui/core/'

interface Student {
  student: any
  response: boolean
}

const Cancel: React.FC<Student> = ({ student, response }) => {
  const [loading, setLoanding] = useState<any>(false)

  const [idCHeckout,] = useState<any>(student.checkout_id)

  const handleAprove = async (boollean: any) => {
    try {
      setLoanding(true)
      let data = {
        student_id: student.student_id,
        email: student.student_email,
        name: student.student_name,
        product_id: student.product_id,
        product_name: student.product_name,
        checkout_id: idCHeckout,
        accept: true,
      }

      await api.post('reverse-payment', data)
      setLoanding(false)
      displaySucessNotification(
        'Estorno realizado com sucesso, recarregando a pagina.',
      )
      setTimeout(() => window.location.reload(true), 1000)
    } catch (error) {
      setLoanding(false)
      displayErrorNotification(error)
    }
  }

  const handleCancel = async (boollean: any) => {
    try {
      setLoanding(true)
      let data = {
        email: student.student_email,
        name: student.student_name,
        product_name: student.product_name,
        checkout_id: idCHeckout,
        accept: false,
      }
      await api.post('reverse-payment', data)
      setLoanding(false)
      displaySucessNotification('Estorno negado, recarregando a pagina.')
      setTimeout(() => window.location.reload(true), 1000)
    } catch (error) {
      setLoanding(false)
      displayErrorNotification(error)
    }
  }
  const whatsapp = (v: any) => {
    window.open(`https://api.whatsapp.com/send?phone=55${v}`)
  }
  const dropdownMenu = (
    <MenuCard>
      {response === false ? (
        <>
          <div onClick={() => handleAprove(true)}>
            <p>Realizar extorno</p>
            <ArrowIcon />
          </div>
          <div onClick={() => handleCancel(false)}>
            <p>
              <strong>Não</strong> realizar extorno
            </p>
            <ArrowIcon />
          </div>
        </>
      ) : (
          <>
            <Inative >
              <p>Realizar extorno</p>
              <ArrowIcon />
            </Inative>
            <Inative>
              <p>
                <strong>Não</strong> realizar extorno
            </p>
              <ArrowIcon />
            </Inative>
          </>
        )}
      <div>
        <p onClick={() => whatsapp(student.student_phone)}>
          Enviar mensagem pelo Whatsapp
        </p>
        <ArrowIcon />
      </div>
    </MenuCard>
  )

  return (
    <>
      <DataTableRow>
        <DataTableCell component="th" scope="row">
          <Photo
            src={!student.student_photo ? Perfil : student.student_photo}
            alt="aluno"
          />
        </DataTableCell>
        <DataTableCell>{student.student_name}</DataTableCell>
        <DataTableCell>{student.student_email}</DataTableCell>
        <DataTableCell>{student.student_phone}</DataTableCell>
        <DataTableCell>{student.product_name}</DataTableCell>
        <DataTableCell>
          {student.checkout_amount.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </DataTableCell>
        <Dropdown overlay={dropdownMenu}>
          <DataTableCell style={{ cursor: 'pointer' }}>
            <img src={MenuIcon} alt="" />
          </DataTableCell>
        </Dropdown>
      </DataTableRow>
      {loading ? (
        <ContainerLoad>
          <CircularProgress color="inherit" style={{ margin: '0 50%' }} />
        </ContainerLoad>
      ) : null}
    </>
  )
}

export default Cancel
