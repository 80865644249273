import { notification } from 'antd'

const notificationStyle = {
  right: '1.875rem',
  width: '16.25rem',
  borderRadius: '0.625rem',
}

export function displayErrorNotification(message: string) {
  notification.error({
    message: message,
    placement: 'bottomRight',
    style: notificationStyle
  })
}

export function displaySucessNotification(message: string) {
  notification.success({
    message: message,
    placement: 'bottomRight',
    style: notificationStyle
  })
}

export function displayWarningNotification(message: string) {
  notification.warning({
    message: message,
    placement: 'bottomRight',
    style: notificationStyle
  })
}