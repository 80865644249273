import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core/'
import {
  DataTable,



  DataTableBody, DataTableCell, DataTableHead,
  DataTableRow
} from 'components/DataTable'
import Search from 'components/Search'
import React, { useCallback, useEffect, useState } from 'react'
import api from 'services/api'
import { displayErrorNotification } from 'utils/displayNotification'
import Cancel from './Cancel'
import {
  ContainerTable,


  FooterPagination, Head, NoStudentMsg,

  SearchContainer,







  StyledPagination
} from './style'

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
  marginBottom: '22px',

}
const ResumCancel = () => {
  const [sells, setSells] = useState<any>([])
  const [history, setHistory] = useState<any>(3)
  const [totalSells, setTotalSells] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [error,] = useState<any>('Nenhum estorno solicitado')
  const [serch, setSearch] = useState<any>('')
  const [pgNumberTrue, setPgNumberTrue] = useState<number>(0)
  const [pgNumberFalse, setPgNumberFalse] = useState<number>(0)
  const [pgNumberNull, setPgNumberNull] = useState<number>(0)
  const [pgNumberSearch,] = useState<number>(0)
  const [pg, setPg] = useState<any>()
  const [loading, setLoading] = useState<any>(false)
  const [responses, setResponses] = useState<any>(false)

  const result = useCallback(async (e: any, b: any) => {
    setLoading(true)

    try {
      let response = await api.get('profile')

      let value = await api.get(
        `/list-chargeback/${response.data.user.id}/${b}?name=${e}`,
        {
          params: { page_index: pgNumberSearch },
        },
      )
      setPageNumber(value.data.page_index)
      setPageSize(value.data.page_size)
      setTotalSells(value.data.total_records)
      setSells(value.data.records)
      setLoading(false)
    } catch (error) {
      setSells([])
      setLoading(false)

      displayErrorNotification('Não foi encontrado ninguem com esse nome.')
    }
  },[pgNumberSearch])

  const searchStudents = useCallback(async (e: any) => {
    setSearch(e)
    setLoading(true)

    try {
      if (history === 1) {
        result(e, true)
      } else if (history === 2) {
        result(e, false)
      } else if (history === 3) {
        let response = await api.get('profile')
        let value = await api.get(
          `/list-chargeback/${response.data.user.id}/null?name=${e}`,
          {
            params: { page_index: pgNumberSearch },
          },
        )

        setPageNumber(value.data.page_index)
        setPageSize(value.data.page_size)
        setTotalSells(value.data.total_records)
        setSells(value.data.records)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)

      setSells([])
      displayErrorNotification('Não foi encontrado ninguem com esse nome.')
    }
  }, [history, pgNumberSearch, result])

  const init = useCallback(async () => {
    setLoading(true)
    try {
      let response = await api.get('profile')
      setPg('init')
      let value = await api.get(`/list-chargeback/${response.data.user.id}`, {
        params: { page_index: pageNumber },
      })
      setLoading(false)

      setPageNumber(value.data.page_index)
      setPageSize(value.data.page_size)
      setTotalSells(value.data.total_records)
      setSells(value.data.records)

      setPgNumberTrue(0)
      setPgNumberFalse(0)
      setPgNumberNull(0)
    } catch (error) {
      setLoading(false)
      displayErrorNotification('Errou ao carregar a pagina')
    }
  },[pageNumber])

  const checkHistory = useCallback(async (e: any) => {
    setLoading(true)

    if ((e.target && e.target.value === 1) || e === 1) {
      setHistory(1)
      setSearch('')
      setResponses(true)
      let response = await api.get('profile')
      setPg('true')

      let value = await api.get(
        `/list-chargeback/${response.data.user.id}/true`,
        {
          params: { page_index: pgNumberTrue },
        },
      )
      setLoading(false)

      setPgNumberTrue(value.data.page_index)
      setPageSize(value.data.page_size)
      setTotalSells(value.data.total_records)
      setSells(value.data.records)

      setPgNumberNull(0)
      setPgNumberFalse(0)
      setPageNumber(0)
    } else if ((e.target && e.target.value === 2) || e === 2) {
      setHistory(2)
      setSearch('')
      setPg('false')
      setResponses(true)
      let response = await api.get('profile')

      let value = await api.get(
        `/list-chargeback/${response.data.user.id}/false`,
        {
          params: { page_index: pgNumberFalse },
        },
      )
      setLoading(false)

      setPgNumberFalse(value.data.page_index)
      setPageSize(value.data.page_size)
      setTotalSells(value.data.total_records)
      setSells(value.data.records)

      setPgNumberNull(0)
      setPgNumberTrue(0)
      setPageNumber(0)
    } else if ((e.target && e.target.value === 3) || e === 3) {
      setHistory(3)
      setSearch('')
      setPg('null')

      let response = await api.get('profile')

      let value = await api.get(
        `/list-chargeback/${response.data.user.id}/null`,
        {
          params: { page_index: pgNumberNull },
        },
      )
      setLoading(false)

      setPgNumberNull(value.data.page_index)
      setPageSize(value.data.page_size)
      setTotalSells(value.data.total_records)
      setSells(value.data.records)

      setPageNumber(0)
      setPgNumberFalse(0)
      setPgNumberTrue(0)
      setLoading(false)
    }
    console.log(history)
  }, [history, pgNumberFalse, pgNumberNull, pgNumberTrue])

  const handlePageNumber = (page: number) => {
    if (pg === 'init') {
      setPageNumber(page - 1)
    } else if (pg === 'true') {
      setPgNumberTrue(page - 1)
    } else if (pg === 'false') {
      setPgNumberFalse(page - 1)
    } else if (pg === 'null') {
      setPgNumberNull(page - 1)
    }
    window.scrollTo(0, 100)
  }

  useEffect(() => {
    if (!history && !serch) {
      init()
    } else if (history && !serch) {
      checkHistory(history)
    } else if (history && serch) {
      searchStudents(serch)
    }
  }, [pageNumber, pgNumberNull, pgNumberTrue, pgNumberFalse, history, serch, init, checkHistory, searchStudents])
  return (
    <ContainerTable>
      <Head>
        <SearchContainer>
          <Search
            placeholder="Busque pelo nome do aluno"
            handleSearch={(e: any) => searchStudents(e.target.value)}
            value={serch}
          ></Search>
        </SearchContainer>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">Estornos</InputLabel>
          <Select
            
            value={history}
            onChange={checkHistory}
            label="Estornos"
            inputProps={{
              name: 'Estornos',
              id: 'outlined-age-native-simple',
            }}
          >
            <MenuItem value={1}>Verificar historico de estornos aprovados</MenuItem>
            <MenuItem value={2}>Verificar historico de estornos negados</MenuItem>
            <MenuItem value={3}>Verificar estornos sem resposta</MenuItem>
          </Select>
        </FormControl>
      </Head>
      <DataTable>
        <DataTableHead>
          <DataTableRow>
            <DataTableCell></DataTableCell>
            <DataTableCell>Nome do aluno</DataTableCell>
            <DataTableCell>Email</DataTableCell>
            <DataTableCell>Telefone</DataTableCell>
            <DataTableCell>Produto</DataTableCell>
            <DataTableCell>Valor</DataTableCell>
            <DataTableCell></DataTableCell>
          </DataTableRow>
        </DataTableHead>

        {sells.length === 0 && sells.checkout_amount === undefined ? (
        <NoStudentMsg>{error}</NoStudentMsg>
      ) : null}
      
        <DataTableBody>
          {loading ? (
            <CircularProgress color="inherit" />
          ) : (
              sells &&
              sells.map((product: any) => (
                <Cancel student={product} key={product.request_chargebacks_id} response={responses} />
              ))
            )}
        </DataTableBody>
      </DataTable>
     

      {totalSells > pageSize && (
        <FooterPagination>
          <StyledPagination
            defaultCurrent={pageNumber + 1}
            total={totalSells}
            onChange={handlePageNumber}
          />
        </FooterPagination>
      )}
    </ContainerTable>
  )
}

export default ResumCancel
