import React, { useEffect, useState } from 'react'
import MenuIcon from 'assets/icons/3dotmenu-icon.svg'
import ArrowIcon from 'components/ArrowIcon'
import { Dropdown } from 'antd'
import { ImgContainer } from 'components/DataTable/style'
import {
  DataTableRow,
  DataTableCell,
} from 'components/DataTable'
import { MenuCard } from './style'
import Perfil from 'assets/perfil.png'

interface Transfer {
  transfer: {
    amount: number
    description: string
    currency: string
    status: string
    transfer_date: Date
    transfer_number: string
    type: string
  }
}

const Transfer: React.FC<Transfer> = ({ transfer }) => {
  const {
    amount,
    description,
    currency,
    status,
    transfer_date,
    transfer_number,
    type
  } = transfer
  const [ data, setData] = useState(Date)
  const [ hora, setHora] = useState(Date)
  const handleDate = (transfer_date: Date)=>{
    const date = new Date(transfer_date).toLocaleDateString();
    const time = new Date(transfer_date).toLocaleTimeString();
    setData(date)
    setHora(time)
  }
  
  useEffect(()=>{
    handleDate(transfer_date)
  },[transfer_date])

  const currencySwitch = () => {
    switch(currency) {
      case 'BRL':
        return 'R$ '
      case 'USD':
        return '$ '
      case 'BTC':
        return 'BTC '
      case 'EUR':
        return '€ '
    }
  }

  return (
    <DataTableRow>
      <DataTableCell>
        <strong style={{fontFamily: 'monospace', fontSize: 18}}>
        {
          currencySwitch()
        }
        {amount}
        </strong>
        </DataTableCell>
      <DataTableCell>{description}</DataTableCell>
      <DataTableCell>{currency}</DataTableCell>
      <DataTableCell>{status === 'pending'? 'Pendente' : status === 'succeeded'? 'Sucedido' : '---' }</DataTableCell>
      <DataTableCell>
        {data + ' | ' + hora}
      </DataTableCell>
      <DataTableCell>
        {transfer_number? transfer_number : 'Pendente' }
      </DataTableCell>
      <DataTableCell>
        {type === 'payout_automatic'? 'Automático' : type === 'payout_manual'? 'manual' : '---'}
      </DataTableCell>

    </DataTableRow>
  )
}

export default Transfer