import styled from 'styled-components'
import { Card } from 'components/CardContainer'
import { StyledLink } from 'components/Link'
import { Link } from 'react-router-dom'

export const Flex = styled.div`
  display: flex;
`


export const ProductCard = styled(Card)`
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  margin-bottom: 5.52475vh;
  @media screen and (max-width: 1207px){
    flex-direction: column;
    width: 290px;
  }
`
interface Image {
  src: string
}

export const ProductImg = styled.div`
  width: 300px;
  height: 200px;
  border-radius: 0.625rem 0px 0px 0.625rem;
  background-color: #e1e1e1;
  overflow: hidden;
  background-image: url('${(props: Image) => props.src}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 1207px){
    width: 100%;
    border-radius: 5px 5px 0 0;
  }
`

export const Content = styled.section`
  width: 84.3%;
  margin: 25px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1207px){
    flex-direction: column;
    width: 85%;

  }
  .responsiveOptionBtn{
    align-self: flex-start;
    @media screen and (max-width: 1207px){
      align-self: flex-end;
    }
  }
  .options{

  }
`
export const OptionsBtn = styled.div`
  cursor: pointer;
  width: 48px;
  height: 48px;
  margin: 8px;
  display: flex;
  justify-content: center;
  align-self: flex-start;
  &&:active{
    background-color: #0000000f;
    border-radius: 50%;
  }
  @media screen and (max-width: 1207px){
    align-self: flex-end;
  }
`

export const ContentCenter = styled(Flex)`
  margin: auto;
  width: 70.16875%;
  justify-content: space-around;
  @media screen and (max-width: 1207px) {
    flex-wrap: wrap;
    padding: 20px;
  }@media screen and (max-width: 402px) {
    flex-wrap: wrap;
    padding: 20px;
    width: 100%;
  }
`

export const ContentTitle = styled(Flex)`
  flex-direction: column;
  text-align: left;
  width: 13.194375vw;
  overflow-wrap: break-word;

  h1 {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4375rem;
    letter-spacing: 0.009375rem;
    color: #5c5c5c;
  }

  h2 {
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.015625rem;
    color: #a2a2a2;
  }
  @media screen and (max-width: 1207px){
    width: 100%;
    margin-top: 8px
  }
`

export const FlexContainer = styled(Flex)`
  align-items: center;
`
export const ButtonAcess = styled(Link)`
  width: 100%;
  border: 1px solid var(--main);
  box-sizing: border-box;
  border-radius: 48px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.25px;
  color: var(--main);
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  outline: none;
  padding: 10px;

  &:hover{
    border: 1px solid #A2A2A2;
    color: #a2a2a2;
  }
`
export const Icon = styled.img`
  width: auto;
  height: 1.34125rem;
  margin-right: 0.625rem;
`

export const Active = styled.p<{ active: boolean }>`
  padding: 0.3125rem 0.625rem;
  height: fit-content;
  margin-top: 0.4375rem;
  background: ${(props) => props.active ? '#28a745' : '#ffc107'};
  border-radius: 1.9375rem;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  color: #FFFFFF;
`
export const Value = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin: 0;
  letter-spacing: 0.009375rem;

  color: #5c5c5c;
`
export const Label = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  margin-left: 2rem;
  color: #a2a2a2;
`
export const Subcard = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.1.5625rem;
  color: #5c5c5c;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border: 0.0625rem solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 0.625rem;
  padding: 0.5rem;

  & a:nth-child(2) {
    border-top: 0.0625rem solid #e0e0e0;
    border-bottom: 0.0625rem solid #e0e0e0;
  }

  & a:nth-child(3) {
    border-bottom: 0.0625rem solid #e0e0e0;
  }
`

export const SubcardLink = styled(StyledLink)`
  font-size: 0.9rem;
  color: #292929;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  padding: 1rem;

  &:hover {
    color: var(--main);
  }
`

export const ProductLink = styled.a`
  font-size: 0.9rem;
  color: #292929;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  padding: 1rem;

  &:hover {
    color: var(--main);
  }
`