import { SubmitHandler } from '@unform/core'
import { FlexContainer } from 'GlobalStyles'
import React, { useEffect, useRef, useState } from 'react'
import api from 'services/api'
import { displaySucessNotification } from 'utils/displayNotification'
import displayYupErrorNotification from 'utils/displayYupErrorNotification'
import * as Yup from 'yup'
import Coupon from './Coupon'
import Courtesy from './Courtesy'
import {
  SubTitle
} from './style'



interface FormData {
  email: string
  cell_phone: string
  name: string
  address: string
  product: any
}

const notificationStyle = {
  right: '1.875rem',
  width: '16.25rem',
  borderRadius: '0.625rem'
}

const textAreaHeight = '11.0625rem'

const Config: React.FC = () => {
  const [products, setProducts] = useState<any>(null)
  const [suggestions, setSuggestions] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  
  
  const getProducts = async () => {
    let { data } = await api.get('/list-all-by-producer-products')
    setProducts(data.records)
  }

  useEffect(() => {
    getProducts()
  }, [])

  const formRef = useRef<SubmitHandler>(null)
  const handleSuggestions: SubmitHandler<FormData> = async data => {
    try {
      const schema = Yup.object().shape({
        suggestions: Yup.string().required('Por favor, digite uma sugestão.'),
      })

      await schema.validate(data, {
        abortEarly: false,
      })

      let newData = {
        suggestions: suggestions,
      }
      setLoading(true)
      await api.post('/create-suggestion', newData)
      displaySucessNotification('Sugestão enviada com sucesso! Agradecemos a sua ideia, isso significa muito para nós ;)')
    } catch (err) {
      displayYupErrorNotification(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <SubTitle>
        <p>Configurações</p>
      </SubTitle>
      <FlexContainer>

        <Coupon products={products} />
        <Courtesy products={products} />

        {/*<TableContainer>
                  <p className="title">Outras configurações</p>
        </TableContainer>*/}

        {/* <TableContainer>
          <p className="title">Sugestões para a plataforma</p>
          <ContentForm ref={formRef} onSubmit={handleSuggestions}>
            <InputsSection>
              <Textarea
                name="suggestion"
                title="Sugestões para a plataforma"
                placeholder="Digite aqui algo que você gostaria em nossa plataforma. :)"
                height={textAreaHeight}
                valueInput={suggestions?.suggestions} //serve para deixar a primeira palavra maiúscula}}
                onChangeInput={handleSuggestion}
              />

              <Register type="submit">
                {loading ? (
                  <CircularProgress color="inherit" size={'1.1rem'} />
                ) : (
                    <p>Enviar sugestão</p>
                  )}
              </Register>
            </InputsSection>
          </ContentForm>
        </TableContainer> */}
      </FlexContainer>

    </>
  )
}

export default Config