/*
Aqui contém a página do financeiro e seus componentes/containers.
*/

import React, { useEffect, useState } from 'react'
import { Container, StyledTab} from '../../GlobalStyles';
import Header from 'components/HeaderPromotor'
import CalendarFinancial from 'components/CalendarFinancial'
import ContainerFinancial from 'containers/Financial';
import ContainerTransfers from 'containers/Transfers';
import ContainerSubscriptions from 'containers/Subscriptions';
import Footer from 'containers/Footer'
import { Tabs } from 'antd';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';

const { TabPane } = Tabs;


const localizer = momentLocalizer(moment)
const myTransferEvents: Array<any> = []

const DateNow = new Date(Date.now())

const Financial = () => {
  const [tab, setTab] = useState(1) 

  const callback = (key: any) =>{
    setTab(key)
  }
  return (
    <>
      
      <Container>
        <Header SearchHeader />
        <StyledTab type="card" defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Financeiro"  key="1">
            {tab==1? 
            <ContainerFinancial/>
            :null}
          </TabPane>
          <TabPane tab="Transferências" key="2">
            {tab==2?
            <ContainerTransfers/>
            :
            null}
          </TabPane>
          <TabPane tab="Calendário Financeiro" key="3">
            {tab==3?
            <Calendar 
            localizer={localizer}
            events={myTransferEvents}
            startAccessor="start"
            endAccessor="end"
            defaultDate={DateNow}
            views={['month', 'week', 'day']}
            messages={{
              month: 'Mês',
              day: 'Dia',
              today: 'Hoje',
              week: 'Semana',
              previous: 'Anterior',
              next: 'Próximo'
            }}
            style={{height: 800}}
            />
            :
            null}
          </TabPane> 
          <TabPane tab="Assinaturas" key="4">
            {tab==4?
            <ContainerSubscriptions/>
            :
            null}
          </TabPane>
        </StyledTab>
      </Container>
      <Footer />
    </>
  )
}

export default Financial