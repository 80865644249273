import React from 'react'

import { StyledLogo } from './style'

import ImgLogo from '../../assets/images/logo.png'

const Logo = () => (
  <StyledLogo>
    <img src={ImgLogo} alt="" />
  </StyledLogo>
)

export default Logo