import React from 'react'

import { Card } from '../CardContainer/index'

import { Dados, NameAfilliate, TypeAfilliate } from './style'

interface AfilliateProps {
  photo: string
  name: string
  sales: string
  type: string
}

const Afilliate: React.FC<AfilliateProps> = ({ photo, name, sales, type }) => {
  return (
    <Card>
      <Dados>
        <img src={photo} alt="Afiliado" />
        <NameAfilliate>
          {name}
          <div>{sales} Vendas</div>
        </NameAfilliate>

        <TypeAfilliate>{type}</TypeAfilliate>
      </Dados>
    </Card>
  )
}

export default Afilliate
