import styled from 'styled-components'

export const Container = styled.div<{ Info: string }>`
  margin-left: 10px;
  margin-right: 10px;
  color: #a1a1a1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin:12px;
  svg{
    font-size:24px;
  }
 
 &:hover{
  div{
    border: 1px solid #a1a1a1;
    border-radius: 10px;
    background: rgb(161, 161, 161);
    color: white;
    display: absolute;
    width: 200px;
    height: auto;
    padding: 13px;
    position: absolute;
    top: 24px;
    left: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:14px;
    z-index:10;


    &:before{
      z-index:10;
      content:"${props => (props.Info)}";
    }
  }
 }
`



