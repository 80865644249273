import styled from 'styled-components'


export const Dados = styled.div`
    width: 132px;
    height: 241px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    img{
        width:103px;
        height: 103px;
        border-radius: 50%;
        border: 5px solid #f1f1f1;
    }
`
export const NameAfilliate = styled.p`
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #5C5C5C;
    
    div{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.25px;
        color: #898989;
    }
`
export const TypeAfilliate = styled.div`
    width: 85px;
    height: 25px;
    border: 2px solid #898989;
    box-sizing: border-box;
    border-radius: 27px;
    text-align: center;
    color: #898989;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    transition: all 0.3s;
    cursor: pointer;


    &:hover{
        border: 2px solid #84a63e;
        color: #84a63e;
    }
`
