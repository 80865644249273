/*
Aqui exibe o co-produtor cadastrado após ter adicionado, isso
aparece na tela após confirmar o seu co-produtor.
*/

import React, { useContext } from 'react'
import { NewProductContext } from '../../../NewProductContext'
import {
  Container,
  CoProducerTable,
  CoProducerTableHeader,
  CoProducerTableContent,
  Label,
  Value,
  DeleteButton,
} from './style'
import DeleteIcon from 'assets/icons/delete-icon.svg'

interface CoProducer {
  id: number
  co_producer_email: string
  contract: any
  comission: string
  comission_percentage: number
}

interface Props {
  co_producers: CoProducer[]
  onDelete: (id: number) => void
}

const handleContract = (co_producer: CoProducer, newProductData: any) => {
  if (co_producer.contract.name) {
    return co_producer.contract.name
  } else {
    return newProductData.contractName
  }
}

const CoProducerList: React.FC<Props> = ({ co_producers, onDelete }) => {
  const [newProductData] = useContext(NewProductContext)

  return (
    <Container>
      {co_producers?.map((co_producer: CoProducer) => (
        <>
          <CoProducerTable key={co_producer.id}>
            <CoProducerTableHeader>
              <Label>Email</Label>
              <Label>Tempo de contrato</Label>
              <Label>Comissões</Label>
              <Label>Porcentagem da comissão</Label>
            </CoProducerTableHeader>
            <CoProducerTableContent>
              <section>
                <Value>{co_producer?.co_producer_email}</Value>
              </section>

              <section>
                <Value>{handleContract(co_producer, newProductData)}</Value>
              </section>

              <section>
                <Value>Vendas do produtor</Value>
              </section>

              <section>
                <Value>{co_producer?.comission_percentage}%</Value>
              </section>
            </CoProducerTableContent>
          </CoProducerTable>

          <DeleteButton
            onClick={() => onDelete(co_producer?.id)}
            src={DeleteIcon}
          />
        </>
      ))}
    </Container>
  )
}

export default CoProducerList
