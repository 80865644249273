import React from "react";
import { GlobalStyles } from "./GlobalStyles";
import Routes from "./routes";

import "antd/dist/antd.css";
import "./style.css";

function App() {

  return (
    <>
      <GlobalStyles />
      <Routes />
    </>
  );
}

export default App;
