import { Header, SubheaderText, Title } from './styles'
// import Button from '../Button'

import React from 'react'

interface IProps {
  title: string
  subtitle?: string
  button?: string
}

const HeaderNewProduct: React.FC<IProps> = ({
  title,
  subtitle,
  button = 'Ver Preview no Mercado',
}) => {
  return (
    <>
      <Header>
        <Title>{title}</Title>
        {/* <Button>{button}</Button> */}
      </Header>

      <SubheaderText>{subtitle}</SubheaderText>
    </>
  )
}

export default HeaderNewProduct
