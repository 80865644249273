import React, { useRef, useState, useEffect, useCallback } from 'react'
import ApiViaCep from 'services/ViaCep'
import api from '../../../services/api'

import axios from 'axios'

import { SubmitHandler } from '@unform/core'
import Banks from 'services/mock'
import {
  CardContainer,
  Title,
  HeaderConquest,
  ContainerForm,
  EditInput,
  FormEnd,
  Button,
  FooterButton,
  SelectForm,
  FlexAcount,
  FlexAdress
} from './style'

import Form from 'components/new/Form'
import ImageInput from 'components/ImgFormData'
import Text from 'components/new/Input/Text'
import { notification } from 'antd'
import { cnpjMask, telMask, agencyMask, cepMask } from 'components/new/Input/mask'
import { CircularProgress } from '@material-ui/core/'

const notificationStyle = {
  right: '1.875rem',
  width: '16.25rem',
  borderRadius: '0.625rem'
}
interface propsUser {
  picture: any
  nivel: string
  description: string
}
const FormBusiness = ({ user }: any) => {
  const [cep, setCep] = useState<string>()
  const [rua, setRua] = useState<string>()
  const [complemento, setComplemento] = useState<string>()
  const [bairro, setBairro] = useState<string>()
  const [holderName, setHolderName] = useState<string>()
  const [numeroCasa, setNumeroCasa] = useState<string>()
  const [cnpj, setCnpj] = useState<string>('')
  const [phone, setPhone] = useState<string>()
  const [bank, setBank] = useState<string>()
  const [serverBank, setServerBAnk] = useState<string>()
  const [agency, setAgency] = useState<string>()
  const [account, setAccount] = useState<string>()
  const [confirmPersona, setConfirmPersona] = useState<string>('cnpj')
  const [listBank, setListBAnk] = useState<string[]>([])
  const [loading, setLoanding] = useState<boolean>(true)
  const [digit, setDigit] = useState<string>()
  const [typeAccount, setTypeAccount] = useState<string>("")
  const [city, setCity] = useState<string>("")
  const [state, setState] = useState<string>("")

  const [business_name, setBusiness_name] = useState<string>("")
  const [business_phone, setBusiness_phone] = useState<string>("")
  const [business_email, setBusiness_email] = useState<string>("")
  const [file, setFile] = useState<any>()

  const formRef = useRef<SubmitHandler>(null)

  const handleSubmit: SubmitHandler<FormData> = async (data) => {
    let obj = {
      typeDocument: 'cnpj',
      bank: bank,
      type_account: typeAccount,
      city: city,
      state: state
    }


    let value = Object.assign(data, obj)
    setLoanding(false)

    try {
      const { data: { seller, marketplace, zoopAuth } } = await api.put('/edit-profile', value)

      const zoopFile = await checkFile(seller, marketplace, zoopAuth)
      if (!file && !zoopFile) {
        notification.error({
          message: 'Documento requerido',
          placement: 'bottomRight',
          style: notificationStyle
        })
        setLoanding(true)
        return 0;
      }

      if (file)
        sendSalesDocument(seller, marketplace, zoopAuth)
      else {
        notification.success({
          message: 'Atualizado com sucesso!',
          placement: 'bottomRight',
          style: notificationStyle
        })
        setLoanding(true)
      }

    } catch (err) {
      notification.error({
        message: err.response.data.message,
        placement: 'bottomRight',
        style: notificationStyle
      })
      setLoanding(true)
    }

  }

  const handleCep = async (e: any) => {
    try {
      let response = await ApiViaCep.get(`${e.replace(/\D/g, '')}/json`); //o replace é para tirar os caracteres que entra no e
      setRua(response.data.logradouro)
      setBairro(response.data.bairro)
      setCity(response.data.localidade)
      setState(response.data.uf)
    } catch (e) { }
  }

  function maskTel(e: any) {
    setPhone(telMask(e.target.value))
  }

  function maskCnpj(e: any) {
    setCnpj(cnpjMask(e.target.value))
  }
  function maskAgency(e: any) {
    setAgency(agencyMask(e.target.value))
  }

  function maskAccout(e: any) {
    setAccount(e.target.value)
  }

  function maskCep(e: any) {
    setCep(cepMask(e.target.value))
  }

  const handleFoto = async (e: any) => {
    setFile(e.target.files[0]);
  }

  const handleValues = useCallback(()=> {
    try {
      setCep(user.cep)
      setHolderName(user.holder_name)
      setComplemento(user.complement)
      setBairro(user.district)
      setNumeroCasa(user.house_number)
      setRua(user.street)
      setServerBAnk(user.bank)
      setDigit(user.digit)
      setCity(user.city)
      setState(user.state)
      setTypeAccount(user.type_account)
      setPhone(telMask(user.cell_phone))

      setConfirmPersona('cnpj')
      setCnpj(cnpjMask(user.cnpj))

      setBank(user.bank)
      setAccount(user.account)
      setAgency(agencyMask(user.agency))

      setBusiness_name(user.business_name)
      setBusiness_phone(user.business_phone)
      setBusiness_email(user.business_email)

    } catch (e) { console.log(e) }
  }, [user.account, user.agency, user.bank, user.business_email, user.business_name, user.business_phone, user.cell_phone, user.cep, user.city, user.cnpj, user.complement, user.digit, user.district, user.holder_name, user.house_number, user.state, user.street, user.type_account]) 

  const sendSalesDocument = async (sellerId: string, marketplaceId: string, zoopAuth: string) => {
    try {
      const documentURL = `https://api.zoop.ws/v1/marketplaces/${marketplaceId}/sellers/${sellerId}/documents`
      const form = new FormData()
      form.append('file', file)
      form.append('category', 'identificacao')

      await axios.post(documentURL, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: zoopAuth,
        },
      })

      notification.success({
        message: 'Atualizado com sucesso!',
        placement: 'bottomRight',
        style: notificationStyle
      })
      setLoanding(true)

    } catch (err) {
      notification.error({
        message: 'Error no envio do documento',
        placement: 'bottomRight',
        style: notificationStyle
      })
      setLoanding(true)
    }
  }

  const checkFile = async (sellerId: string, marketplaceId: string, zoopAuth: string) => {
    try {
      const documentURL = `https://api.zoop.ws/v1/marketplaces/${marketplaceId}/sellers/${sellerId}/documents`

      const { data: { items } } = await axios.get(documentURL, {
        headers: {
          Authorization: zoopAuth,
        },
      })

      return items.length ? true : false
    } catch (err) {
      return false
    }
  }

  useEffect(() => {
    handleValues()

    Banks.getBank()
      .then((data: any) => {
        setListBAnk(data.banks)
      })
  }, [handleValues])

  return (
    <>
      <main>
        <CardContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <HeaderConquest>
              <Title>
                Dados da empresa
              </Title>
            </HeaderConquest>
            <ContainerForm>
              <FormEnd>
                <EditInput title="Numero de telefone">
                  <Text
                    name="phone"
                    value={phone}
                    onChange={maskTel}
                    maxLength={15} //Cabe o DDD + Número com 9 dígitos
                    required
                  />
                </EditInput>

                <EditInput title="Número do CNPJ">
                  <Text
                    name="cnpj"
                    onChange={maskCnpj}
                    value={cnpj}
                    maxLength={20}
                  />
                </EditInput>

                <EditInput title="Nome da empresa">
                  <Text
                    name="business_name"
                    value={business_name}
                    maxLength={60}
                    onChange={(e) => setBusiness_name(e.target.value)}
                    required
                  />
                </EditInput>
                <EditInput title="Telefone da empresa">
                  <Text
                    name="business_phone"
                    value={business_phone}
                    maxLength={15}
                    onChange={(e) => setBusiness_phone(telMask(e.target.value))}
                    required
                  />
                </EditInput>
                <EditInput title="Email da empresa">
                  <Text
                    name="business_email"
                    value={business_email}
                    maxLength={60}
                    onChange={(e) => setBusiness_email(e.target.value)}
                    required
                  />
                </EditInput>

                <EditInput title="Nome do dono da conta">
                  <Text
                    name="holderName"
                    value={holderName}
                    maxLength={60}
                    onChange={(e) => setHolderName(e.target.value)}
                    required
                  />
                </EditInput>
                <EditInput title="Selecione seu banco">
                  <SelectForm>
                    <select name="bank" value={bank} onChange={e => setBank(e.target.value)} required>
                      {serverBank === null ?
                        <option value={serverBank}>{serverBank}</option>
                        :
                        <option></option>

                      }
                      {listBank.map((item: any) => (

                        <option value={item.Code}>{item.Code}   {item.Name}</option>
                      ))}
                    </select>
                  </SelectForm>
                </EditInput>
                <EditInput title="Selecione tipo de conta">
                  <SelectForm>
                    <select name="typeAccount" value={typeAccount} onChange={e => setTypeAccount(e.target.value)} required>
                      {typeAccount === 'checking'
                        ?
                        <><option value={typeAccount}>Conta corrente</option>
                          <option value='savings'>Conta poupança</option></>
                        :
                        typeAccount === 'savings'
                          ?
                          <><option value={typeAccount}>Conta poupança</option>
                            <option value='checking'>Conta corrente</option></>

                          :
                          <>
                            <option></option>
                            <option value='savings'>Conta poupança</option>
                            <option value='checking'>Conta corrente</option>
                          </>
                      }

                    </select>
                  </SelectForm>
                </EditInput>
                <EditInput title="Número da sua agencia">
                  <Text
                    name="agency"
                    value={agency}
                    onChange={maskAgency}
                    required
                  />
                </EditInput>
                <FlexAcount>
                  <div style={{height: '136px'}}>
                    <EditInput title="Número da sua conta" width="190px">
                      <Text
                        name="account"
                        value={account}
                        maxLength={16}
                        onChange={e => setAccount(e.target.value)}
                        required
                      />
                    </EditInput>
                    <EditInput title="DG" width="80px">
                    <Text
                      name="digit"
                      value={digit}
                      maxLength={5}
                      onChange={e => setDigit(e.target.value)}
                      required
                    />
                    </EditInput>
                    
                  </div>
                  <p>Informe sua conta e seu digíto separadamente.</p>
                </FlexAcount>

              </FormEnd>
              <FormEnd>
                <EditInput title="CEP da empresa">
                  <Text
                    name="cep"
                    value={cep}
                    onChange={maskCep}
                    onBlurCapture={e => handleCep(e.target.value)}
                    required
                  />
                </EditInput>
                <EditInput title="Rua da empresa">
                  <Text
                    name="street"
                    value={rua}
                    onChange={(e) => setRua(e.target.value)}
                    required
                  />
                </EditInput>
                <EditInput title="Número da empresa">
                  <Text
                    name="house_number"
                    value={numeroCasa}
                    onChange={(e) => setNumeroCasa(e.target.value)}
                  />
                </EditInput>
                <EditInput title="Bairro da empresa">
                  <Text
                    name="district"
                    value={bairro}
                    onChange={(e) => setBairro(e.target.value)}
                    required
                  />
                </EditInput>
                <EditInput title="Complemento">
                  <Text
                    name="complement"
                    value={complemento}
                    onChange={e => setComplemento(e.target.value)}
                  />
                </EditInput>

                <FlexAdress>

                  <EditInput title="Cidade da empresa" width={"290px"}>
                    <Text
                      name="city"
                      value={city}
                      onChange={e => setCity(e.target.value)}
                      required
                    />
                  </EditInput>

                  <EditInput title="UF" width={"100px"}>
                    <Text
                      name="state"
                      value={state}
                      maxLength={2}
                      onChange={e => setState(e.target.value)}
                      required
                    />
                  </EditInput>
                </FlexAdress>
                <h3>Documento com foto (RG)</h3>
                <ImageInput name="document" handleFoto={handleFoto} />

              </FormEnd>
            </ContainerForm>
            <FooterButton>

              {
                !loading ? (
                  <CircularProgress color="inherit" />
                )
                  :
                  <Button>
                    Salvar Alterações
                          </Button>
              }

            </FooterButton>
          </Form>

        </CardContainer>
      </main>
    </>
  )
}

export default FormBusiness;