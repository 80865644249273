import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  input[type="file"] {
    display: none;
  }

`

export const InputLabel = styled.label<any>`
  padding: 10px 25px;
  border-radius: 5px;
  border: 1px solid #292929;
  font-size: 0.9rem;
  cursor: pointer;
  font-weight: bold;
`

export const PreviewImg = styled.img`
  margin-bottom: 1rem;
  width: auto;
  max-width: 472px;
  max-height: 500px;
`

export const Error = styled.span`
  font-size: 15px;
  color: #DB6262;
  margin-top: 1rem;
`