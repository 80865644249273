import React, { useState } from 'react'
import {
  Main,
  PaymentOptions,
  OptionGroup,
  Option,
  OptionIcon,
  OptionLabel,
  FormContainer,

  AddedProducts,
  ProductsContainer,
  BuyNowButton
} from './style'


import CloseBtnIcon from 'assets/icons/closebtn-x-icon.svg'
import CredCardIcon from 'assets/icons/credcard-icon.svg'
import TwoCardsIcon from 'assets/icons/two-cards-icon.svg'
import BilletIcon from 'assets/icons/billet-icon.svg'
import Product from './Product'
import Step from 'components/CheckoutSteps'
import ThankYou from '../ThankYou'
import OneCart from 'components/new/Payaments/OneCart'
import TwoCart from 'components/new/Payaments/TwoCart'
import Billet from 'components/new/Payaments/Billet'


const Payment = () => {
  const [thankYou, setThankYou] = useState(false)
  const [oneCart, setOneCart] = useState(true)
  const [twoCart, setTwoCart] = useState(false)
  const [billet, setBillet] = useState(false)

  const handleThankYou = () => {
    return setThankYou(true)
  }
  const handleOneCart = () => {
    setOneCart(true)
    setTwoCart(false)
    setBillet(false)
  }
  const handleTwoCart = () => {
    setOneCart(false)
    setTwoCart(true)
    setBillet(false)
  }
  const handleBillet = () => {
    setOneCart(false)
    setTwoCart(false)
    setBillet(true)
  }
  return (
    <>
      {!thankYou ? (
        <>
          <Step Step2={"Active"} />
          <Main>
            <PaymentOptions>
              <OptionGroup defaultValue="cred-card">
                <Option value="cred-card" onClick={() => handleOneCart()}>
                  <div className="option-container">
                    <OptionIcon src={CredCardIcon} alt="" />
                    <OptionLabel>Cartão de Crédito</OptionLabel>
                  </div>
                </Option>

                <Option value="two-cards" onClick={() => handleTwoCart()}>
                  <div className="option-container" id="two-cards">
                    <OptionIcon src={TwoCardsIcon} alt="" />
                    <OptionLabel>Dois cartões</OptionLabel>
                  </div>
                </Option>

                <Option value="billet" onClick={() => handleBillet()}>
                  <div className="option-container">
                    <OptionIcon src={BilletIcon} alt="" />
                    <OptionLabel>Boleto</OptionLabel>
                  </div>
                </Option>
              </OptionGroup>
            </PaymentOptions>

            <FormContainer>
            {oneCart === true 
              ? 
                <OneCart />
              :
                twoCart === true
              ?
                <TwoCart />
              :
                billet === true
              ?
                <Billet />
              :
              <>
              </>
            }
              

              <AddedProducts>
                <h1>Produtos adicionados</h1>

                <ProductsContainer>

                  <div className="product">
                    <img src={CloseBtnIcon} alt="" onClick={() => { }} />
                    <Product />
                  </div>

                  <div className="product">
                    <img src={CloseBtnIcon} alt="" onClick={() => { }} />
                    <Product />
                  </div>

                  <div className="product">
                    <img src={CloseBtnIcon} alt="" onClick={() => { }} />
                    <Product />
                  </div>

                </ProductsContainer>
              </AddedProducts>
            </FormContainer>
            <BuyNowButton onClick={() => handleThankYou()}>Comprar agora</BuyNowButton>
          </Main>
        </>
      ) : (
          <ThankYou />
        )}
    </>
  )
}

export default Payment
