import styled from 'styled-components'
import { Pagination } from 'antd'

export const ContainerCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  margin-top: 3.125rem;
  width: 100%;
  justify-content: center;
`
export const NoProductMsg = styled.h1`
  text-align: center;
  font-size: 1.25rem;
  margin-top: 3rem;
  color: #292929;
`

export const StyledPagination = styled(Pagination)`
  margin: 1rem auto 2.5rem;
  text-align-last: center;

  > .ant-pagination-item-active {
    border-color: var(--main);

    > a {
      color: var(--main);
    }

    &&&:hover {
      border-color: var(--main);
      color: var(--main);
    }
  }
`
