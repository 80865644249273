import styled from 'styled-components'

type Props = {
  rotate?: number,
  color?: string
}

export const StyledIcon = styled.img<Props>`
  color: ${(props) => props.color ? props.color : '#5C5C5C'};
  transform: rotate(${(props) => props.rotate ? props.rotate : 0}deg);
`