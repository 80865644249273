import styled from 'styled-components'
import Button from 'components/new/Button'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 16px;

  h1{
    width: 100%;
    padding: 0 8px;
    font-size:24px;
  }

  p{
    font-size: 18px;
    text-indent: 30px;
    text-align: justify;
    width: 100%;
    &::first-letter{
      text-transform: capitalize;
    }
  }

`
export const DivFile = styled.div`
  background-color: #F7F8FA;
  height: auto;
  padding: 8px 0px 8px 16px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;

  a{
    display: flex;
    align-items: center;
  }

  img{
    width: 20px;
    height: 20px;
  }
  p{
    font-size: 16px;
    margin:0;
    letter-spacing: 0;
    color: #007791;
    padding-left: 10px;
  }
`

export const ResultBlock = styled.div`
  display: flex;
  align-items: center;
  height: 340px;
  border: 1px solid #c0c0c0;
  border-radius: 10px;
  max-width: 657px;
  width: 100%;
  margin: 40px auto;
  padding: 25px;

  > div {
    width: 50%;
  }
`

export const Right = styled.div`
  width: 50%;

  > h3 {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.25px;
    color: var(--main);
    margin-bottom: 15px;
  }

  > strong {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #292929;
  }

  > p {
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #898989;
    margin: 10px 0 30px;
  }
`

export const QuestionsWrapper = styled.div`
  margin-top: 45px;
`

export const StyledButton = styled(Button)`
  margin: 0 auto;
`