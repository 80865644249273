import React from 'react';
import { MenuLink } from './styles'
import {
    HomeOutlined,
    AuditOutlined,
    BarsOutlined,
    ShopOutlined,
    DollarCircleOutlined,
    ExclamationCircleOutlined,
    ToolOutlined,
    SettingOutlined,
  } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'

const SuperAdmMobileView = () => {

    return (
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
            <MenuLink.Item key="1" icon={<HomeOutlined />}>
                <Link to="/"></Link>
            </MenuLink.Item>
            <MenuLink.Item key="2" icon={<DollarCircleOutlined />}>
                <Link to="/financial"></Link>
            </MenuLink.Item>
            <MenuLink.Item key="3" icon={<ShopOutlined />}>
                <Link to="/products"></Link>
            </MenuLink.Item>
            <MenuLink.Item key="4" icon={<AuditOutlined />}>
                <Link to="/student-producer"></Link>
            </MenuLink.Item>
            <MenuLink.Item key="5" icon={<BarsOutlined />}>
                <Link to="/reports"></Link>
            </MenuLink.Item>
            <MenuLink.Item key="6" icon={<ExclamationCircleOutlined />}>
                <Link to="/cancel"></Link>
            </MenuLink.Item>
            <MenuLink.Item key="7" icon={<ToolOutlined />}>
                <Link to="/config"></Link>
            </MenuLink.Item>
            <MenuLink.Item key="8" icon={<SettingOutlined />}>
                <Link to="/BackOffice"></Link>
            </MenuLink.Item>
        </Menu>
    )
}

export default SuperAdmMobileView