import DotImage from 'components/DotImage'
import StyledBadge from 'components/new_components/Badge'
import React, { useState } from 'react'
import { AiOutlineBell } from 'react-icons/ai'
import Notification from '../new/Notification'
import { ContainerNotifications } from './styles'


interface Props{
  count?: any
  not?: any
}
const Notifications: React.FC<Props> = ({count, not}) => {
  const [collapsed, setCollapsed] = useState(true)

  const mouseOver = () => {
    setCollapsed(!collapsed)
  }
  const mouseOut = () => {
    setCollapsed(!collapsed)
  }
    
  

  return (
    <>
      <ContainerNotifications         
        onMouseEnter={() => mouseOver()}
        onMouseLeave={() => mouseOut()}
      >
        <StyledBadge badgeContent={count}>
          <DotImage
            background={false}
            src=''
            icon={<AiOutlineBell color="#81BD03" size={20} />}
          >
          </DotImage>
        </StyledBadge>
        {!collapsed ? (
        <Notification not={not}/>
        ): null }
      </ContainerNotifications>
    </>
  )
}

export default Notifications
