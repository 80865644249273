import styled from 'styled-components'

export const FilterButton = styled.button`
  border: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  background-color: transparent;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  color: #5C5C5C;
  position: absolute;
  right: 1.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-left: 0.5rem;
  }
`