import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import { RadioContainer, RadioCustom, StyledGroup } from './style'

interface Props {
  name: string
  options: {
    id: number
    name: string
  }[]
  onSubmit?: any
  onChange?: any
  defaultValue?: any
  disabled?: boolean
}

const Radios: React.FC<Props> = ({
  name,
  options = [],
  defaultValue = '1',
  onSubmit,
  onChange,
  disabled,
  ...rest
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([])
  const { fieldName, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.find(ref => ref.checked)?.value || ''
      },
      setValue: (refs: HTMLInputElement[], id: any) => {
        const inputRef = refs.find(ref => ref.id === id)
        if (inputRef) inputRef.checked = true
      },
      clearValue: (refs: HTMLInputElement[]) => {
        const inputRef = refs.find(ref => ref.checked === true)
        if (inputRef) inputRef.checked = false
      },
    })
  }, [defaultValue, fieldName, registerField])

  return (
    <StyledGroup>
      {options?.map((option, index: any) => (
        <RadioContainer key={index}>
          <RadioCustom htmlFor={option.name}>
            <input
              ref={ref => inputRefs.current.push(ref as HTMLInputElement)}
              id={option.name}
              type="radio"
              name={name}
              onChange={onChange}
              defaultChecked={defaultValue.includes(option.id)}
              value={option.id}
              disabled={disabled}
              {...rest}
            />
            <span className="radio-custom"></span>
          </RadioCustom>
          <p>{option.name}</p>
        </RadioContainer>
      ))}
    </StyledGroup>
  )
}

export default Radios