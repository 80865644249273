import React from 'react'
import { CardHeader, CardFooter, Container, CardBody } from './styles'
import { FaDollarSign, FaChevronRight } from 'react-icons/fa'
import {Link} from 'react-router-dom'

interface Props {
  icon?: any
  value?: any // TODO: Para que isso aqui?
  titulo?: string
  children?: any
  url?: any
  onCh?: any
  cardIcon?: any
}

const Card: React.FC<Props> = ({ children, cardIcon,icon, value, titulo, url, onCh }) => {
  return (
    <Container>
      <CardHeader>
        <div className="first">
          {cardIcon}
          <p className="text">{titulo}</p>
        </div>
        {icon ? (
          <div className="second" onClick={onCh}>{icon}</div>
        ) : (
          <div className="second">
            <p className="text">{value}</p>
          </div>
        )}
      </CardHeader>
      <CardBody>
        {children}
      </CardBody>
      <CardFooter>
          <Link to={url}>
            <p className="text">Ver mais</p>
          </Link>
        <FaChevronRight color="var(--main)" size={15} style={{ marginRight: 20 }} />
      </CardFooter>
    </Container>
  );
};

export default Card;
