import { FormHandles, SubmitHandler } from '@unform/core'
import React, { useRef, useState } from 'react'
import * as Yup from 'yup'
import Form from 'components/new/Form'
import Input from '../../../components/Form/Input'
import { InputGroup } from '../../../components/Form/Input/style'
import api from '../../../services/api'
import { displayErrorNotification, displaySucessNotification } from 'utils/displayNotification'
import { Button } from './style'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'

const NewInstallment = () => {
  const [loading, setLoading] = useState(false)
  const [repayment, setRepayment] = useState<any>()
  const [isActive, setIsActive] = useState<any>()

  const formRef = useRef<FormHandles>(null)

  const handleSubmit: SubmitHandler<FormData> = async (body: object) => {
    setLoading(true)
    try {
      const schema = Yup.object().shape({
        quantity: Yup.string().required('O campo categoria é obrigatório'),
      })

      let obj = {
        name: repayment,
        is_active: isActive
      }

      await api.post('/create-repayment', obj)
      displaySucessNotification('Reembolso criado com sucesso! Sua página irá atualizar.')

      setTimeout(() => window.location.reload(true), 250)

      setLoading(false)

    } catch (err) {
      displayErrorNotification(err.response.data.message)
      setLoading(false)
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <TableContainer>
        <TableRow>
          <InputGroup>
            <Input type="text" name="repayment" onChange={(e: any) => setRepayment(e.target.value)} placeholder="Novo reembolso" />
            <legend>Reembolso</legend>
          </InputGroup>
        </TableRow>
      </TableContainer>

      <Button>
        Cadastrar
      </Button>
    </Form>
  )
}

export default NewInstallment