import React from 'react'
import { FormContainer, PaymentOptionForm, AddProductForm } from './style'

import Input from 'components/Input'
import Text from 'components/Input/Text'
import Select from 'components/Input/Select'
import Search from 'components/Search'
import Filter from '../../Filter'

const Payment = () => {
  return (
    <>
      <FormContainer>
        <PaymentOptionForm>
          <div>
            <Input title="Cartão de crédito">
              <Text placeholder="Número do Cartão" />
            </Input>

            <Input title="Titular">
              <Text placeholder="Nome do titular como" />
            </Input>
          </div>

          <div>
            <Input title="MM">
              <Text />
            </Input>
            <Input title="AA">
              <Text />
            </Input>
            <Input title="Código de segurança">
              <Text />
            </Input>
          </div>
        </PaymentOptionForm>

        <AddProductForm>
          <h1>Adicionar produto</h1>

          <div>
            <Search placeholder="Busque por produtos e produtores">
              <Filter>Filtros</Filter>
            </Search>

            <Input title="Opções de parcelamento">
              <Select>
                <option value="1x-parcel">1x de R$ 3.500,00</option>
              </Select>
            </Input>
          </div>
        </AddProductForm>
      </FormContainer>
    </>
  )
}

export default Payment
