import { Col, Row } from 'antd'
import TableCategories from 'components/BackOffice/Categories'
import TableSubCategories from 'components/BackOffice/SubCategories'
import TableCurrency from 'components/BackOffice/CurrencyType'
import TableInstallment from 'components/BackOffice/Installments'
import TablePaymentMethod from 'components/BackOffice/PaymentMethod'
import TableMarketPlace from 'components/BackOffice/MarketPlace'
import TableRepayment from 'components/BackOffice/Repayment'
import Header from 'components/HeaderPromotor'
import { Container } from 'GlobalStyles'
import React, { useEffect, useState } from 'react'
import api from 'services/api'
import NewCategory from './newCategory'
import NewSubCategory from './newSubCategory'
import NewCurrency from './newCurrencyType'
import NewInstallment from './newInstallment'
import NewPaymentMethod from './newPaymentMethod'
import NewMarketPlace from './newMarketPlace'
import NewRepayment from './newRepayment'
import { displayErrorNotification } from 'utils/displayNotification'
import history from 'routes/history'
import {
  BodyContainer, SubTitle,
  TableContainer
} from './style'

const Config = () => {
  const [err] = useState('')
  const [categories, setCategories] = useState<any>([])
  const [currency, setCurrency] = useState<any>([])
  const [installment, setInstallment] = useState<any>([])
  const [repayment, setRepayment] = useState<any>()
  const [paymentMethod, setPaymentMethod] = useState<any>()
  const [marketPlace, setMarketPlace] = useState<any>()
  const [profileValid, setProfileValid] = useState<boolean>(false)

  const getCategories = async () => {
    try {
      let { data: { categories } } = await api.get('/list-category')
      setCategories(categories)
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const getCurrency = async () => {
    try {
      let { data: { current } } = await api.get('/list-current')
      setCurrency(current)
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const getInstallment = async () => {
    try {
      let { data: { installment } } = await api.get('/list-installments')
      setInstallment(installment)
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const getRepayment = async () => {
    try {
      let { data: { repayment } } = await api.get('/list-repayment')
      setRepayment(repayment)
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const getPaymentMethod = async () => {
    try {
      let { data: { paymentMethod } } = await api.get('/list-payment-method')
      setPaymentMethod(paymentMethod)
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const getMarketPlace = async () => {
    try {
      let { data: { marketPlace } } = await api.get('/list-market-places')
      setMarketPlace(marketPlace)
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const checkProfileStatus = async () => {
    let { data } = await api.get('/profile')
    data.user.type_user === 'superadm' ? setProfileValid(true) : history.push('/')
  }

  useEffect(() => {
    checkProfileStatus()
    getCategories()
    getCurrency()
    getInstallment()
    getRepayment()
    getPaymentMethod()
    getMarketPlace()
  }, [])

  return (
    <>
      <Container>
        <Header SearchHeader />
        <SubTitle>
          <p>BackOffice</p>
        </SubTitle>
        { profileValid ? (
        <Row>
          <BodyContainer>
            <Col>
              {
                err.length > 0 ? (
                  <h2>{err}</h2>
                ) : (
                    <TableContainer>
                      <TableCategories categories={categories} />
                      <NewCategory />
                    </TableContainer>
                  )
              }

            </Col>

            <Col>
              {
                err.length > 0 ? (
                  <h2>{err}</h2>
                ) : (
                    <TableContainer>
                      <TableCurrency currencyType={currency} />
                      <NewCurrency />
                    </TableContainer>
                  )
              }

            </Col>

            <Col>
              {
                err.length > 0 ? (
                  <h2>{err}</h2>
                ) : (
                    <TableContainer>
                      <TableInstallment installment={installment} />
                      <NewInstallment />
                    </TableContainer>
                  )
              }

            </Col>

            <Col>
              {
                err.length > 0 ? (
                  <h2>{err}</h2>
                ) : (
                    <TableContainer>
                      <TableRepayment repayment={repayment} />
                      <NewRepayment />
                    </TableContainer>
                  )
              }

            </Col>

            <Col>
              {
                err.length > 0 ? (
                  <h2>{err}</h2>
                ) : (
                    <TableContainer>
                      <TablePaymentMethod paymentMethod={paymentMethod} />
                      <NewPaymentMethod />
                    </TableContainer>
                  )
              }

            </Col>

            <Col>
              {
                err.length > 0 ? (
                  <h2>{err}</h2>
                ) : (
                    <TableContainer>
                      <TableMarketPlace marketPlace={marketPlace} />
                      <NewMarketPlace />
                    </TableContainer>
                  )
              }

            </Col>
          </BodyContainer>
        </Row>
          ) : (
            <></>
          ) 
        }
      </Container>
    </>
  )
}

export default Config