/*
Após cadastrada a aula, ao clicar nela, você verá essa página de visualização aqui.
*/


import React, { useState, useContext } from 'react'
import {
  Container,
  NoVideoMsg,
  AddVideoBtn,
  StyledForm,
  SideContainer,
  Description,
  Attachment,
} from './style'
import { Modal } from 'antd'
import Input from 'components/new/Input'
import Text from 'components/new/Input/Text'
import { EditProductContext } from '../../../EditProductContext'
import displayYupErrorNotification from 'utils/displayYupErrorNotification'
import { displaySucessNotification } from 'utils/displayNotification'
import * as Yup from 'yup'
import ReactPlayer from 'react-player/lazy'
import { CircularProgress } from '@material-ui/core/'

interface Props {
  module: any
  classe: any
  visible: boolean
  onCancel: () => void
}

const ClassPreviewModal: React.FC<Props> = ({
  module,
  classe,
  visible,
  onCancel,
}) => {
  let { name, video_link, description, attachment } = classe
  const [editProductData, setEditProductData] = useContext(EditProductContext)
  const [videoUrl, setVideoUrl] = useState<string>('')
  const [duration, setDuration] = React.useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const handleCancel = () => {
    onCancel()
    setVideoUrl('')
  }

  const handleDuration = (duration: number) => {
    setDuration(duration)
  }

  const handleVideoLink = (evt: any) => {
    setVideoUrl(evt.target.value)
  }

  const handleAddVideo = async (data: any) => {
    setVideoUrl(data.video_link)
    setLoading(true)

    try {
      const schema = Yup.object().shape({
        video_link: Yup.string()
          .url('Url inválida.')
          .required('Adicione um link para o vídeo.'),
      })

      await schema.validate(data)

      let newModules = editProductData.modules.map((moduleMap: any) => {
        if (moduleMap.id === module.id) {
          let newClasses = moduleMap.classes.map((classMap: any) => {
            if (classMap.id === classe.id) {
              return {
                ...classMap,
                video_link: data.video_link,
                time: duration,
              }
            }

            return classMap
          })

          return {
            ...moduleMap,
            classes: newClasses,
          }
        } else {
          return moduleMap
        }
      })

      setEditProductData({
        ...editProductData,
        modules: newModules,
      })
      displaySucessNotification('Vídeo adicionado com sucesso.')
      handleCancel()
      setLoading(false)
    } catch (err) {
      displayYupErrorNotification(err)
    }

    setLoading(false)
  }

  return (
    <Modal
      title={name}
      visible={visible}
      footer={null}
      closable={true}
      onCancel={handleCancel}
      width={900}
    >
      <Container>
        {!video_link || video_link === '' ? (
          <StyledForm onSubmit={handleAddVideo}>
            <NoVideoMsg>Aula sem vídeo.</NoVideoMsg>

            <Input title="Link da aula">
              <Text
                name="video_link"
                placeholder={'Link do vimeo'}
                onChange={handleVideoLink}
                onBlur={handleVideoLink}
                value={videoUrl}
              />
            </Input>
            <AddVideoBtn type="submit">
              {loading ? (
                <CircularProgress color="inherit" size={20} thickness={5} />
              ) : (
                'Adicionar Vídeo'
              )}
            </AddVideoBtn>

            <ReactPlayer
              key={'vimeo'}
              url={videoUrl}
              onDuration={handleDuration}
              width="0"
              height="0"
            />
          </StyledForm>
        ) : (
          <ReactPlayer
            key={'vimeo'}
            url={video_link}
            controls
            width="640px"
            height="360px"
          />
        )}
        <SideContainer>
          <Description>
            <h1>Descrição da aula</h1>

            <p>{description}</p>
          </Description>

          <Attachment>
            <h1>Anexo</h1>

            {attachment ? (
              <a target="_blank" href={attachment} rel="noopener noreferrer">
                Ver anexo
              </a>
            ) : (
              <p>Nenhum anexo.</p>
            )}
          </Attachment>
        </SideContainer>
      </Container>
    </Modal>
  )
}

export default ClassPreviewModal