import { Pagination } from 'antd'
import { Card } from 'components/CardContainer'
import Form from 'components/new/Form'
import styled from 'styled-components'

export const BodyContainer = styled.main`
    display: flex;
`

export const CardContainer = styled(Card)`
    width: 100%;
    height: 400px;
    padding: 40px;
    margin-left: 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`
export const ContentForm = styled(Form)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

`

export const ActionBtn = styled.button`
  border: none;
  color: var(--main);
  outline: none;
  background: none;
  font-weight: 500;  
  display: inline-flex;
  justify-content: space-between;
  cursor: pointer;

  &.next-btn,
  &.done-btn {
    position: absolute;
  }

  &.next-btn {
    bottom: 0;
    right: 3.5625rem;
  }

  &.done-btn {
    bottom: 0.4rem;
    right: 5.25rem;
  }
`

export const InputsSection = styled.section`
  width: 90%;
  display: flex;
  flex-direction: column;
`
export const FlexInput = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Register = styled.button`
  margin-top: 12px;
  bottom: 0.4rem;
  right: 5.25rem;
  padding: 0.425rem 1.25rem;
  outline: none;
  background: var(--main);
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  align-self: flex-end;  

  p {
    margin: 0;
  }
`


export const BtnYear = styled.div`
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: var(--main);
    display: flex;
    p{
        margin-left: 40px;
        margin-right: 40px;
    }
    svg{
        margin-top: 5px;
        cursor: pointer;
    }

    svg:first-child{
        transform: rotate(180deg);
    }
`

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1300px){
    flex-direction: column;
  }
`
export const TableContainer = styled(Card)`
    
    width: 500px;
    margin-left: 16px;
    margin-bottom: 12px;
    .title{
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #5C5C5C;
    }

    .dBoHet{
        height: 70%;
    }
    @media screen and (max-width:1300px){
      width: 100%;
      margin-left: 0;
      margin-top: 12px;
      margin-bottom: 12px;
    }
`
export const Button = styled.button`
    border: 1px solid var(--main);
    box-sizing: border-box;
    border-radius: 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: var(--main);
    background: transparent;
    width: 150px;
    height: 36px;
    cursor: pointer;
    transition: all 0.3s;
    outline: none;
    margin-right: 15px;
    &:hover{
        border: 1px solid #5C5C5C;
        color: #5C5C5C; 
    }
`
export const QtdSells = styled.div`
    width: 100%;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #5C5C5C;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
`
export const FooterCard = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.15px;
    color: #5C5C5C;

    p{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        letter-spacing: 0.15px;
        color: #5C5C5C;
        margin-left: 7px;
    }
    div{
        display: flex;
        margin-left: 80px;
    }
`
export const SubTitle = styled.div`
    margin-top: 10px;
    width:100%;
    text-align:left;
    color: #898989;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 40px;

    p{
        margin: 0;
        cursor: pointer;
    }
`

export const StyledPagination = styled(Pagination)`
  margin: 1rem auto 2.5rem;

  > .ant-pagination-item-active {
    border-color: var(--main);

    > a {
      color: var(--main);
    }

    &&&:hover {
      border-color: var(--main);
      color: var(--main);
    }
  }
`
export const FooterPagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`