/*
Aqui você pode editar as informações do módulo, como o nome dele e somente isso mesmo.
*/

import React, { ChangeEvent, useState, useContext } from 'react'
import { Modal } from 'antd'
import { ModalButton } from '../../shared-styles'
import { Form } from '@unform/web'
import Input from 'components/new/Input'
import Text from 'components/new/Input/Text'
import { EditProductContext } from '../../../EditProductContext'
import { displayErrorNotification } from 'utils/displayNotification'

interface Module {
  id: number
  name: string
  sequence: number
  classes: []
  tests: []
}

interface Props {
  module: Module
  visible: boolean
  onCancel: () => void
}

const EditModuleModal: React.FC<Props> = ({ module, visible, onCancel }) => {
  const [editedName, setEditedName] = useState<string>(module.name)
  const [editProductData, setEditProductData] = useContext(EditProductContext)

  const handleNewName = (evt: ChangeEvent<HTMLInputElement>) => {
    setEditedName(capitalize(evt.target.value))
  }

  const handleEdit = () => {
    if (editedName === '') {
      displayErrorNotification('Por favor, adicione um nome.')
      return
    }

    let newModules = editProductData.modules.map((moduleMap: Module) => {
      if (moduleMap.id === module.id) {
        return {
          ...moduleMap,
          name: editedName,
        }
      } else {
        return moduleMap
      }
    })

    setEditProductData({
      ...editProductData,
      modules: newModules,
    })

    onCancel()
  }

  const capitalize = (e: any) => {
    return e.charAt(0).toUpperCase() + e.slice(1)
  }

  return (
    <Modal
      title="Editar módulo"
      visible={visible}
      closable={true}
      onCancel={onCancel}
      footer={[
        <ModalButton type="button" onClick={onCancel}>
          Cancelar
        </ModalButton>,

        <ModalButton className="submit" onClick={handleEdit}>
          Editar módulo
        </ModalButton>,
      ]}
    >
      <Form onSubmit={() => { }}>
        <Input title="Nome do módulo">
          <Text
            name="name"
            placeholder="Digite o nome do modúlo"
            value={editedName}
            onChange={handleNewName}
            maxLength={100}
          />
        </Input>
      </Form>
    </Modal>
  )
}

export default EditModuleModal