import React from 'react'
import {
  Main,
  OrganicHeader,
  Content,
  Title,
  Text,
  ContentFooter,
  Product,
  ProductImg,
  ProductInfos,
  CheckContainer,
  BuyNowButton
} from './style'

const OrganicInsta = () => {
	return (
    <Main>
      <OrganicHeader><h1>Insta Orgânico</h1></OrganicHeader>

      <Content>
        <Title>Aproveite esse mega desconto de 50%</Title>

        <Text>
          O curso Insta Orgânico possui 17 módulos e +30 aulas sobre o Instagram.
          No Curso você vai aprender técnicas e aplicadas na Seja CEO para conquistar
          40 mil seguidores organicamente e faturar +40 mil reais. 
        </Text>

        <ContentFooter>
          <Product>
            <ProductImg>
              <img src="" alt="" />
            </ProductImg>

            <ProductInfos>
              <p className="title">Manual de Crescimento Orgânico no Instagram</p>
              <p className="price">R$ 9,90</p>
              <p className="description">Comemoração de 40k</p>
            </ProductInfos>
          </Product>

          <CheckContainer>
            <input type="checkbox" />
            <p>Quero aproveitar a oportunidade e comprar junto - R$ 153,50</p>
          </CheckContainer>
        </ContentFooter>

        <BuyNowButton>Comprar agora</BuyNowButton>
      </Content>
    </Main>
	)
}

export default OrganicInsta