import styled from 'styled-components'
import { Radio } from 'antd'
import { Button } from '../style'

export const Main = styled.main`
  width: 100%;
  margin-top: 3.6875rem;
  position: relative;
`

export const PaymentOptions = styled.section`
  width: 16.25rem;
  margin-bottom: 1.875rem;
  display: flex;
`

export const OptionGroup = styled(Radio.Group)`
  display: flex;

  & > * { border-radius: 0.625rem !important;}
  & > *::before { display: none !important; } 
`

export const Option = styled(Radio.Button)`  
  width: 7rem;
  height: 7rem;
  border: 0.0625rem solid;
  border-color: #A2A2A2;  
  margin-right: 0.7rem;
  cursor: pointer;  

  .option-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.8rem auto 0;
  }

  & #two-cards img {
    width: 3.5625rem;
  }
`

export const OptionIcon = styled.img`
  width: 1.5625rem;
  height: 1.5625rem;  
`

export const OptionLabel = styled.p`
  font-weight: normal;
  font-size: 0.907rem;
  line-height: 1rem;
  text-align: center;
  margin-top: 0.4rem;
  letter-spacing: 0.025rem;
  color: #A2A2A2;
`

export const FormContainer = styled.section`
  width: 100%;
`

export const PaymentOptionForm = styled.form`
  width: 100%;
  display: flex;

  div:first-child {
    flex: 1;
  }

  div:nth-child(2) {
    align-self: flex-end;
    display: flex;
    flex: 1;
    margin-left: 7.3rem;

    & fieldset:first-child,
    & fieldset:nth-child(2) {
      width: 40%;
      margin-right: 2.15rem;
    }
  }
`

export const AddProductForm = styled.form`
  width: 100%;
  margin-bottom: 1.875rem;

  h1 {
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.015625rem;
    color: #000000;
  }

  div {   
    width: 100%; 
    display: flex;
    justify-content: space-between;
    align-items: center;

    fieldset {
      margin: 0;
    }

    form {
      width: 96%;
      margin-top: 0.6rem;
      margin-right: 7.3rem;
    }
  }
`

export const AddedProducts = styled.section`
  h1 {
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.015625rem;
    color: #000000;
  }
`

export const ProductsContainer = styled.section`  
  border: 0.0625rem solid #C0C0C0;    
  border-radius: 0.625rem; 

  .product {    
    width: 100%;
    padding: 1rem 1.57812rem;
    display: flex;
    justify-content: space-around;
    align-items: center;

    img {
      cursor: pointer;
    }
  }
`

export const BuyNowButton = styled(Button)`
  margin: 1.3rem 0;
  position: relative;
  left: 86.3%;
`