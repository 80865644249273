import { Form } from '@unform/web'
import styled from 'styled-components'
import backgroundSvg from '../../../assets/images/backMain.jpg'

export const MainContainer = styled.main`
  background-image: url(${backgroundSvg});
  background-attachment: fixed;
  background-size: cover;
  background-position-y: bottom;
  background-color: #0f0f0f;
  /* background-blend-mode: overlay; */
  width: 100vw;
  min-height: 100vh;
  display: flex;
`

export const Container = styled.section`
  width: 667px;
  min-height: 100vh;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
  margin: 0;

  @media (min-width: 660px) {
    border-radius: 0;
    min-height: 70vh;
  }

  @media (min-width: 1024px) {
    min-height: 76vh;
  }
  @media (max-width: 640px) {
    width: 100vw;
  }
`

export const FormContainer = styled.div`
  width: 100%;

  @media (min-width: 600px) {
    width: 80%;
    margin: 0 auto;
  }

  a {
    text-decoration: underline;
    text-align: center;
  }

  a:hover {
    color: var(--main);
  }

  .forgotPassword {
    text-decoration: none;

    p {
      margin-top: 1.5rem;
      color: #999;
    }

    p:hover {
      color: var(--main);
    }
  }
`

export const PolicyPrivacy = styled.div`
  color: white;

  h3 {
    color: white;
  }
`

export const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`

export const PrimaryButton = styled.button`
  width: 100%;
  text-align: center;
  background-color: var(--main);
  padding: 1rem 0;
  color: #fff;
  border-radius: 0.5rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0.3125rem 0 var(--main-shade);
  transition: box-shadow 0.4s, transform 0.3s;

  margin-bottom: 2rem;
  margin-top: 1.5rem;

  &:hover {
    background-color: var(--main-highlight);
  }

  &:active {
    box-shadow: 0 0 0 #4e6f06;
    transform: translateY(0.3125rem);
  }
`
