import styled from 'styled-components'
import { Card } from '../../../../components/CardContainer'

export const Flex = styled.div`
  display: flex;
`

export const ProductCard = styled(Card)`
  width: 67.9375rem;
  margin-bottom: 1.031875rem;
  padding: 0;
  display: flex;
`

export const ProductImg = styled.div`
  width: 7.25rem;
  height: 7.25rem;
  max-height: 7.25rem;
  border-radius: 10px 0px 0px 10px;
  background-color: #e1e1e1;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
`

export const Content = styled.section`
  width: 84.33%;
  margin: auto;
  display: flex;
  align-items: center;
`

export const ContentTitle = styled(Flex)`
  text-align: left;
  width: 17%;

  h1 {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.015625rem;
    color: #5c5c5c;
  }
`

export const ContentAuthor = styled.p`
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  color: #a2a2a2;
  margin: 0 1.1rem;
`

export const ContentCenter = styled(Flex)`
  margin: auto;
  width: 40.94%;

  & section:last-child {
    padding: 0 4.1rem;
    border-right: 0.0300258rem solid rgba(0, 0, 0, 0.1);
  }
`

export const FlexContainer = styled(Flex)`
  align-items: center;
`

export const Icon = styled.img`
  width: auto;
  height: 1.34125rem;
  margin-right: 0.625rem;
`

export const Value = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin: 0;
  letter-spacing: 0.009375rem;

  color: #5c5c5c;
`

export const Label = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  margin-left: 2rem;
  color: #a2a2a2;
`

export const Promotion = styled.div`
  width: 13rem;
  margin-top: 1rem; 

  p {
    font-weight: 500;
    font-size: 1.65rem;
    line-height: 1.4375rem;
    letter-spacing: 0.009375rem;
    color: var(--main);
  }
`

export const DashedLabel = styled(Label)`
  text-decoration: line-through;
  margin: 0;
`

export const Discount = styled.p`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  color: #DB6262;
  margin-top: 1rem;
`
