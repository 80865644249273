import { Button } from '../style'
import styled from 'styled-components'
import Form from 'components/new/Form'

interface ButtonProps {
  opacity: string
}

export const UploadButton = styled(Button)<ButtonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--main);
  font-weight: bold;
  opacity: ${props => props.opacity};
  z-index: 1;
  img {
    max-width: 6.25rem;
    max-height: 6.25rem;
  }
`

export const ContentForm = styled(Form)`
  width: 100%;
  display: grid;
  grid-template-columns: 0.7fr 2fr;
  grid-column-gap: 4.5rem;
  margin-top: 2.440624rem;
`

export const InputsSection = styled.section`
  grid-column: 2;
`

export const Content = styled.section`
  width: 100%;
  display: flex;
  margin-top: 2.440625rem;
`

export const ImgSection = styled.section`
  grid-column: 1;
  p {
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.025rem;
    color: #5c5c5c;
    margin-top: 1.36rem;
    margin-left: 0.85rem;
  }
`

export const ImgContainer = styled.div`
  width: 100%;
  height: 12.5rem;
  border-radius: 0.625rem;
  border: 1px solid #c0c0c0;
  display: flex;
  overflow: hidden;
  position: relative;
  button {
    margin: auto;
  }
`

interface ImgProps {
  display: string
  image: string
}

export const ProductImg = styled.div<ImgProps>`
  width: 100%;
  height: auto;
  border-radius: inherit;
  display: ${props => props.display};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: url('${props => props.image}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

export const FormContainer = styled.form`
  width: 100%;
  p {
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.025rem;
    color: #898989;
    mix-blend-mode: normal;
    opacity: 0.6;
    margin-left: 1.25rem;
  }
`