import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import { Container } from './style'

interface Props {
  name: string
  placeholder?: string
  width?: string
  height?: string
  title?: string
  style?: object
  disabled?: boolean
  valueInput?: any
  onChangeInput?: any
}

const Textarea: React.FC<Props> = ({
  name,
  width = '100%',
  height = '6.7375rem',
  placeholder = 'Breve descrição do produto',
  title = 'Título',
  style,
  disabled,
  valueInput,
  onChangeInput,
  ...rest
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const { fieldName, defaultValue, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
    })
  }, [fieldName, registerField])

  return (
    <Container style={style} width={width} height={height}>
      <fieldset>
        <textarea
          id={fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          maxLength={5000}
          placeholder={placeholder}
          onChange={onChangeInput}
          disabled={disabled}
          value={valueInput}
          {...rest}
        />
        <legend>{title}</legend>
      </fieldset>
      <p>{valueInput ? 5000 - valueInput?.length : 5000} caracteres restantes</p>
    </Container>
  )
}

export default Textarea