import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
  root: {
    background: 'var(--main)',
    color: '#fff',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
      background: 'var(--main)',
   }
  },
});

interface Props{
  children?: any
  onClick?: any
  style?: any
  icon?: any
}

const StyledButton: React.FC<Props> = ({children, onClick, style, icon}) => {
  const classes = useStyles();
  return (
  <Button style={style} onClick={onClick} className={classes.root}>
    {icon? icon : null}
    {children}
  </Button>);
}

export default StyledButton;