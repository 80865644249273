import React from 'react';

import {
    ContainerSteps,
    StepsNumber,
    Steps,
    StepActive
} from './style';

interface Props {
    Step1?: string
    Step2?: string
    Step3?: string
  }

const CheckoutSteps: React.FC<Props> = ({Step1, Step2, Step3}) =>{
    return (
        <ContainerSteps>
            
        {Step1 === "Active"  
            ?
                <>
                    <StepActive>
                        <StepsNumber>
                            1
                        </StepsNumber>
                        Dados pessoais
                    </StepActive>

                    <Steps>
                        <StepsNumber>
                            2
                        </StepsNumber>
                        Pagamento
                    </Steps>

                    <Steps>
                        <StepsNumber>
                            3
                        </StepsNumber>
                        Obrigado!
                    </Steps>
                </>
            :
                <>
                </>
            }

            {Step2 === "Active"  
            ?
                <>
                    <Steps>
                        <StepsNumber>
                            1
                        </StepsNumber>
                        Dados pessoais
                    </Steps>

                    <StepActive>
                        <StepsNumber>
                            2
                        </StepsNumber>
                        Dados pessoais
                    </StepActive>

                    <Steps>
                        <StepsNumber>
                            3
                        </StepsNumber>
                        Obrigado!
                    </Steps>
                </>
            :
                <>
                </>
            }
            {Step3 === "Active"  
            ?
                <>
                    <Steps>
                        <StepsNumber>
                            1
                        </StepsNumber>
                        Dados pessoais
                    </Steps>

                    <Steps>
                        <StepsNumber>
                            2
                        </StepsNumber>
                        Dados pessoais
                    </Steps>

                    <StepActive>
                        <StepsNumber>
                            3
                        </StepsNumber>
                        Obrigado!
                    </StepActive>
                </>
            :
                <>
                </>
            }
        </ContainerSteps>
    )
};

export default CheckoutSteps;