/*
Aqui é um componente de módulo, onde é criado um teste.
*/


import React, { useState, useContext, ChangeEvent } from 'react'
import { Modal } from 'antd'
import { ModalFooter, ModalButton } from '../../shared-styles'
import { Form } from '@unform/web'
import Input from 'components/new/Input'
import Text from 'components/new/Input/Text'
import Textarea from 'components/new/Input/Textarea'
import PdfFileInput from 'components/new/Input/PdfFileInput'
import { FormContainer } from './style'
import { EditProductContext } from '../../../EditProductContext'
import { displaySucessNotification } from 'utils/displayNotification'
import displayYupErrorNotification from 'utils/displayYupErrorNotification'
import * as Yup from 'yup'
import api from 'services/api'
import asyncForEach from 'utils/asyncForEach'
import { CircularProgress } from '@material-ui/core/'

interface NewTest {
  sequence: number
  name: string
  time: number
  description: string
  pdf_link: File
}

interface Props {
  visible: boolean
  moduleID: number
  onCancel: () => void
}

async function uploadPDF(pdf: string, newSequence: number, id: number, moduleSquence: number) {
  let result = await api.post('upload-pdf', {
    pdf_link: pdf,
    index: newSequence,
    module: moduleSquence,
    type: 'test',
    product_id: id,
  })
  return result.data
}

const NewTestModal: React.FC<Props> = ({ visible, moduleID, onCancel }) => {
  const [editProductData, setEditProductData] = useContext(EditProductContext)
  const [pdfTest, setPdfTest] = useState<any>(null)
  const [hasPdfTest, setHasPdfTest] = useState<boolean>(false)
  const [defaultValues, setDefaultValues] = useState<any>({
    name: '',
    time: 0,
    description: '',
  })
  const [loading, setLoading] = useState<boolean>(false)

  const randomId = (Math.random().toString(33).substring(2)).replace(/[^a-zA-Z]/g, '')

  const handleValueChange = (
    evt: ChangeEvent<HTMLInputElement>,
    property: string,
  ) => {
    setDefaultValues({
      ...defaultValues,
      [property]: capitalize(evt.target.value),
    })
  }

  const handlePdfTest = (evt: any) => {
    if (evt.target.files && evt.target.files[0]) {
      let reader = new FileReader()
      reader.onload = function () {
        setPdfTest(reader.result)
        setHasPdfTest(true)
      }
      reader.readAsDataURL(evt.target.files[0])
    }

    let pdfInput: any = document.querySelector(`#${randomId}`)
    pdfInput.value = null
  }

  const resetValues = () => {
    setPdfTest(null)
    setHasPdfTest(false)
    setDefaultValues({
      name: '',
      time: 0,
      description: '',
    })
  }

  const handleCancel = () => {
    resetValues()
    onCancel()
  }

  const handleNewTest = async (data: NewTest) => {
    setLoading(true)
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O nome do Teste é obrigatório.'),
        time: Yup.number()
          .min(1, 'O teste deve ter ao menos 1 minuto de duração.')
          .required('O tempo do Teste deve ser específicado.'),
        description: Yup.string().required('Por favor, adicione uma descrição.'),
        pdf_link: Yup.string()
          .typeError("Adicione um arquivo PDF.")
          .test("fileSize", "O arquivo é maior que 30MB.", (value: any) => {
            return (value.length * (3 / 4)) - 2 <= 3 * (10 ** 7) //esse é o tamanho de 30mb em bytes
          }),
      })

      let dataWithPdf = {
        ...data,
        pdf_link: pdfTest,
      }

      await schema.validate(dataWithPdf)

      let newModules: any[] = []

      await asyncForEach(editProductData.modules, async (module: any) => {
        if (module.sequence === moduleID) {
          let newSequence = module.tests.length ? module.tests.length + 1 : 0
          let newPdf = await uploadPDF(pdfTest, newSequence, editProductData.id, module.sequence)

          let newTest = {
            ...data,
            sequence: newSequence,
            pdf_link: newPdf,
          }

          let newTests = module.tests.concat(newTest)

          newModules.push({
            ...module,
            tests: newTests,
          })
        } else {
          newModules.push(module)
        }
      })

      setEditProductData({
        ...editProductData,
        modules: newModules,
      })

      //onSubmit()
      handleCancel()
      setLoading(false)
      displaySucessNotification('Teste criado com sucesso!')
    } catch (err) {
      displayYupErrorNotification(err)
      setLoading(false)
    }
  }

  // const onSubmit = async () => {
  //   await api.put(`/update-product/${editProductData.id}`, editProductData)
  // }

  const capitalize = (e: any) => {
    return e.charAt(0).toUpperCase() + e.slice(1)
  }

  return (
    <Modal
      title={'Adicionar Teste'}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <Form onSubmit={handleNewTest}>
        <FormContainer>
          <Input title="Titulo do Teste">
            <Text
              name="name"
              placeholder={'Digite o título do Teste'}
              onChange={(evt: any) => handleValueChange(evt, 'name')}
              maxLength={100}
              value={defaultValues.name}
            />
          </Input>

          <Input title="Tempo do Teste">
            <Text
              name="time"
              type="number"
              min="0"
              placeholder={'Tempo em minutos'}
              onChange={(evt: any) => handleValueChange(evt, 'time')}
              value={defaultValues.time}
            />
          </Input>

          <Textarea
            name="description"
            title="Descrição do Teste"
            placeholder={'Descrição do Teste'}
            height={'7.06rem'}
            onChangeInput={(evt: any) => handleValueChange(evt, 'description')}
            valueInput={defaultValues.description}
            style={{ marginBottom: '2rem' }}
          />

          <PdfFileInput
            name={randomId}
            label={hasPdfTest ? 'PDF Adicionado' : 'Adicionar PDF'}
            onChange={handlePdfTest}
            hasPdf={hasPdfTest}
          />
        </FormContainer>

        <ModalFooter>
          <ModalButton type="button" onClick={handleCancel}>
            Cancelar
          </ModalButton>
          <ModalButton className="submit" type="submit">
            {loading ? (
              <CircularProgress color="inherit" size={20} thickness={5} />
            ) : (
                'Adicionar Teste'
              )}
          </ModalButton>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default NewTestModal
