import styled from 'styled-components'
import { Card } from 'components/CardContainer'
import { StyledLink } from 'components/Link'
import Textarea from 'components/new/Input/Textarea'


export const InputTextArea = styled(Textarea)`
  font-size: 16px;
  color: #2f2f2f;
`

export const Flex = styled.div`
  display: flex;
`

export const ProductCard = styled(Card)`
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  margin-bottom: 5.52475vh;
  @media screen and (max-width: 1151px){
    flex-direction: column;
  }
`

interface Image {
  src: string
}

export const ProductImg = styled.div`
  width: 9.614375vw;
  height: 9.12864rem;
  border-radius: 0.625rem 0px 0px 0.625rem;
  background-color: #e1e1e1;
  overflow: hidden;
  background-image: url('${(props: Image) => props.src}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 1151px){
    width: 100%;
    border-radius: 5px 5px 0 0;
  }
`

export const Content = styled.section`
  width: 84.3%;
  margin: 25px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1151px){
    flex-direction: column;
    width: 85%;

  }
  .responsiveOptionBtn{
    align-self: flex-start;
    @media screen and (max-width: 1151px){
    align-self: flex-end;

    }
  }
  
`

export const ContentTitle = styled(Flex)`
  flex-direction: column;
  text-align: left;
  width: 13.194375vw;
  overflow-wrap: break-word;

  h1 {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4375rem;
    letter-spacing: 0.009375rem;
    color: #5c5c5c;
  }

  h2 {
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.015625rem;
    color: #a2a2a2;
  }
  @media screen and (max-width: 1151px){
    width: 100%;
    margin-top: 8px
  }
`

export const ContentCenter = styled(Flex)`
  margin: auto;
  width: 70.16875%;
  justify-content: space-around;
  @media screen and (max-width: 1151px) {
    flex-wrap: wrap;
    padding: 20px;
  }@media screen and (max-width: 402px) {
    flex-wrap: wrap;
    padding: 20px;
    width: 100%;
  }
`

export const FlexContainer = styled(Flex)`
  align-items: center;
`

export const Icon = styled.img`
  width: auto;
  height: 1.34125rem;
  margin-right: 0.625rem;
`

export const Active = styled.p<{ currentstatus: string }>`
  padding: 0.3125rem 0.625rem;
  height: fit-content;
  margin: 0.4375rem;
  background: ${(props) => {
    switch (props.currentstatus) {
      case 'APROVADO':
        return "#28a745";
      case 'EM ANÁLISE':
        return "#ffc107";
      case 'RECUSADO':
        return "#dc3545"
      case 'BLOQUEADO':
        return "#dc3545"
    }
  }};
  border-radius: 1.9375rem;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  color: #ffffff;
  text-align: center;
  @media screen and (max-width:1151px){
    align-self: flex-end;
  }
`

export const Value = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin: 0;
  letter-spacing: 0.009375rem;

  color: #5C5C5C;
`

export const Label = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  margin-left: 2rem;
  color: #A2A2A2;
`

export const Link = styled(StyledLink)`
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  margin-left: 2rem;
`

export const Subcard = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.1.5625rem;
  color: #5C5C5C;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  border: 0.0625rem solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 0.625rem;
  padding: 0.5rem;

  & a:nth-child(2) {    
    border-top: 0.0625rem solid #E0E0E0;
    border-bottom: 0.0625rem solid #E0E0E0;
  }
`
export const OptionsBtn = styled.div`
  cursor: pointer;
  padding: 8px 16px;
  align-self: flex-start;
  @media screen and (max-width: 1151px){
    align-self: flex-end;
  }
`

export const SubcardLink = styled(StyledLink)`
  font-size: 0.9rem;
  color: #292929;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  padding: 1rem;

  &:hover {
    color: var(--main);
  }
`

export const ProductLink = styled.a`
  font-size: 0.9rem;
  color: #292929;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  padding: 1rem;

  &:hover {
    color: var(--main);
  }
`
interface Props {
  display: any;
}

export const Overlay = styled.div<Props>`
  position: fixed;
  display: ${props => props.display}; 
  width: 100%; 
  height: 100%; 
  background-color:#000000a1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2; 
`
export const Modal = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 5;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: black;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  padding: 12px;
  background-color: white;
  border-radius: 5px;
  

  min-height: 30%;
  width: 75%;

  p {
    margin: 8px;
    color: #2f2f2f;
    font-size: 16px;
  }

`
export const Title = styled.div`
  padding: 4px;
  display: flex;
  justify-content: space-between;
  img{
    padding: 8px;
    width: 32px;
    height: 32px;
    cursor: pointer; 
  }
`

export const Button = styled.button`
  height: 50px;
  padding: 0.4375rem 1.18875rem;
  color: #FFFFFF;
  border-radius: 3rem;
  background-color: var(--main);
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  &:hover{
    color: #FFFFFF;
    background-color: #e6d627;
  }
`

interface ButtonTaxProps {
  bgcolor: any;
}

export const ButtonTax = styled.button<ButtonTaxProps>`
  height: 5%;
  padding: 10px;
  color: #FFFFFF;
  border-radius: 3rem;
  background-color: ${props => props.bgcolor};
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  &:hover{
    color: #FFFFFF;
    background-color: ${props => props.bgcolor + '55'};
  }
`