import { Popover } from 'antd'
import Perfil from 'assets/perfil.svg'
import React, { useEffect, useState } from 'react'
import { AiOutlineCalendar } from 'react-icons/ai'
import { FaChevronRight } from 'react-icons/fa'
import { Link, NavLink } from 'react-router-dom'
import api from 'services/api'
import ChampionIcon from '../../assets/icons/champion-icon.svg'
import DotImage from '../DotImage'
import MiniProfile from '../MiniProfile'
import Notifications from '../Notifications'
import {
  Champion,
  Classes,
  Container,
  ContainerInputs,
  ContainerProgress,
  ContainerTitles,
  Evolution,
  ProgressBar,
  SubTitle,
  Title,
} from './styles'

interface Props {
  SearchHeader?: boolean
}

const HeaderPromotor: React.FC<Props> = ({ SearchHeader }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [HeaderSearch] = useState<boolean>(true)
  const [photo, setPhoto] = useState<any>(Perfil)
  const [countNt, setCountNt] = useState<any>(0)
  const [not, setNot] = useState<any>([])
  const [type, setType] = useState<any>(true)
  const [nameUser, setNameUser] = useState<any>('')

  const handleVisibleChange = () => {
    setVisible(!visible)
  }

  const getUser = async () => {
    let count = 0
    let atention = []
    let response = await api.get('profile')
    setNameUser(response.data.user.name)
    if (
      response.data.user.type_user === 'producer' ||
      response.data.user.type_user === 'superadm'
    ) {
      setType('Produtor')
    } else {
      setType('Estudante')
    }

    if (
      response.data.user.type_user === 'producer' ||
      response.data.user.type_user === 'superadm'
    ) {
      if (response.data.user.cep === null) {
        count++
        let msg = {
          name: 'Falta preencher seu endereço.',
        }
        atention.push(msg)
        setCountNt(count)
      }
      if (response.data.user.cpf === null && response.data.user.cnpj === null) {
        count++
        let msg = {
          name: 'Falta preencher seu CPF/CNPJ.',
        }
        atention.push(msg)
        setCountNt(count)
      }
      if (response.data.user.phone === null) {
        count++
        let msg = {
          name: 'Falta preencher o número do seu telefone.',
        }
        atention.push(msg)
        setCountNt(count)
      }
      if (response.data.user.bank === null) {
        count++
        let msg = {
          name: 'Falta preencher o número do seu banco.',
        }
        atention.push(msg)
        setCountNt(count)
      }
      if (response.data.user.agency === null) {
        count++
        let msg = {
          name: 'Falta preencher o número de sua agência.',
        }
        atention.push(msg)
        setCountNt(count)
      }
      if (response.data.user.account === null) {
        count++
        let msg = {
          name: 'Falta preencher o número de sua conta.',
        }
        atention.push(msg)
        setCountNt(count)
      }
    } else {
      if (response.data.user.cep === null) {
        count++
        let msg = {
          name: 'Falta preencher seu endereço.',
        }
        atention.push(msg)
        setCountNt(count)
      }
      if (response.data.user.cpf === null) {
        count++
        let msg = {
          name: 'Falta preencher seu CPF.',
        }
        atention.push(msg)
        setCountNt(count)
      }
      if (response.data.user.rg === null) {
        count++
        let msg = {
          name: 'Falta preencher seu RG.',
        }
        atention.push(msg)
        setCountNt(count)
      }
      if (
        response.data.user.nickname === null ||
        response.data.user.nickname === ''
      ) {
        count++
        let msg = {
          name: 'Falta preencher seu apelido.',
        }
        atention.push(msg)
        setCountNt(count)
      }
      if (response.data.user.phone === null) {
        count++
        let msg = {
          name: 'Falta preencher seu telefone.',
        }
        atention.push(msg)
        setCountNt(count)
      }
    }

    if (response.data.user.avatar !== null) {
      setPhoto(response.data.user.avatar)
    }
    setNot(atention)
  }

  useEffect(() => {
    getUser()
  }, [])
  return (
    <>
      {HeaderSearch === SearchHeader ? (
        <Container>
          <ContainerInputs>
            {/* <SearchContainer>
                <Search placeholder={'O que você gostaria de fazer?'} />
              </SearchContainer> */}

            {/* <ContainerSelect>
              visualizado por:
              <Select>
                <p>
                {type}
                </p>
              </Select>
            </ContainerSelect> */}
            <div style={{ display: 'flex' }}>
              {countNt > 0 ? (
                <Link to="/personal" style={{ textDecoration: 'none' }}>
                  <Notifications count={countNt} not={not} />
                </Link>
              ) : null}
              <NavLink to="/calendar">
                <DotImage
                  src=""
                  icon={<AiOutlineCalendar color="#4285f4" size={20} />}
                ></DotImage>
              </NavLink>
              <Popover
                placement="topRight"
                content={<MiniProfile photo={photo} />}
                trigger="click"
                visible={visible}
                onVisibleChange={handleVisibleChange}
              >
                <DotImage background={true} src={photo}></DotImage>
              </Popover>
            </div>
          </ContainerInputs>
        </Container>
      ) : (
        <Container>
          <ContainerTitles>
            <Title>Produtos</Title>
            <FaChevronRight
              color="#898989"
              size={15}
              style={{ marginLeft: 10 }}
            />
            <SubTitle>Como Economizar para o Futuro</SubTitle>
          </ContainerTitles>
          <ContainerProgress>
            <Classes>
              2/8 Concluído
              <strong>25%</strong>
            </Classes>
            <ProgressBar>
              <Evolution />
              <Champion>
                <img src={ChampionIcon} alt="Champion" />
              </Champion>
            </ProgressBar>
          </ContainerProgress>
        </Container>
      )}
    </>
  )
}

export default HeaderPromotor
