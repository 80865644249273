import styled from 'styled-components'

export const SearchContainer = styled.form`
  width: 99%;
  margin: 8px auto;
  background: #FFFFFF;
  border: 0.0625rem solid #C0C0C0;
  box-sizing: border-box;
  border-radius: 2.8125rem;
  padding: 0.6306rem 1.6018rem;
`

export const Input = styled.input`
  margin-left: 1.4425rem;
  width: 20rem;
  border: none;
  outline: none;

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.015625rem;
    color: #C0C0C0;
  }
`