import React from "react";
import {
  DataTable,
  DataTableHead,
  DataTableRow,
  DataTableCell,
  DataTableBody,
} from 'components/DataTable'
import {
  ContainerTable
} from './style'
import Repayments from './Repayment'
import { CircularProgress } from '@material-ui/core/'

interface Repayment {
  repayment: any
}

const SkeletonTable: React.FC<Repayment> = ({ repayment }) => {

  return (
    <ContainerTable>
      <DataTable>
        <DataTableHead>
          <DataTableRow>
            <DataTableCell>Reembolso</DataTableCell>
          </DataTableRow>
        </DataTableHead>

        <DataTableBody>
          {
            !repayment ? (
              <CircularProgress color="inherit" />
            ) : (
                repayment && repayment.map((product: any) => (
                  <Repayments repayment={product} key={product.id} />
                ))
              )
          }
        </DataTableBody>
      </DataTable>
    </ContainerTable>
  );
};

export default SkeletonTable;
