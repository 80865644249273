/*
Aqui fica a página com todos os passos da criação de produto
*/

import React, { useState } from 'react'
import {
  MainContainer,
  StepsContainer,
  StepsContainerTitle,
  NewProductSteps,
  ContentContainer,
} from './style'
import BasicInfo from './BasicInfo'
import PriceInfo from './PriceInfo'
import CheckoutConfig from './CheckoutConfig'
import { StepContext } from '../../StepContext'
import { AiOutlineDollarCircle, AiOutlinePicture, AiOutlineShoppingCart } from 'react-icons/ai'
const { Step } = NewProductSteps

const steps = [
  {
    title: 'Informações Básicas',
    content: <BasicInfo />,
    icon: <AiOutlinePicture/>
  },
  {
    title: 'Informações de Preço',
    content: <PriceInfo />,
    icon: <AiOutlineDollarCircle/>
  },
  {
    title: 'Configuração de Checkout',
    content: <CheckoutConfig />,
    icon: <AiOutlineShoppingCart/>
  }
]

const Steps: React.FC = () => {
  const [current, setCurrent] = useState<number>(0)

  const handleNext = () => {
    setCurrent(current + 1)
  }

  const handlePreview = () => {
    setCurrent(current - 1)
  }



  return (
    <StepContext.Provider value={{ handlePreview, handleNext }}>
      <MainContainer>
        <StepsContainer>
          <StepsContainerTitle>Etapas do Cadastro</StepsContainerTitle>

          <NewProductSteps current={current} direction="vertical">
            {steps.map((item: any, index: number) => (
              <Step key={item.title} title={item.title} />
            ))}
          </NewProductSteps> 
        
        </StepsContainer>

        <ContentContainer>
          {steps[current].content}          
        </ContentContainer>
      </MainContainer>
    </StepContext.Provider>
  )
}

export default Steps
