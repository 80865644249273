import React, { useState, useEffect, useContext } from 'react'
import { ModalButton } from '../../shared-styles'
import { CoProducerBtn, InputContainer } from './style'
import Input from 'components/new/Input'
import Text from 'components/new/Input/Text'
import Select from 'components/new/Input/Select'
import Check from 'components/new/Input/Checkbox'
import { Skeleton, Modal } from 'antd'
import * as Yup from 'yup'
import displayYupErrorNotification from 'utils/displayYupErrorNotification'
import {
  displayErrorNotification,
  displaySucessNotification,
} from 'utils/displayNotification'
import api from 'services/api'
import { EditProductContext } from '../../../EditProductContext'

interface CoProducer {
  co_producer_email: string
  comission: string
  contract: any
  comission_percentage: string
}

const createNewCoProducer = (): CoProducer => ({
  co_producer_email: '',
  contract: {},
  comission_percentage: '',
  comission: 'SELL_PRODUCTOR',
})

const CoProducerRegister: React.FC = ({ ...rest }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [contracts, setContracts] = useState<any>(null)
  const [editProductData, setEditProductData] = useContext(EditProductContext)
  const [coProducer, setCoProducer] = useState<CoProducer>(createNewCoProducer)
  const [contractLabelValue, setContractLabelValue] = useState<any>(null)

  const showModal = (e: any) => {
    e.preventDefault()

    if (hasCoProducer()) {
      return
    } else {
      setVisible(true)
    }
  }

  const hideModal = () => {
    setVisible(false)
    reset()
  }

  const getContracts = async () => {
    let { data } = await api.get('/contracts')
    setContracts(data)
  }

  const handleEmail = (evt: any) => {
    evt.preventDefault()

    setCoProducer({
      ...coProducer,
      co_producer_email: evt.target.value,
    })
  }

  const handleComissionPercentage = (evt: any) => {
    let prop = evt.target.id.toString()
    let stringValue = evt.target.value.toString().replace(',', '.')
    let value = parseFloat(stringValue)

    if (value > 100) {
      value = 100
    }

    setCoProducer({
      ...coProducer,
      [prop]: value,
    })
  }

  const handleContract = ({ label, value }: any) => {
    setCoProducer({
      ...coProducer,
      contract: { id: value, name: label },
    })

    setContractLabelValue({ label, value })
  }

  const hasCoProducer = () => {
    if (editProductData.co_producers.length === 1) {
      displayErrorNotification('Você já tem um Co-produtor cadastrado.')
      return true
    }

    return false
  }

  const reset = () => {
    setCoProducer(createNewCoProducer())
    setContractLabelValue(null)
  }

  /*const handleComission = (evt: any) => {
    setCoProducer({
      ...coProducer,
      comission: { id: evt.target.value }
    })
  }*/

  const setNewCoProducer = async () => {
    let { data } = await api.get(
      `/is-email-valid/${coProducer.co_producer_email}`,
    )

    try {
      const schema = Yup.object().shape({
        contract: Yup.object().shape({
          id: Yup.number().required('Selecione o tempo de contrato.'),
          name: Yup.string().required('Selecione o tempo de contrato.'),
        }),
        comission_percentage: Yup.number().required(
          'Digite a porcentagem do co-produtor',
        ),
      })

      await schema.validate(coProducer)
    } catch (err) {
      displayYupErrorNotification(err)
      return
    }

    if (data) {
      setEditProductData({
        ...editProductData,
        co_producers: [coProducer, ...editProductData.co_producers],
      })

      displaySucessNotification('Co-produtor cadastrado com sucesso!')

      hideModal()
    } else {
      displayErrorNotification('O email do co-produtor não está cadastrado.')
    }
  }

  useEffect(() => {
    getContracts()
  }, [])

  let comissions = [{ id: 'SELL_PRODUCTOR', name: 'Vendas do produtor' }]

  return (
    <>
      <CoProducerBtn onClick={showModal}>Adicionar Co-produtor</CoProducerBtn>

      <Modal
        title="Informações do Co-produtor"
        visible={visible}
        closable={true}
        onCancel={hideModal}
        footer={[
          <ModalButton type="button" onClick={hideModal}>
            Cancelar
          </ModalButton>,
          <ModalButton
            onClick={setNewCoProducer}
            className="submit"
            type="submit"
          >
            Adicionar Co-produtor
          </ModalButton>,
        ]}
        {...rest}
      >
        <Input title="Email do Co-produtor">
          <Text
            placeholder={'joao_produtor@email.com'}
            name="co_producer_email"
            type="email"
            onBlur={handleEmail}
            onChange={handleEmail}
            value={coProducer.co_producer_email}
          />
        </Input>

        <Input title="Tempo de contrato">
          <Select
            name="contract"
            placeholder={'Selecione o tempo de contrato para o co-produtor'}
            options={contracts}
            onChange={handleContract}
            value={contractLabelValue}
          />
        </Input>

        <InputContainer>
          <h1>De quais vendas você deseja receber comissão?</h1>
          <section className="content">
            {!comissions ? (
              <Skeleton.Button active />
            ) : (
              <Check name="comission" options={comissions} noBorder checked />
            )}
          </section>
        </InputContainer>

        <Input
          title="Porcentagem da comissão (%)"
          style={{ marginTop: '2rem' }}
        >
          <Text
            name="comission_percentage"
            placeholder={'10'}
            type="number"
            onChange={handleComissionPercentage}
            onBlur={handleComissionPercentage}
            value={coProducer.comission_percentage}
          />
        </Input>
      </Modal>
    </>
  )
}

export default CoProducerRegister
