import React, { useState } from 'react'
import {
  MainContainer,
  CheckoutContainer,
  Content
} from './style'

import PersonalData from './PersonalData'

const steps = [
  {
    title: 'Dados pessoais',
    content: <PersonalData />,
  }

]

const StudentCheckout = () => {
  const [current] = useState<number>(0)  



  return (
    <MainContainer>
      <CheckoutContainer>
        <Content>
          {steps[current].content}    
        </Content>
      </CheckoutContainer>
    </MainContainer>
  )
}

export default StudentCheckout
