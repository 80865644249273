import React from 'react'
import { ContainerSelect, SelectInput } from './style'

// import { Container } from './styles';
interface Props {
  title: String
}

const Select: React.FC<Props> = ({ title }) => {
  return (
    <ContainerSelect className="selector">
      {title}
      <SelectInput></SelectInput>
    </ContainerSelect>
  )
}

export default Select
