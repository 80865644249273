import React from "react";
import {
  DataTable,
  DataTableHead,
  DataTableRow,
  DataTableCell,
  DataTableBody,
} from 'components/DataTable'
import {
  ContainerTable
} from './style'
import Categories from './Category'
import { CircularProgress } from '@material-ui/core/'

interface Props {
  categories: any
}

const ResumFinan: React.FC<Props> = ({ categories }) => {

  return (
    <ContainerTable>
      <DataTable>
        <DataTableHead>
          <DataTableRow>
            <DataTableCell>Nome da categoria</DataTableCell>
          </DataTableRow>
        </DataTableHead>

        <DataTableBody>

          {
            !categories ? (
              <CircularProgress color="inherit" />
            ) : (
                categories && categories.map((product: any) => (
                  <Categories categories={product} key={product.id} />
                ))
              )
          }
        </DataTableBody>
      </DataTable>
    </ContainerTable>
  );
};

export default ResumFinan;
