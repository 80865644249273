/*
FUTURA tabela de afiliados
*/

import React, { useEffect } from 'react'
import Search from '../../../components/Search'
import Button from '../../../components/Button'
import { CardTitle } from '../../../components/CardContainer'
import Affiliate from './Affiliate'
import {
  MyAffiliatesCard,
  TotalAffiliates,
} from './style'
import { Header, SearchContainer, FlexContainer } from './style'
import {
  DataTable,
  DataTableHead,
  DataTableRow,
  DataTableCell,
  DataTableBody
} from '../../../components/DataTable'

const MyAffiliates = () => {

  useEffect(() => {})

  return (
    <MyAffiliatesCard>
      <CardTitle>Meus afiliados</CardTitle>

      <Header>
        <SearchContainer>
          <Search placeholder="Pesquisar entre meus afiliados" />
        </SearchContainer>

        <FlexContainer>
          <TotalAffiliates>55 afiliados</TotalAffiliates>
          
          <Button color="#5C5C5C" arrowIcon width="8rem">
            Mais Vendas
          </Button>
        </FlexContainer>
      </Header>

      <DataTable>
        <DataTableHead>
          <DataTableRow>
            <DataTableCell></DataTableCell>
            <DataTableCell>Nome</DataTableCell>
            <DataTableCell>Produto(s) Afiliado(s)</DataTableCell>
            <DataTableCell>Vendas</DataTableCell>
            <DataTableCell>Tipo</DataTableCell>
            <DataTableCell>Afiliações</DataTableCell>
          </DataTableRow>
        </DataTableHead>

        <DataTableBody>
          <Affiliate />
          <Affiliate />
          <Affiliate />
          <Affiliate />
          <Affiliate />
          <Affiliate />
        </DataTableBody>
      </DataTable>
    </MyAffiliatesCard>
  )
}

export default MyAffiliates
