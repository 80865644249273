import React from 'react'
import {
  ProductCard,
  ProductImg,
  Content,
  ContentTitle,
  ContentAuthor,
  ContentCenter,
  Icon,
  Value,
  Label,
  DashedLabel,
  FlexContainer,
  Promotion,
  Discount
} from './style'
import VideoCamIcon from '../../../../assets/icons/videocam-icon.svg'
import StarIcon from '../../../../assets/icons/star-icon.svg'

const Product = (/*{ product }*/) => {
  return (
    <ProductCard>
      <ProductImg>
        <img src="" alt="" />
      </ProductImg>

      <Content>
        <ContentTitle>
          <h1>Como economizar para o futuro</h1>
        </ContentTitle>

        <ContentAuthor>Augusto Nascimento</ContentAuthor>

        <ContentCenter>
          <section>
            <FlexContainer>
              <Icon src={VideoCamIcon} style={{ width: '1.265625rem' }} />
              <Value>20h</Value>
            </FlexContainer>

            <Label>de vídeo</Label>
          </section>

          <section>
            <FlexContainer>
              <Icon src={StarIcon} />
              <Value>4,5</Value>
            </FlexContainer>

            <Label>Avaliação</Label>
          </section>
        </ContentCenter>

        <Promotion>
          <DashedLabel>R$ 3.500,00</DashedLabel>
          <p>R$ 1.750,00</p>
        </Promotion>

        <Discount>Desconto de 50%</Discount>
      </Content>
    </ProductCard>
  )
}

export default Product
