import React, { useState } from "react";
import {
  DataTable,
  DataTableHead,
  DataTableRow,
  DataTableCell,
  DataTableBody,
} from 'components/DataTable'
import {
  NoStudentMsg,
  ContainerTable
} from './style'
import Sales from './Sales'
import { CircularProgress } from '@material-ui/core/'
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

interface Props {
  products: any
  tableOrderFilter: any
}
interface Table {
  name: string
  colunm: string
  order: boolean
}



  const ResumFinan: React.FC<Props> = ({products, tableOrderFilter }) => {
    const sells = products
    const [table, setTable] = useState<Table[]>([
      { name: 'Checkout ID', colunm: '', order: false },
      { name: 'Produto', colunm: 'product_name', order: false },
      { name: 'Valor sem Juros(R$)', colunm: 'amount', order: false },
      { name: 'Comprador', colunm: 'lead_name', order: false },
      { name: 'Email comprador', colunm: 'lead_email', order: false },
      { name: 'Tel. Comprador', colunm: 'lead_cell_phone', order: false },
      { name: 'Forma de pag.', colunm: 'payment_method', order: false },
      { name: 'Situação pag.', colunm: 'payment_status', order: false },
      { name: 'Pedido gerado?', colunm: '', order: false },
      { name: 'Etiqueta gerada?', colunm: '', order: false },
      { name: 'Finalizado?', colunm: '', order: false },
      { name: 'Fornecedor', colunm: '', order: false },
      { name: 'Data', colunm: '', order: false },
    ])
  
    const setFalse = (name: string) => {
      table.forEach((item, index) => {
        if (item.name !== name)
          table[index] = { ...item, order: false }
      })
  
      setTable(table)
    }
    return (
      <ContainerTable>
        <DataTable>
        <DataTableHead>
          <DataTableRow>
          {
              table.map((item, index) => (
                <DataTableCell onClick={() => {
                  if (item.colunm.length)
                    tableOrderFilter(item.colunm, !item.order ? 'ASC' : 'DESC')

                  const newItem = { ...item, order: !item.order }
                  table[index] = newItem

                  setFalse(item.name)

                  setTable(table)
                }}>
                  <p style={{ color: 'black' }}>{item.colunm.length ? item.order ? (<AiOutlineArrowUp />) : (<AiOutlineArrowDown />) : ''}</p>
                  {item.name}
                </DataTableCell>
              ))
            }
          </DataTableRow>
        </DataTableHead>

        <DataTableBody>
          
        {
            !sells ? (
              <CircularProgress color="inherit" />
            ) : (
              sells && sells.map((product: any) => (
                <Sales student={product} key={product.id}/>
              ))
            )
          }
        </DataTableBody>
      </DataTable>
              {
            !sells ? (
              <CircularProgress color="inherit" />
            ) : sells && sells.length === 0 ? (
              <NoStudentMsg>Você não realizou nenhuma venda</NoStudentMsg>
            ) :
            null
          }
      </ContainerTable>
    );
  };
  
  export default ResumFinan;
  