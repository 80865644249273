import styled from 'styled-components'
import { Button } from '../../shared-styles'
import { Form } from '@unform/web'

export const Container = styled.div`
  display: flex;
`

export const NoVideoMsg = styled.h1`
  font-size: 1.25rem;
  color: #777;
  margin: auto;
`

export const VideoFrame = styled.iframe`
  border: none;
  outline: none;
`

export const AddVideoBtn = styled(Button)`
  color: #777;
  border: 1px solid #777;
  border-radius: 5px;
  padding: 10px 25px;

  &:hover {
    color: #292929;
    border-color: #292929;
  }
`

export const StyledForm = styled(Form)`
  width: 80%;
  height: 18.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SideContainer = styled.aside`
  display: grid;
  width: 26%;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr;
  padding: 0.3125rem 1.25rem;
`

export const Attachment = styled.div`
  color: #292929;
  width: 100%;

  h1 {
    color: #777;
    font-size: 0.9rem;
  }

  a {
    font-size: 1rem;
    color: #292929;

    &:hover {
      color: var(--main);
    }
  }

  p {
    font-size: 1rem;
  }
`

export const Description = styled.div`
  font-size: 1rem;
  width: 100%;

  h1 {
    color: #777;
    font-size: 0.9rem;
  }

  p {
    font-size: 1rem;
  }
`
