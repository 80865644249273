import styled from 'styled-components'

interface Props {
  width?: string
  height?: string
  title?: string
}

export const Container = styled.fieldset<Props>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 2.8125rem;
  padding: 0;
  margin-bottom: 1.6rem;
  border: 1px solid #c0c0c0;
  position: relative;

  & > input:focus ~ legend,
  & > .ant-selector-select:focus ~ legend,
  & > textarea:focus ~ legend {
    color: var(--main);
  }

  & > input {
    border-radius: inherit;
    width: 90%;
    padding-bottom: 0.7rem;
    padding-left: 1.0625rem;
    font-size: 1rem;
    outline: none;
    border: none;
    background: none;
    margin-top: -0.625rem;

    &::placeholder {
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0.015625rem;
      color: #a2a2a2;
    }
  }
`

export const Title = styled.legend`
  margin-left: 1rem;
  background-color: #fff;
  padding: 0 0.1875rem;
  font-size: 0.82rem;
  line-height: 1rem;
  letter-spacing: 0.4px;
  color: #5c5c5c;
  transition: color 0.2s;
  width: max-content;
`
