import React from 'react'
import { Container } from './style'

interface Props {
  defaultValue?: string
  children?: any
}

const Select: React.FC<Props> = ({
  defaultValue,
  children
}) => (
  <Container defaultValue={defaultValue}>
    {children}
  </Container>
)

export default Select
