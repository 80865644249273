import styled from 'styled-components'

interface ContainerProps {
  hasPdf?: boolean
}

export const Container = styled.label<ContainerProps>`
  border: 1px solid #777;
  border-radius: 50px;
  color: #777;
  cursor: pointer;
  padding: 10px 25px;

  ${({ hasPdf }) =>
    hasPdf
      ? `color: #292929;
          border-color: #292929;
          font-weight: bold;
          letter-spacing: 0.5px;`
      : ''};

  &:hover {
    color: #292929;
    border-color: #292929;
  }

  > input[type='file'] {
    display: none;
  }
`
