import styled from 'styled-components'

export const InputContainer = styled.input<any>`
  border-radius: inherit;
  width: 90%;
  padding-bottom: 0.9rem;
  padding-left: 1.0625rem;
  font-size: 1rem;
  outline: none;
  border: none;
  background: none;
  margin-top: 0.325rem;
  
  &::placeholder {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.015625rem;
    color: #a2a2a2;
  }
`