import React, { useEffect, useRef, useState } from 'react'
import { CircularProgress } from '@material-ui/core/'
import { SubmitHandler } from '@unform/core'
import Facebook from 'assets/icons/facebook.svg'
import Instagram from 'assets/icons/instagram.svg'
import Linkedin from 'assets/icons/linkedin.svg'
import Youtube from 'assets/icons/youtube.svg'
import Perfil from 'assets/perfil.png'
import Form from 'components/new/Form'
import Text from 'components/new/Input/Text'
import ImgModal from 'components/ProfileStudent/ModalPerfil'
import StudyProgress from 'components/ProfileStudent/Progress'
import SocialMedia from 'components/ProfileStudent/SocialMidia'
import Conquest from 'containers/Conquest'
import Personal from 'containers/Personal'
import { displayErrorNotification, displaySucessNotification } from 'utils/displayNotification'
import api from 'services/api'
import {
  Basic,
  Button, CardContainer,
  ContainerForm,
  ContainerFormDescription, Description, EditInput,
  FicharActive,
  FicharInative, Flex, FooterButton, InputTextAre, Picture,
  StyledLink,
  Tabs, Title
} from './style'
import { Link } from 'react-router-dom'
const textAreaHeight = '7.0625rem'
const notificationStyle = {
  right: '1.875rem',
  width: '16.25rem',
  borderRadius: '0.625rem',
}

interface propsUser {
  picture: string
  name: string
  nameExibition: string
  email: string
  BreveDescription: string
  Help: string
  BeHelp: string
  linkedin: string
  facebook: string
  instagram: string
  youtube: string
  nickname: string
  progress: number
  active: number
}

const ContainerBasicInfo = () => {
  const [conquest,] = useState<Boolean>(false)
  const [personal, setPersonal] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [nickname, setNickname] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [helpMe, setHelpMe] = useState<string>('')
  const [helpYou, setHelpYou] = useState<string>('')
  const [facebook, setFacebook] = useState<string>('')
  const [instagram, setInstagram] = useState<string>('')
  const [youtube, setYoutube] = useState<string>('')
  const [linkedin, setLinkedin] = useState<string>('')
  const [password, setPassword] = useState<any>('')
  const [, setEmail] = useState<string>('')
  const [photo, setPhoto] = useState<any>(Perfil)
  const [confirm, setConfirm] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [activeStudents, setActiveStudent] = useState<Number>(0)
  const [hours, setHours] = useState<Number>(0)

  const handleValues = async () => {
    try {
      let overview = await api.get('overview')
      if (overview.data.total_students === undefined || overview.data.total_students === null) {
        setActiveStudent(0)
      } else {
        setActiveStudent(overview.data.total_students)
      }

      let hours = await api.get('/info-products')
      if (hours.data.class_time === undefined || hours.data.class_time === null) {
        setHours(0)
      } else {
        setHours(hours.data.class_time)
      }


      let response = await api.get('profile')
      if (response.data.user.avatar !== null) setPhoto(response.data.user.avatar)
      setName(response.data.user.name)
      setLastName(response.data.user.last_name)
      setNickname(response.data.user.nickname)
      setDescription(response.data.user.description)
      setHelpMe(response.data.user.help_me)
      setHelpYou(response.data.user.help_you)
      setLinkedin(response.data.user.linkedin)
      setYoutube(response.data.user.youtube)
      setInstagram(response.data.user.instagram)
      setFacebook(response.data.user.facebook)
      setEmail(response.data.user.email)
    } catch (e) {

    }
  }


  useEffect(() => {
    handleValues()
  }, [])

  const formRef = useRef<SubmitHandler>(null)

  const handleSubmit: SubmitHandler<FormData> = async data => {
    setLoading(false)

    let senha

    if (confirm) {
      if (password === undefined || password.length < 8) {
        displayErrorNotification(`A senha precisa ter ao menos 8 caracteres.`)
        setLoading(false)
      } else {
        senha = {
          confirm: confirm,
          password: password,
        }

        let value = Object.assign(data, senha)
        try {
          await api.put('/edit-profile-common', value)
          displaySucessNotification(`Atualizado com sucesso!`)
          setLoading(true)

          sessionStorage.clear()
        } catch (err) {
          displayErrorNotification(`Erro ao atualizar o perfil. ${err.response.data.message}`)
        }
      }
    }

    if (!confirm) {
      let value = Object.assign(data, senha)
      try {
        await api.put('/edit-profile-common', value)
        displaySucessNotification(`Atualizado com sucesso!`)
        setLoading(true)

        sessionStorage.clear()
      } catch (err) {
        displayErrorNotification(err.response.data.message)
      }
    }
  }
  const handlePicture = () => {
    setVisible(true)
  }

  const handleOk = () => {
    let ConvertPhoto: any = sessionStorage.getItem('photo')
    setPhoto(ConvertPhoto)
    setVisible(false)
  }

  const handleCancel = (e: any) => {
    setVisible(false)
  }

  const handlePassword = () => {
    if (confirm) {
      setConfirm(false)
    } else {
      setConfirm(true)
    }
  }

  return (
    <>
      {personal === true ? (
        <Personal />
      ) : conquest === true ? (
        <Conquest />
      ) : (
            <main>
              {/*
                    <SubTitle>
                        Perfil
                        <FaChevronRight color="#898989" size={12} style={{ marginLeft: 5, marginRight: 5 }} />
                        <p onClick={() => handleConquest()}>Conquista</p> 
                        <FaChevronRight color="#898989" size={12} style={{ marginLeft: 5, marginRight: 5 }} />
                        <p onClick={() => handlePersonal()}>Dados Pessoais</p>
                    </SubTitle>
                    */}
              <CardContainer>

                <Form ref={formRef} onSubmit={handleSubmit} >
                  <Tabs>
                    <FicharActive>Perfil</FicharActive>
                    <StyledLink to='/personal'>
                      <FicharInative type="submit">
                        <p>Dados pessoais</p>
                      </FicharInative>
                    </StyledLink>

                  </Tabs>
                  {/*
                        <FicharInative2>
                            Dados bancários
                        </FicharInative2>*/}
                  <Title>Informações Básicas</Title>
                  <Basic>
                    <Picture onClick={() => handlePicture()}>
                      <img src={photo} alt="" />
                    </Picture>
                    <ContainerForm>
                      <Flex>
                        <EditInput title="Nome">
                          <Text
                            name="name"
                            placeholder="Digite aqui seu nome..."
                            value={name}
                            onChange={e => setName(e.target.value)}
                            maxLength={100}
                            required
                          />
                        </EditInput>
                        <EditInput title="Sobrenome">
                          <Text
                            name="last_name"
                            placeholder="Digite aqui seu sobrenome..."
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            maxLength={100}
                            required
                          />
                        </EditInput>
                      </Flex>
                      <EditInput title="Nome para exibição">
                        <Text
                          name="nickname"
                          placeholder="Escreva seu apelido"
                          value={nickname}
                          onChange={e => setNickname(e.target.value)}
                          maxLength={60}
                        />
                      </EditInput>

                      {/*                       
                      <div>
                        <FormPass>
                          <div onChange={() => handlePassword()}>
                            <input type="checkbox" id="confirmPass" value="true" />
                            <label htmlFor="confirmPass">
                              Deseja trocar de senha ?
                        </label>
                          </div>
                        </FormPass>
                        <EditInput title="Senha">
                          {confirm === true ? (
                            <Text
                              name="password"
                              placeholder="Digite sua senha"
                              value={password}
                              type="password"
                              onChange={e => setPassword(e.target.value)}
                            />
                          ) : (
                              <Text
                                name="password"
                                placeholder="Digite sua senha"
                                value={''}
                                type="password"
                                disabled
                              />
                            )}
                        </EditInput>
                      </div> */}
                    </ContainerForm>
                    <StudyProgress active={activeStudents} hours={hours} />
                  </Basic>
                  <Description>
                    <ContainerFormDescription>
                      <Title>Sobre</Title>
                      <InputTextAre
                        name="description"
                        title="Breve descrição"
                        placeholder="Escreva um pouco sobre você..."
                        height={textAreaHeight}
                        valueInput={description}
                        onChangeInput={(e: any) => setDescription(e.target.value)}
                      />
                      <InputTextAre
                        name="help_you"
                        title="No que posso Ajudar?"
                        placeholder="No que você pode ajudar os outros?"
                        height={textAreaHeight}
                        valueInput={helpYou}
                        onChangeInput={(e: any) => setHelpYou(e.target.value)}
                      />
                      <InputTextAre
                        name="help_me"
                        title="No que gostaria de ser ajudado"
                        placeholder="No que os outros poderiam te ajudar?"
                        height={textAreaHeight}
                        valueInput={helpMe}
                        onChangeInput={(e: any) => setHelpMe(e.target.value)}
                      />

                      <Title>Redes Sociais</Title>

                      <SocialMedia
                        imagem={Linkedin}
                        name={'linkedin'}
                        placeholder="Digite seu Linkedin"
                        value={linkedin ? linkedin : 'linkedin.com/in/'}
                        onChange={e => setLinkedin(e.target.value)}
                        maxLength={80}
                      />
                      <SocialMedia
                        imagem={Facebook}
                        name={'facebook'}
                        placeholder="Digite seu facebook"
                        value={facebook ? facebook : 'facebook.com/'}
                        onChange={e => setFacebook(e.target.value)}
                        maxLength={80}
                      />
                      <SocialMedia
                        imagem={Instagram}
                        name={'instagram'}
                        placeholder="Digite seu instagram"
                        value={instagram ? instagram : 'instagram.com/'}
                        onChange={e => setInstagram(e.target.value)}
                        maxLength={80}
                      />
                      <SocialMedia
                        imagem={Youtube}
                        name={'youtube'}
                        placeholder="Digite seu canal"
                        value={youtube ? youtube : 'youtube.com/'}
                        onChange={e => setYoutube(e.target.value)}
                        maxLength={80}
                      />
                    </ContainerFormDescription>
                    {/*<Conquistas />*/}
                    <FooterButton>
                      {!loading ? (
                        <CircularProgress color="inherit" />
                      ) : (
                          <Button>Salvar Alterações</Button>
                        )}
                    </FooterButton>
                  </Description>
                </Form>
              </CardContainer>

              <ImgModal
                visible={visible}
                handleOk={handleOk}
                handleCancel={handleCancel}
              />
            </main>
          )}
    </>
  )
}

export default ContainerBasicInfo
