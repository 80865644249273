import React from 'react'
import { ContainerInputSearch } from './styles'
import { AiOutlineSearch } from 'react-icons/ai'

interface Props {
  Label?: String
}

const InputSearch: React.FC<Props> = ({Label}) => {
  return (
    <>
      <ContainerInputSearch>
        <AiOutlineSearch
          color="#A2A2A2"
          size={20}
          style={{ marginRight: 10, marginLeft: 20 }}
        />

        <p style={{ color: '#C0C0C0', margin: 0 }}>
          {Label}

        </p>
      </ContainerInputSearch>
    </>
  )
}

export default InputSearch
