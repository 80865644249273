import React, { useState, useEffect } from 'react'
import { Container, SiderEdit, ContainerImage } from './styles'
import logo from './logo.png'
import Logo from '../Logo'
import api from 'services/api'
import { Layout } from 'antd'
import { Link } from 'react-router-dom'
import SuperAdmDesktopView from './componentsDesktop/superadm/index'
import ProducerDesktopView from './componentsDesktop/producer/index'
import StudentDesktopView from './componentsDesktop/student/index'
import SuperAdmMobileView from './componentsMobile/superadm/index'
import ProducerMobileView from './componentsMobile/producer/index'
import StudentMobileView from './componentsMobile/student/index'

const { Header, Sider, Content } = Layout

const Menu2: React.FC = ({ children }: any) => {
  const [collapsed, setCollapsed] = useState(true)
  const [typeUser, setTypeUser] = useState<any>(true)
  const toggle = () => collapsed ? setCollapsed(false) : null

  const mouseOver = () => {
    setCollapsed(false)
  }

  const mouseOut = () => {
    setCollapsed(true)
  }

  const getUser = async () => {
    let response = await api.get("profile");
    setTypeUser(response.data.user.type_user)
  }

  const sideMenuTypeUserDesktop = () => {
    switch (typeUser) {
      case 'student':
        return ( <StudentDesktopView /> )
      case 'producer':
        return( <ProducerDesktopView /> )
      case 'superadm':
        return( <SuperAdmDesktopView /> )
      default:
        return ( <StudentDesktopView /> )
    }
  }

  const sideMenuTypeUserMobile = () => {
    switch (typeUser) {
      case 'student':
        return ( <StudentMobileView /> )
      case 'producer':
        return( <ProducerMobileView /> )
      case 'superadm':
        return( <SuperAdmMobileView /> )
      default:
        return ( <StudentMobileView /> )
    }
  }

  useEffect(() => {
    getUser()
  }, [])

  return (
    <SiderEdit>
      <Sider
        className="calls"
        style={{ backgroundColor: 'black', height: 'auto' }}
        trigger={null}
        collapsed={collapsed}
        onClick={toggle}
        onMouseEnter={() => mouseOver()}
        onMouseLeave={() => mouseOut()}
      >
        <Link to="/">
          <ContainerImage>
            {collapsed ? (
              <img src={logo} alt="" onClick={toggle} style={{ width: '60px', height: '60px', marginTop: 20 }} />
            ) : (
                <Logo />
              )}
          </ContainerImage>
        </Link>
        <Container>
          {sideMenuTypeUserDesktop()}
        </Container>
      </Sider>
      <Layout className="site-layout">
        <Header className="header">
          <Link to="/">
            <div className='logo'>
              <img src={logo} alt="" width="64px" height="auto" />
            </div>
          </Link>
            {sideMenuTypeUserMobile()}
        </Header>
        <Content
          className="site-layout-background"
          style={{
            minHeight: 280,
          }}
        >
          {children}
        </Content>
      </Layout>
    </SiderEdit>
  )
}

export default Menu2
