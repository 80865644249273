import styled from 'styled-components'

export const CardTopAffiliate = styled.div`
  width: 200px;
  height: 65px;
  display:flex;
  

  img:first-child{
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: 3px solid #ebbd19;
  }
`
export const DadosAfilliate = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin: 0 0 0 10px;
  padding-top: 10px;
  p{
    color: #8e8e8e;
    margin: 0;
    font-weight: 400;

  }

`