import React, { useState, useEffect, useRef, useContext } from 'react'
import { SubmitHandler, Scope } from '@unform/core'
import HeaderNewProduct from 'components/new/HeaderNewProduct'
import StyledSwitch from 'components/new/Switch'
import Radios from 'components/new/Input/Radio'
import Input from 'components/new/Input'
import Text from 'components/new/Input/Text'
import CurrencyInput from 'components/new/Input/CurrencyInput'
import Check from 'components/new/Input/Checkbox'
import api from 'services/api'
import { Skeleton } from 'antd'
import {
  ContentFooter,
  ActionBtn,
  MainContainer,
  ContentContainer,
} from '../shared-styles'
import {
  FormContainer,
  InputContainer,
  SwitchContainer,
  Flex,
  NoCoProducer,
} from './style'
import ArrowIcon from 'components/ArrowIcon'
import CoProducerList from './CoProducerList'
import { StepContext } from '../../../StepContext'
import { ViewProductContext } from '../../ViewProduct'
import * as Yup from 'yup'
import displayYupErrorNotification from 'utils/displayYupErrorNotification'

interface FormData {
  currency: string
  repayments: string
  payments: any[]
  installments: any[]
  value: number
  quantity: number,
  interestToClient: boolean
  co_producers: any[]
}

const PriceInfo: React.FC = () => {
  const { handlePreview, handleNext } = useContext(StepContext)
  const [viewProductData, setViewProductData] = useContext(ViewProductContext)

  const [currencies, setCurrencies] = useState<any>(null)
  const getCurrencies = async () => {
    let { data } = await api.get('/currencies')
    setCurrencies(data)
  }

  const [repayments, setRepayments] = useState<any>(null)
  const getRepayments = async () => {
    let { data } = await api.get('/repayments')
    setRepayments(data)
  }

  const [paymentMethods, setPaymentMethods] = useState<any>(null)
  const getPaymentMethods = async () => {
    let { data } = await api.get('/payments-methods')
    setPaymentMethods(data)
  }

  const [installments, setInstallments] = useState<any>(null)
  const getInstallments = async (value?: number) => {
    let { data } = await api.get(`/installments/${value}`)
    setInstallments(data)
  }

  const [interestToClient, setInterestToClient] = useState<boolean>(false)
  const handleInterest = () => {
    setInterestToClient(!interestToClient)
    setViewProductData({
      ...viewProductData,
      interest_to_client: !viewProductData.interest_to_client,
    })
  }

  const [hasInstallment, setHasInstallment] = useState<boolean>(false)
  const handleHasInstallment = () => {
    setHasInstallment(!hasInstallment)
    setViewProductData({
      ...viewProductData,
      hasInstallment: hasInstallment,
    })
  }

  const [interestAndInstallment, setInterestAndInstallment] = useState(false)
  const handleInterestAndInstallment = (evt: any) => {
    if (evt.target.id === 'Cartão de crédito') {
      setInterestAndInstallment(!interestAndInstallment)
      setHasInstallment(false)
      setViewProductData({
        ...viewProductData,
        interest_to_client: false,
      })
    }

    return
  }

  const [moneyValue, setMoneyValue] = useState(0)
  const handleProductValue = (value: number) => {
    let convertedValue = value / 100

    getInstallments(convertedValue)
    setMoneyValue(value)
  }

  const handleCurrencySymbol = (evt: any) => {
    let currency = evt.target.id

    switch (currency) {
      case 'DOLAR':
        setViewProductData({
          ...viewProductData,
          // value: convertProductValue(viewProductData.value, 'USD'),
          currencySymbol: 'USD',
        })
        return
      case 'EURO':
        setViewProductData({
          ...viewProductData,
          currencySymbol: 'EUR',
        })
        return
      case 'LIBRA':
        setViewProductData({
          ...viewProductData,
          currencySymbol: 'GBP',
        })
        return
      default:
        setViewProductData({
          ...viewProductData,
          currencySymbol: 'BRL',
        })
        return
    }
  }

  const handleCoProducerDelete = (id: number) => {
    let newCoProducers = viewProductData.co_producers.filter(
      (co_producer: any) => co_producer.id !== id,
    )

    setViewProductData({
      ...viewProductData,
      co_producers: newCoProducers,
    })
  }

  const [userOptions, setUserOptions] = useState<any>({
    currencies: '',
    repayments: '',
    payments: [],
  })

  const convertProductValue = async (value: number, currency: string) => {
    let from = viewProductData.currencySymbol
    let to = currency
    let key = '0e3ea4d06ce503197c5c'

    let { body } = await fetch(`
      https://free.currconv.com/api/v7/convert?q=${from}_${to}&compact=ultra&apiKey=${key}
    `)
  }

  const setLocalData = () => {
    setUserOptions({
      currencies: viewProductData.currency.id.toString(),
      repayments: viewProductData.repayment.id.toString(),
      installments: viewProductData.installments[0].installment.id.toString(),
      payments: viewProductData.payments.map((payment: any) =>
        payment.payment_method.toString(),
      ),
    })

    let credCard = viewProductData.payments.find(
      (pay: any) => pay.payment_method === 1,
    )

    if (!credCard) setInterestAndInstallment(true)

    if (viewProductData.installments.length > 0) handleHasInstallment()

    setMoneyValue((viewProductData.value).toLocaleString('pt-br', { minimumFractionDigits: 2 }))
    setInterestToClient(viewProductData.interest_to_client)
  }

  useEffect(() => {
    setLocalData()
    getCurrencies()
    getRepayments()
    getPaymentMethods()
    getInstallments(viewProductData.value || 0)
  }, [setLocalData, viewProductData.value])

  const formRef = useRef<SubmitHandler>(null)

  const handleSubmit: SubmitHandler<FormData> = async data => {
    try {
      const schema = Yup.object().shape({
        currency: Yup.string().required(),
        repayment: Yup.string(),
        payments: Yup.array()
          .of(Yup.string())
          .required('Escolha os tipos de pagamento.'),
        value: Yup.number()
          .min(1, 'Valor mínimo de 1,00.')
          .required('Adicione um valor ao seu produto')
          .typeError('Oops! Escolha um valor do tipo número.'),

        interestToClient: Yup.boolean(),
      })

      let dataWithValue = {
        ...data,
        value: viewProductData.value / 100,
      }

      await schema.validate(dataWithValue, {
        abortEarly: false,
      })

      let co_producer = viewProductData.co_producers[0]
      ? {
          co_producer_email:
            viewProductData.co_producers[0].co_producer_email,
          contract: { id: viewProductData.co_producers[0].contract.id },
          comission: viewProductData.co_producers[0].comission,
          comission_percentage:
            viewProductData.co_producers[0].comission_percentage,
        }
      : null

    let newData = {
      currency: { id: parseInt(data.currency) },
      repayment: { id: parseInt(data.repayments) },
      payments: data.payments.map((payment: any) => ({
        payment_method: parseInt(payment),
      })),
      installments: hasInstallment
        ? [{ installment: parseInt(data.installments[0]) }]
        : [],
      value: moneyValue / 100,
      interest_to_client: viewProductData.interest_to_client,
      co_producers: co_producer ? [co_producer] : [],
    }

      setViewProductData({
        ...viewProductData,
        finalJSON: {
          ...viewProductData.finalJSON,
          ...newData,
        },
      })
      handleNext()
    } catch (err) {
      displayYupErrorNotification(err)
    }
  }

  return (
    <MainContainer>
      <ContentContainer>
        <HeaderNewProduct
          title="2 - Informações de Preço"
          subtitle="Defina a precificação e formas de pagamento."
        />

        <FormContainer ref={formRef} onSubmit={handleSubmit}>
          <Flex>
            <Input title={'Preço do Produto'} width={'200px'}>
              <CurrencyInput
                value={(viewProductData.value * 100)}
                currency={viewProductData.currencySymbol}
                onValueChange={() => {}}
                disabled
              />
            </Input>
          </Flex>

          <InputContainer>
            <h1>Tipo de Moeda</h1>
            <section className="content">
              {!currencies ? (
                <Skeleton.Button active />
              ) : (
                  <Radios
                    name="currency"
                    options={currencies}
                    defaultValue={userOptions.currencies}
                    onChange={() => {}}
                    disabled={true}
                  />
                )}
            </section>
          </InputContainer>

          <InputContainer>
            <div style={{ display: "flex", alignItems: 'baseline', justifyContent: 'space-between' }}>
              <h1>Co-produtor</h1>
            </div>
            
            <section className="content">
              {viewProductData.co_producers &&
              viewProductData.co_producers.length === 0 ? (
                <NoCoProducer>Nenhum co-produtor cadastrado</NoCoProducer>
              ) : (
                <CoProducerList
                  onDelete={handleCoProducerDelete}
                  co_producers={viewProductData.co_producers}
                />
              )}
            </section>
          </InputContainer>

          <InputContainer>
            <h1>Prazo de Reembolso</h1>
            <section className="content">
              {!repayments ? (
                <Skeleton.Button active />
              ) : (
                  <Radios
                    name="repayments"
                    options={repayments}
                    defaultValue={userOptions.repayments}
                    disabled={true}
                  />
                )}
            </section>
          </InputContainer>

          <InputContainer>
            <h1>Formas de Pagamento</h1>
            <section className="content">
              {!paymentMethods ? (
                <Skeleton.Button active />
              ) : (
                  <Check
                    name="payments"
                    options={paymentMethods}
                    defaultValue={userOptions.payments}
                    onChange={() => {}}
                  />
                )}
            </section>
          </InputContainer>

          <SwitchContainer>
              <StyledSwitch
                onChange={handleHasInstallment}
                checked={hasInstallment}
                disabled={true}
              />
              <p>Parcelamento máximo disponível</p>
          </SwitchContainer>
          <InputContainer>
            <section className="content">
              {!installments ? (
                <Skeleton.Button active />
              ) : (
                  <Check
                    name="installments"
                    options={installments}
                    checked={hasInstallment}
                  />
                )}
            </section>
          </InputContainer>

          <SwitchContainer>
            <StyledSwitch
              onChange={handleInterest}
              checked={viewProductData.interest_to_client}
              disabled={true}
            />
            <p>Repassar juros para o cliente, se houver?</p>
          </SwitchContainer>

          <ContentFooter>
            <ActionBtn onClick={handlePreview}>
              <ArrowIcon rotateDeg={90} color="var(--main)" />
              <p>Voltar</p>
            </ActionBtn>

            <ActionBtn className="next-btn" type="submit">
              <p>Próximo</p>
              <ArrowIcon rotateDeg={-90} color="var(--main)" />
            </ActionBtn>
          </ContentFooter>
        </FormContainer>
      </ContentContainer>
    </MainContainer>
  )
}

export default PriceInfo