/*
Página do usuário, onde ele adiciona informações como o seu nome, etc
*/

import React from 'react'
import { Container } from '../../../GlobalStyles'

import ContainerHeader from 'containers/Header'
import ContainerPersonal from 'containers/Personal'

const Personal = () => {
  return (
    <Container>
      <ContainerHeader Search={true}/>
      <ContainerPersonal />
    </Container>
  )
}

export default Personal