import React from 'react';
import { MenuLink } from './styles'
import {
    HomeOutlined,
    AuditOutlined,
    BarsOutlined,
    ShopOutlined,
    DollarCircleOutlined,
    ExclamationCircleOutlined,
    ToolOutlined,
  } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const ProducerDesktopView = () => {


    return (
      <>
        <MenuLink
          className="menu"
          style={{ background: 'black' }}
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
        >
          <MenuLink.Item key="1" icon={<HomeOutlined />}>
            <Link to="/">Home</Link>
          </MenuLink.Item>
          <MenuLink.Item key="2" icon={<DollarCircleOutlined />}>
            <Link to="/financial">Financeiro</Link>
          </MenuLink.Item>
          <MenuLink.Item key="3" icon={<ShopOutlined />}>
            <Link to="/products">Produtos</Link>
          </MenuLink.Item>

          <MenuLink.Item key="4" icon={<AuditOutlined />}>
            <Link to="/student-producer">Alunos</Link>
          </MenuLink.Item>
          <MenuLink.Item key="5" icon={<BarsOutlined />}>
            <Link to="/reports">Relatórios</Link>
          </MenuLink.Item>
          <MenuLink.Item key="6" icon={<ExclamationCircleOutlined />}>
            <Link to="/cancel">Estorno</Link>
          </MenuLink.Item>
          <MenuLink.Item key="7" icon={<ToolOutlined />}>
            <Link to="/config">Configurações</Link>
          </MenuLink.Item>
        </MenuLink>
      </>
    )
}

export default ProducerDesktopView

