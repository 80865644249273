import styled from 'styled-components'

export const Dot = styled.span`
  height: 50px;
  width: 50px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 9px 5px 20px #eeeeee, 0px 2px 5px #e4e4e4;
`

export const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 0px 20;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 1124px) {
    padding: 8px;
    margin-top: 92px;
  }
`

export const SearchContainer = styled.div`
  width: 31.25vw;
  @media screen and (max-width: 1124px) {
    width: 100%
  }
`

export const ContainerCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;
`

export const ContainerInputs = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;

  @media (max-width: 1124px) {
    justify-content: center;
    flex-direction: column-reverse;
  }
`

export const Select = styled.div`
  width: 126.6px;
  height: 40px;
  margin: 4px;
  border-radius: 2px;
  background: #ffffff;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  display: flex;
  justify-content: left;
  align-items: center;
  p{
   margin: 0 auto;
   text-align: center;
  }
`
export const ContainerSelect = styled.div`
  width: 257px;
  height: 40px;
  border-radius: 45px;
  display: flex;
  align-items: center;
  @media (max-width: 886px) {
    flex-direction: column;
    margin-bottom: 12px;
    height: auto;
  }


`

export const ContainerTitles = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  margin-left: 20px;

  flex-wrap: wrap;
`

export const Title = styled.p`
  font-size: 14px;
  color: var(--main);
  margin-bottom: 3px;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const SubTitle = styled.p`
  font-size: 16px;
  color: #4f4f4f;
  font-weight: bold;
  margin: 0 10px;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const ContainerProgress = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
`
export const Classes = styled.p`
  font-size: 14px;
  color: #898989;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  strong {
    margin-left: 10px;
    margin-right: 10px;
  }
`
export const ProgressBar = styled.div`
  width: 376px;
  height: 11px;
  background: #e5e5e5;
  border-radius: 5px;
  position: relative;
`

export const Evolution = styled.div`
  position: absolute;
  width: 94px;
  height: 11px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: var(--main);
  top: 0;
  left: 0;
`

export const Champion = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #d8c410;
  position: absolute;
  left: 75%;
  bottom: -17px;

  display: flex;
  align-items: center;
  justify-content: center;
`
