import styled from 'styled-components'
import ProgressBar from '../ProgressBar'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 12px auto;

  div > strong {
    margin: 0 6px;
  }
`

export const StepsWrapper = styled.div`
  min-width: 90px;
  @media screen and (max-width:493px){
    display:none;
  }
`

export const StyledProgress = styled(ProgressBar)`
  width: 300px;
`
