/*
Aqui é um componente de módulo, onde é criado uma nova aula.
*/

import CircularProgress from '@material-ui/core/CircularProgress'
import { Form } from '@unform/web'
import { Modal } from 'antd'
import Input from 'components/new/Input'
import PdfFileInput from 'components/new/Input/PdfFileInput'
import VideoFileUploader from 'components/new/Input/VideoUpload'
import Text from 'components/new/Input/Text'
import Textarea from 'components/new/Input/Textarea'
import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import api from 'services/api'
import apiVideoUploader from 'services/api-video-upload'
import asyncForEach from 'utils/asyncForEach'
import displayYupErrorNotification from 'utils/displayYupErrorNotification'
import { displaySucessNotification } from 'utils/displayNotification'
import * as Yup from 'yup'
import { EditProductContext } from '../../../EditProductContext'
import { ModalButton, ModalFooter } from '../../shared-styles'
import DeleteIcon from 'assets/icons/delete-icon.svg'
import { AiOutlineVideoCameraAdd, AiOutlineCheck, AiOutlineFilePdf } from "react-icons/ai"

interface NewClass {
  name: string
  video_link: string
  video_key: string
  description: string
}

interface Props {
  visible: boolean
  moduleID: number
  onCancel: () => void
  video_key: string
}

async function uploadPDF(pdf: string, newSequence: number, id: number, moduleSquence: number) {
  let result = await api.post('upload-pdf', {
    pdf_link: pdf,
    index: newSequence,
    module: moduleSquence,
    type: 'class',
    product_id: id,
  })
  return result.data
}

const NewClassModal: React.FC<Props> = ({ visible, moduleID, onCancel }) => {
  const [editProductData, setEditProductData] = useContext(EditProductContext)
  const [attachment, setAttachment] = useState<any>(null)
  const [videoAttachment, setVideoAttachment] = useState<any>({})
  const [hasVideoAttachment, setHasVideoAttachment] = useState<boolean>(false)
  const [hasAttachment, setHasAttachment] = useState<boolean>(false)
  const [progress, setProgress] = useState(0);
  const [defaultValues, setDefaultValues] = useState<NewClass>({
    name: '',
    video_link: '',
    video_key: '',
    description: '',
  })
  const [videoDuration, setVideoDuration] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const randomId = (Math.random().toString(33).substring(2)).replace(/[^a-zA-Z]/g, '')

  const handleTextValueChange = (
    evt: ChangeEvent<HTMLInputElement>,
    property: string,
  ) => {
    setDefaultValues({
      ...defaultValues,
      [property]: evt.target.value,
    })
  }

  const handleAttachment = (evt: any) => {
    if (evt.target.files && evt.target.files[0]) {
      let reader = new FileReader()
      reader.onprogress = updateProgress
      reader.onload = function () {
        setAttachment(reader.result)
        setHasAttachment(true)
      }
      reader.readAsDataURL(evt.target.files[0])
    }

    function updateProgress(evt: any) {
      if (evt.lengthComputable) {
        setProgress(Math.round((evt.loaded / evt.total) * 100))
      }
    }

    let attachmentInput: any = document.querySelector(`#${randomId}`)
    attachmentInput.value = null
  }

  const handleVideoAttachment = async (evt: any) => {
    const data = new FormData()
    data.append('file', evt.target.files[0])
    await apiVideoUploader.post('/upload', data, {
      onUploadProgress: (event: any) => {
        setLoading(true)
        setProgress(Math.round((event.loaded * 100) / event.total))
      },
    })
      .then((response) => {
        displaySucessNotification(`A vídeo aula foi enviada com sucesso!`)
        setVideoAttachment(response.data)
        setHasVideoAttachment(true)
        setLoading(false)
        setProgress(0)
      })
      .catch((err) => {
        displayYupErrorNotification(`Houve um problema ao realizar o upload.`);
        setProgress(0)
      })
  }

  const handleDeleteVideoLink = async () => {
    try {
      await apiVideoUploader.delete(`/delete/${videoAttachment.key}`)
      setVideoAttachment({})
      setHasVideoAttachment(false)
      displaySucessNotification('Vídeo deletado com sucesso!')
    } catch (err) {
      displayYupErrorNotification(err)
    }
  }

  const getVideoDuration = (duration: number) => {
    setVideoDuration(0)
  }

  const resetValues = () => {
    setAttachment(null)
    setHasAttachment(false)
    setVideoAttachment({})
    setHasVideoAttachment(false)
    setDefaultValues({
      name: '',
      video_link: '',
      video_key: '',
      description: '',
    })
  }

  const handleResetAndClose = () => {
    resetValues()
    onCancel()
  }

  const handleCancel = () => {
    if (hasVideoAttachment) {
      if (window.confirm('Deseja descartar as alterações?')) {
        handleDeleteVideoLink()
        handleResetAndClose()
      }
    } else {
      handleResetAndClose()
    }
  }

  const handleNewClass = async (data: NewClass) => {
    setLoading(true)
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O nome da aula é obrigatório.'),
        description: Yup.string().required('Por favor adicione uma descrição.'),
        video_link: Yup.string()
          .nullable(true)
          .typeError('Adicione apenas tipos de vídeo válidos.')
          .test("fileSize", "O arquivo é maior que 30MB.", (value: any) => {
            return (value ? value.length * (3 / 4) : 1) - 2 <= 3 * (10 ** 7) //esse é o tamanho de 30mb em bytes
          }),
        attachment: Yup.string()
          .nullable(true)
          .typeError("Adicione um arquivo PDF.")
          .test("fileSize", "O arquivo é maior que 30MB.", (value: any) => {
            return (value ? value.length * (3 / 4) : 1) - 2 <= 3 * (10 ** 7) //esse é o tamanho de 30mb em bytes
          }),
      })

      await schema.validate(data)

      let newModules: any = []

      await asyncForEach(editProductData.modules, async (module: any) => {
        if (module.sequence === moduleID) {
          let newSequence = module.classes.length
            ? module.classes.length + 1
            : 0

          let newAttachment = attachment
            ? await uploadPDF(attachment, newSequence, editProductData.id, module.sequence)
            : null

          let newClass = {
            ...data,
            sequence: newSequence,
            video_link: videoAttachment.url,
            video_key: videoAttachment.key,
            attachment: newAttachment,
            time: videoDuration,
          }

          let newClasses = module.classes.concat(newClass)

          newModules.push({
            ...module,
            classes: newClasses,
          })
        } else {
          newModules.push(module)
        }
      })

      setEditProductData({
        ...editProductData,
        modules: newModules,
      })

      handleResetAndClose()
    } catch (err) {
      displayYupErrorNotification(err)
    } finally {
      setLoading(false)
    }
  }

  const capitalize = (e: any) => {
    return e.charAt(0).toUpperCase() + e.slice(1)
  }

  return (
    <Modal
      title="Adicionar Aula"
      visible={visible}
      footer={null}
      onCancel={handleCancel}
    >
      <Form onSubmit={handleNewClass}>
        <Input title="Nome da Aula">
          <Text
            name="name"
            placeholder={'Digite o nome da Aula'}
            maxLength={100}
            onChange={(evt: any) => handleTextValueChange(evt, 'name')}
            value={capitalize(defaultValues.name)}
          />
        </Input>

        <Textarea
          name={'description'}
          title="Descrição da Aula"
          placeholder={'Descreva sobre a Aula'}
          height={'7.06rem'}
          valueInput={capitalize(defaultValues.description)}
          onChangeInput={(evt: any) =>
            handleTextValueChange(evt, 'description')
          }
          style={{ marginBottom: '2rem' }}
        />

        {hasVideoAttachment ?
          <ReactPlayer
            url={videoAttachment.url}
            onDuration={getVideoDuration}
            width="100%"
            height="200px"
            controls={true}
            muted={true}
            playing={false}
            style={{ marginTop: -20, marginBottom: 20 }}
          />
          : null}

        <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', justifyContent: 'center' }}>
          <VideoFileUploader
            name={'video_link'}
            label={hasVideoAttachment ? <><AiOutlineCheck color={'var(--main)'} /> Aula adicionada</> : <><AiOutlineVideoCameraAdd /> Adicionar aula</>}
            onChange={handleVideoAttachment}
            hasLink={hasVideoAttachment}
          />

          {hasVideoAttachment ?
            <img
              src={DeleteIcon}
              alt="Clique aqui para deletar o vídeo"
              title="Clique aqui para deletar o vídeo"
              onClick={() => { window.confirm('Você realmente quer apagar esse vídeo?') ? handleDeleteVideoLink() : alert('Seu vídeo não foi apagado.') }}
            />
            : null
          }

          {/* <Text
            name="video_link"
            placeholder={'Link do vimeo'}
            onChange={(evt: any) => handleTextValueChange(evt, 'video_link')}
            maxLength={200}
            value={defaultValues.video_link}
          /> */}

          <PdfFileInput
            name={randomId}
            label={hasAttachment ? <><AiOutlineCheck color={'var(--main)'} /> Anexo Adicionado</> : <><AiOutlineFilePdf /> Adicionar anexo</>}
            onChange={handleAttachment}
            hasPdf={hasAttachment}
          />
        </div>

        <ModalFooter>
          <ModalButton type="button" onClick={handleCancel}>
            Cancelar
          </ModalButton>
          <ModalButton className="submit" type="submit" disabled={loading}>
            {loading ? (
              <CircularProgress variant="determinate" value={progress} size={20} thickness={5} color={'inherit'} />
            ) : (
                'Adicionar Aula'
              )}
          </ModalButton>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default NewClassModal