import styled from 'styled-components'

export const Sociais = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    margin-top: 40px;
    justify-content: center;

    input{
        width: 70%;
        height: 40px;
        border-radius: 20px;
        border: 1px solid #C0C0C0;
        padding-left: 20px;
        outline: none;
        margin-left: 12px;
    }
    @media screen and (max-width:887px){
        width: 100%;
    }
`