import React, { useState, useEffect, useRef, useContext } from 'react'

import history from 'routes/history'

import { SubmitHandler } from '@unform/core'
import {
  ContentFooter,
  ActionBtn,
  MainContainer,
  ContentContainer,
  
} from '../shared-styles'
import {
  Flex,
  ContentForm,
  DivOrder,
  Aprove,
  Refuse,
  Modal,
  Button,
  Overlay,
  InputTextAre
} from './style'
import api from 'services/api'
import HeaderNewProduct from 'components/new/HeaderNewProduct'
import Input from 'components/new/Input'
import Textarea from 'components/new/Input/Textarea'
import Text from 'components/new/Input/Text'
import Select from 'components/new/Input/Select'
import Form from 'components/new/Form'
import ArrowIcon from 'components/ArrowIcon'
import { CircularProgress } from '@material-ui/core/'
import { StepContext } from '../../../StepContext'
import { ViewProductContext } from '../../ViewProduct'
import * as Yup from 'yup'
import {
  displaySucessNotification,
  displayErrorNotification,
} from 'utils/displayNotification'

const textAreaHeight = '6.7375rem'

interface FormData {
  descriptionBump: string
  descriptionSell: string
  discountBump: string
  discountSell: string
  orderBump: string
  orderSell: string
}

const CheckoutConfig: React.FC = () => {
  const { handlePreview, handleNext } = useContext(StepContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [editProductData, setEditProductData] = useContext(ViewProductContext)

  const [optionsBump, setOptionsBump] = useState<any>(null)
  const [optionsSell, setOptionsSells] = useState<any>(null)
  const [selectedOrderSell, setSelectedOrderSell] = useState<any>(null)
  const [selectedBump, setSelectedBump] = useState<any>(null)

  const [motivo, setMotivo] = useState<string>('')

  const [displayOverlay, setDisplayOverlay] = useState('none')
  const getBumps = async () => {
    let { data } = await api.get('/order-bump')
    setOptionsBump(data)
  }

  const handleBump = ({ value, label }: any) => {
    setSelectedBump({ value, label })

    setEditProductData({
      ...editProductData,
      order_bump: selectedBump,
    })
  }

  const getUpSell = async () => {
    let { data } = await api.get('/up-sell')
    setOptionsSells(data)
  }

  const handleOrderSell = ({ value, label }: any) => {
    setSelectedOrderSell({ value, label })

    setEditProductData({
      ...editProductData,
      order_sell: selectedOrderSell,
    })
  }

  const handleTextValue = (evt: any) => {
    setEditProductData({
      ...editProductData,
      [evt.target.id]: evt.target.value,
    })
  }

  const setData = () => {
    let bumps = editProductData.bumps
    let upsells = editProductData.upsells

    if (bumps.length > 0) {
      setEditProductData({
        ...editProductData,
        activeOrderBump: true,
      })
    }

    if (upsells.length > 0) {
      setEditProductData({
        ...editProductData,
        activeOrderSell: true,
      })
    }
  }

  useEffect(() => {
    getBumps()
    getUpSell()
    setData()
  }, [setData])

  const formRef = useRef<SubmitHandler>(null)
  const handleAprove = async (id: any) =>{
    try {
      await api.put(`aprove-product/${id}`, )

      displaySucessNotification('Produto aprovado com sucesso! Sua página irá atualizar.')
     
      let { data: { records } } = await api.get('/list-all-products?currentstatus=EM ANÁLISE')
      
      if (!records.length) {
        history.replace('/backOffice')
        return 0;
      }

      history.push({
        pathname: '/products/view',
        search: `?query=${records[0].id}`,
        state: { product_id: records[0].id }
      })

      window.location.reload()
    } catch(err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const handleRefuse = async (id: any) =>{
    try {
      await api.put(`refuse-product/${id}`, { motivo })

      displaySucessNotification('Produto recusado com sucesso! Sua página irá atualizar.')

      let { data: { records } } = await api.get('/list-all-products?currentstatus=EM ANÁLISE')

      if (!records.length) {
        history.replace('/backOffice')
        return 0;
      }

      history.push({
        pathname: '/products/view',
        search: `?query=${records[0].id}`,
        state: { product_id: records[0].id }
      })

      window.location.reload()

    } catch(err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  return (
    <MainContainer>
      <ContentContainer>
        <HeaderNewProduct
          title="3 - Configuração de Checkout"
          subtitle="Habilite e configure as opções de Order bump e Upsell no checkout deste produto."
        />
        <ContentForm ref={formRef}>

          <Textarea
            name="descriptionBump"
            title="Breve descrição"
            placeholder="Essa descrição ajudará a vender o seu produto!"
            height={textAreaHeight}
            disabled
            valueInput={editProductData.descriptionBump}
          />

          <Flex>
            <DivOrder>
              <Input title="Order Bump">
                <Select
                  isDisabled
                  options={optionsBump}
                  name="orderBump"
                  placeholder="Selecione o produto"
                  defaultValue={editProductData.order_bump}
                />
              </Input>
            </DivOrder>

            <Input title="Desconto neste produto" width="23%">
              <Text
                name="discountBump"
                type="number"
                placeholder="0,00 % OFF"
                disabled
                value={editProductData.discountBump}
              />
            </Input>
          </Flex>

          <Textarea
            name="descriptionSell"
            title="Breve descrição"
            placeholder="Essa descrição ajudará a vender o seu produto!"
            height={textAreaHeight}
            disabled
            valueInput={editProductData.descriptionSell}
          />

          <Flex style={{ marginBottom: '0' }}>
            <DivOrder>
              <Input title="Order Sell">
                <Select
                  isDisabled
                  options={optionsSell}
                  name="orderSell"
                  placeholder="Selecione o produto"
                  defaultValue={editProductData.order_sell}
                />
              </Input>
            </DivOrder>

            <Input title="Desconto neste produto" width="23%">
              <Text
                name="discountSell"
                type="number"
                placeholder="0,00 % OFF"
                disabled
                value={editProductData.discountSell}
              />
            </Input>
          </Flex>

          <ContentFooter>
            <ActionBtn onClick={handlePreview}>
              <ArrowIcon rotateDeg={90} color="var(--main)" />
              <p>Voltar</p>
            </ActionBtn>

            <Refuse type="button" onClick={() => setDisplayOverlay('block')}>
              {loading ? (
                <CircularProgress color="inherit" size={'1.1rem'} />
              ) : (
                <p>Recusar</p>
              )}
            </Refuse>
            <Aprove type="button" onClick={() => handleAprove(editProductData.id)}>
              {loading ? (
                <CircularProgress color="inherit" size={'1.1rem'} />
              ) : (
                <p>Aprovar</p>
              )}
            </Aprove>
          </ContentFooter>
        </ContentForm>
      </ContentContainer>
      <Overlay display={ displayOverlay }>
          <Modal>
            <p>Escreva o motivo pelo qual o produto será recusado
              <span
                style={{ left: '0', float: 'right' }} 
                onClick={ () => {setDisplayOverlay('none'); setMotivo('');}}>X</span></p>
            <Form ref={formRef}>
              <InputTextAre
                name="motivo"
                title="Motivo"
                height={'100%'}
                valueInput={motivo}
                onChangeInput={(e: any) => setMotivo(e.target.value)}
              />
            </Form>
            <Button onClick={() => handleRefuse(editProductData.id) }>Confirmar</Button>
          </Modal>
        </Overlay>
    </MainContainer>
  )
}

export default CheckoutConfig
