import styled from 'styled-components'

import { Select } from 'antd'

export const Container = styled(Select)`
  width: calc(100% - 0.9375rem);
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: -10px;

  &&& * {
    border: none;
    background: none;
    box-shadow: none !important;
  }

  &&& span.ant-select-arrow {
    top: 35%;
    color: #5C5C5C;
    font-size: 13px;
  }
`
