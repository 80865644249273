import React from "react";
import {
  DataTable,
  DataTableHead,
  DataTableRow,
  DataTableCell,
  DataTableBody,
} from 'components/DataTable'
import {
  ContainerTable
} from './style'
import CurrencyType from './Currency'
import { CircularProgress } from '@material-ui/core/'

interface Props {
  currencyType: any
}

const SkeletonTable: React.FC<Props> = ({ currencyType }) => {

  return (
    <ContainerTable>
      <DataTable>
        <DataTableHead>
          <DataTableRow>
            <DataTableCell>Tipo da moeda</DataTableCell>
          </DataTableRow>
        </DataTableHead>

        <DataTableBody>

          {
            !currencyType ? (
              <CircularProgress color="inherit" />
            ) : (
                currencyType && currencyType.map((product: any) => (
                  <CurrencyType currencyType={product} key={product.id} />
                ))
              )
          }
        </DataTableBody>
      </DataTable>
    </ContainerTable>
  );
};

export default SkeletonTable;
