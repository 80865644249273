import React, { useRef, useState, useEffect } from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'

import { useField } from '@unform/core'

import 'react-datepicker/dist/react-datepicker.css'

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string
  defaultValue?: any
}

const DatePicker: React.FC<Props> = ({
  name,
  defaultValue = null,
  ...rest
}) => {
  const datepickerRef = useRef(null)
  const { fieldName, registerField } = useField(name)

  const [date, setDate] = useState(defaultValue)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref: any) => {
        ref.clear()
      },
    })
  }, [fieldName, registerField])

  return (
    <ReactDatePicker
      ref={datepickerRef}
      dateFormat="dd/MM/yyyy"
      selected={date}
      showMonthDropdown
      dropdownMode="select"
      onChange={setDate}
      {...rest}
    />
  )
}

export default DatePicker
