import React from "react"
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import {
  Container
} from "./style"

interface Props {
  InfoText: string
}

const Info: React.FC<Props> = ({ InfoText }) => {
  return (
    <Container Info={InfoText}>
      <AiOutlineQuestionCircle/>
      <div>
      </div>
    </Container>
  )
}

export default Info;
