import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .calendar {
    width: 65%;
    height: 50%;
    left: 235.99px;
    top: 133.51px;

    /* White */

    background: #ffffff;
    /* Card Shadow */

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;

    box-shadow: 9px 5px 20px #eeeeee, 0px 2px 5px #e4e4e4;
    border-radius: 10px;

    @media (min-width: 1400px) {
      width: 55%;
    }
  }
  .Atividades {
    width: 30%;
    height: 450px;
    left: 235.99px;
    top: 133.51px;
    padding: 20px;

    /* White */

    background: #ffffff;
    /* Card Shadow */

    box-shadow: 9px 5px 20px #eeeeee, 0px 2px 5px #e4e4e4;
    border-radius: 10px;
  }

  @media (max-width: 600px) {
    padding: 30px;
  }
`
