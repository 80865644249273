export const createValueLabelForSelectInput = (id: number, name: string) => ({
  value: id,
  label: name,
})

export const createBumpSellsObject = () => ({
  description: null,
  discount: null,
  product_tenant: {
    id: null,
    name: '',
  },
})

const createBumpSellsObjectToFinalJson = (data: any) => {
  let obj = data.map((item: any) => ({
    tenant_id: item.product_tenant ? item.product_tenant.id : null,
    description: item.description ? item.description : null,
    discount: item.discount ? item.discount : {},
  }))

  return obj
}

const createInstallmentsArray = (data: any) => {
  if (data.installments.length > 0) {
    return data.installments.map((el: any) => ({
      installment: el.installment.id,
    }))
  } else {
    return data.installments
  }
}

const createPaymentsArray = (data: any) => {
  if (data.payments.length > 0) {
    return data.payments.map((pay: any) => ({
      payment_method: pay.payment_method.id,
    }))
  } else {
    return data.payments
  }
}

export const createFinalJSON = (data: any) => ({
  id: data.id,
  name: data.name,
  description: data.description,
  category: { id: data.category.id },
  sub_category: { id: data.sub_category.id },
  photo: data.photo,
  updated_photo: false,
  currency: { id: data.currency.id },
  repayment: { id: data.repayment.id },
  payments: createPaymentsArray(data),
  installments: createInstallmentsArray(data),
  value: data.value,
  interest_to_client: data.interest_to_client,
  co_producers: data.co_producers,
  bumps: createBumpSellsObjectToFinalJson(data.bumps),
  upsells: createBumpSellsObjectToFinalJson(data.upsells),
  modules: data.modules,
  release_date: data.release_date,
})
