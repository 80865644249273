import React, { useState, useEffect } from 'react'

import {
  Card,
  ContainerTitle,
  Title,
  ValoresBlock,
  Buttonk,
  Loot,
  ValoresFlex,
  TitleLot,
  ModalButton,
  Content,
  BlurComponent
} from './style'
import api from 'services/api'
import EyerOff from 'assets/icons/eye-off.svg'
import Info from 'components/Financial/Info'
import { Col, Modal } from 'antd'
import Radios from 'components/new/Input/Radio'
import { FormContainer } from 'components/new/Modal/Upload/style'
import { Form } from '@unform/web'
import Input from 'components/new/Input'
import CurrencyInput from 'components/new_components/CurrencyInput'
import { CircularProgress } from '@material-ui/core/'
import { AiOutlineEyeInvisible } from 'react-icons/ai'
import StyledModal from 'components/new_components/Modal'

interface ResumFinanProps {
  valorBruto?: any
  valorLiquido?: any
  disponivelSaque?: any
  width?: string
  col?: number
}
interface SimulateDataInterface {
  prepayment_net_amount: number
}

const ResumFinan: React.FC<ResumFinanProps> = ({
  valorBruto,
  valorLiquido,
  disponivelSaque,
  width,
  col,
}) => {
  const [saldoAtual, setSaldoAtual] = useState(valorBruto)
  const [saldoDaConta, setSaldoDaConta] = useState(valorLiquido)
  const [saque, setSaque] = useState(disponivelSaque)
  const [visibleModal, setVisibleModal] = useState<boolean>(false)
  
  const [cpf, setCpf] = useState<any>('')
  const [cnpj, setCnpj] = useState<any>('')
  const [bank, setBank] = useState<any>()
  const [agency, setAgency] = useState<any>()
  const [account, setAccount] = useState<any>()
  const [digit, setDigit] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [blur, setBlur] = useState(true)
  const [warning, setWarning] = useState(false)

  const [moneyValue, setMoneyValue] = useState('')
  const [dateValue, setDateValue] = useState('')
  const [simulateData, setSimulateDate] = useState<SimulateDataInterface | null>();
  const [prepaymentId, setPrepaymentId] = useState<string>();
  
  const handleProductValue = (value: string) => {
    setMoneyValue(value)
  }

  const handleCancel = () => {
    setVisibleModal(false)
  }
  const handleWarning = () => {
    setWarning(true)
  }

  // const handleOk = () => {
  //   setVisibleModal(false)
  // }

  const handleVisible = () => {
    if (saldoDaConta === 'R$ 0,00') {
      info(
        'Saldo para saque muito baixo, por favor tente novamente quando tiver um saldo maior.',
      )
    } else {
      if (!account || !digit || !agency || !bank) {
        info('falta cadastrar seus dados bancarios e pessoais.')
      } else {
        setVisibleModal(true)
      }
    }
  }

  const info = (value: string) => {
    Modal.error({
      title: 'Erro',
      content: (
        <div>
          <p>{value}</p>
        </div>
      ),
      onOk() { },
    })
  }
  const addDays = (days: number) => {
    var result = new Date(Date.now());
    result.setDate(result.getDate() + days);
    return result;
  }
  
  const confirmPrepayment = async () => {
    setLoading(true)
    let infos = {
      prepaymentId
    }
    try {
      await api.post('/commit-prepayments', infos)
      Modal.success({
        title: 'Sucesso',
        content: 'Antecipação solicitada com sucesso.',
      });
      setLoading(false)
    } catch (err) {
      setLoading(false)
      Modal.error({
        title: 'Erro',
        content: 'Problema ao confirmar a antecipação, entre em contato.',
      });
    }
  }
  const handleSimulatePrepayment = async () => {
    if (moneyValue > saque) {
      Modal.warning({
        title: 'Erro',
        content: (
          <div>
            <p>Valor desejado é maior que o disponivel para antecipação.</p>
          </div>
        ),
        onOk() { },
      })
    } else {
      const value = parseFloat(moneyValue.replace(/\D/g, '')) / 100

      setLoading(true)
      let profile = await api.get("profile")
      const date = addDays(Number.parseInt(dateValue))

      let infos = {
        seller_id: profile.data.user.seller_id,
        prepayment_date: `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`,
        target_amount: value
      }

      try {
        const { data: { data } } = await api.post('/simulate-prepayments', infos)
        setSimulateDate(data)
        setPrepaymentId(data.id)
        setLoading(false)
      } catch (err) {
        setLoading(false)
        Modal.error({
          title: 'Erro',
          content: 'Problema ao fazer a simulação de antecipação, entre em contato.',
        });
      }
    }
  }

  const handleOk = async () => {
    if (moneyValue > saque) {
      Modal.warning({
        title: 'Erro',
        content: (
          <div>
            <p>Valor desejado é maior que o disponivel para saque.</p>
          </div>
        ),
        onOk() { },
      })
    } else {
      let convert = moneyValue.toString()
      let max = convert.length - 2
      let replace = convert.substr(-2)
      let replace1 = convert.substr(0, max)
      let value = `${replace1}.${replace}`
      setLoading(true)
      let profile = await api.get('profile')
      let data = {
        seller_id: profile.data.user.seller_id,
        amount: parseFloat(value),
      }
      try {
        await api.post('/create-bank-account-transfer', data)
        setLoading(false)

        Modal.success({
          content: 'Saque realizado com sucesso!',
        })
        setVisibleModal(false)
      } catch (err) {
        setLoading(false)
        Modal.error({
          title: 'Erro',
          content: 'Erro ao fazer o saque.',
        })
      }
    }
  }

  const handleVisibleChange = async () => {
    if (!blur) {
      setBlur(true)
    } else {
      setBlur(false)
    }
  }




  useEffect(() => {
    const init = async () => {

      let data = await api.get('profile')
      let id = data.data.user.seller_id
      let saqueDisp = await api.get(`/check-balance/${id}`)
        .then((res) => {
          if (res.data.balance.current_balance === 0) {
            setSaque('R$ 0,00')
          } else {
            setSaque(
              res.data.balance.current_balance.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }),
            )
          }
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data.message);
            if (error.response.data.message === 'Não foi possível consultar o saldo') {
              handleWarning()
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
        })




      setDigit(data.data.user.digit)
      setCpf(data.data.user.cpf)
      setCnpj(data.data.user.cnpj)
      setBank(data.data.user.bank)
      setAccount(data.data.user.account)
      setAgency(data.data.user.agency)

      let financial = await api.get('/financial-summary')
      if (financial.data.financial_summary.net_value === 0) {
        setSaldoAtual('R$ 0,00')
      } else {
        setSaldoAtual(
          financial.data.financial_summary.net_value.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
        )
      }
    }
    init()

  }, [saque, warning])

  return (
    <Content>
      <Card>
        <ContainerTitle>
          <Title>
            Resumo Financial
            {warning ? <StyledModal
              btnOpen={false}
              title='Preencha seus dados'
              text='Pelo visto você ainda não preencheu seus dados financeiros... 
            mas não se preocupe! Atualize eles agora, e os dados serão mostrados aqui :)'
            /> : false}
            <AiOutlineEyeInvisible onClick={handleVisibleChange} style={{ fontSize: 48, padding: 8, color: "var(--main)" }} />
          </Title>


        </ContainerTitle>

        <>
          <BlurComponent blur={blur}>
            <ValoresBlock>
              <div>
                {saldoAtual}
                <p>Valor Líquido(R$)</p>
              </div>
              <div>

              </div>
              <div>
                {saque ? saque : '---'}
                <p>Valor a antecipar (R$)</p>
              </div>
            </ValoresBlock>
          </BlurComponent>
          <Buttonk onClick={handleVisible}>
            Fazer uma Antecipação
          </Buttonk>
          {/* <SeeMore>
                Ver mais
                <FaChevronRight color="#85be08" size={15} style={{ marginLeft: 10 }} />
              </SeeMore> */}
        </>

      </Card>
      <Modal
        title="Realizar Antecipação"
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        onOk={() => { }}
        closable={true}
        footer={[
          <ModalButton type="button" onClick={handleCancel} value="Cancelar" />,
          (
            simulateData ? <ModalButton className="submit" type='button' onClick={confirmPrepayment} value="Confirmar" /> :
              <ModalButton className="submit" type='button' onClick={handleSimulatePrepayment} value="Simular" />)
        ]}
      >
        <Form onSubmit={() => { }}>
          <FormContainer>
            <TitleLot>
              <h4>
                Confirme seus dados bancários cadastrados no seu perfil, e insira o valor que deseja antecipar.
              </h4>
            </TitleLot>
            <Loot>
              <div>
                <h5>Banco</h5>
                <p>
                  {bank}
                </p>
              </div>
              <div>
                <h5>Agência</h5>
                <p>{agency}</p>
              </div>
              <div>
                <h5>Conta</h5>
                <p>{account} - {digit}</p>
              </div>
              <div>
                <h5>CPF / CNPJ</h5>
                {cpf ?
                  <p>{cpf}</p>
                  :
                  <p>{cnpj}</p>
                }
              </div>
            </Loot>
            <div>
              <h5>Disponível para Antecipar (R$)</h5>
              {saque}
            </div>
            {loading
              ?
              <CircularProgress color="inherit" />
              :
              (
                simulateData ? (
                  <>
                    <TitleLot>
                      <h5>
                        Simulação de Antecipação
                      </h5>
                    </TitleLot>
                    <Loot>
                      <div>
                        <h5>Valor à antecipar</h5>
                        <p>
                          R$ {simulateData.prepayment_net_amount / 100}
                        </p>
                      </div>
                    </Loot>
                  </>
                ) : (<>
                  <br />
                  <Input title={'Valor que deseja antecipar'} width={'50%'}>
                    <CurrencyInput
                      value={moneyValue}
                      onValueChange={handleProductValue}
                    />
                  </Input>
                  <h5>Dias para antecipação</h5>
                  <Radios
                    name="dateValue"
                    options={[{
                      id: 3,
                      name: '3'
                    },
                    {
                      id: 7,
                      name: '7'
                    },
                    {
                      id: 12,
                      name: '12'
                    }, {
                      id: 30,
                      name: '30'
                    }]}
                    onChange={(e: any) => setDateValue(e.target.value)}
                  />
                </>)

              )
            }

          </FormContainer>
        </Form>
      </Modal>
    </Content>
  )
}

export default ResumFinan
