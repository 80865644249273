import Badge from '@material-ui/core/Badge';
import React from 'react';

interface Props{
  children?: any
  badgeContent?: any
}


export default function StyledBadge(props: Props) {
  return (
    <Badge badgeContent={props.badgeContent} color="error">
      {props.children}
    </Badge>
  );
}