import styled from 'styled-components'
import { Card } from 'components/CardContainer'
import Input from 'components/new/Input'

export const CardContainer = styled(Card)`
    width: 100%;
    padding: 0;
    margin-bottom: 40px;
    box-shadow: none;
`
export const HeaderConquest = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const Title = styled.p`
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #5C5C5C;
    padding-top:20px;
`
export const SubTitle = styled.div`
    margin-top: 10px;
    width:100%;
    text-align:left;
    color: #898989;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;

    p{
        margin: 0;
        cursor: pointer;
    }
`
export const FlexAdress = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    div{
        width: 100%;
        height: 40px;
    }


    fieldset{
            margin-bottom: 10px;
    }
    p{
        font-size: 13px;
        color: #A2A2A2;
        padding-left: 15px;
        margin: 0;
    }
`
export const FlexAcount = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    div{
        width: 100%;
        height: 40px;
    }

    fieldset{
            margin-bottom: 10px;
    }
    p{
        font-size: 13px;
        color: #A2A2A2;
        padding-left: 15px;
        margin: 0;
    }
`
export const ContainerForm = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
`
export const EditInput = styled(Input)`
`
export const SaveButton = styled.button`
    cursor: pointer;
    background: transparent;
    margin-top: 80px;
    width: 150px;
    height: 36px;
    text-align: center;
    border: 1px solid #C0C0C0;
    border-radius: 48px;
    color: #C0C0C0;
    transition: all 0.3s;
    outline: none;
    &:hover{
        border: 1px solid var(--main);
        color: white;
        background-color: var(--main);

    }
`
export const SelectForm = styled.div`
    select{
        margin-left: 10px;
        width: 95%;
        border: none;
        background: white;
        margin-bottom: 5px;
        height: 30px;
        outline: none;
    }
`
export const FormEnd = styled.div`
    width: 100%;
    max-width: 424px;
    min-width: 224px;

    button{
        margin-top: 20px;
    }
`
export const FicharActive = styled.div`
    width: 120px;
    height: 30px;
    border: 1px solid #d9d9d9;
    border-bottom: none;
    position: relative;
    left: 125px;
    top: -99px;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #ffff;
`
export const FormEdit = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;
    max-width: 200px;

    

`
export const FicharInative = styled.div`
    width: 120px;
    height: 30px;
    border: 1px solid #d9d9d9;
    position: relative;
    top: -70px;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #ffff;
    cursor: pointer;

    :hover{
        border: 1px solid var(--main);
    }
`
export const Button = styled.button`
    width: 150px;
    color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 3rem;
    background-color: var(--main);
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.015625rem;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 36px;
    margin-top: 80px;


    &:hover {
    color: #FFFFFF;
    background-color: var(--main-highlight);
    }
`
export const FooterButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`