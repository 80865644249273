/*
3º parte da edição de produto:
Aqui você cuidará de colocar produtos adicionais que o cliente poderá pagar
junto ao checkout (junto com algum desconto).
*/

import React, { useState, useEffect, useRef, useContext } from 'react'
import { SubmitHandler } from '@unform/core'
import {
  ContentFooter,
  ActionBtn,
  MainContainer,
  ContentContainer,
} from '../shared-styles'
import { Checkbox, Flex, ContentForm, DivOrder } from './style'
import api from 'services/api'
import HeaderNewProduct from 'components/new/HeaderNewProduct'
import Input from 'components/new/Input'
import Textarea from 'components/new/Input/Textarea'
import Text from 'components/new/Input/Text'
import Select from 'components/new/Input/Select'
import StyledSwitch from 'components/new/Switch'
import ArrowIcon from 'components/ArrowIcon'
import { StepContext } from '../../../StepContext'
import { EditProductContext } from '../../EditProductContext'
import * as Yup from 'yup'
import displayYupErrorNotification from 'utils/displayYupErrorNotification'

const textAreaHeight = '6.7375rem'

interface FormData {
  descriptionBump: string
  descriptionSell: string
  discountBump: string
  discountSell: string
  orderBump: string
  orderSell: string
}

const CheckoutConfig: React.FC = () => {
  const { handlePreview, handleNext } = useContext(StepContext)
  const [editProductData, setEditProductData] = useContext(EditProductContext)
  const [optionsBump, setOptionsBump] = useState<any>(null)
  const [optionsSell, setOptionsSells] = useState<any>(null)
  const [doExistBump, setDoExistBump] = useState(true)
  const [doExistUpSell, setDoExistUpSell] = useState(true)
  const [selectedOrderSell, setSelectedOrderSell] = useState<any>(null)
  const [selectedBump, setSelectedBump] = useState<any>(null)

  const getBumps = async () => {
    let { data } = await api.get('/order-bump')
    data.length === 0 ? setDoExistBump(true) : setDoExistBump(false)
    setOptionsBump(data)
  }

  const handleBump = ({ value, label }: any) => {
    setSelectedBump({ value, label })

    setEditProductData({
      ...editProductData,
      order_bump: selectedBump,
    })
  }

  const getUpSell = async () => {
    let { data } = await api.get('/up-sell')
    data.length === 0 ? setDoExistUpSell(true) : setDoExistUpSell(false)
    setOptionsSells(data)
  }

  const handleOrderSell = ({ value, label }: any) => {
    setSelectedOrderSell({ value, label })

    setEditProductData({
      ...editProductData,
      order_sell: selectedOrderSell,
    })
  }

  const handleTextValue = (evt: any) => {
    setEditProductData({
      ...editProductData,
      [evt.target.id]: capitalize(evt.target.value),
    })
  }

  const [activeOrderBump, setActiveOrderBump] = useState<boolean>(false)
  const handleOrderBumpActive = () => {
    setActiveOrderBump(!activeOrderBump)

    setEditProductData({
      ...editProductData,
      activeOrderBump: !editProductData.activeOrderBump,
    })
  }

  const [activeOrderSell, setActiveOrderSell] = useState<boolean>(false)
  const handleOrderSellActive = () => {
    setActiveOrderSell(!activeOrderSell)

    setEditProductData({
      ...editProductData,
      activeOrderSell: !editProductData.activeOrderSell,
    })
  }

  const [discountBumpValue, setDiscountBumpValue] = useState<any>()
  const [discountSellValue, setDiscountSellValue] = useState<any>()
  const handleDiscountValue = (evt: any) => {
    let property = evt.target.id
    let stringValue = evt.target.value.toString().replace(',', '.')
    let value = parseFloat(stringValue)

    if (value > 100) {
      value = 100
    }

    setEditProductData({
      ...editProductData,
      [property]: value,
    })

    switch (property) {
      case 'discountBump':
        setDiscountBumpValue(value)
        return
      case 'discountSell':
        setDiscountSellValue(value)
        return
      default:
        return
    }
  }

  const setData = () => {
    if (editProductData.activeOrderBump) {
      setActiveOrderBump(!activeOrderBump)
    }

    if (editProductData.activeOrderSell) {
      setActiveOrderSell(!activeOrderSell)
    }

    if (editProductData.discountBump) {
      setDiscountBumpValue(editProductData.discountBump.toString())
    }

    if (editProductData.discountSell) {
      setDiscountSellValue(editProductData.discountSell.toString())
    }
  }

  useEffect(() => {
    setData()
    getBumps()
    getUpSell()
  }, [])

  const formRef = useRef<SubmitHandler>(null)
  const handleSubmit: SubmitHandler<FormData> = async data => {
    try {
      const schema = Yup.object().shape({
        tenant_id: Yup.number().required('Escolha o produto.'),
        description: Yup.string()
          .required('Adicione uma descrição.')
          .typeError('Adicione uma descrição.'),
        discount: Yup.number()
          .required('Adicione o desconto para o produto.')
          .typeError('Adicione o desconto para o produto.'),
      })
      let bumpsConvert: any = {
        id: editProductData.bumps.length ? editProductData.bumps[0].id : null,
        tenant_id: data.orderBump ? data.orderBump : null,
        description: data.descriptionBump ? data.descriptionBump : null,
        discount: data.discountBump ? parseFloat(data.discountBump) : {},
      }

      let upsellsConvert: any = {
        id: editProductData.upsells.length ? editProductData.upsells[0].id : null,
        tenant_id: data.orderSell ? data.orderSell : null,
        description: data.descriptionSell ? data.descriptionSell : null,
        discount: data.discountSell ? parseFloat(data.discountSell) : {},
      }

      if (editProductData.activeOrderBump) {
        await schema.validate(bumpsConvert)
      }

      if (editProductData.activeOrderSell) {
        await schema.validate(upsellsConvert)
      }

      let checkoutConfig = {
        bumps: [bumpsConvert],
        upsells: [upsellsConvert],
      }

      setEditProductData({
        ...editProductData,
        finalJSON: {
          ...editProductData.finalJSON,
          ...checkoutConfig,
        },
      })

      handleNext()
    } catch (err) {
      console.log(err)
      displayYupErrorNotification(err)
    }
  }

  const capitalize = (e: any) => {
    return e.charAt(0).toUpperCase() + e.slice(1)
  }

  return (
    <MainContainer>
      <ContentContainer>
        <HeaderNewProduct
          title="3 - Configuração de Checkout"
          subtitle="Habilite e configure as opções de Order bump e Upsell no checkout deste produto."
        />
        <ContentForm ref={formRef} onSubmit={handleSubmit}>
          <Checkbox>
            <StyledSwitch
              onChange={handleOrderBumpActive}
              checked={activeOrderBump}
              disabled={doExistBump}
            />
            {doExistBump ? <p>Order Bump desabilitado (você não cadastrou nenhum produto).</p> : <p>Order bump Habilitado</p>}
          </Checkbox>

          <Textarea
            name="descriptionBump"
            title="Breve descrição"
            placeholder="Essa descrição ajudará a vender o seu produto!"
            height={textAreaHeight}
            disabled={!activeOrderBump}
            onChangeInput={handleTextValue}
            valueInput={editProductData.descriptionBump}
          />

          <Flex>
            <DivOrder>
              <Input title="Order Bump">
                <Select
                  isDisabled={!activeOrderBump}
                  options={optionsBump}
                  name="orderBump"
                  placeholder="Selecione o produto"
                  onChange={handleBump}
                  onBlur={handleBump}
                  defaultValue={editProductData.order_bump}
                />
              </Input>
            </DivOrder>

            <Input title="Desconto neste produto" width="200px">
              <Text
                name="discountBump"
                type="number"
                placeholder="0,00 % OFF"
                disabled={!activeOrderBump}
                onChange={handleDiscountValue}
                onBlur={handleDiscountValue}
                value={editProductData.discountBump}
              />
            </Input>
          </Flex>

          <Checkbox>
            <StyledSwitch
              onChange={handleOrderSellActive}
              checked={activeOrderSell}
              disabled={doExistUpSell}
            />
            {doExistUpSell ? <p>Upsell desabilitado (você não cadastrou nenhuma oferta após a venda).</p> : <p>Upsell Habilitado</p>}
          </Checkbox>

          <Textarea
            name="descriptionSell"
            title="Breve descrição"
            placeholder="Essa descrição ajudará a vender o seu produto!"
            height={textAreaHeight}
            disabled={!activeOrderSell}
            onChangeInput={handleTextValue}
            valueInput={editProductData.descriptionSell}
          />

          <Flex style={{ marginBottom: '0' }}>
            <DivOrder>
              <Input title="Order Sell">
                <Select
                  isDisabled={!activeOrderSell}
                  options={optionsSell}
                  name="orderSell"
                  placeholder="Selecione o produto"
                  onChange={handleOrderSell}
                  onBlur={handleOrderSell}
                  defaultValue={editProductData.order_sell}
                />
              </Input>
            </DivOrder>

            <Input title="Desconto neste produto" width="200px">
              <Text
                name="discountSell"
                type="number"
                placeholder="0,00 % OFF"
                disabled={!activeOrderSell}
                onChange={handleDiscountValue}
                onBlur={handleDiscountValue}
                value={editProductData.discountSell}
              />
            </Input>
          </Flex>

          <ContentFooter>
            <ActionBtn onClick={handlePreview}>
              <ArrowIcon rotateDeg={90} color="var(--main)" />
              <p>Voltar</p>
            </ActionBtn>

            <ActionBtn type="submit" className="next-btn">
              <p>Próximo</p>
              <ArrowIcon rotateDeg={-90} color="var(--main)" />
            </ActionBtn>
          </ContentFooter>
        </ContentForm>
      </ContentContainer>
    </MainContainer>
  )
}

export default CheckoutConfig
