import React from 'react'

import { Container } from './style'

interface ButtonProps {
  color?: string
  width?: string
  btnStyle?: string
  arrowIcon?: boolean
  onClick?: any
  style?: object
  onMouseOver?: any
  props?: object
  height?: string
}

const Button: React.FC<ButtonProps> = ({
  color = 'var(--main)',
  width = 'max-content',
  btnStyle = 'bordered',
  height = '30px',
  arrowIcon = false,
  onClick,
  children,
  onMouseOver,
  style,
  props,
  ...rest
}) => {
  return (
    <Container
      style={style}
      onClick={onClick}
      onMouseOver={onMouseOver}
      icon={arrowIcon}
      btnStyle={btnStyle}
      width={width}
      height={height}
      color={color}
      {...props}
      {...rest}
    >
      {children}
    </Container>
  )
}
// TODO: Definir e padronizar, ou usa ; ou não
export default Button
