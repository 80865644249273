import styled from 'styled-components'

export const Container = styled.div`
  min-width: 280px;
  width: 334px;
  margin: 12px;
  max-width: 100%;
  min-height: 195px;
  background: #ffffff;
  box-shadow: 9px 5px 20px #eeeeee, 0px 2px 5px #e4e4e4;
  border-radius: 10px;
  
  margin-bottom: 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;

`

export const CardFooter = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .text {
    color: var(--main);
    font-size: 14px;
    margin: 0px;
    margin-right: 10px;
    line-height: 20px;
  }
`

export const CardBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  flex-wrap: wrap;
  width: 90%;
  height: 65%;
`

export const CardHeader = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  color: var(--main);

  .first {
    width: 80%;
    background-color: white;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 20px;

    .text {
      color: #5c5c5c;
      font-size: 11px;
      margin: 0px;
      margin-left: 9px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 700;
      line-height: 20px;
    }
    
    }

  .second {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;

    svg{
      cursor:pointer;
    }
    .text {
      color: #898989;
      font-size: 16px;
      font-weight: bold;
      margin: 0px;
      margin-right: 5px;
      line-height: 20px;
    }
  }
`
