import styled from 'styled-components'

export const NoStudentMsg = styled.h1`
  text-align: center;
  font-size: 1.25rem;
  margin: 8rem 0;
  color: #292929;
  width: 100%;

`
export const ContainerTable = styled.div`
  width: 100%;
  max-width: 100%;
  border: 1px solid #c0c0c0;
  border-radius: 12px;
  padding: 4px;


`