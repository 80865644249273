import styled from 'styled-components'
export const SelectInput = styled.div`
  width: 126.6px;
  height: 40px;
  margin: 10px;
  border-radius: 45px;
  background: #ffffff;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  align-items: center;
`
export const ContainerSelect = styled.div`
  width: 256.6px;
  height: 40px;
  border-radius: 45px;
  display: flex;
  align-items: center;
`
