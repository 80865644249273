import React, { useRef, useState } from 'react'
import { FormHandles } from '@unform/core'
import api from 'services/api'

import { Dropdown } from 'antd'
import {
  DataTableRow,
  DataTableCell
} from 'components/DataTable'
import { ProductLink, Subcard, OptionsBtn, InputMod } from './style'
import ThreeDotMenu from 'assets/icons/3dotmenu-icon.svg'
import { displayErrorNotification, displaySucessNotification } from 'utils/displayNotification'
import Form from 'components/new/Form'
import { Button } from './style'
import { InputGroup } from '../../../Form/Input/style'
import TableRow from '@material-ui/core/TableRow'

interface Repayment {
  repayment: {
    id: string
    name: string
    is_active: boolean
  }
};

const Repayment: React.FC<Repayment> = ({ repayment }) => {
  const {
    id,
  } = repayment

  const [name, setName] = useState(repayment.name || '')
  const [edit, setEdit] = useState(false)

  const formRef = useRef<FormHandles>(null)

  const handleDelete = async (id: any) => {
    try {
      await api.delete(`/delete-repayment/${id}`)
      displaySucessNotification('Reembolso apagado com sucesso! Sua página irá atualizar.')

      setTimeout(() => window.location.reload(true), 250)
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const handleEdit = async () => {
    try {
      await api.put(`/update-repayment/${id}`, { name })
      displaySucessNotification('Reembolso editado com sucesso! Sua página irá atualizar.')

      setTimeout(() => window.location.reload(true), 250)
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }

  }

  const dropdownMenu = (
    <Subcard>
      <ProductLink onClick={() => setEdit(true)}>Editar</ProductLink>
      <ProductLink onClick={() => handleDelete(id)}><span style={{ color: 'red' }}>Apagar</span></ProductLink>
    </Subcard>
  )

  return (
    <>
      <DataTableRow>
        {
          edit ? (
            <td>
              <Form ref={formRef} onSubmit={handleEdit}>
                <TableRow>
                  <InputGroup>
                    <InputMod type="text" name="name" onChange={(e: any) => setName(e.target.value)} placeholder="Valor do reembolso" value={name} />
                    <legend>Novo nome para o reembolso</legend>
                  </InputGroup>
                  <Button type="submit">✓</Button>
                </TableRow>
              </Form>
            </td>
          ) : (
              <>
                <DataTableCell>{name}</DataTableCell>
                <Dropdown overlay={dropdownMenu}>
                  <OptionsBtn>
                    <img className='' src={ThreeDotMenu} alt="" />
                  </OptionsBtn>
                </Dropdown>
              </>
            )
        }

      </DataTableRow>
    </>
  )
}

export default Repayment