import axios from 'axios'

const api = axios.create({
  baseURL: 'http://localhost:3003/', //ip do parpas
})

api.interceptors.request.use(config => {
  // const token = localStorage.getItem('@xgrow:jwt')
  const headers = { 'Content-type': 'multipart/form-data' }

  // if (token) {
  //   headers.Authorization = `Basic ${token}`
  // }

  return { ...config, headers }
})

export default api
