import styled from 'styled-components'

interface InputGroupProps {
  width?: string
}
export const Card = styled.div<InputGroupProps>`
  border: 1px solid #c1c1c1;
  border-radius: 0.625rem;
  background-color: #FFF;
  color: #5C5C5C;
  
  padding: 16px;
  height: 100%;
  margin-bottom: 16px;

`
export const TitleLot = styled.div`
  h4{
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: #5C5C5C;
  }
`
export const Loot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  div{
    margin-top: 30px;
    text-align: center;

    h5{
      font-size: 15px;
      color: #5C5C5C;
    }
    p{
      font-size: 15px;
    }
  }
`
export const ContainerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  img{
      width: 24px;
      height: 24px;
      cursor: pointer;
      margin-left: 10px;
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 1.5px;
  color: #5C5C5C;
  text-transform: uppercase;
  display: flex;
  align-items: center;

`
export const ValoresFlex = styled.div`
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.15px;
    color: #5C5C5C;
    padding-bottom: 30px;    
    display: flex;
    align-items: end;
    justify-content: space-between;
    height:148px;
    p{
        margin-top: 10px;
        margin-bottom: 0;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #A2A2A2;
    }
`
export const BlurComponent = styled.div<{ blur: boolean }>`
  filter: ${(props) => props.blur ? `blur(5px)` : `none`};
  -webkit-touch-callout: ${(props) => props.blur ? `none` : null}; /* iOS Safari */
  -webkit-user-select: ${(props) => props.blur ? `none` : null}; /* Safari */
  -khtml-user-select: ${(props) => props.blur ? `none` : null}; /* Konqueror HTML */
  -moz-user-select: ${(props) => props.blur ? `none` : null}; /* Old versions of Firefox */
  -ms-user-select: ${(props) => props.blur ? `none` : null}; /* Internet Explorer/Edge */
  user-select: ${(props) => props.blur ? `none` : null}; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`

export const ValoresBlock = styled.div`    
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.15px;
    color: #5C5C5C;
    display: block;
    justify-content: space-between;

    p{
        margin-top: 10px;
        margin-bottom: 30px;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #A2A2A2;
    }
    @media screen and (max-width: 1253px){
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 40%;
    margin: 0 auto;
    }
`


export const Buttonk = styled.button`
    border: 1px solid #111111;
    box-sizing: border-box;
    border-radius: 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #111111;
    background: transparent;
    min-width: 150px;
    min-height: 36px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s;
    outline: none;
    &:hover{
        border: 1px solid #5C5C5C;
        color: #5C5C5C; 
    }
`

export const ModalButton = styled.input`
  border: none;
  border-radius: 0.2125rem;
  padding: 0.625rem 1.5625rem;
  font-size: 0.9rem;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  margin: 0 0.6rem;
  transition: all .1s;
  text-align: center;
  border: 1px solid #E0E0E0;
  color: gray;
  background-color: white;

  &:hover {
    border-color: #292929;
    color: #292929;
  }  

  &&&.submit {
    background-color: var(--main);
    color: whitesmoke;

    &:hover {
      background-color: #92ce0f;
      color: #243302;
    }
  } 
`

export const SeeMore = styled.p`
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.25px;
    color: var(--main);
    cursor: pointer;
    transition: all 0.3s;
    padding-top: 10px;

    &:hover{
        color: var(--main); 
    }

    svg{
        padding-top: 5px;
        margin-left: 5px;
    }
`