import React from 'react'
import Champion from 'assets/icons/champion-icon.svg'
import { Container, Fill, Trophy } from './styles'

interface Props {
  amountFilled: number
  rewardAt?: number
}

const ProgressBar: React.FC<Props> = ({ amountFilled, rewardAt, ...rest }) => {
  return (
    <Container {...rest}>
      <Fill amount={amountFilled} />
      
      <Trophy >
        <img src={Champion} alt="trophy" />
      </Trophy>
        
    </Container>
  )
}

export default ProgressBar
