import styled from 'styled-components'

export const ConquestCard = styled.div`
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #C0C0C0;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #5C5C5C;
    height: 70px;
    padding-left: 20px;
    width: 800px;
    margin-bottom: 20px;
    img{
        height: 48px;
        width: 48px;
    }
    strong{
        padding-left: 20px;
        padding-right: 20px;
    }
`
export const NoSelect = styled.div`
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #5C5C5C;
    margin-bottom: 20px;
    label{
        padding-left:20px;
    }
`