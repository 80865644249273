import React, { useRef, useEffect } from 'react'
import { useField } from '@unform/core'
import { CheckContainer, StyledGroup, CheckboxCustom } from './style'

interface Props {
  name: string
  options: any
  defaultValue?: any
  disabled?: boolean
  checked?: boolean
  noBorder?: boolean
  onChange?: any
}

const Check: React.FC<Props> = ({
  name,
  options = [],
  defaultValue = [],
  disabled = false,
  checked,
  noBorder = false,
  onChange,
  ...rest
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([])
  const { fieldName, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.filter(ref => {
          return ref.checked 
        }).map(ref => {
          return ref.value
        })
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach(ref => {
          ref.checked = false
        })
      },
      setValue: (refs: HTMLInputElement[], values: any[]) => {
        refs.forEach((ref, index) => {
          if (values.includes(ref.id)) {
            ref.checked = true
          }          
        })
      },
    })
  }, [defaultValue, fieldName, registerField])

  return (
    <StyledGroup>
      {options?.map((option: any, index: number) => (
        <CheckContainer key={option.id} noBorder={noBorder}>       
          <CheckboxCustom htmlFor={option.name}>
            <input
              defaultChecked={defaultValue.find((dv: any) => option.id === parseInt(dv))}
              ref={ref => {
                inputRefs.current[index] = ref as HTMLInputElement
              }}
              checked={checked}
              value={option.id}
              onChange={onChange}
              type="checkbox"
              disabled={disabled}
              id={option.name}
              {...rest}
            />
            <span className="checkbox-custom"></span>
          </CheckboxCustom>
          <p>{option.name}</p>
        </CheckContainer>
      ))}
    </StyledGroup>
  )
}

export default Check