import React, { useEffect, useRef } from 'react'
import { useField } from '@unform/core'
import { Sociais } from './style'

interface Props {
    name: string
    imagem: string
}

type InputProps = JSX.IntrinsicElements['input'] & Props

const SocialMidia: React.FC<InputProps> = ({name, imagem, ...rest}) => {

    const inputRef = useRef<HTMLInputElement>(null)

    const { fieldName, defaultValue, registerField } = useField(name)

    useEffect(() => {
        registerField({
          name: fieldName,
          path: 'value',
          ref: inputRef.current,
        })
      }, [fieldName, registerField])

    return (
        <>
            <Sociais>
                <img src={imagem} alt="" />
                <input
                    id={fieldName}
                    ref={inputRef}
                    defaultValue={defaultValue}
                    {...rest}
                />
            </Sociais>
        </>
    )
}

export default SocialMidia