import styled from 'styled-components'

export const ProductCardContainer = styled.div`
  width: 300px;
  margin: 8px;
  border-radius: 0.625rem;
  
  color: white;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 425px){
    width: 100%;
  }
`

export const ProductCardImageContainer = styled.section`
  position: relative;
  display: flex;
`

type ImgProps = {
  image: string
}

const Image = styled.div<ImgProps>`
  width: 100%;
  height: 12.3rem;
  background: #e6e6e6;
  border-radius: 10px 10px 0 0;

  background-image: url('${props => props.image}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

export const ProductImg = styled(Image)``

const Badge = styled.div`
  border-radius: 1.875rem;
  color: white;
  background: #292929;
  z-index: 1;
`

export const CardImageFooter = styled.footer`
  position: absolute;
  top: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 5%;
`

export const ProductAvaliation = styled(Badge)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2px 5px;
  width: 55px;
`

export const ProductPrice = styled(Badge)`
  border-radius: 1.875rem;
  padding: 0.125rem 0.625rem;
  color: white;
  width: max-content;
`

export const ProductCardDetailsContainer = styled.section`
  padding: 0.9375rem 1.875rem;
  min-height: 175px;
  height: auto;
  background: #292929;
  border-radius: 0 0 0.625rem 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ProductName = styled.h1`
  font-size: 1rem;
  color: white;
  width: 100%;
  word-break: break-all;
`

export const ProductAuthor = styled.section`
  align-items: center;
  display: flex;
  width: 100%;
`

export const AuthorImage = styled(Image)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`

export const AuthorName = styled.p`
  width: max-content;
  font-size: 0.9rem;
  margin: 0;
`

export const ProductLink = styled.a`
  text-decoration: none;
  color: var(--main);
  font-size: 0.9rem;
  text-align: right;
  letter-spacing: 0.3px;

  &:hover {
    color: var(--main-highlight);
  }
`
