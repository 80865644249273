import styled from 'styled-components'

export const MainContainer = styled.main`
  display: flex;
  min-height: 40.405rem;
`

export const ContentContainer = styled.main`
  width: 100%;
  min-height: 620px;
  padding: 1.85875rem 2.269375rem;
  position: relative;
  box-shadow: 0.5625rem 0.3125rem 1.25rem #eeeeee, 0 0.125rem 0.3125rem #e4e4e4;
  border-radius: 10px;
`

export const Button = styled.button`
  border: none;
  outline: none;
  background: none;
  width: max-content;
  cursor: pointer;
`

export const ActionBtn = styled(Button)`
  color: var(--main);
  font-weight: 500;
  display: inline-flex;
  justify-content: space-between;

  &.next-btn,
  &.done-btn {
    position: absolute;
  }

  &.next-btn {
    bottom: 0;
    right: 3.5625rem;
  }

  &.done-btn {
    bottom: 0.4rem;
    right: 5.25rem;
  }
`

export const ModalFooter = styled.div`
  margin-top: 3rem;
  margin-left: 20%;
  width: max-content;
`

export const ModalButton = styled.button`
  outline: none;
  width: max-content;
  min-width: 10.875rem;
  cursor: pointer;

  border-radius: 0.2125rem;
  padding: 0.625rem 1.5625rem;
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0 0.6rem;
  transition: all 0.1s;
  text-align: center;
  border: 1px solid #e0e0e0;
  color: gray;
  background-color: white;

  &&&[type='button']:hover {
    border-color: #292929;
    color: #292929;
  }

  &&&.submit {
    background-color: var(--main);
    color: whitesmoke;

    &:hover {
      background-color: #92ce0f;
      color: #243302;
    }

    &:disabled {
      opacity: 0.4;
      color: #243302;
    }

    &:disabled:hover {
      background-color: var(--main);
      color: whitesmoke;
    }
  }
`

export const ContentFooter = styled.footer`
  width: 100%;
  display: flex;
  height: 1.875rem;
  justify-content: space-between;
  align-items: center;
  margin: 3rem 0 0;
  position: absolute;
  bottom: 0.625rem;
`

export const Loading = styled.div`
  background: #c33;
  color: white;
  display: flex;
  justify-content: center;
`
