/*
Aqui fica a página principal para visualizar os produtos existentes,
tanto como produtor, como aluno (vise o operador ternário).
*/

import { CircularProgress } from '@material-ui/core/'
import ShopingCartIcon from 'assets/icons/shoping-cart-icon.svg'
import StarIcon from 'assets/icons/star-icon.svg'
import StudentIcon from 'assets/icons/student-icon.svg'
import VideocamIcon from 'assets/icons/videocam-icon.svg'
import HeaderPromotor from 'components/HeaderPromotor'
import Course from 'components/new/Course'
import Product from 'components/new/Product'
import Search from 'components/new/Search'
import Footer from 'containers/Footer'
import { Container } from 'GlobalStyles'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import api from 'services/api'
import {
  GoToBtn, Header,









  IconDiv, NewProductBtn,FormEdit,



  NoProductMsg,
  ProductList, SearchContainer,
  SmallIcon,



  StyledPagination, Subheader,



  Title
} from './style'

const Products = () => {
  const [products, setProducts] = useState<any>(null)
  const [prodProducts, setProdProducts] = useState<any>(null)
  const [typeProd, setTypeProd] = useState<any>(0) // 0 é para os adquiridos, 1 é para os comprados
  const [typeUser, setTypeUser] = useState<any>()
  const [loading, setLoading] = useState<Boolean>(false)
  const [, setErr] = useState<string>('Você não tem nenhum produto cadastrado :/',)
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(0)
  const [totalProducts, setTotalProducts] = useState<number>(0)
  const [rating, setRating] = useState<string>('-')
  const [hours, setHours] = useState<number>(0)
  const [totalStudent, setStudent] = useState<number>(0)
  const [totalSellers, setTotalSellers] = useState<number>(0)
  let history = useHistory()

  const getProducts = useCallback(async () => {
    setLoading(true)
    let response = await api.get('profile')
    setTypeUser(response.data.user.type_user)

    if (response.data.user.type_user === 'student') {
      let response = await api.get('/list-all-by-student-products')
      setProducts(response.data.records)
      setPageNumber(response.data.page_index)
      setPageSize(response.data.page_size)
      setTotalProducts(response.data.total_records)
      setLoading(false)
    } else {
      await api.get('/list-all-by-student-products')
      .then((res)=>{
        setProdProducts(res.data.records)
      })
      let { data } = await api.get('/list-all-by-producer-products', {
        params: { page_index: pageNumber },
      })
      let values = await api.get('/get-resume-products')
      setRating(values.data.avaliation.toFixed(2))
      setHours(values.data.time_video)
      setStudent(values.data.total_students)
      setTotalSellers(values.data.total_sells)
      let response = await api.get('profile')
      setTypeUser(response.data.user.type_user)
      setProducts(data.records)
      setPageNumber(data.page_index)
      setPageSize(data.page_size)
      setTotalProducts(data.total_records)
      setLoading(false)
      
      
    }
  }, [pageNumber])

  const handleTypeProd = (type: number)=>{
    setTypeProd(type)
  }
  const search = async (v: any) => {
    if (v.length < 1) {
      getProducts()
    }
    if (v.length >= 2) {
      if (typeUser === 'student') {
        setLoading(true)
        try {
          let { data } = await api.get(
            `/list-all-by-student-products?name=${v}`,
          )
          setLoading(false)
          setProducts(data.records)
          setErr('Você não tem nenhum produto cadastrado com esse nome.')
        } catch (error) {
          setErr('Tente novamente')
          setLoading(false)
        }
      } else {
        setLoading(true)
        try {
          let { data } = await api.get(
            `/list-all-by-producer-products?name=${v}`,
          )
          setLoading(false)
          setProducts(data.records)
          setErr('Você não tem nenhum produto cadastrado com esse nome.')
        } catch (error) {
          setErr('Tente novamente')
          setLoading(false)
        }
      }
    }
  }

  const validateSale = async () => {
    history.push('/products/new')
  }

  const handlePageNumber = (page: number) => {
    setPageNumber(page - 1)
  }

  useEffect(() => {
    getProducts()
    sessionStorage.clear()
    console.log(typeProd)
  }, [getProducts, pageNumber, typeProd])

  return (
    <>
      <Container>
        <HeaderPromotor SearchHeader />

        <Title>Produtos</Title>

        <Header>
          {typeUser === 'student' ? null : (
            <NewProductBtn onClick={() => validateSale()}>
              <span>+</span>
              Novo produto
            </NewProductBtn>
          )}
          <SearchContainer>
            <Search
              placeholder="Busque pelo nome do produto"
              handleSearch={(e: any) => search(e.target.value)}
            />
          </SearchContainer>
          
        </Header>

        <Subheader>
          {typeUser === 'student' ? (
            <h1>{totalProducts} Produto(s) comprado(s)</h1>
          ) : (
              <>
                <h1>{totalProducts} Produto(s) Cadastrado(s)</h1>
                <IconDiv>
                <section>
                    <SmallIcon src={StudentIcon} />
                    <p>{totalStudent}</p>
                  </section>

                  <section>
                    <SmallIcon src={ShopingCartIcon} />
                    <p>{totalSellers}</p>
                  </section>

                  <section>
                    <SmallIcon src={VideocamIcon} />
                    <p>{hours}</p>
                  </section>

                  <section>
                    <SmallIcon src={StarIcon} />
                    <p>{rating}</p>
                  </section>
                </IconDiv>
              </>
            )}
             
        </Subheader>
        {typeUser==='producer' || typeUser==='superadm'?
          <FormEdit>
            <div>
              <input type="radio" id="autoria" value="autoria" name="autoria" onClickCapture={(e: any) => handleTypeProd(0)} checked={typeProd===0? true : false} />
              <label htmlFor="autoria">Minha autoria</label>
            </div>
            <div>
              <input type="radio" id="comprados" value="comprados" name="typeProd" onClickCapture={(e: any) => handleTypeProd(1)} checked={typeProd===1? true : false} />
              <label htmlFor="comprados">Comprados</label>
            </div>
          </FormEdit>
          :
          null
          }

        <ProductList>
          {loading ? (
            <CircularProgress color="inherit" style={{ margin: '0 50%' }} />
          ) : (
              <>
                {typeUser === 'student' ? (
                  <>
                    {!products ? (
                      <>
                        <NoProductMsg>
                          Você não comprou nenhum produto ainda :/
                    </NoProductMsg>
                        <GoToBtn to='/'>
                          <span>+</span>
                      Adquira um produto
                    </GoToBtn>
                      </>

                    ) : (
                        <Course product={products} />
                      )}
                  </>
                ) : (
                    <>
                        
                        {typeProd===0?
                          !products ? 
                            <>
                            <Title>Seus Produtos</Title>
                              <NoProductMsg>
                                Você não criou nenhum produto ainda :/
                              </NoProductMsg>
                              <NewProductBtn onClick={() => validateSale()}>
                                <span>+</span>
                                Criar produto
                              </NewProductBtn>
                            </>
                          : 
                          <>
                          <Title>Seus Produtos</Title>
                            {products &&
                              products.map((product: any) => (
                                <Product product={product} key={product.product_id} />
                              )) }
                          </>
                          : null
                          }
                        
                        
                        {typeProd===1?
                          !prodProducts ? (
                          <>
                          <Title>Produtos adquiridos</Title>
                            <NoProductMsg>
                              Você não comprou nenhum produto ainda :/
                            </NoProductMsg>
                            <GoToBtn to='/market'>
                              <span>+</span>
                              Adquira um produto
                            </GoToBtn>
                            </>

                          ):
                          <>
                            <Title>Produtos adquiridos</Title>
                            <Course product={prodProducts} />
                          </>
                          : null
                            // <Course product={prodProducts} />
                          
                            
                          }
                    </>
                  )}
              </>
            )}

          {products && typeProd===0?
          totalProducts > pageSize && (
            <StyledPagination
              defaultCurrent={pageNumber + 1}
              total={totalProducts}
              onChange={handlePageNumber}
            />
          ):
          null
          }
          {prodProducts && typeProd===1?
          totalProducts > pageSize && (
            <StyledPagination
              defaultCurrent={pageNumber + 1}
              total={totalProducts}
              onChange={handlePageNumber}
            />
          ):
          null
          }
        </ProductList>
      </Container>
      <Footer />
    </>
  )
}

export default Products
