import styled from 'styled-components'

// TODO: Se isso aqui é só css não é um component

export const Card = styled.div`
  border-radius: 0.625rem;
  background-color: #FFF;
  color: #5C5C5C;
  box-shadow: 0.5625rem 0.3125rem 1.25rem #EEEEEE, 0 0.125rem 0.3125rem #E4E4E4;
  padding: 3%;
`

export const CardTitle = styled.h1`
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 1rem;
  margin-bottom: 1.7756rem;

  letter-spacing: 0.09375rem;
  text-transform: uppercase;

  color: #5C5C5C;
`