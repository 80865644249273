import React from 'react'
import {
  Main,
  ThanksCard,
  ThanksContent,
  ThanksIcon,
  ThanksText,
} from './style'
import ConcludedIcon from '../../../assets/icons/concluded-icon.svg'
import OrganicInsta from './OrganicInsta'
import Button from '../../../components/Button'
import Step from '../../../components/CheckoutSteps'


const ThankYou = () => {
  return (
    <div style={{ width: '100%' }}>
      <Main>
        <Step Step3={"Active"} />
        <ThanksCard>
          <ThanksContent>
            <ThanksIcon src={ConcludedIcon} alt="" />
            <ThanksText>Sua compra foi realizada com sucesso!</ThanksText>
            <Button>Concluir</Button>
          </ThanksContent>
        </ThanksCard>
      </Main>

      <OrganicInsta />
    </div>
  )
}

export default ThankYou
