import { CircularProgress } from '@material-ui/core/'
import { Dropdown, Modal } from 'antd'
import ThreeDotMenu from 'assets/icons/3dotmenu-icon.svg'
import React, { useEffect, useState } from 'react'
import {
  ProductCard,
  ProductImg,
  Content,
  ContentTitle,
  ContentCenter,
  Icon,
  Value,
  Label,
  Link,
  FlexContainer,
  Active,
  Subcard,
  ProductLink,
  ModalButton,
  DeleteProductMsg,
  PLink,
  OptionsBtn,
  ActivatedOrDeactivated,
} from './style'
import history from 'routes/history'
import StudentIcon from 'assets/icons/student-icon.svg'
import ProductIcon from 'assets/icons/index.svg'
import ShopingCartIcon from 'assets/icons/shoping-cart-icon.svg'
import StarIcon from 'assets/icons/star-icon.svg'
import VideoCamIcon from 'assets/icons/videocam-icon.svg'
import ArrowIcon from 'components/new/ArrowIcon'
import api from 'services/api'
import {
  displayErrorNotification,
  displaySucessNotification
} from 'utils/displayNotification'


interface Product {
  product_id: number
  photo: any
  name: string
  currentstatus: string
  is_active: boolean
  time_video: any
  avaliation: any
  total_sells: any
  total_students: any
}

interface Props {
  product: Product
}

const Product: React.FC<Props> = ({ product }) => {
  const {
    product_id,
    photo,
    name,
    currentstatus,
    is_active,
    time_video,
    avaliation,
    total_sells,
    total_students,
  } = product
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(
    false,
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [category, setCategory] = useState('')
  const [variation, setVariation] = useState('')


  const handleEdit = async (product_id: number) => {
    history.push('/products/edit', { product_id: product_id })
  }

  const handleConfirmDelete = () => {
    setShowConfirmDeleteModal(!showConfirmDeleteModal)
  }

  const handleDelete = async (product_id: number) => {
    setLoading(true)

    try {
      await api.delete(`/remove-product/${product_id}`)

      displaySucessNotification(
        'Produto deletado com sucesso! Sua página irá atualizar.',
      )
      setTimeout(() => window.location.reload(true), 250)
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }

    setLoading(false)
    handleConfirmDelete()
  }

  const handleActive = async (product_id: number) => {
    if (currentstatus === 'APROVADO') {
      try {
        await api.put(`active-product/${product_id}`)

        displaySucessNotification(
          'Produto ativado com sucesso!',
        )
        setTimeout(() => window.location.reload(true), 250)
      } catch (err) {
        displayErrorNotification(err.response.data.message)
      }
    } else {
      displayErrorNotification('Seu produto ainda não foi analisado pela nossa equipe.')
    }
  }

  const handleDeactivate = async (product_id: number) => {
    try {
      await api.put(`inactivate-product/${product_id}`)

      displaySucessNotification(
        'Produto desativado com sucesso!',
      )

      setTimeout(() => window.location.reload(true), 250)
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const viewCourse = (product_id: any) => {
    sessionStorage.clear()
    sessionStorage.setItem('course', product_id)
  }

  useEffect(() => {
    const getCategory = async () => {
      let info = ''
      let res = await api.get(`/get-product/${product_id}`)
      setCategory(res.data.category.name + '/' + res.data.sub_category.name)

      /* eslint-disable */
      res.data.modules.map((module: any) => {
        info += module.name + ': '
        module.classes.map((c: any) => {
          info += c.description.toUpperCase() + '/ '
        }) 
      }) 
      /* eslint-enable */

      setVariation(info.trim().slice(0, -1))
    }
    getCategory()
  }, [product_id])

  const dropdownMenu = (
    <Subcard>
      {currentstatus === 'EM ANÁLISE' ? (
        <>
          <ProductLink disabled>
            Esperando análise
        </ProductLink>
        </>

      ) : currentstatus === 'APROVADO' ? (
        is_active ? (
          <>
            <ProductLink onClick={() => handleDeactivate(product_id)}>
              Desativar produto
          </ProductLink>
          </>
        ) : (
            <>
              <ProductLink onClick={() => handleActive(product_id)}>
                Ativar produto
          </ProductLink>
            </>
          )



      ) : (
            <></>
          )
      }

      {currentstatus === 'APROVADO' ? (
        <ProductLink
          target="_blank"
          href={`https://checkout.skycode.com.br/?product_id=${product_id}`}
        >
          Link do produto
        </ProductLink>
      ) : (
          <ProductLink disabled target="_blank">
            Link do produto
          </ProductLink>
        )}
      <ProductLink onClick={() => handleEdit(product_id)}>Editar produto</ProductLink>
      <PLink onClick={() => viewCourse(product_id)} href="/course">
        Visualizar produto
      </PLink>
      <ProductLink danger onClick={handleConfirmDelete}>
        Deletar produto
      </ProductLink>

      <Modal
        title={'Deletar produto'}
        onCancel={handleConfirmDelete}
        closable={true}
        visible={showConfirmDeleteModal}
        footer={[
          <ModalButton type="button" onClick={handleConfirmDelete}>
            Cancelar
          </ModalButton>,
          <ModalButton
            danger
            onClick={() => handleDelete(product_id)}
            className="submit"
            type="submit"
          >
            {loading ? (
              <CircularProgress color="inherit" size={20} thickness={5} />
            ) : (
                'Deletar produto'
              )}
          </ModalButton>,
        ]}
      >
        <DeleteProductMsg>
          Tem certeza que deseja deletar esse produto? (Esta ação não pode ser
          desfeita!)
        </DeleteProductMsg>
      </Modal>
    </Subcard>
  )

  return (
    <ProductCard>
      <ProductImg src={photo} />

      <Content>
        <ContentTitle>
          <h1>{name}</h1>
          <h2>Categoria: {category}</h2>
          {variation ? <h2>Detalhes: {variation}</h2> : null}
        </ContentTitle>

        <ContentCenter>
          <section>
            <FlexContainer>
              <Icon src={ProductIcon} />
              <Value>{product_id}</Value>
            </FlexContainer>

            <Label>ID</Label>
          </section>
          
          <section>
            <FlexContainer>
              <Icon src={StudentIcon} />
              <Value>{total_students}</Value>
            </FlexContainer>

            <Link to="/student-producer">
              Alunos
              <ArrowIcon rotateDeg={-90} color="var(--main)" />
            </Link>
          </section>

          <section>
            <FlexContainer>
              <Icon src={ShopingCartIcon} />
              <Value>{total_sells}</Value>
            </FlexContainer>

            <Link to="/financial">
              Vendas
              <ArrowIcon rotateDeg={-90} color="var(--main)" />
            </Link>
          </section>

          <section>
            <FlexContainer>
              <Icon src={VideoCamIcon} style={{ width: '1.265625rem' }} />
              <Value>{time_video}</Value>
            </FlexContainer>

            <Label>de vídeo</Label>
          </section>

          <section>
            <FlexContainer>
              <Icon src={StarIcon} />
              <Value>{avaliation}</Value>
            </FlexContainer>

            <Label>Avaliação</Label>
          </section>

          <section>
            <Active currentstatus={currentstatus}>{currentstatus}</Active>
            <ActivatedOrDeactivated is_active={is_active} onClick={() => { is_active ? handleDeactivate(product_id) : handleActive(product_id) }} title={`Clique para ${is_active ? `desativar` : `ativar`} o produto`}> {is_active ? 'Produto ativo' : 'Produto desativado'}</ActivatedOrDeactivated>
          </section>
        </ContentCenter>

      </Content>
      <Dropdown overlay={dropdownMenu}>
        <OptionsBtn>
          <img
            src={ThreeDotMenu}
            alt=""
          />
        </OptionsBtn>
      </Dropdown>
    </ProductCard>
  )
}

export default Product
