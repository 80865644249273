/*
2º Passo da edição de produto:
Aqui fica os passos de: preço, tipo da moeda, adição de co-produtor, prazo de reembolso,
formas de pagamento, opção de ativar o parcelamento máximo disponível e a opção de repassar
os juros para o cliente (caso tenha).
*/

import React, { useState, useEffect, useRef, useContext } from 'react'
import { SubmitHandler } from '@unform/core'
import HeaderNewProduct from 'components/new/HeaderNewProduct'
import StyledSwitch from 'components/new/Switch'
import Radios from 'components/new/Input/Radio'
import Input from 'components/new/Input'
import CurrencyInput from 'components/new/Input/CurrencyInput'
import Check from 'components/new/Input/Checkbox'
import api from 'services/api'
import { Skeleton } from 'antd'
import {
  ContentFooter,
  ActionBtn,
  MainContainer,
  ContentContainer,
} from '../shared-styles'
import {
  FormContainer,
  InputContainer,
  SwitchContainer,
  Flex,
  NoCoProducer,
} from './style'
import ArrowIcon from 'components/ArrowIcon'
import { StepContext } from '../../../StepContext'
import { EditProductContext } from '../../EditProductContext'
import CoProducerRegister from './CoProducerRegister'
import CoProducerList from './CoProducerList'
import * as Yup from 'yup'
import displayYupErrorNotification from 'utils/displayYupErrorNotification'

interface FormData {
  currency: string
  repayments: string
  payments: any[]
  installments: any[]
  value: number
  interestToClient: boolean
  co_producers: any[]
}

const PriceInfo: React.FC = () => {
  const { handlePreview, handleNext } = useContext(StepContext)
  const [editProductData, setEditProductData] = useContext(EditProductContext)

  const [currencies, setCurrencies] = useState<any>(null)
  const getCurrencies = async () => {
    let { data } = await api.get('/currencies')
    setCurrencies(data)
  }

  const [repayments, setRepayments] = useState<any>(null)
  const getRepayments = async () => {
    let { data } = await api.get('/repayments')
    setRepayments(data)
  }

  const [paymentMethods, setPaymentMethods] = useState<any>(null)
  const getPaymentMethods = async () => {
    let { data } = await api.get('/payments-methods')
    setPaymentMethods(data)
  }

  const [installments, setInstallments] = useState<any>(null)
  const getInstallments = async (value?: number) => {
    let { data } = await api.get(`/installments/${value}`)
    setInstallments(data)
  }

  const [interestToClient, setInterestToClient] = useState<boolean>(false)
  const handleInterest = () => {
    setInterestToClient(!interestToClient)
    setEditProductData({
      ...editProductData,
      interest_to_client: !editProductData.interest_to_client,
    })
  }

  const [hasInstallment, setHasInstallment] = useState<boolean>(false)
  const handleHasInstallment = () => {
    setHasInstallment(!hasInstallment)
    setEditProductData({
      ...editProductData,
      hasInstallment: hasInstallment,
    })
  }

  const [interestAndInstallment, setInterestAndInstallment] = useState(false)
  const handleInterestAndInstallment = (evt: any) => {
    if (evt.target.id === 'Cartão de crédito') {
      setInterestAndInstallment(!interestAndInstallment)
      setHasInstallment(false)
      setEditProductData({
        ...editProductData,
        interest_to_client: false,
      })
    }

    return
  }

  const [moneyValue, setMoneyValue] = useState(0)
  const handleProductValue = (value: number) => {
    let convertedValue = value / 100

    getInstallments(convertedValue)

    setMoneyValue(value)
  }

  const handleCurrencySymbol = (evt: any) => {
    let currency = evt.target.id

    switch (currency) {
      case 'DOLAR':
        setEditProductData({
          ...editProductData,
          // value: convertProductValue(editProductData.value, 'USD'),
          currencySymbol: 'USD',
        })
        return
      case 'EURO':
        setEditProductData({
          ...editProductData,
          currencySymbol: 'EUR',
        })
        return
      case 'LIBRA':
        setEditProductData({
          ...editProductData,
          currencySymbol: 'GBP',
        })
        return
      default:
        setEditProductData({
          ...editProductData,
          currencySymbol: 'BRL',
        })
        return
    }
  }

  const handleCoProducerDelete = (id: number) => {
    let newCoProducers = editProductData.co_producers.filter(
      (co_producer: any) => co_producer.id !== id,
    )

    setEditProductData({
      ...editProductData,
      co_producers: newCoProducers,
    })
  }

  const [userOptions, setUserOptions] = useState<any>({
    currencies: '',
    repayments: '',
    payments: [],
  })

  const setLocalData = () => {
    setUserOptions({
      currencies: editProductData.currency.id.toString(),
      repayments: editProductData.repayment.id.toString(),
      payments: editProductData.payments.map((payment: any) =>
        payment.payment_method.toString(),
      ),
    })

    let credCard = editProductData.payments.find(
      (pay: any) => pay.payment_method === 1,
    )

    if (!credCard) setInterestAndInstallment(true)

    if (editProductData.installments.length > 0) {
      handleHasInstallment()
    }

    setInterestToClient(editProductData.interest_to_client)
    setMoneyValue(editProductData.value * 100)
  }

  useEffect(() => {
    setLocalData()

    getCurrencies()
    getRepayments()
    getPaymentMethods()
    getInstallments(editProductData.value || 0)
  },[])

  const formRef = useRef<SubmitHandler>(null)

  const handleSubmit: SubmitHandler<FormData> = async data => {
    try {
      const schema = Yup.object().shape({
        currency: Yup.string().required(),
        repayment: Yup.string(),
        payments: Yup.array()
          .of(Yup.string())
          .required('Escolha os tipos de pagamento.'),
        value: Yup.number()
          .min(1, 'Valor mínimo de 1,00.')
          .required('Adicione um valor ao seu produto'),

        interestToClient: Yup.boolean(),
      })

      let dataWithValue = {
        ...data,
        value: moneyValue / 100,
      }

      await schema.validate(dataWithValue, {
        abortEarly: false,
      })

      let co_producer = editProductData.co_producers[0]
        ? {
            co_producer_email:
              editProductData.co_producers[0].co_producer_email,
            contract: { id: editProductData.co_producers[0].contract.id },
            comission: editProductData.co_producers[0].comission,
            comission_percentage:
              editProductData.co_producers[0].comission_percentage,
          }
        : null

      let newData = {
        currency: { id: parseInt(data.currency) },
        repayment: { id: parseInt(data.repayments) },
        payments: data.payments.map((payment: any) => ({
          payment_method: parseInt(payment),
        })),
        installments: hasInstallment
          ? [{ installment: parseInt(data.installments[0]) }]
          : [],
        value: moneyValue / 100,
        interest_to_client: editProductData.interest_to_client,
        co_producers: co_producer ? [co_producer] : [],
      }

      setEditProductData({
        ...editProductData,
        finalJSON: {
          ...editProductData.finalJSON,
          ...newData,
        },
      })
      handleNext()
    } catch (err) {
      displayYupErrorNotification(err)
    }
  }

  return (
    <MainContainer>
      <ContentContainer>
        <HeaderNewProduct
          title="2 - Informações de Preço"
          subtitle="Defina a precificação e formas de pagamento."
        />

        <FormContainer ref={formRef} onSubmit={handleSubmit}>
          <Flex>
            <Input title={'Preço do Produto'} width={'200px'}>
              <CurrencyInput
                value={moneyValue}
                currency={editProductData.currencySymbol}
                onValueChange={handleProductValue}
              />
            </Input>            
          </Flex>

          <InputContainer>
            <h1>Tipo de Moeda</h1>
            <section className="content">
              {!currencies ? (
                <Skeleton.Button active />
              ) : (
                <Radios
                  name="currency"
                  options={currencies}
                  defaultValue={userOptions.currencies}
                  onChange={handleCurrencySymbol}
                />
              )}
            </section>
          </InputContainer>

          <InputContainer>
            <div style={{ display: "flex", alignItems: 'baseline', justifyContent: 'space-between' }}>
              <h1>Co-produtor</h1>
              <CoProducerRegister />
            </div>
            
            <section className="content">
              {editProductData.co_producers &&
              editProductData.co_producers.length === 0 ? (
                <NoCoProducer>Nenhum co-produtor cadastrado</NoCoProducer>
              ) : (
                <CoProducerList
                  onDelete={handleCoProducerDelete}
                  co_producers={editProductData.co_producers}
                />
              )}
            </section>
          </InputContainer>

          <InputContainer>
            <h1>Prazo de Reembolso</h1>
            <section className="content">
              {!repayments ? (
                <Skeleton.Button active />
              ) : (
                <Radios
                  name="repayments"
                  options={repayments}
                  defaultValue={userOptions.repayments}
                />
              )}
            </section>
          </InputContainer>

          <InputContainer>
            <h1>Formas de Pagamento</h1>
            <section className="content">
              {!paymentMethods ? (
                <Skeleton.Button active />
              ) : (
                <Check
                  name="payments"
                  options={paymentMethods}
                  defaultValue={userOptions.payments}
                  onChange={handleInterestAndInstallment}
                />
              )}
            </section>
          </InputContainer>

          <InputContainer>
            <SwitchContainer>
              <StyledSwitch
                onChange={handleHasInstallment}
                checked={hasInstallment}
                disabled={interestAndInstallment}
              />
              <p>Parcelamento máximo disponível</p>
            </SwitchContainer>

            <section className="content">
              {!installments ? (
                <Skeleton.Button active />
              ) : (
                <Check
                  name="installments"
                  options={installments}
                  checked={hasInstallment}
                  disabled={!hasInstallment}
                />
              )}
            </section>
          </InputContainer>

          <SwitchContainer>
            <StyledSwitch
              onChange={handleInterest}
              checked={editProductData.interest_to_client}
              disabled={interestAndInstallment}
            />
            <p>Repassar juros para o cliente, se houver?</p>
          </SwitchContainer>

          <ContentFooter>
            <ActionBtn onClick={handlePreview}>
              <ArrowIcon rotateDeg={90} color="var(--main)" />
              <p>Voltar</p>
            </ActionBtn>

            <ActionBtn className="next-btn" type="submit">
              <p>Próximo</p>
              <ArrowIcon rotateDeg={-90} color="var(--main)" />
            </ActionBtn>
          </ContentFooter>
        </FormContainer>
      </ContentContainer>
    </MainContainer>
  )
}

export default PriceInfo
