import styled from 'styled-components'
import { Button } from '../../shared-styles'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const DownloadBtn = styled(Button)`
  padding: 10px 25px;
  border: 1px solid #777;
  color: #777;
  border-radius: 5px;
  margin-top: 2rem;

  &:hover {
    color: #292929;
    border-color: #292929;
  }
`

export const Time = styled.p`
  margin: 1rem 0;
`

export const Description = styled.p`
  margin: 2rem 0;
  font-size: 1rem;
  width: max-content;
  text-align: center;
`
