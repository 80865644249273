import styled from 'styled-components'

export const CoProducerForm = styled.div``

export const CoProducerBtn = styled.button`
  width: max-content;
  outline: none;
  border: solid 1px;
  border-radius: 50px;
  padding: 12px;
  background: none;
  font-weight: bold;
  font-size: 0.9rem;
  color: var(--main);
  cursor: pointer;
`

export const InputContainer = styled.div`
  h1 {
    font-size: 0.9rem;
    letter-spacing: 0.03125rem;
    color: #292929;
  }
  
  .content > div:first-child {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(24%,1fr));
    grid-gap: 0.85rem;    
  }
  .content>input{
    
  }
`

export const Button = styled.input`
  border: none;
  border-radius: 0.2125rem;
  padding: 0.625rem 1.5625rem;
  font-size: 0.9rem;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  margin: 0 0.6rem;
  transition: all .1s;
  text-align: center;

  &&&.cancel {
    border: 1px solid #E0E0E0;
    color: gray;
    background-color: white;

    &:hover {
      border-color: #292929;
      color: #292929;
    }
  }

  &&&.submit {
    background-color: var(--main);
    color: whitesmoke;

    &:hover {
      background-color: #92ce0f;
      color: #243302;
    }
  } 
`