import styled from 'styled-components'
import {Card} from 'components/CardContainer'

export const CardContainer = styled(Card)`
    margin-top: 40px;
    width: 100%;
    padding: 40px;
    margin-bottom: 40px;
`
export const HeaderConquest = styled.div`
    display: flex;
    Resultados da pesquisa
    align-items: center;
    justify-content: space-between;
`
export const Title = styled.p`
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #5C5C5C;
    padding-top:20px;
`
export const SelectAll = styled.button`
    height: 30px;
    outline: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--main);
    background: transparent;
    border: none;
    transition: all 0.3s;
    cursor: pointer;
    &:hover{
        color: grey;
    }
`
export const SubTitle = styled.div`
    margin-top: 10px;
    width:100%;
    text-align:left;
    color: #898989;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;

    p{
        margin: 0;
        cursor: pointer;
    }
`
export const ContainerConquest = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
`