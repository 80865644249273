import { FormHandles, SubmitHandler } from '@unform/core'
import React, { useRef, useState } from 'react'
import * as Yup from 'yup'
import Form from 'components/new/Form'
import Input from '../../../components/Form/Input'
import { InputGroup } from '../../../components/Form/Input/style'
import api from '../../../services/api'
import { displayErrorNotification, displaySucessNotification } from 'utils/displayNotification'
import { Button, InputBtn } from './style'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'

interface Props {
  category_id: string
}

const NewSubCategory: React.FC<Props> = ({ category_id }) => {
  const [loading, setLoading] = useState(false)
  const [subCategory, setSubCategory] = useState<string>('')
  const [isActive] = useState<boolean>(true)

  const formRef = useRef<FormHandles>(null)

  const handleSubmit: SubmitHandler<FormData> = async data => {
    setLoading(true)
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('O nome da sub categoria é obrigatório.'),
      })

      await schema.validate(data, {
        abortEarly: false,
      })

      let obj = {
        name: subCategory,
        is_active: isActive,
        category: category_id,
      }

      await api.post('/subcategories', obj)
      displaySucessNotification('Sub categoria criada com sucesso!')
      setTimeout(() => window.location.reload(true))
    } catch (err) {
      console.log(err)
      displayErrorNotification(err.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <InputBtn>
      <TableContainer>
        <TableRow>
          <InputGroup>
            <Input type="text" name="name" onChange={(e: any) => {setSubCategory(e.target.value)}} placeholder="Nova subcategoria" />
            <legend>Subcategoria</legend>
          </InputGroup>
        </TableRow>

      </TableContainer>

      <Button>
        Cadastrar
      </Button>
      </InputBtn>
    </Form>
  )
}

export default NewSubCategory