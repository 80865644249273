import styled from 'styled-components'

interface InputGroupProps {
  width?: string
}

export const InputGroup = styled.fieldset<InputGroupProps>`
  position: relative;
  width: ${props => props.width ? props.width : '100%'};
  border-radius: 2.8125rem;
  padding: 0;
  border: 1px solid #A2A2A2;
  display: flex;
  color: white;
  margin-bottom: 4px;

  & > input:focus ~ legend {
    color: var(--main);
  }

  legend {
    margin-left: 1rem;
    padding: 0 0.1875rem;
    font-size: 0.82rem;
    letter-spacing: 0.4px;
    color: #5C5C5C;
    transition: color 0.2s;
    width: max-content;
  }   

  svg {
    color: #656565;
    position: absolute;
    left: 0.9375rem;
  }

  span {
    position: absolute;
    bottom: -1.4375rem;
    right: 1rem;
    color: #EF6545;
  }
  input{
    box-shadow: none;
    color: #689df6;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: white;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }
`

export const StyledInput = styled.input<any>`
  border-radius: inherit;
  width: 90%;
  padding-bottom: 1rem;
  padding-left: 2.8625rem;
  font-size: 1rem;
  outline: none;
  border: none;
  background: none;

  &::placeholder {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.015625rem;
    color: #A2A2A2;
  }  

`