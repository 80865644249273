import React, { useState, useEffect } from 'react'

import { Container, User, Comments, ContainerForm, ListMore } from './styles'
import api from 'services/api'
import { displayErrorNotification, displaySucessNotification } from 'utils/displayNotification'
import { CircularProgress } from '@material-ui/core/'
import altImg from 'assets/perfil.svg'

interface Props {
  idClass?: any
}
const Comment: React.FC<Props> = ({ idClass }) => {
  const [text, setText] = useState<any>()
  const [coments, setComents] = useState<any>()
  const [loading, setLoading] = useState<any>(false)

  const listComment = async (v: any) => {
    if (v) {
      let value = v.toString()
      let response = await api.get(`get-comments/${value}`)
      setComents(response.data.comments)
    }

  }

  const commenting = async () => {
    setLoading(true)
    let value = {
      class_id: idClass,
      comment: text
    }
    try {
      await api.post('/course-comment', value)
      listComment(idClass)
      displaySucessNotification('Comentário feito com sucesso!')
      setLoading(false)
    } catch (error) {
      displayErrorNotification('Erro ao criar o comentario!')
      setLoading(false)
    } finally {
      setText('')
    }
  }

  useEffect(() => {
    listComment(idClass)

  }, [idClass])

  return (
    <>
      <Container>
        {coments && coments.length > 0 ?
          <>
            {loading === true
              ?
              <CircularProgress color="inherit" style={{ margin: '0 50%' }} />
              :
              <>
                {coments && coments.map((item: any) => (
                  <>
                    <User>
                      <img src={item.student_photo? item.student_photo : altImg} alt='img' />
                      <h2>{item.student_name}</h2>
                    </User>
                    <Comments>
                      {item.comment}
                      <h6>Comentado dia: {(new Date(item.date)).toLocaleString('pt-BR')}</h6>
                    </Comments>

                  </>

                ))}
              </>
            }
          </>



          :
          <h1>Sem comentarios</h1>
        }
      </Container>
      {/* {coments && coments.length > 3 ?
      <ListMore>
          Listar mais comentarios
      </ListMore>
        :
        null
      } */}
      <ContainerForm>
        <h3>
          Comentar
        </h3>
        <textarea name="coments" placeholder="Escreva seu comentario" value={text} maxLength={500} onChange={(e: any) => setText(e.target.value)} />
        <button onClick={() => commenting()}>Comentar</button>
      </ContainerForm>
    </>
  )
}

export default Comment
