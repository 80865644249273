import { Tabs } from 'antd'
import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle` 
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', verdana, sans-serif;
    scroll-behavior: smooth !important;
  }
  
  html{
    scroll-behavior: smooth !important;
  }
  html,body{
    overflow-x: hidden;
    
  }
  /* Scrollbar */
  * {
    scrollbar-width: thin;          /* "auto" or "thin"  */
    scrollbar-color: var(--main) black;   /* scroll thumb & track */
  }

  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    width: 10px;  
    height: 7px;             /* width of the entire scrollbar */
  }
  *::-webkit-scrollbar-track {
    background: black;        /* color of the tracking area */
  }
  *::-webkit-scrollbar-thumb {
    background-color: var(--main);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 2px solid black;  /* creates padding around scroll thumb */
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  html, body, .ant-layout {
    background: white;
    font-family: 'Roboto', verdana, sans-serif;
  }

  a {
    font-size: 1rem;
    text-decoration: none;
    color: white;
  }
  :root{
    /* Themes */
    --main-highlight: #0c69ff;
    --main: #4285f4;
    --main-shade: #030154;
    
    --secondary-highlight: #E47490;
    --secondary: #B82549;
    --secondary-shade: #69152A;

    --tertiary-highlight: #74BDE4;
    --tertiary: #2584B8;
    --tertiary-shade: #154B69;

    --quarternaty-highlight: #E4DE74;
    --quarternaty: #B8B025;
    --quarternaty-shade: #696415;

    --quinternary-highlight: #3ad864;
    --quinternary: #34a853;
    --quinternary-shade: #256837;
  }
`
export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`
export const Container = styled.main`
  margin: auto;
  margin-top: 2.2556rem;
  width: calc(100% - 9rem);
  min-height: 100vh;
  padding: 1rem;

  @media (min-width: 1330px) {
    padding: 0;
  }
  @media (max-width: 767px) {
    margin: 0;
    width: 100%;
  }
`
export const StyledTab = styled(Tabs)``
