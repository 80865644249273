/*
Página do usuário, onde ele adiciona informações como o seu nome, etc
*/

import React from 'react'
import { Container } from '../../GlobalStyles'

import ContainerHeader from 'containers/Header'
import ContainerBasicInfo from 'containers/StudentBasicInfo'

const Profile = () => {
  return (
    <Container>
      <ContainerHeader Search={true}/>
      <ContainerBasicInfo />
    </Container>
  )
}

export default Profile