/*
FUTURA página com as atividades e eventos da semana na qual o aluno tem disponibilidade
*/

import React from 'react'
import { Container } from './styles'
import HeaderPromotor from '../../components/HeaderPromotor'
import moment from 'moment'
import Select from '../../components/Select'
import SelectMonth from '../../components/SelectMonth/indes'
import { Tabs } from 'antd'
import styled from 'styled-components'

const Span = styled.div`
  display: flex;
  justify-content: space-between;
`

const TitleCalendar = styled.h1`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #898989;
`

const ContainerCalendar = styled.div`
  margin-left: 40;
  width: 95%;
  height: 100%;
  padding: 30;
`
const { TabPane } = Tabs
const Calendar = () => {
  const weekArray = moment().daysInMonth()
  let rows = []
  for (let i = 0; i <= weekArray; i++) {
    rows.push(<li> {i}</li>)
  }

  return (
    <Container>
      <HeaderPromotor></HeaderPromotor>
      <ContainerCalendar>
        <TitleCalendar>Calendário de Transferências</TitleCalendar>
        <Span>
          <div className="calendar">
            <div
              style={{
                width: '100%',
                height: '20%',
                display: 'flex',
                justifyContent: 'space-between',
                padding: 20,
                alignItems: 'center',
              }}
            >
              <Select title=""></Select>
              <SelectMonth ></SelectMonth>
            </div>
            {rows.map((item, index) => {

              // eslint-disable-next-line array-callback-return
              console.log(item)
              if (index === 0) return
              return (
                <div
                  style={{
                    width: 85,
                    height: 85,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <h1>{index}</h1>
                  <div style={{ display: 'flex' }}>
                    {(index === 6 || index === 8 || index === 5) && (
                      <div
                        style={{
                          width: 10,
                          height: 10,
                          backgroundColor: 'red',
                          borderRadius: 0,
                        }}
                      ></div>
                    )}

                    {(index === 18 || index === 13 || index === 5) && (
                      <div
                        style={{
                          width: 10,
                          height: 10,
                          backgroundColor: 'blue',
                          borderRadius: 20,
                        }}
                      ></div>
                    )}
                    {(index === 1 || index === 31) && (
                      <div
                        style={{
                          width: 10,
                          height: 10,
                          backgroundColor: 'green',
                          borderRadius: 20,
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>

          <div className="Atividades">
            <Tabs centered={true} defaultActiveKey="2">
              <TabPane tab={<span>Eventos da Semana</span>} key="1">
                Eventos da Semana
              </TabPane>
              <TabPane tab={<span>Atividades</span>} key="2">
                Atividades
              </TabPane>
            </Tabs>
          </div>
        </Span>
      </ContainerCalendar>
    </Container>
  )
}

export default Calendar
