/*
Aqui fica a página principal tanto para o produtor como para o aluno (note o operador ternário)
*/


import { Title } from 'components/Financial/ResumFinan/style'
import Footer from 'containers/Footer'
import React, { useEffect, useState } from 'react'
import api from 'services/api'
import HeaderPromotor from '../../../../components/HeaderPromotor'

import HomeStudent from '../../Student'
import { Container, SubTitle } from './styles'
const StudentView = () => {
  const [name, setName] = useState<any>()
  const [countProducts, setCountProducts] = useState<any>()
  const [typeUser, setTypeUser] = useState<any>(true)
  const [, setType] = useState<any>(true)
  const [activeStudents, setActiveStudents] = useState<any>(0)
  const [totalStudents, setTotalStudents] = useState<any>(0)
  const [completedStudents, setCompletedStudents] = useState<any>(0)
  const [rating, setRating] = useState<any>('-')
  const [hours, setHours] = useState<any>(0)


  const [bruto, setBruto] = useState('R$ 0,00');
  const [liquido, setLiquido] = useState('R$ 0,00');
  const [saque, setSaque] = useState('R$ 0,00');


  const financial = async () => {
    let seller = await api.get("profile")
    let id = seller.data.user.seller_id
    let financial = await api.get('/financial-summary')

    let saqueDisp = await api.get(`/check-balance/${id}`)


    if (saqueDisp.data.balance.current_balance === 0) {
      setSaque('R$ 0,00')
    } else {
      setSaque(
        saqueDisp.data.balance.current_balance.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
      )
    }

    setBruto(financial.data.financial_summary.gross_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))
    setLiquido(financial.data.financial_summary.net_value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))

  }

  const getProduct = async () => {
    let { data } = await api.get('/list-all-by-producer-products')
    setCountProducts(data.total_records ? data.total_records : 0)

    let response = await api.get('/info-products')
    setRating(response.data.avaliation.toFixed(2))
    setHours(response.data.class_time)
  }


  const getOverview = async () => {
    let overview = await api.get("overview")
    setActiveStudents(overview.data.started ? overview.data.started : 0)
    setCompletedStudents(overview.data.concluded ? overview.data.concluded : 0)
    setTotalStudents(overview.data.total_students ? overview.data.total_students : 0)
  }

  useEffect(() => {
    const getUser = async () => {
      let response = await api.get("profile")
      setTypeUser(response.data.user.type_user.trimEnd())

      if (response.data.user.type_user === "producer" || response.data.user.type_user === "superadm") {
        setType("Produtor")
      } else {
        setType("Estudante")
      }

      if (response.data.user.nickname === null || response.data.user.nickname === '') {
        setName(response.data.user.name)
      } else {
        setName(response.data.user.nickname)
      }

    }

    getUser()
    financial()
    getOverview()
    getProduct()

  }, [])


  return (
    <>
      <Container>
        <HeaderPromotor SearchHeader={true} />
        <SubTitle>
          <p>Home</p>
        </SubTitle>
          <HomeStudent />
      </Container>
      <Footer />
    </>
  )
}

export default StudentView
