import styled from 'styled-components';

export const ContainerSteps = styled.div`
    margin: 0;
    width: 100%;
    height: 66px;
    display: flex;
    border: 1px solid #C0C0C0;
    background: #E6E6E6;
    box-sizing: border-box;
    border-radius: 9.38011px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.234503px;
    color: #A2A2A2;
`
export const Steps = styled.div`
    width: 33.33%;
    height: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.234503px;
    color: #A2A2A2;
    display: flex;
    align-items: center;
`
export const StepsNumber = styled.div`
    margin-left: 30px;
    margin-right: 20px;
    display: flex;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #A2A2A2;
`

export const StepActive = styled.div`
    width: 33.33%;
    height: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.234503px;
    color: #A2A2A2;
    display: flex;
    align-items: center;
    color: white;
    background:var(--main);
    border-radius: 9.38011px;
    border: 1px solid #C0C0C0;

    
    div{
        margin-left: 30px;
        margin-right: 20px;
        display: flex;
        width: 36px;
        height: 36px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: none;
        color: var(--main);
        background: white;
    }
`