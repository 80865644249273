import React, { useState } from 'react'
import { Main } from '../style'
import {
  FormContainer,
  ProductInfoContainer,
  ProductInfoImg,
  ProductInfoContent,
  ProductInfoTitle,
  ProductInfoText,
  PaymentContent,
  Price,
  PriceAlternative,
  AcceptedCards,
} from './style'

import Input from 'components/Input'
import Text from 'components/Input/Text'
import Button from 'components/Button'
import Payment from '../Payment'
import Step from 'components/CheckoutSteps'


const PersonalData = () => {
  const [payment, setPayment] = useState(false)

  const handlePayment = () => {
    return setPayment(true)
  }

  return (
    <>
      {!payment ? (
        <>
          <Step Step1={"Active"} />
          <Main>
            <FormContainer>
              <Input title="Nome" >
                <Text placeholder="Seu nome"/>
              </Input>

              <Input title="E-mail">
                <Text type="email" placeholder="Seu e-mail"/>
              </Input>

              <Input title="Confirme seu e-mail">
                <Text type="email" placeholder="Confirmar e-mail"/>
              </Input>

              <Button
                width="12rem"
                style={{ float: 'right' }}
                btnStyle="filled"
                onClick={() => handlePayment()}
              >
                Próximo passo
          </Button>
            </FormContainer>

            <ProductInfoContainer>
              <div style={{ display: 'flex', marginBottom: '1.5rem' }}>
                <ProductInfoImg />

                <ProductInfoContent>
                  <ProductInfoTitle>
                    Conquiste sua Liberdada Financeira
              </ProductInfoTitle>

                  <ProductInfoText>
                    Esse é um produto digital. Você receberá os dados para acessá-lo
                    via internet.
              </ProductInfoText>

                  <ProductInfoText>
                    <span>Autor</span>: Augusto Nascimento
              </ProductInfoText>
                </ProductInfoContent>
              </div>

              <PaymentContent>
                <Price>R$ 3.500,00</Price>
                <PriceAlternative>Ou em 12x de R$ 292,00* no cartão</PriceAlternative>
                <AcceptedCards>
                  <h1>Cartões</h1>
                </AcceptedCards>
              </PaymentContent>
            </ProductInfoContainer>
          </Main>
        </>
      ) : (
          <>
            <Payment />
          </>
        )
      }
    </>

  )
}

export default PersonalData
