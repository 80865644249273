/*
Aqui é a página principal do aluno
*/

import React, { useState, useEffect } from 'react'
import api from 'services/api'
import { ContainerCards, StyledPagination, NoProductMsg } from './style'
import { CircularProgress } from '@material-ui/core/'
import ProductCard from './ProductCard'

const HomePage: React.FC = () => {
  const [products, setProducts] = useState<any>(null)
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(0)
  const [totalProducts, setTotalProducts] = useState<number>(0)

  const handlePageNumber = (page: number) => {
    setPageNumber(page - 1)

    window.scrollTo(0, 100)
  }

  useEffect(() => {
    const getAllProducts = async () => {
      let { data } = await api.get('/list-all-products-market', {
        params: { page_index: pageNumber },
      })
      setProducts(data.records)
      setPageNumber(data.page_index)
      setPageSize(data.page_size)
      setTotalProducts(data.total_records)
    }
    getAllProducts()
  }, [pageNumber])

  return (
    <>
      <ContainerCards>
        {!products ? (
          totalProducts === 0 ? (
            <NoProductMsg>
              {' '}
              Não há produtos cadastrados no momento. Por favor, volte mais
              tarde... quem sabe não aparece uma novidade? ;)
            </NoProductMsg>
          ) : (
            <CircularProgress
              size={'3rem'}
              color="inherit"
              style={{ margin: '0 auto' }}
            />
          )
        ) : (
          products.map(ProductCard)
        )}
      </ContainerCards>
      {products && totalProducts > pageSize && (
        <StyledPagination
          defaultCurrent={pageNumber + 1}
          total={totalProducts}
          onChange={handlePageNumber}
        />
      )}
    </>
  )
}

export default HomePage
