import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
  ProductCard,
  ProductImg,
  Content,
  ContentTitle,
  ContentCenter,
  Icon,
  Value,
  Label,
  Link,
  ButtonTax,
  FlexContainer,
  Active,
  Subcard,
  ProductLink,
  Modal,
  Button,
  Overlay,
  InputTextArea,
  OptionsBtn,
  Title
} from './style'
import history from 'routes/history'
import { SubmitHandler } from '@unform/core'

import ShopingCartIcon from 'assets/icons/shoping-cart-icon.svg'
import ThreeDotMenu from 'assets/icons/3dotmenu-icon.svg'
import closeIcon from 'assets/icons/closebtn-x-icon.svg'
import MoneyIcon from 'assets/icons/money-icon.svg'
import ArrowIcon from 'components/new/ArrowIcon'
import Form from 'components/new/Form'
import Input from 'components/new/Input'
import Text from 'components/new/Input/Text'

import { Dropdown } from 'antd'
import api from 'services/api'
import { displayErrorNotification, displaySucessNotification } from 'utils/displayNotification'
import VideocamIcon from 'assets/icons/videocam-icon.svg'

interface Product {
  product: {
    id: number,
    photo: any,
    name: string,
    teacher_id: number,
    currentstatus: string,
    category: string,
    is_activeProduct: boolean,
    fee: number
  }
  key: any
}

const notificationStyle = {
  right: '1.875rem',
  width: '16.25rem',
  borderRadius: '0.625rem'
}

const Product: React.FC<Product> = ({ product, key }) => {
  const { id, photo, name, teacher_id, currentstatus, category, is_activeProduct, fee } = product
  const [vendas, setVendas] = useState()
  const [hours, setHours] = useState<number>(0)
  const [motivo, setMotivo] = useState<string>('')
  const [isToBlock, setIsToBlock] = useState(false)
  const [displayOverlay, setDisplayOverlay] = useState('none')
  const [isProfileValid, setIsProfileValid] = useState<boolean>(false)

  const formRef = useRef<SubmitHandler>(null)

  const [feeEdit, setFeeEdit] = useState(fee)

  const [editingFee, setEditingFee] = useState(false)

  const handleFee = (e: any) => {
    setFeeEdit(e.target.value)
  }

  const handleEditFee = async () => {
    try {
      await api.post(`edit-product-fee/${id}`, { newFee: feeEdit })

      displaySucessNotification('Taxa atualizada com sucesso!')
      setEditingFee(false)
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }


  const handleAprove = async (id: any) => {
    try {
      await api.put(`aprove-product/${id}`,)

      displaySucessNotification('Produto aprovado com sucesso! Sua página irá atualizar.')
      setTimeout(() => window.location.reload(true))

    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const handleRefuse = async (id: any) => {
    try {
      await api.put(`refuse-product/${id}`, { motivo })

      displaySucessNotification('Produto recusado com sucesso!')
      setTimeout(() => window.location.reload(true))

    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const handleBlock = async (id: any) => {
    try {
      await api.put(`block-product/${id}`, { motivo })

      displaySucessNotification('Produto bloqueado com sucesso!')

      setTimeout(() => window.location.reload(true))
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const handleReview = async (id: any) => {
    try {
      await api.put(`review-product/${id}`)

      displaySucessNotification('Produto posto em análise com sucesso!')
      setTimeout(() => window.location.reload(true))
    } catch (err) {
      displayErrorNotification(err.response.data.message)
    }
  }

  const handleVendas = async () => {
    await api.get(`/get-resume-products`)
      .then((res) => {
        const { data } = res

        setVendas(data.total_sells)
        setHours(data.time_video)
      })
  }

  const profileValid = useCallback(async () => {
    try {
      const resProfile = await api.get(`/profile-superadm/${teacher_id}`)
      await api.get(`/check-balance/${resProfile.data.user.seller_id}`)
        .then((resSellerID) => {
          setIsProfileValid(resSellerID.data.status)
        })
        .catch((res) => {
          console.log(res.response.data.message)
        })

    } catch (err) {
      //não precisa colocar nada aqui, já que o 'erro' vai ser mostrado com 'usuario inativo', no produto
      return
    }
  }, [teacher_id])

  const handleView = async (id: any) => {
    history.push('/products/view', { product_id: id })
  }

  const generateRelatory = async (id: number) => {
    try {
      let obj = {
        producer_id: id
      }
      await api.post(`/report-generate-report`, obj)

      var byteArray = new Uint8Array(id);
      var a = window.document.createElement('a');

      a.href = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
      a.download = `relatorio-de-produtores-${Date.now()}.xlsx`;

      // Append anchor to body.
      document.body.appendChild(a)
      a.click();

      // Remove anchor from body
      document.body.removeChild(a)
    } catch (err) {
      displayErrorNotification('Como diria Raul Seixas: Tente outra vez.')
    }


  }
  useEffect(() => {
    profileValid()
    handleVendas()
  }, [profileValid])

  const dropdownMenu = (
    <Subcard>
      {
        currentstatus === 'EM ANÁLISE' || currentstatus === 'EM ANALISE' ? (
          <>
            <ProductLink onClick={() => handleAprove(id)}><span style={{ color: 'green' }}>Aprovar produto</span></ProductLink>
            <ProductLink onClick={() => setDisplayOverlay('block')}><span style={{ color: 'red' }}>Recusar produto</span></ProductLink>
          </>
        ) : (<></>)
      }
      {
        currentstatus === 'APROVADO' ? (
          <>
            <ProductLink onClick={() => { setDisplayOverlay('block'); setIsToBlock(true) }}><span style={{ color: 'red' }}>Bloquear produto</span></ProductLink>
            <ProductLink target='_blank' href={`http://0.0.0.0:8090/?product_id=${id}`}>Link do Produto</ProductLink>
          </>
        ) : (<></>)
      }
      {
        currentstatus === 'RECUSADO' ? (
          <ProductLink onClick={() => handleReview(id)}><span style={{ color: 'var(--main)' }}>Voltar para análise</span></ProductLink>
        ) : (<></>)
      }
      {
        currentstatus === 'BLOQUEADO' ? (
          <>
            <ProductLink onClick={() => handleAprove(id)}><span style={{ color: 'green' }}>Aprovar produto</span></ProductLink>
            <ProductLink onClick={() => handleReview(id)}><span style={{ color: 'var(--main)' }}>Voltar para análise</span></ProductLink>
          </>
        ) : (<></>)
      }
      {
        !is_activeProduct ? (
          <>
            {/* <ProductLink onClick={() => generateRelatory(id)}>Gerar relatório</ProductLink> */}
            <ProductLink onClick={() => handleView(id)}>Visualizar produto</ProductLink>
          </>
        ) : (
            <>
              <ProductLink onClick={() => handleView(id)}>Visualizar produto</ProductLink>
            </>
          )
      }

    </Subcard>
  )

  return (
    <ProductCard key={key}>
      <ProductImg src={photo} />

      <Content>
        <ContentTitle>
          <h1>{name}</h1>
          <h2>Categoria: {category}</h2>
        </ContentTitle>

        <ContentCenter>
          <section>
            <FlexContainer>
              <Icon src={ShopingCartIcon} />
              <Value>{vendas}</Value>
            </FlexContainer>

            <Link to="/financial">
              Vendas
              <ArrowIcon rotateDeg={-90} color="var(--main)" />
            </Link>
          </section>
          <section>
            <FlexContainer>
              <Icon src={VideocamIcon} />
              <Value>{hours}</Value>
            </FlexContainer>

            <Label>de vídeo</Label>
          </section>
          <section>
            {
              editingFee ? (
                <Form onSubmit={handleEditFee}>
                  <Input title={'Taxa'} width={'35%'}>
                    <Text
                      name="feeEdit"
                      value={feeEdit}
                      onChange={handleFee}
                      type="number"
                    />
                  </Input>
                  <ButtonTax type="submit" bgcolor="#00FF00">✔</ButtonTax>
                  <ButtonTax type="button" bgcolor="#ff0000" onClick={() => setEditingFee(false)}>X</ButtonTax>
                </Form>
              ) : (
                  <>
                    <FlexContainer style={{ cursor: 'pointer' }} onClick={() => setEditingFee(true)}>
                      <Icon src={MoneyIcon} />
                      <Value>{feeEdit}%</Value>
                    </FlexContainer>

                    <Label>Taxa</Label>
                  </>
                )
            }

          </section>
          <section>
            <FlexContainer>
              <Value>{isProfileValid ?
                (<span style={{ color: 'green' }}>Usuário ativo</span>) :
                (<span style={{ color: 'red' }}>Usuário não ativo</span>)}</Value>
            </FlexContainer>
          </section>
          <section>
            <Active currentstatus={currentstatus}>{currentstatus}</Active>
          </section>
        </ContentCenter>

        <Dropdown overlay={dropdownMenu}>
          <OptionsBtn>
            <img className='' src={ThreeDotMenu} alt="" />
          </OptionsBtn>
        </Dropdown>

        <Overlay display={displayOverlay}>
          <Modal>
            <Title>
              <p>Explique o motivo:</p>

              <img src={closeIcon}
                alt='x'
                onClick={() => {
                  setDisplayOverlay('none');
                  setMotivo('');
                }}
              />

            </Title>
            <Form ref={formRef}>
              <InputTextArea
                name="motivo"
                title="Motivo"
                height={'100%'}
                valueInput={motivo}
                placeholder=''
                onChangeInput={(e: any) => setMotivo(e.target.value)}
              />
            </Form>
            <Button onClick={isToBlock ? () => handleBlock(id) : () => handleRefuse(id)}>Confirmar</Button>
          </Modal>
        </Overlay>
      </Content>
    </ProductCard>
  )
}

export default Product
