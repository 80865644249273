import React, { CSSProperties, FC, useCallback } from 'react'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

interface Props {
  className?: string
  max?: number
  onValueChange: (value: any) => void
  style?: CSSProperties
  value: any
  currency?: string
  placeholder?: string
  disabled?: boolean
}

const CurrencyInput: FC<Props> = ({
  className = '',
  max = Number.MAX_SAFE_INTEGER,
  onValueChange,
  style = {},
  value,
  placeholder,
  disabled,
  currency = 'BRL',
}) => {
  const numberMask = createNumberMask({
    prefix: currency === 'BRL' ? 'R$' : '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
  })

  const handleChange = useCallback((e: any) => {
    const val = e.target.value

    val.includes(',') ? onValueChange(e.target.value) : onValueChange((e.target.value) + ',00')
  }, [onValueChange])

  return (
    <MaskedInput
      className={className}
      mask={numberMask}
      placeholder={`${currency} 0,00`}
      onChange={handleChange}
      style={style}
      tabIndex={-1}
      disabled={disabled}
      value={value}
    />
  )
}

export default CurrencyInput
