
import React, { useEffect, useState } from 'react';
import {
  Container,
  TitleContainer,
  InfoAfiliados,
  NewSolit,
  TopAfilliate,
  Afilliates,
  Cards
} from './styles'
import api from 'services/mock/index'
import {FaChevronRight}  from 'react-icons/fa';
import PeopleIcons from '../../assets/icons/peoples-icon.svg';
import TopsAffiliates from 'components/TopAffiliate/index';

const ContainerAfiliados = () => {
  const [ topAffiliate, setTopAffiliate ] = useState([])

  useEffect(() => {
    api.getReports()
      .then((data: any) => {
        setTopAffiliate(data.affiliate.slice(0,3))
      })
  }, [])
  return (
    <>
      <Container>
        <TitleContainer>
          Afiliados
        </TitleContainer>
        <Cards>
            <InfoAfiliados>
              SOLICITAÇÕES DE AFILIAÇÕES
            <NewSolit>
              <img src={PeopleIcons} alt='Peoples'/>
              <div>
                <strong>4 Novas</strong>
                <p>
                  solicitações 
                  <FaChevronRight color="#85be08" size={15} style={{ marginLeft: 10 }} />
                </p>
              </div>
            </NewSolit>
            </InfoAfiliados>
        </Cards>

        <Cards>
          <TopAfilliate>
            MEUS TOP AFILIADOS
            <Afilliates>
            {topAffiliate.map((affiliate: any) => (
              <TopsAffiliates photo={affiliate.photo} name={affiliate.name} sales={affiliate.sales} />
            ))}
            </Afilliates>
          </TopAfilliate>
        </Cards>

      </Container>
      
    </>
  )
}

export default ContainerAfiliados
