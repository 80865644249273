import React, { useRef, useState, useEffect, useCallback } from 'react'
import ApiViaCep from 'services/ViaCep'
import api from '../../../services/api'

import axios from 'axios'

import { SubmitHandler } from '@unform/core'
import Banks from 'services/mock'
import {
  CardContainer,
  Title,
  HeaderConquest,
  ContainerForm,
  EditInput,
  FormEnd,
  Button,
  FooterButton,
  SelectForm,
  FlexAcount,
  FlexAdress
} from './style'

import Form from 'components/new/Form'
import ImageInput from 'components/ImgFormData'


import Text from 'components/new/Input/Text'
import { notification } from 'antd'
import { cpfMask, telMask, agencyMask, cepMask } from 'components/new/Input/mask'
import { CircularProgress } from '@material-ui/core/'

const notificationStyle = {
  right: '1.875rem',
  width: '16.25rem',
  borderRadius: '0.625rem'
}
interface propsUser {
  picture: any
  nivel: string
  description: string
}
const FormIndividual = ({ user }: any) => {
  const [cep, setCep] = useState<any>()
  const [rua, setRua] = useState<any>()
  const [complemento, setComplemento] = useState<any>()
  const [bairro, setBairro] = useState<any>()
  const [holderName, setHolderName] = useState<any>()
  const [numeroCasa, setNumeroCasa] = useState<any>()
  const [cpf, setCpf] = useState<any>('')
  const [cell_phone, setPhone] = useState<any>()
  const [bank, setBank] = useState<any>()
  const [serverBank, setServerBAnk] = useState<any>()
  const [agency, setAgency] = useState<any>()
  const [account, setAccount] = useState<any>()
  const [confirmPersona, setConfirmPersona] = useState<any>('cnpj')
  const [listBank, setListBAnk] = useState<any>([])
  const [loading, setLoanding] = useState<any>(true)
  const [digit, setDigit] = useState<any>()
  const [typeAccount, setTypeAccount] = useState<any>("")
  const [city, setCity] = useState<any>("")
  const [state, setState] = useState<any>("")

  const [file, setFile] = useState<any>()

  const formRef = useRef<SubmitHandler>(null)

  const handleSubmit: SubmitHandler<FormData> = async (data) => {
    let obj = {
      typeDocument: 'cpf',
      bank,
      type_account: typeAccount,
      city,
      state
    }

    let value = Object.assign(data, obj)

    setLoanding(false)
    try {
      await api.put('/edit-profile', value)
      .then( async(res)=>{
        const { data: { seller, marketplace, zoopAuth } } = res
        const zoopFile = await checkFile(seller, marketplace, zoopAuth)
        if (!file && !zoopFile) {
          notification.error({
            message: 'Documento requerido',
            placement: 'bottomRight',
            style: notificationStyle
          })
          setLoanding(true)
          return 0;
        }

        if (file)
          sendSalesDocument(seller, marketplace, zoopAuth)
        else {
          notification.success({
            message: 'Atualizado com sucesso!',
            placement: 'bottomRight',
            style: notificationStyle
          })
          setLoanding(true)
        }
        })
      .catch((err)=>{
        notification.error({
          message: err.response.data.message,
          placement: 'bottomRight',
          style: notificationStyle
        })
        setLoanding(true)
      })
    } catch (err) {
      notification.error({
        message: err.response.data.message,
        placement: 'bottomRight',
        style: notificationStyle
      })
      setLoanding(true)
    }

  }

  const handleCep = async (e: any) => {
    try {
      let response = await ApiViaCep.get(`${e.replace(/\D/g, '')}/json`); //o replace é para tirar os caracteres que entra no e
      setRua(response.data.logradouro)
      setBairro(response.data.bairro)
      setCity(response.data.localidade)
      setState(response.data.uf)
    } catch (e) { }
  }

  function maskCpf(e: any) {
    setCpf(cpfMask(e.target.value))
  }
  function maskTel(e: any) {
    setPhone(telMask(e.target.value))
  }
  function maskAgency(e: any) {
    setAgency(agencyMask(e.target.value))
  }

  function maskAccout(e: any) {
    setAccount(e.target.value)
  }

  function maskCep(e: any) {
    setCep(cepMask(e.target.value))
  }

  const handleFoto = async (e: any) => {
    setFile(e.target.files[0]);
  }

  const handleValues =  useCallback(()=>{
    try {
      setCep(user.cep)
      setHolderName(user.holder_name)
      setComplemento(user.complement)
      setBairro(user.district)
      setNumeroCasa(user.house_number)
      setRua(user.street)
      setServerBAnk(user.bank)
      setDigit(user.digit)
      setCity(user.city)
      setState(user.state)
      setTypeAccount(user.type_account)
      setPhone(telMask(user.cell_phone))

      setConfirmPersona('cpf')
      
      setCpf(cpfMask(user.cpf))

      setBank(user.bank)
      setAccount(user.account)
      setAgency(agencyMask(user.agency))

    } catch (e) { console.log(e) }
  }, [user.account, user.agency, user.bank, user.cell_phone, user.cep, user.city, user.complement, user.cpf, user.digit, user.district, user.holder_name, user.house_number, user.state, user.street, user.type_account]) 

  const sendSalesDocument = async (sellerId: string, marketplaceId: string, zoopAuth: string) => {
    try {
      const documentURL = `https://api.zoop.ws/v1/marketplaces/${marketplaceId}/sellers/${sellerId}/documents`
      const form = new FormData()
      form.append('file', file)
      form.append('category', 'identificacao')

      await axios.post(documentURL, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: zoopAuth,
        },
      })

      notification.success({
        message: 'Atualizado com sucesso!',
        placement: 'bottomRight',
        style: notificationStyle
      })
      setLoanding(true)

    } catch (err) {
      notification.error({
        message: 'Erro no envio do documento',
        placement: 'bottomRight',
        style: notificationStyle
      })
      setLoanding(true)
    }
  }

  const checkFile = async (sellerId: string, marketplaceId: string, zoopAuth: string) => {
    try {
      const documentURL = `https://api.zoop.ws/v1/marketplaces/${marketplaceId}/sellers/${sellerId}/documents`

      const { data: { items } } = await axios.get(documentURL, {
        headers: {
          Authorization: zoopAuth,
        },
      })

      return items.length ? true : false
    } catch (err) {
      return false
    }
  }

  useEffect(() => {
    handleValues()

    Banks.getBank()
      .then((data: any) => {
        setListBAnk(data.banks)
      })
  }, [handleValues])

  return (
    <>
      <main>
        <CardContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <HeaderConquest>
              <Title>
                Dados do Individuo
              </Title>
            </HeaderConquest>
            <ContainerForm>
              <FormEnd>
                <EditInput title="Número de telefone">
                  <Text
                    name="cell_phone"
                    value={cell_phone}
                    onChange={maskTel}
                    maxLength={15} //Cabe o DDD + Número com 9 dígitos
                    required
                  />
                </EditInput>
                <EditInput title="Número do CPF">
                  <Text
                    name="cpf"
                    onChange={maskCpf}
                    maxLength={14}
                    value={cpf}
                  />
                </EditInput>

                <EditInput title="Nome do dono da conta">
                  <Text
                    name="holderName"
                    value={holderName}
                    maxLength={60}
                    onChange={(e) => setHolderName(e.target.value)}
                    required
                  />
                </EditInput>
                <EditInput title="Selecione seu banco">
                  <SelectForm>
                    <select name="bank" value={bank} onChange={e => setBank(e.target.value)} required>
                      {serverBank === null ?
                        <option value={serverBank}>{serverBank}</option>
                        :
                        <option></option>

                      }
                      {listBank.map((item: any) => (

                        <option value={item.Code}>{item.Code}   {item.Name}</option>
                      ))}
                    </select>
                  </SelectForm>
                </EditInput>
                <EditInput title="Selecione tipo de conta">
                  <SelectForm>
                    <select name="typeAccount" value={typeAccount} onChange={e => setTypeAccount(e.target.value)} required>
                      {typeAccount === 'checking'
                        ?
                        <><option value={typeAccount}>Conta corrente</option>
                          <option value='savings'>Conta poupança</option></>
                        :
                        typeAccount === 'savings'
                          ?
                          <><option value={typeAccount}>Conta poupança</option>
                            <option value='checking'>Conta corrente</option></>

                          :
                          <>
                            <option></option>
                            <option value='savings'>Conta poupança</option>
                            <option value='checking'>Conta corrente</option>
                            </>
                      }

                    </select>
                  </SelectForm>
                </EditInput>
                <EditInput title="Número da sua agência">
                  <Text
                    name="agency"
                    value={agency}
                    onChange={maskAgency}
                    required
                  />
                </EditInput>
                <FlexAcount>
                  <div style={{height: '136px'}}>
                    <EditInput title="Número da sua conta" width="190px">
                      <Text
                        name="account"
                        value={account}
                        maxLength={16}
                        onChange={e => setAccount(e.target.value)}
                        required
                      />
                    </EditInput>
                    <EditInput title="DG" width="80px">
                    <Text
                      name="digit"
                      value={digit}
                      maxLength={5}
                      onChange={e => setDigit(e.target.value)}
                      required
                    />
                  </EditInput>
                  </div>
                  <p>Informe sua conta e seu digíto separadamente.</p>
                </FlexAcount>

              </FormEnd>
              <FormEnd>
                <EditInput title="Número do seu cep">
                  <Text
                    name="cep"
                    value={cep}
                    onChange={maskCep}
                    onBlurCapture={e => handleCep(e.target.value)}
                    required
                  />
                </EditInput>
                <EditInput title="N da sua rua">
                  <Text
                    name="street"
                    value={rua}
                    onChange={(e) => setRua(e.target.value)}
                    required
                  />
                </EditInput>
                <EditInput title="Número da sua casa">
                  <Text
                    name="house_number"
                    value={numeroCasa}
                    onChange={(e) => setNumeroCasa(e.target.value)}
                  />
                </EditInput>
                <EditInput title="N do seu bairro">
                  <Text
                    name="district"
                    value={bairro}
                    onChange={(e) => setBairro(e.target.value)}
                    required
                  />
                </EditInput>
                <EditInput title="Complemento">
                  <Text
                    name="complement"
                    value={complemento}
                    onChange={e => setComplemento(e.target.value)}
                  />
                </EditInput>

                <FlexAdress>

                  <EditInput title="Cidade" width={"100%"}>
                    <Text
                      name="city"
                      value={city}
                      onChange={e => setCity(e.target.value)}
                      required
                    />
                  </EditInput>

                  <EditInput title="UF" width={"80px"}>
                    <Text
                      name="state"
                      value={state}
                      maxLength={2}
                      onChange={e => setState(e.target.value)}
                      required
                    />
                  </EditInput>
                </FlexAdress>

                <h3>Documento com foto (RG)</h3>
                <ImageInput name="document" handleFoto={handleFoto} />


              </FormEnd>
            </ContainerForm>
            <FooterButton>
              {
                !loading ? (
                  <CircularProgress color="inherit" />
                )
                  :
                  <Button>
                    Salvar Alterações
                  </Button>
              }

            </FooterButton>
          </Form>

        </CardContainer>
      </main>
    </>
  )
}

export default FormIndividual;