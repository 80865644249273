import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 18.75rem;
  height: 18.75rem;
  color: #292929;
`

export const UserName = styled.p`
  text-decoration: none;
  font-size: 1rem;
  margin-bottom: 1.25rem;
  font-weight: bold;
`

export const ProfilePic = styled.div`
  width: 7.225rem;
  height: 7.225rem;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1.25rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const StyledLink = styled(Link)`
  color: #292929;
  cursor: pointer;

  &:hover {
    border-color: #c1c1c1;
    color: #353535;
  }
`

export const SettingButton = styled.button`
  border-radius: 1.875rem;
  border: 1px solid #e5e5e5;
  outline: none;
  height: 1.875rem;
  width: max-content;
  padding: 0.9375rem 2.8125rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    border-color: #c1c1c1;
  }

  p {
    margin: 0;
  }

  img {
    margin-right: 0.625rem;
  }
`

export const Footer = styled.footer`
  padding-top: 2rem;
`

export const LogoutButton = styled.button`
  padding: 0.9375rem 3.75rem;
  border-radius: 0.625rem;
  text-align: center;
  color: var(--main);
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: bold;
  background: none;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: #0c69ff;
  }
`
