/*
FUTURA página de afiliados
*/

import React from 'react'
import { Container } from '../../GlobalStyles'

import ContainerHighAffiliate from '../../containers/HighAffiliates/index'
import ContainerTopAffiliate from '../../containers/TopAffiliate/index'
import ContainerHeader from '../../containers/Header/index'
import ContainerMyAffiliate from './MyAffiliates/index'
import Footer from 'containers/Footer'

const Affiliates = () => {
  return (
    <Container>
      <ContainerHeader Search={true} />
      <ContainerTopAffiliate />
      <ContainerMyAffiliate />
      <ContainerHighAffiliate />
      <Footer />
    </Container>
  )
}

export default Affiliates
