/*
Aqui contém a página do financeiro e seus componentes/containers.
*/

import React from 'react'
import { Container } from '../../GlobalStyles';
import Header from 'components/HeaderPromotor'
import ContainerConfig from 'containers/Config';
import Footer from 'containers/Footer'


const Config = () => {
  return (
    <>
      <Container>
        
        <Header SearchHeader />
        <ContainerConfig/>
      </Container>
      <Footer />
    </>
  )
}

export default Config