import styled from 'styled-components'
import { Steps } from 'antd'
import { Card } from 'components/CardContainer'

export const MainContainer = styled(Card)`
  margin: auto;
  margin-top: 2.2556rem;
  width: 92.06067%;
  min-height: 100vh;
`

export const CheckoutContainer = styled.main`
  width: 100%;
  min-height: 35.5rem;
`

export const Content = styled.div`
  width: 100%;
`

export const Main = styled.main`
  display: flex;
  width: 100%;
  margin-top: 3.6875rem;
`

export const Button = styled.button`
  width: max-content;
  padding: 0.4375rem 2.18875rem;
  color: #FFFFFF;
  box-sizing: border-box;
  border-radius: 3rem;
  background-color: var(--main);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  border: none;
  outline: none;
  cursor: pointer;
  
  &:hover {
    color: #FFFFFF;
    background-color: var(--main-highlight);
  }
`

export const CheckoutSteps = styled(Steps)` 
  background: #E6E6E6;
  border: 0.0586257rem solid #C0C0C0;
  box-sizing: border-box;
  border-radius: 0.586257rem;
  color: #A2A2A2;
      z-index:-1;

  .ant-steps-item-active {
    &::before {
      background: #82DB02;
      width:100%;
      height: 57px;
      border-radius: 5px;
    }

    & > .ant-steps-item-title {
      color: #82DB02;
      
    }
  }

  & > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
    border-color: #82DB02;
  }
  .ant-steps-item-icon {
    background-color: #E6E6E6;
    border-color: #A1A1A1;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    background-color: white;
    border-color: black;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: white;
  }

`