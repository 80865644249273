import { FormHandles, SubmitHandler } from '@unform/core'
import React, { useRef, useState } from 'react'
import { FiAtSign, FiLock } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import * as Yup from 'yup'
import {
  Container,
  FormContainer,
  MainContainer,
  PrimaryButton,
  StyledForm,
} from 'components/Form/Container'
import Input from 'components/Form/Input'
import Logo from 'components/Logo'
import { LoadingSpin } from 'components/Loading'
import { InputGroup } from 'components/Form/Input/style'
import history from 'routes/history'
import api from 'services/api'
import { TOKEN_KEY, TOKEN_USER } from 'utils/login'
import { StyledNav } from './style'
import { displayErrorNotification } from 'utils/displayNotification'

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const formRef = useRef<FormHandles>(null)

  const handleSubmit: SubmitHandler<FormData> = async (body: object) => {
    setLoading(true)

    try {
      const schema = Yup.object().shape({
        email: Yup.string().email().required('O email é obrigatório'),
        password: Yup.string()
          .min(8, 'A senha precisa de no mínimo, 8 caracteres')
          .required('A senha é obrigatória.'),
      })

      await schema.validate(body, { abortEarly: false })
      const { data } = await api.post('/login', body)

      await localStorage.setItem(TOKEN_KEY, data.token)
      await localStorage.setItem(TOKEN_USER, JSON.stringify(data.user))
      history.push('/')
    } catch (err) {
      const validationErrors: any = {}

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message
        })

        if (formRef && formRef.current)
          formRef.current.setErrors(validationErrors)

        setLoading(false)
        return
      }
      displayErrorNotification(
        err.response ? err.response.data.message : 'Erro no servidor.',
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <MainContainer>
      <Container>
        <Logo />
        <StyledNav>
          <NavLink activeClassName="active" to="/register">
            Criar conta
          </NavLink>
          <NavLink activeClassName="active" to="/login">
            Acessar conta
          </NavLink>
          <NavLink activeClassName="active" to="/privacyPolicy">
            Política de privacidade
          </NavLink>
        </StyledNav>

        <FormContainer>
          <StyledForm ref={formRef} onSubmit={handleSubmit}>
            <InputGroup>
              <FiAtSign size={20} />
              <Input type="email" name="email" placeholder="Seu email" />
              <legend>Email</legend>
            </InputGroup>

            <InputGroup>
              <FiLock size={20} />
              <Input type="password" name="password" placeholder="Sua senha" />
              <legend>Senha</legend>
            </InputGroup>

            <PrimaryButton>
              {loading ? <LoadingSpin /> : 'Entrar'}
            </PrimaryButton>
          </StyledForm>

          <NavLink to="/register">
            <p>Não tem conta? Cadastre-se!</p>
          </NavLink>

          <NavLink to="/forgot" className="forgotPassword">
            <p>Esqueci minha senha</p>
          </NavLink>
        </FormContainer>
      </Container>
    </MainContainer>
  )
}

export default Login
