import React from 'react'
import Icon from '../../../assets/icons/chevron-down.svg'
import { StyledIcon } from './style'

type Props = {
  rotateDeg?: number,
  color?: string
}

const ArrowIcon = ({ rotateDeg, color }: Props) => (
  <StyledIcon rotate={rotateDeg} color={color} src={Icon} alt="" />
)

export default ArrowIcon