import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const StyledLink = styled(Link)`
  position: relative;
  text-decoration: none;
  color: var(--main);

  svg {
    position: absolute;
    top: 2px;
    margin-left: 0.3125rem;
    transform: rotate(-90deg);
  }

  &:hover {
    color: var(--main);
  }
`