import React from 'react'

import { Container } from './style'

interface Props {
  name: string
  label: any
  onChange?: any
  hasPdf?: boolean
}

const PdfFileInput: React.FC<Props> = ({
  name,
  label,
  onChange,
  hasPdf = false,
  ...rest
}) => (
    <Container htmlFor={name} hasPdf={hasPdf}>
      <input
        id={name}
        type="file"
        accept="application/pdf"
        onChange={onChange}
        {...rest}
      />
      {label}
    </Container>
  )

export default PdfFileInput
