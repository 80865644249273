import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react'
import { Container, InputLabel, PreviewImg } from './style'
import api from 'services/api'
import { useField } from '@unform/core'

interface Props {
  name: string
  handleFoto: any
}

type InputProps = JSX.IntrinsicElements['input'] & Props

const ImageInput: React.FC<InputProps> = ({ name, handleFoto, ...rest }) => {

  const inputRef = useRef<HTMLInputElement>(null)

  const { fieldName, registerField, defaultValue } = useField(name)
  const [preview, setPreview] = useState(defaultValue)

  const handlePreview = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    let base64: any

    if (evt.target.files && evt.target.files?.[0]) {
      var reader = new FileReader()
      reader.readAsDataURL(evt.target.files[0])
      reader.onload = function (evt) {
        var img: any = new Image()

        img.onload = function () {

          base64 = reader.result

          setPreview(base64)

          let value: any = base64.toString()
          sessionStorage.setItem("photo", value)

          return
        }

        img.src = reader.result
      }
    }
    return base64
  }, [])
  return (
    <Container>
      {preview && <PreviewImg src={preview} alt="Preview" width="100" />}
      <InputLabel for="imgInput">Selecionar arquivo</InputLabel>
      <input
        type="file"
        id="imgInput"
        accept="image/png, image/jpeg"
        ref={inputRef}
        onBlur={handlePreview}
        onChange={e => { handleFoto(e); handlePreview(e); }}
        {...rest}
      />
      <br />
      <p>Tamanho ideal da imagem 500px x 500px</p>
    </Container>
  )
}

export default ImageInput