import { CircularProgress } from '@material-ui/core/'
import { SubmitHandler } from '@unform/core'
import Input from 'components/new/Input'
import {
  cpfMask
} from 'components/new/Input/mask'
import Select from 'components/new/Input/Select'
import Text from 'components/new/Input/Text'
import React, { useRef, useState } from 'react'
import api from 'services/api'
import { displaySucessNotification } from 'utils/displayNotification'
import displayYupErrorNotification from 'utils/displayYupErrorNotification'
import * as Yup from 'yup'
import {
  ContentForm,
  FlexInput,
  InputsSection,
  Register,
  TableContainer
} from './style'


interface FormData {
  email: string
  cell_phone: string
  name: string
  address: string
  product: any
}

interface Props {
  products: any
}

const notificationStyle = {
  right: '1.875rem',
  width: '16.25rem',
  borderRadius: '0.625rem'
}

const textAreaHeight = '11.0625rem'

const Courtesy: React.FC<Props> = ({ products }) => {
  const [email, setEmail] = useState<any>(null)
  const [selectedProduct, setSelectedProduct] = useState<any>(null)
  const [name, setName] = useState<any>('')
  const [cellPhone, setCellPhone] = useState<any>('')
  const [address, setAddress] = useState<any>('')
  const [document, setDocument] = useState<any>('')
  const [loading, setLoading] = useState<boolean>(false)

  const handleProducts = ({ value, label }: any) => {
    setSelectedProduct({
      ...selectedProduct,
      product: { value, label }
    })
  }

  const handleEmail = (e: any) => {
    setEmail(e.target.value)
  }

  const handleName = (e: any) => {
    setName(e.target.value)
  }

  const handleCellPhone = (e: any) => {
    setCellPhone(e.target.value)
  }

  const handleAddress = (e: any) => {
    setAddress(e.target.value)
  }

  const handleDocument = (e: any) => {
    setDocument(cpfMask(e.target.value))
  }

  const formRef = useRef<SubmitHandler>(null)
  const handleSubmitCourtesy: SubmitHandler<FormData> = async data => {
    try {
      const schema = Yup.object().shape({
        product: Yup.number().required('Selecione um produto.').typeError('Por favor, selecione um produto.'),
        email: Yup.string().email().required('Adicione um e-mail para ser enviado.'),
        name: Yup.string().nullable(),
        cell_phone: Yup.string().nullable(),
        address: Yup.string().nullable(),
      })

      await schema.validate(data, {
        abortEarly: false,
      })

      let { data: { user } } = await api.get('profile')

      let newData = {
        product: { id: selectedProduct.product.value },
        producer: { id: user.id },
        email,
        name,
        document,
        cell_phone: cellPhone,
        address,
      }
      setLoading(true)
      await api.post('/checkout-cortesia', newData)
      displaySucessNotification('Voucher enviado com sucesso!')
    } catch (err) {
      displayYupErrorNotification(err)
    } finally {
      setLoading(false)
    }
  }
  return (
    <TableContainer>
      <p className="title">Acesso cortesia</p>
      <ContentForm ref={formRef} onSubmit={handleSubmitCourtesy}>
        <InputsSection>
          <FlexInput>
            <Input title="Produto desejado">
              <Select
                options={products}
                name="product"
                placeholder="Selecione o produto"
                onChange={handleProducts}
                defaultValue={selectedProduct?.product}
                required
              />
            </Input>

            <Input title="E-mail do cliente">
              <Text
                name="email" placeholder="E-mail do beneficiado"
                onChange={handleEmail} maxLength={100} required />
            </Input>
          </FlexInput>
          <Input title="Nome do cliente">
            <Text
              name="name" placeholder="Nome do beneficiado"
              onChange={handleName} maxLength={100} />
          </Input>

          <Input title="Documento do cliente">
            <Text
              name="document" placeholder="Documento do beneficiado"
              value={document}

              onChange={handleDocument} maxLength={14} />
          </Input>

          <Input title="Telefone do cliente">
            <Text
              name="cell_phone" placeholder="Telefone do beneficiado"
              onChange={handleCellPhone} maxLength={100} />
          </Input>

          {/* <Input title="Endereço do cliente">
            <Text
              name="adress" placeholder="Endereço do beneficiado"
              onChange={handleAddress} maxLength={100} />
          </Input> */}


          <Register type="submit">
            {loading ? (
              <CircularProgress color="inherit" size={'1.1rem'} />
            ) : (
                <p>Enviar cortesia</p>
              )}
          </Register>
        </InputsSection>
      </ContentForm>
    </TableContainer>
  )
}

export default Courtesy