import styled from 'styled-components'
import Form from 'components/new/Form'

export const MainContainer = styled.main`
  display: flex;
`

export const DivOrder = styled.div`
  width: 200px;
`
export const ActionBtn = styled.button`
  border: none;
  color: var(--main);
  outline: none;
  background: none;
  font-weight: 500;  
  display: inline-flex;
  justify-content: space-between;
  cursor: pointer;

  &.next-btn,
  &.done-btn {
    position: absolute;
  }

  &.next-btn {
    bottom: 0;
    right: 3.5625rem;
  }

  &.done-btn {
    bottom: 0.4rem;
    right: 5.25rem;
  }
`

export const Register = styled.button`
  position: absolute;
  bottom: 0.4rem;
  right: 5.25rem;
  padding: 0.425rem 1.25rem;
  outline: none;
  background: var(--main);
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
  }
`
export const ContentFooter = styled.footer`
  width: 100%;
  display: flex;
  height: 1.875rem;
  align-items: center;
  justify-content: space-between;
  margin: 3rem 0 0;
  position: absolute;
  bottom: 0.625rem;
`
export const ContentContainer = styled.main`
  width: 100%;
  padding: 1.85875rem 2.269375rem;
  position: relative;
  box-shadow: 0.5625rem 0.3125rem 1.25rem #EEEEEE, 0 0.125rem 0.3125rem #E4E4E4;
  border-radius: 10px;
`
export const Checkbox = styled.div`
  grid-column: 1;
  display: flex;
  align-items: center;
  width: 18.75rem;
  margin-top: 3.125rem;

  input {
    margin-right: 0.9rem;
  }
  
  p {
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.015625rem;
    color: #5C5C5C;
    margin: 0;
  } 
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 2rem 0;
  flex-wrap: wrap;

  & input[type="image"] {
    margin-top: -2.2%;
    outline: none;
  }
`

export const ContentForm = styled(Form)`
  width: 100%;
  margin-bottom: 3rem;
`