import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  input[type="file"] {
    display: none;
  }
`

export const InputLabel = styled.label<any>`
  padding: 0.625rem 1.5625rem;
  border-radius: 0.3125rem;
  border: 1px solid #292929;
  font-size: 0.9rem;
  cursor: pointer;
  font-weight: bold;
`

export const PreviewImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  margin-bottom: 2rem;
`

export const Error = styled.span`
  font-size: 0.9375rem;
  color: #DB6262;
  margin-top: 1rem;
`