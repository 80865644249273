import { Card } from 'components/CardContainer'
import Input from 'components/new/Input'
import Textarea from 'components/new/Input/Textarea'
import { Link } from 'react-router-dom'
import styled from 'styled-components'


export const InputTextAre = styled(Textarea)`
`
export const ContainerFormDescription = styled.div`
    width:80%;
    @media screen and (max-width:887px){
        width: 100%
    }
`
export const StyledLink = styled(Link)`
    color: #9e9e9e;
    font-size: 14px;
    text-decoration: none;
    :hover{
        color: #9e9e9e;
    }
`
export const Tabs = styled.div`
    display: flex;
    position: relative;
    
    top: -70px;
    left: -39px;
    @media screen and (max-width: 767px){
        left: -5px;
        top: -34px;
    }
`
export const FicharActive = styled.div`
    width: 120px;
    height: 30px;
    border: 1px solid #d9d9d9;
    border-bottom: none;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #ffff;
    padding-top: 4px;
`
export const FicharInative = styled.button`
    width: 120px;
    height: 30px;
    border: 1px solid #d9d9d9;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #ffff;
    padding-top: 4px;
    cursor: pointer;
    outline: none;

    :hover{
        border: 1px solid var(--main-shade);
    }
`
export const FicharInative2 = styled.div`
    width: 120px;
    height: 30px;
    border: 1px solid #d9d9d9;
    position: relative;
    top: -129px;
    left: 250px;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #ffff;
    cursor: pointer;

    :hover{
        border: 1px solid #85a83c;
    }
`
export const EditInput = styled(Input)`
`
export const SubTitle = styled.div`
    margin-top: 10px;
    width:100%;
    text-align:left;
    color: #898989;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;

    p{
        margin: 0;
        cursor: pointer;
    }
`
export const FormPass = styled.div`
    margin-bottom: 10px;
    label{
        padding-left: 20px;
    }

`
export const CardContainer = styled(Card)`
    margin-top: 40px;
    border-top-left-radius: 0;
    width: 100%;
    padding: 40px;
    margin-bottom: 40px;
    @media screen and (max-width: 767px){
        padding: 4px;
    }
`
export const Title = styled.p`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #5C5C5C;
`
export const Basic = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width:887px){
        flex-direction: column;
    }
`
export const Flex = styled.div`
    display: flex;
    @media screen and (max-width: 767px){
    flex-direction: column;
    }   
`
export const Description = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    flex-wrap: wrap;
`
export const Picture = styled.div`
    border-radius: 50%;
    width: 160px;
    height: 160px;
    border: 2px solid #FAFAFA;
    box-shadow: 5px 5px 5px #E4E4E4;
    cursor:pointer;
    img{
        width: 160px;
        height: 160px;
        border-radius: 50%;
        object-fit: cover;
    }
`
export const ContainerForm = styled.div`
   width: 60%;
   @media screen and (max-width:887px){
        width: 100%
    }
`
export const Conquistas = styled.div`
   width:200px;
   height: 290px;
   border-radius: 5px;
   border: 1px solid #C0C0C0;
   display: flex;
   flex-direction: column;
    justify-content: space-around;
    align-items: center; 
`
export const Premium = styled.div`
    margin: 12px 0;
    display: flex;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #5C5C5C;
    justify-content: center;
    align-items: center;
    img{
        margin-right: 10px;
    }
`
export const Button = styled.button`
    width: 150px;
    color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 3rem;
    background-color: var(--main);
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.015625rem;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 36px;
    margin-top: 80px;


    &:hover {
    color: #FFFFFF;
    background-color: var(--main-highlight);
    }
`
export const FooterButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;
`
export const SaveButton = styled.button`
    cursor: pointer;
    background: transparent;
    margin-top: 80px;
    width: 150px;
    height: 36px;
    text-align: center;
    border: 1px solid #C0C0C0;
    border-radius: 48px;
    color: #C0C0C0;
    transition: all 0.3s;
    outline: none;
    &:hover{
        border: 1px solid var(--main);
        color: white;
        background-color: var(--main);
    }
`