import React from 'react';
import { MenuLink } from './styles'
import {
    HomeOutlined,
    AuditOutlined,
    BarsOutlined,
    ShopOutlined,
    DollarCircleOutlined,
    ExclamationCircleOutlined,
    ToolOutlined,
    SettingOutlined,
    ContactsOutlined,
  } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const SuperAdmDesktopView = () => {

    return (
        <MenuLink
        className="menu"
        style={{ background: 'black' }}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={['1']}
        >
            <MenuLink.Item key="1" icon={<HomeOutlined />}>
                <Link to="/">Home</Link>
            </MenuLink.Item>
            <MenuLink.Item key="2" icon={<DollarCircleOutlined />}>
                <Link to="/financial">Financeiro</Link>
            </MenuLink.Item>
            <MenuLink.Item key="3" icon={<DollarCircleOutlined />}>
                <Link to="/marketPlace">MarketPlace</Link>
            </MenuLink.Item>
            <MenuLink.Item key="4" icon={<ShopOutlined />}>
                <Link to="/products">Produtos</Link>
            </MenuLink.Item>
            <MenuLink.Item key="5" icon={<AuditOutlined />}>
                <Link to="/student-producer">Alunos</Link>
            </MenuLink.Item>
            <MenuLink.Item key="6" icon={<ContactsOutlined />}>
                <Link to="/suppliers">Fornecedores</Link>
            </MenuLink.Item>
            <MenuLink.Item key="7" icon={<BarsOutlined />}>
                <Link to="/reports">Relatórios</Link>
            </MenuLink.Item>
            <MenuLink.Item key="8" icon={<ExclamationCircleOutlined />}>
                <Link to="/cancel">Estorno</Link>
            </MenuLink.Item>
            <MenuLink.Item key="9" icon={<ToolOutlined />}>
                <Link to="/config">Configurações</Link>
            </MenuLink.Item>
            <MenuLink.Item key="10" icon={<SettingOutlined />}>
                <Link to="/BackOffice">BackOffice</Link>
            </MenuLink.Item>
        </MenuLink>
    )
}

export default SuperAdmDesktopView