import styled from 'styled-components'
import { Input } from 'antd'


export const FormContainer = styled.form`
  width: 100%;

  p {
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.025rem;
    color: #898989;
    mix-blend-mode: normal;
    opacity: 0.6;
    margin-left: 1.25rem;
  }
`

export const Upload = styled(Input)`
    height: 55px;
    border-radius: 2.8125rem;
    padding: 10px;
    margin-bottom: 1.6rem;
    border: 1px solid #c0c0c0;
    position: relative;

    & > input:focus ~ legend,
    & > .ant-selector-select:focus ~ legend,
    & > textarea:focus ~ legend {
    color: var(--main);
    }

    & > input {
    border-radius: inherit;
    width: 90%;
    padding-bottom: 0.7rem;
    padding-left: 1.0625rem;
    font-size: 1rem;
    outline: none;
    border: none;
    background: none;
    margin-top: -0.625rem;

    &::placeholder {
        font-size: 0.875rem;
        line-height: 1.25rem;
        letter-spacing: 0.015625rem;
        color: #a2a2a2;
    }
    }

`
export const Container = styled.input`
  border-radius: inherit;
  width: 90%;
  padding-bottom: 0.9rem;
  padding-left: 1.0625rem;
  font-size: 1rem;
  outline: none;
  border: none;
  background: none;
  margin-top: 0.325rem;

  &::placeholder {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.015625rem;
    color: #a2a2a2;
  }
`