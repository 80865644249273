import styled from 'styled-components'

export const Container = styled.div`

    &&& text{
        fill: #A2A2A2;
    }

    &&& .recharts-cartesian-axis-line{
        display: none;
    }
    &&& .recharts-cartesian-axis-tick-line{
        display: none;
    }

    &&& .recharts-cartesian-grid-horizontal line{
        stroke-dasharray: 0;
    }

    &&& .recharts-cartesian-grid-vertical{
         display: none;
    }
    &&& .recharts-default-legend{
        display: none;
    }
    // &&& .recharts-line path{
    //     stroke: var(--main);
    // }
    // &&& .recharts-line-dots circle{
    //     stroke: var(--main);
    // }
    // &&& .recharts-line-dots circle:hover{
    //     stroke: var(--main);
    // }
    // &&& .recharts-active-dot circle{
    //     stroke: var(--main);
    // }
`