import { RadioGroup } from '@material-ui/core'
import { Pagination } from 'antd'
import { Card } from 'components/CardContainer'
import styled from 'styled-components'

export const BodyContainer = styled.main`
    display: flex;
`

export const CardContainer = styled(Card)`
    width: 100%;
    height: 400px;
    padding: 40px;
    margin-left: 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`
export const BtnYear = styled.div`
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: var(--main);
    display: flex;
    p{
        margin-left: 40px;
        margin-right: 40px;
    }
    svg{
        margin-top: 5px;
        cursor: pointer;
    }

    svg:first-child{
        transform: rotate(180deg);
    }
`

export const FlexContainer = styled.div`
  display: flex;
  @media screen and (max-width: 1253px){
    flex-direction: column;
  }
`
export const CardTitle = styled.h1`
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 1rem;
  margin-bottom: 1.7756rem;

  letter-spacing: 0.09375rem;
  text-transform: uppercase;

  color: #5C5C5C;
`
export const GraphContainer = styled.div`
    border: 1px solid #c1c1c1;
    border-radius: 0.625rem;
    padding: 16px;
    width: 80%;
    margin-left: 16px;
    margin-bottom: 16px;
    .title{
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #5C5C5C;
    }

    .dBoHet{
        height: 70%;
    }
    @media screen and (max-width:1253px){
      width: 100%;
      margin-left: 0;
      margin-top: 12px;
    }
`
export const TableContainer = styled.div`
    border: 1px solid #c1c1c1;
    border-radius: 0.625rem;
    padding: 16px;
    width: 100%;
    margin-bottom: 16px;
    .title{
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #5C5C5C;
    }

    .dBoHet{
        height: 70%;
    }
    @media screen and (max-width:1253px){
      width: 100%;
      margin-left: 0;
      margin-top: 12px;
    }
`
export const Button = styled.button`
    border: 1px solid var(--main);
    box-sizing: border-box;
    border-radius: 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: var(--main);
    background: transparent;
    width: 150px;
    height: 36px;
    cursor: pointer;
    transition: all 0.3s;
    outline: none;
    margin-right: 15px;
    &:hover{
        border: 1px solid #5C5C5C;
        color: #5C5C5C; 
    }
`
export const QtdSells = styled.div`
    width: 100%;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #5C5C5C;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
`
export const FooterCard = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.15px;
    color: #5C5C5C;

    p{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        letter-spacing: 0.15px;
        color: #5C5C5C;
        margin-left: 7px;
    }
    div{
        display: flex;
        margin-left: 80px;
    }
`
export const SubTitle = styled.div`
    margin-top: 10px;
    width:100%;
    text-align:left;
    color: #898989;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    p{
        margin: 0;
        cursor: pointer;
    }
`

export const StyledPagination = styled(Pagination)`
  margin: 1rem auto 2.5rem;

  > .ant-pagination-item-active {
    border-color: var(--main);

    > a {
      color: var(--main);
    }

    &&&:hover {
      border-color: var(--main);
      color: var(--main);
    }
  }
`
export const FooterPagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const FilterContainer = styled.div`
  grid-column: 2;
  background-color: #fff;
  border-radius: 10px;
  margin: 5px 0;
`
export const InfoFinancial = styled.div`
  
  background-color: #fff;
  
`
export const Flex = styled.div`
 margin-top: 12px;
 display: flex;
 flex-wrap: wrap;
 justify-content: space-between;
`
export const HeaderTable = styled.div`
 margin-top: 12px;
 display: flex;
 justify-content: space-between;
 padding: 0 16px;
`
export const StyledRadio = styled(RadioGroup)`
  flex-direction: row !important;
  label{
    width: 200px;
  }

`