import React from 'react'
import { Dot } from './styles'


interface Props {
  src?: string,
  onClick?: any,
  icon?: any
  background?: any
}

const DotImage: React.FC<Props> = ({ src, onClick, icon, background }) => {
  return (
    <Dot background={background} className="dot" onClick={onClick}>
      {' '}
      {src? <img
        src={src}
        alt=""
        style={{ height: 40, width: 40, borderRadius: '50%', objectFit: "cover" }}
      /> : false}
      {icon? icon : false }
    </Dot>
  )
}

export default DotImage
