import { CircularProgress } from '@material-ui/core/'
import { Dropdown } from 'antd'
import Button from 'components/Button'
import { CardTitle } from 'components/CardContainer'
import {
  DataTable,



  DataTableBody, DataTableCell, DataTableHead,
  DataTableRow
} from 'components/DataTable'
import RangeSlider from 'components/RangeSlider'
import Search from 'components/Search'
import React, { useCallback, useEffect, useState } from 'react'
import { AiOutlineDownload } from 'react-icons/ai'
import api from 'services/api'
import Student from './Student'
import {
  ButtonActive, ButtonInative, DownloadCard,
  FilterCard, FlexContainer, FooterPagination, Header, MyStudentsCard,



  NoStudentMsg, SearchContainer, StyledPagination
} from './style'

interface MyStudentResponse {
  suppliers: []
  total_of_suppliers: number
}

const MyStudents: React.FC = () => {

  const [suppliers, setSuppliers] = useState<any>([])
  const [advance, setAdvance] = useState<any>()
  const [more15, setMore15] = useState<boolean>(false)
  const [totalStudents, setTotalSuppliers] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const [pageNumber, setPageNumber] = useState<number>(0)
  const getSuppliers = useCallback(async () => {

    let { data } = await api.get('/suppliers', {
      params: { page_index: pageNumber, page_size: pageSize },
    })

    if (data.records === undefined) {
      setSuppliers([])
    } else {
      setSuppliers(data.records)
    }
    setPageNumber(data.page_index)
    setPageSize(parseInt(data.page_size, 10))
    setTotalSuppliers(data.total_records)
  },[pageNumber, pageSize])

  const handlePageNumber = (page: number, size?: number) => {
    setPageNumber(page - 1)
    setPageSize(size || pageSize)
    window.scrollTo(0, 100)
  }

  useEffect(() => {
    getSuppliers()
  }, [getSuppliers, pageNumber])

  const handleDownload = async (evt: any) => {
    evt.preventDefault()
    let response = await api.get('pdf-suppliers')
    window.open(response.data.pdf_url)
  }

  const searchSupplier = async (e: any) => {
    if (e.length < 1) {
      getSuppliers()
    } else {
      setSuppliers([])
      if (more15) {
        let { data } = await api.get(
          `/suppliers?name=${e}&last_interation=15`,
        )
        if (data.records === undefined) {
          setSuppliers([])
        } else {
          setSuppliers(data.records)
        }
        setPageNumber(data.page_index)
        setPageSize(parseInt(data.page_size, 10))
        setTotalSuppliers(data.total_records)
      } else if (more15 && advance) {
        let { data } = await api.get(
          `/suppliers?name=${e}&progress=${advance}&last_interation=15`,
        )
        if (data.records === undefined) {
          setSuppliers([])
        } else {
          setSuppliers(data.records)
        }
        setPageNumber(data.page_index)
        setPageSize(parseInt(data.page_size, 10))
        setTotalSuppliers(data.total_records)
      } else if (advance) {
        let { data } = await api.get(
          `/suppliers?name=${e}&progress=${advance}`,
        )
        if (data.records === undefined) {
          setSuppliers([])
        } else {
          setSuppliers(data.records)
        }
        setPageNumber(data.page_index)
        setPageSize(parseInt(data.page_size, 10))
        setTotalSuppliers(data.total_records)
      } else {
        let { data } = await api.get(`/suppliers?name=${e}`)

        if (data.records === undefined) {
          setSuppliers([])
        } else {
          setSuppliers(data.records)
        }
        setPageNumber(data.page_index)
        setPageSize(parseInt(data.page_size, 10))
        setTotalSuppliers(data.total_records)
      }
    }
  }

  /*
  const activeMoreDay = async () => {
    if (more15) {
      setMore15(false)
      let { data } = await api.get(`/suppliers`)
      if (data.records === undefined) {
        setSuppliers([])
      } else {
        setSuppliers(data.records)
      }
    } else {
      setMore15(true)
      let { data } = await api.get(`/suppliers?last_interation=15`)
      if (data.records === undefined) {
        setSuppliers([])
      } else {
        setSuppliers(data.records)
      }
    }
  }
  */

  const downloadDropdown = (
    <DownloadCard onClick={(e: any) => handleDownload(e)}>
      PDF
      <AiOutlineDownload/>
    </DownloadCard>
  )

  /*
  const filterDropdown = (
    <FilterCard>
      <div>
        <p>Última Interação:</p>
        {more15 ? (
          <ButtonActive onClick={() => activeMoreDay()}>
            Há mais de 15 dias
          </ButtonActive>
        ) : (
            <ButtonInative onClick={() => activeMoreDay()}>
              Há mais de 15 dias
            </ButtonInative>
          )}
      </div>
      <div>
        <p>Avanço no(s) curso(s) até:</p>
        <RangeSlider val={(e: any) => setAdvance(e)} value={advance} />
      </div>
    </FilterCard>
  )
  */

  return (
    <>
      <MyStudentsCard>
        <CardTitle>Meus fornecedores</CardTitle>

        <Header>
          <SearchContainer>
            <Search
              placeholder="Busque pelo nome do fornecedor"
              handleSearch={(e: any) => searchSupplier(e.target.value)}
            >
            </Search>
          </SearchContainer>
          <div style={{margin: '0 auto'}}>
            <FlexContainer>
              {/* <TotalStudents></TotalStudents> */}
              {/* <Dropdown overlay={filterDropdown} trigger={['click']}>
                <Button style={{ marginRight: '8px' }} arrowIcon onClick={(e: any) => e.preventDefault()}>
                  Filtrar
                </Button>
              </Dropdown> */}
              <Dropdown overlay={downloadDropdown} trigger={['click']}>
                <Button arrowIcon width="8rem">
                  Download
                </Button>
              </Dropdown>
            </FlexContainer>
          </div>
        </Header>

        <DataTable>
          <DataTableHead>
            <DataTableRow>
              <DataTableCell></DataTableCell>
              <DataTableCell>Nome</DataTableCell>
              <DataTableCell>Email</DataTableCell>
              {/*
              <DataTableCell>Nome do Curso</DataTableCell>
              */}
              <DataTableCell>Website</DataTableCell>
              <DataTableCell>Pix</DataTableCell>
              <DataTableCell>Telefone</DataTableCell>
            </DataTableRow>
          </DataTableHead>

          <DataTableBody>
            {!suppliers ? (
              <CircularProgress color="inherit" style={{ margin: '0 50%' }} />
            ) : suppliers.length === 0 ? (
              <NoStudentMsg>Você não tem nenhum forncedor :/</NoStudentMsg>
            ) : (
                  suppliers.map((supplier: any) => (
                    <Student supplier={supplier} key={supplier.id} />
                  ))
                )}

            
          </DataTableBody>
          
        </DataTable>
        {suppliers && totalStudents > pageSize && (
              <FooterPagination>
                <StyledPagination
                  defaultCurrent={pageNumber + 1}
                  total={totalStudents}
                  onChange={handlePageNumber}
                />
              </FooterPagination>
            )}
      </MyStudentsCard>
    </>
  )
}

export default MyStudents
