import styled from 'styled-components'

interface Props {
  width?: string
  height?: string
}

export const Container = styled.div<Props>`
  fieldset {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    border-radius: 0.625rem;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
    border: 1px solid #c0c0c0;
    position: relative;
    & > textarea:focus ~ legend {
      color: var(--main);
    }
    legend {
      margin-left: 1rem;
      background-color: #fff;
      padding: 0 0.1875rem;
      font-size: 0.82rem;
      line-height: 1rem;
      letter-spacing: 0.4px;
      color: #5c5c5c;
      transition: color 0.2s;
      width: max-content;
    }
  }
  textarea {
    resize: none;
    width: 100%;
    height: 80%;
    outline: none;
    border-radius: inherit;
    border: none;
    padding-left: 1.0625rem;
    &::placeholder {
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0.015625rem;
      color: #a2a2a2;
    }
  }
  p {
    font-weight: normal;
    font-size: 0.81rem;
    line-height: 1rem;
    letter-spacing: 0.025rem;
    color: #898989;
    mix-blend-mode: normal;
    opacity: 0.6;
    margin-left: 1.25rem;
  }
`