import React from 'react'
import { Line } from "react-chartjs-2";

import {
    Container
} from './style'

interface Content{
    label: string,
    data: Array<number>,
    fill: boolean,
    backgroundColor?: string,
    borderColor: string,
  
}

interface Data{
    labels: Array<string>
    content: Array<Content>
    // datasets: Array<object>
}
// Exemplo de dados
// const data = {
//   labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
//   datasets: [
//     {
//       label: "First dataset",
//       data: [33, 53, 85, 41, 44, 65],
//       fill: true,
//       backgroundColor: "rgba(75,192,192,0.2)",
//       borderColor: "rgba(75,192,192,1)"
//     },
//     {
//       label: "Second dataset",
//       data: [33, 25, 35, 51, 54, 76],
//       fill: false,
//       borderColor: "#742774"
//     }
//   ]
// };

const SimpleLineChart: React.FC<Data> = ({labels, content, }) => {
    const options = {
        spanGaps: false,
        pointBackgroundColor: '#fff',
        scales: {
            yAxes: [{
              gridLines: {
                drawBorder: false,
              },
            }],
            xAxes: [{
                gridLines: {
                  display: false,
                },
              }],
          },
    }
    const dataGraph = {
        labels,
        datasets: content
    };
    return (
            <Container>
                <Line data={dataGraph} options={options}/>
            </Container>
    )
}

export default SimpleLineChart;