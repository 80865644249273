import styled from 'styled-components'
import Form from 'components/new/Form'

export const FormContainer = styled(Form)`
  width: 85%;
  max-width: 55rem;
  display: grid;
  grid-gap: 2.5rem 0rem;
  margin: 2.440624rem 0;
`

export const Flex = styled.section`
  display: flex;
  align-items: center;

  & > * {
    margin: 0;
  }
`

export const NoCoProducer = styled.h2`
  text-align: center;
  margin: 2rem 0;
  font-size: 0.9rem;
  color: #777;
`

export const DonateForm = styled(Form)`
  display: grid;
  grid-template-columns: 17.425rem 64%;
  grid-template-rows: auto;
  grid-column-gap: 2.7rem;
  margin-bottom: 2rem;
`

export const Donate = styled.section`
  grid-row: 1;
  grid-column: 1 / 3;
  display: flex;
  align-items: center;
  width: 18.75rem;
  margin: 3.125rem 0 1.125rem;
  input {
    margin-right: 0.7rem;
    width: 0.9375rem;
    height: 1.25rem;
  }
  p {
    line-height: 1.25rem;
    margin: 0;
    letter-spacing: 0.015625rem;
    color: #5c5c5c;
    width: 8.25rem;
  }
`

export const InputContainer = styled.div`
  h1 {
    font-size: 0.9rem;
    letter-spacing: 0.03125rem;
    color: #292929;
  }

  .content > div:first-child {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(24%, 1fr));
    grid-gap: 0.85rem;
  }
`

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.3rem 0 1.3rem;

  p {
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.03125rem;
    color: #292929;
    margin: 0;
  }
`
