import { CircularProgress } from '@material-ui/core/'
import { SubmitHandler } from '@unform/core'
import Input from 'components/new/Input'
import Select from 'components/new/Input/Select'
import Text from 'components/new/Input/Text'
import React, { useEffect, useRef, useState } from 'react'
import api from 'services/api'
import { displaySucessNotification } from 'utils/displayNotification'
import displayYupErrorNotification from 'utils/displayYupErrorNotification'
import * as Yup from 'yup'
import {
  ContentForm,
  InputsSection,
  Register,
  TableContainer
} from './style'

import {
  cpfMask,
} from 'components/new/Input/mask'
interface FormData {
  email: string
  cell_phone: string
  name: string
  address: string
  product: any
}

interface Props {
  products: any
}

const notificationStyle = {
  right: '1.875rem',
  width: '16.25rem',
  borderRadius: '0.625rem'
}

const textAreaHeight = '11.0625rem'

const Coupon: React.FC<Props> = ({ products }) => {
  const [email, setEmail] = useState<any>(null)
  const [selectedProduct, setSelectedProduct] = useState<any>(null)
  const [name, setName] = useState<any>('')
  const [discount, setDiscount] = useState<number>(0)
  const [document, setDocument] = useState<any>('')
  const [loading, setLoading] = useState<boolean>(false)

  const handleProducts = ({ value, label }: any) => {
    setSelectedProduct({
      ...selectedProduct,
      product: { value, label }
    })
  }

  const handleDiscount = (e: any) => {
    setDiscount(e.target.value)
  }

  const handleEmail = (e: any) => {
    setEmail(e.target.value)
  }

  const handleName = (e: any) => {
    setName(e.target.value)
  }

  const handleDocument = (e: any) => {
    setDocument(cpfMask(e.target.value))
  }

  const formRef = useRef<SubmitHandler>(null)
  const handleSubmitCoupon: SubmitHandler<FormData> = async data => {
    try {
      const schema = Yup.object().shape({
        product: Yup.number().required('Selecione um produto.').typeError('Por favor, selecione um produto.'),
        email: Yup.string().email().required('Adicione um e-mail para ser enviado.'),
        discount: Yup.number().required('Coloque um desconto para o produto.').typeError('Por favor, adicione um desconto.'),
        document: Yup.string().required('Adicione o documento do cliente.'),
        name: Yup.string().nullable(),

      })

      await schema.validate(data, {
        abortEarly: false,
      })

      let newData = {
        productId: selectedProduct.product.value,
        email: email,
        discount: discount,
        document: document,
        name: name,
      }
      setLoading(true)
      await api.post('/generate-cupom', newData)
      displaySucessNotification('Produto com desconto enviado com sucesso!')
    } catch (err) {
      displayYupErrorNotification(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <TableContainer>
      <p className="title">Gerar cupom</p>
      <ContentForm ref={formRef} onSubmit={handleSubmitCoupon}>
        <InputsSection>
          <Input title="Produto desejado">
            <Select
              options={products}
              name="product"
              placeholder="Selecione o produto"
              onChange={handleProducts}
              defaultValue={selectedProduct?.product}
            />
          </Input>

          <Input title="E-mail do cliente">
            <Text
              name="email" placeholder="E-mail do beneficiado"
              onChange={handleEmail} maxLength={100} />
          </Input>

          <Input title="Desconto do produto">
            <Text
              name="discount" type="number" min={1} max={90} placeholder="%"
              onChange={handleDiscount} maxLength={3} />
          </Input>

          <Input title="Documento do cliente">
            <Text
              name="document" placeholder="Documento do beneficiado"
              value={document}
              onChange={handleDocument} maxLength={100} />
          </Input>

          <Input title="Nome do cliente">
            <Text
              name="name" placeholder="Nome do beneficiado"
              onChange={handleName} maxLength={100} />
          </Input>


          <Register type="submit">
            {loading ? (
              <CircularProgress color="inherit" size={'1.1rem'} />
            ) : (
                <p>Enviar desconto</p>
              )}
          </Register>
        </InputsSection>
      </ContentForm>
    </TableContainer>
  )
}

export default Coupon