import { CircularProgress } from '@material-ui/core/'
import { Radio } from 'antd'
import Product from 'components/BackOffice/Product'
import HeaderPromotor from 'components/HeaderPromotor'
import { displaySucessNotification, displayErrorNotification } from 'utils/displayNotification'
import Search from 'components/new/Search'
import { Container } from 'GlobalStyles'
import React, { useCallback, useEffect, useState } from 'react'
import api from 'services/api'
import history from 'routes/history'
import {
  Button, ErrorScreen, Header,
  NewProductBtn,
  NoProductMsg,
  ProductList, SearchContainer, Subheader,
  Title
} from './style'


const Backoffice = () => {
  const [products, setProducts] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [profileValid, setProfileValid] = useState<boolean>(false)
  const [status, setStatus] = useState('EM ANÁLISE')
  const [err, setErr] = useState<string>()
  const [producersId, setProducersId] = useState<string[]>([''])
  
  const getProducts = useCallback(async (info?: any) => {
    if (info === 'TODOS OS PRODUTOS') {
      let { data } = await api.get(`/list-all-products`)
      setProducts(data.records)
    } else {
      let { data } = await api.get(`/list-all-products?currentstatus=${info || status}`)
      setProducts(data.records)
    }
  }, [status])

  const checkProfileStatus = async () => {
    let { data } = await api.get('/profile')
    data.user.type_user === 'superadm' ? setProfileValid(true) : history.push('/')
  }

  const search = async (v: any) => {
    if (v.length < 1) {
      getProducts()
    }
    if (v.length >= 2) {
      setLoading(true)
      try {
        let { data } = await api.get(`/list-all-products?name=${v}`)
        setProducts(data.records)
        setErr('Você não tem nenhum produto cadastrado com esse nome.')
      } catch (error) {
        setErr('Tente novamente')
      } finally {
        setLoading(false)
      }
    }
  }

  const listProducersById = async () => {
    let { data } = await api.get(`/report-list-productors`)

    const producerList = data.users.map((user: { id: any }) => user.id)
    setProducersId(producerList)
  }

  const genRelatorio = async () => {
    try {
      let obj = {producer_id: producersId}
      await api.post(`/report-generate-multiple-report`, obj)

      var byteArray = new Uint8Array(producersId.length);
      var a = window.document.createElement('a');

      a.href = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
      a.download = `relatorio-de-produtores-${Date.now()}.xlsx`;

      // Append anchor to body.
      document.body.appendChild(a)
      a.click();

      // Remove anchor from body
      document.body.removeChild(a)

    } catch (error) {
      setErr('Tente outra vez.')
    }
  }

  const generateSales = async () => {
    try {
      await api.post(`/generate-sales`)

      displaySucessNotification('Gerar vendas executado com sucesso.')
    } catch (error) {
      displayErrorNotification(`Não foi possível executar a tarefa.`)
      // window.open('https://auth.mercadolivre.com.br/authorization', '_blank')
    }
  }

  const generateOrders = async () => {
    try {
      /*
      await api.get(`http://localhost:3333/generate-supplier-orders`, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      */

      await api.get(`/generate-orders`)

      displaySucessNotification('Gerar vendas executado com sucesso.')
    } catch (error) {
      displayErrorNotification(`Não foi possível executar a tarefa.`)
      // window.open('https://auth.mercadolivre.com.br/authorization', '_blank')
    }
  }

  useEffect(() => {
    checkProfileStatus()
    getProducts()
    listProducersById()
  }, [getProducts])

  return (
    <Container>
      <HeaderPromotor SearchHeader />

      <Title>BackOffice</Title>
      { profileValid ? (
        <>
          <Header>
            <NewProductBtn to="/BackOfficeConfig">
              Configurações
            </NewProductBtn>
            <SearchContainer>
              <Search
                placeholder="Busque pelo nome do produto"
                handleSearch={(e: any) => search(e.target.value)}
              />
              <div style={{display: 'flex'}}>
                <NewProductBtn to="/BackOffice/productors">
                  Ver produtores
                </NewProductBtn>
                <Button onClick={() => generateSales()}>Gerar Vendas</Button>
                <Button onClick={() => generateOrders()}>Gerar Pedidos</Button>
                <Button onClick={() => genRelatorio()}>Gerar Relatorio</Button>
              </div>
              <br />

              <Radio.Group value={status} onChange={(e: any) => { setStatus(e.target.value); getProducts(e.target.value); }}>
                  <Radio.Button value="TODOS OS PRODUTOS">Todos os produtos</Radio.Button>
                  <Radio.Button value="EM ANÁLISE">Em Análise</Radio.Button>
                  <Radio.Button value="APROVADO">Aprovados</Radio.Button>
                  <Radio.Button value="RECUSADO">Recusados</Radio.Button>
                  <Radio.Button value="BLOQUEADO">Bloqueados</Radio.Button>
              </Radio.Group>
            </SearchContainer>
          </Header>

          <Subheader>
            <h1>{products ? products.length : '0'} {status === "TODOS OS PRODUTORES" ? "Produtor(es) Cadastrado(s)" : "Produto(s) Cadastrado(s)"}</h1>
          </Subheader>
          <ProductList>
            {loading ? (
              <CircularProgress color="inherit" style={{ margin: '0 50%' }} />
            ) : !products ? (
              <CircularProgress color="inherit" />
            ) : products && products.length === 0 ? (
              <NoProductMsg>Não há produto cadastrado :/</NoProductMsg>
            ) : (
                    products &&
                    products.map((product: any) => (
                      <Product product={product} key={product.id} />
                    ))
                  )}
          </ProductList>
        </>
      ) : (
          <ErrorScreen>
            {
              err ? (
                <p>{err}</p>
              ) : (
                  <CircularProgress color="inherit" />
                )
            }
          </ErrorScreen>
        )
      }
    </Container>
  )
}

export default Backoffice
