const colors = [
  "#32a852",
  "#fcff2e",
  "#ff9d2e",
  "#ff362b",
  "#5cff2b",
  "#2b4eff",
  "#a424ff",
  "#f424ff",
  "#fc0d5d",
  "#19ffcd",
]

export default colors
