import React from "react";
import {
  DataTable,
  DataTableHead,
  DataTableRow,
  DataTableCell,
  DataTableBody,
} from 'components/DataTable'
import {
  ContainerTable
} from './style'
import PayMethod from './PayMethod'
import { CircularProgress } from '@material-ui/core/'

interface PaymentMethod {
  paymentMethod: any
}

const SkeletonTable: React.FC<PaymentMethod> = ({ paymentMethod }) => {

  return (
    <ContainerTable>
      <DataTable>
        <DataTableHead>
          <DataTableRow>
            <DataTableCell>Formas de pagamento</DataTableCell>
          </DataTableRow>
        </DataTableHead>

        <DataTableBody>
          {
            !paymentMethod ? (
              <CircularProgress color="inherit" />
            ) : (
                paymentMethod && paymentMethod.map((product: any) => (
                  <PayMethod paymentMethod={product} key={product.id} />
                ))
              )
          }
        </DataTableBody>
      </DataTable>
    </ContainerTable>
  );
};

export default SkeletonTable;
