import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react'
import { Container, InputLabel, PreviewImg, Error } from './style'

import { useField } from '@unform/core'

interface Props {
  name: string
}

type InputProps = JSX.IntrinsicElements['input'] & Props

const ImageInput: React.FC<InputProps> = ({ name, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const { fieldName, registerField, defaultValue } = useField(name)
  const [preview, setPreview] = useState(defaultValue)
  const [error, setError] = useState(false)

  const handlePreview = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    let base64: any
    if (evt.target.files && evt.target.files?.[0]) {
      var reader = new FileReader()
      reader.readAsDataURL(evt.target.files[0])
      reader.onload = function (evt) {
        var img: any = new Image()

        img.onload = function () {
          if (img.width < 300 || img.height < 300) {
            setError(true)
          } else {
            setError(false)
            base64 = reader.result

            setPreview(base64)

            let value: any = base64.toString()
            sessionStorage.setItem('photo', value)
          }

          return
        }

        img.src = reader.result

        if (error) img.src = null
      }
    }

    return base64
  }, [error])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        ref.value = ''
        setPreview(null)
        setError(false)
      },
      setValue(_: HTMLInputElement, value: string) {
        setPreview(value)
      },
    })
  }, [fieldName, registerField])

  return (
    <Container>
      {preview && <PreviewImg src={preview} alt="Preview" />}
      <InputLabel for="imgInput">Selecionar arquivo</InputLabel>
      <input
        type="file"
        id="imgInput"
        accept="image/png, image/jpeg"
        ref={inputRef}
        onChange={handlePreview}
        {...rest}
      />
      {error && <Error>A imagem deve ter no mínimo 300 x 300</Error>}
    </Container>
  )
}

export default ImageInput