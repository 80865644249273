import styled from 'styled-components'
import { Steps } from 'antd'
import { Card, CardTitle } from 'components/CardContainer'

export const MainContainer = styled.main`
  display: flex;
  @media screen and (max-width: 1023px){
    flex-direction: column;
  }
`

export const StepsContainer = styled(Card)`
  width: 23.178%;
  min-width: 13.125rem;
  min-height: 40.405rem;
  margin-right: 2.480625rem;
  @media screen and (max-width: 1023px){
    width: 100%;
    min-width: unset;
    min-height: unset;
    margin-bottom: 16px;
  }
`

export const StepsContainerTitle = styled(CardTitle)``

export const ContentContainer = styled.section`
  width: 100%;
  min-height: 40.405rem;
  position: relative;
`

export const ContentFooter = styled.footer`
  width: 100%;
  display: flex;
  height: 30px;
  justify-content: space-between;
  margin: 3rem 0 0;
  position: absolute;
  bottom: 10px;
`

export const Button = styled.button`
  border: none;
  color: var(--main);
  outline: none;
  background: none;
  font-weight: 500;  
  display: inline-flex;
  justify-content: space-between;
  cursor: pointer;

  &.next-btn,
  &.done-btn {
    position: absolute;
  }

  &.next-btn {
    bottom: 0;
    right: 3.5625rem;
  }

  &.done-btn {
    bottom: 0.4rem;
    right: 5.25rem;
  }
`

export const ButtonBordered = styled.button`
  border: 1px solid var(--main);
  color: var(--main);
  outline: none;
  box-sizing: border-box;
  width: max-content;
  padding: 0.4375rem 1.18875rem;
  border-radius: 3rem;
  background-color: #FFF;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  letter-spacing: 0.015625rem;
  cursor: pointer;  
`

export const BtnBorderedWithIcon = styled.button`
  width: max-content;
  padding: 0.4375rem 1.18875rem;
  background: transparent;
  box-sizing: border-box;
  border-radius: 3rem;
  border: 1px solid var(--main);
  color: var(--main);
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  outline: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    margin-right: 0.41375rem;
    font-size: 1.34375rem;
  }
`

export const HelpButton = styled(ButtonBordered)`
  margin: 2rem auto 0;
  width: 95%;
`

export const NewProductSteps = styled(Steps)`
  .ant-steps-item-container {
    padding-bottom: 2.5rem;
    
  }

  &&& .ant-steps-item-wait::after{
    color: red !important;
  }
  
  .ant-steps-item-finish .ant-steps-item-icon >
  .ant-steps-icon {
    color: var(--main);
  }

  .ant-steps-item-process >
  .ant-steps-item-container >
  .ant-steps-item-content >
  .ant-steps-item-title {
    color: var(--main);
    line-height: 1.5;
  }

  .ant-steps-item-title {
    line-height: 1.5;
    width: 7rem;
  }
  

  .ant-steps-vertical >
  .ant-steps-item:not(:last-child) >
  .ant-steps-item-container >
  .ant-steps-item-tail {
    display: block;
    background: var(--main);
    
  
    
    ::after {
      background: var(--main);
      
    }
  }

  .ant-steps-item-icon {
    width: 2.37125rem;
    height: 2.37125rem;
    padding: 0.1875rem;
    
    svg{
      width: 100%;
      height: 100%;
      color: #999;
    }
  }
  
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: var(--main);
  }
  
  .ant-steps-item-process .ant-steps-item-icon {
    background: var(--main);
    border-color: var(--main);
  }  
  
  .ant-steps-item-finish >
  .ant-steps-item-container >
  .ant-steps-item-tail::after {
    background-color: #000;
    position: absolute;
    top: 2.3125rem;
    left: 0.125rem;
    width: 0.0625rem;
  }

`
interface ModalButtonProps {
  danger?: boolean
}

export const ModalButton = styled.button<ModalButtonProps>`
  outline: none;
  width: max-content;
  min-width: 10.875rem;
  cursor: pointer;

  border-radius: 0.2125rem;
  padding: 0.625rem 1.5625rem;
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0 0.6rem;
  transition: all 0.1s;
  text-align: center;
  border: 1px solid #e0e0e0;
  color: gray;
  background-color: white;

  &&&[type='button']:hover {
    border-color: #292929;
    color: #292929;
  }

  &&&.submit {
    background-color: var(--main);
    color: whitesmoke;

    &:hover {
      background-color: #92ce0f;
      color: #243302;
    }
  }
`
