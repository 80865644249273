import styled from 'styled-components'
import { Slider } from 'antd'

export const StyledSlider = styled(Slider)`
  &&& .ant-slider-rail {
    height: 0.125rem;
    padding: 0;

    &::selection {
      background: var(--main);    
    }
  }

  &&& .ant-slider-track {
    height: 0.125rem;
    background-color: var(--main);
    padding: 0;

    &:hover {
      background: var(--main) !important;
    }
  }

  &&& .ant-slider-handle {
    width: 0.75rem;
    height: 0.75rem;
    border: none;
    background-color: var(--main);
    padding: 0;
    top: 0.535rem;
  }
`