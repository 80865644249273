import styled, { keyframes } from 'styled-components'

const girar = keyframes`
   from {
      transform: rotate(0);
   } to {
      transform: rotate(360deg);
   }
`

export const LoadingSpin = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  margin: auto;
  border: 0.25rem solid;
  border-color: white transparent transparent transparent;
  border-radius: 50%;
  animation-name: ${girar};
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
`