/*
Página de registro de usuário.
*/

import { FormHandles, SubmitHandler } from '@unform/core'
import React, { useRef, useState } from 'react'
import { FiAtSign, FiLock, FiUser } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import { displayErrorNotification, displaySucessNotification } from 'utils/displayNotification'
import * as Yup from 'yup'
import {
  Container,
  FormContainer,
  MainContainer,
  PrimaryButton,
  StyledForm
} from '../../components/Form/Container'
import Input from '../../components/Form/Input'
import { InputGroup } from '../../components/Form/Input/style'
import { LoadingSpin } from '../../components/Loading'
import Logo from '../../components/Logo'
import history from '../../routes/history'
import api from '../../services/api'
import {
  Flex, StyledNav,

  TypeUser
} from './style'

const Register = () => {
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState<any>("producer")
  const [ password, setPassword] = useState<any>()
  const [ confirmPassword, setConfirmPassword] = useState<any>()
  
  const formRef = useRef<FormHandles>(null)

  const handleSubmit: SubmitHandler<FormData> = async (body: object) => {
    setLoading(true)
    let userValue = type
    setType(userValue)
    if(password === confirmPassword){
      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('O campo nome é obrigatório'),
          lastName: Yup.string().required('O campo sobrenome é obrigatório'),
          email: Yup.string().email().required('O e-mail é obrigatório'),
          password: Yup.string()
            .min(8, 'A senha precisa de no mínimo, 8 caracteres')
            .required('A senha é obrigatória.'),
        })

        let obj = {
          type_user: type
        }

        let value = Object.assign(body, obj)

        await schema.validate(value, { abortEarly: false })
        await api.post('/register', value)

        setLoading(false)

        displaySucessNotification('Usuário cadastrado com sucesso!')
        //displaySucessNotification(`Foi enviado um e-mail de confirmação para ${value['email']}. Por favor, cheque seu e-mail para confirmar a conta.`)

        history.push('/login')
      } catch (err) {
        const validationErrors: any = {}

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })

          if (formRef && formRef.current)
            formRef.current.setErrors(validationErrors)

            setLoading(false)
            return
        }

        displayErrorNotification(err.response.data.message)
        setLoading(false)
      }
    } else {
      displayErrorNotification('Suas senhas devem ser iguais.')
      setLoading(false)
    }
  }

  return (
    <MainContainer>
      <Container>
        <Logo />

        <StyledNav>
          <NavLink activeClassName="active" to="/register">
            Criar conta
          </NavLink>
          <NavLink activeClassName="active" to="/login">
            Acessar conta
          </NavLink>
          <NavLink activeClassName="active" to="/privacyPolicy">
            Política de privacidade
          </NavLink>
        </StyledNav>

        <FormContainer>
          <StyledForm ref={formRef} onSubmit={handleSubmit}>
            <Flex>
              <InputGroup>
                <FiUser size={20} />
                <Input type="text" name="name" placeholder="Seu nome" />
                <legend>Nome</legend>
              </InputGroup>

              <InputGroup>
                <FiUser size={20} />
                <Input
                  type="text"
                  name="lastName"
                  placeholder="Seu sobrenome"
                />
                <legend>Sobrenome</legend>
              </InputGroup>
            </Flex>

            <InputGroup>
              <FiAtSign size={20} />
              <Input type="email" name="email" placeholder="Seu email" />
              <legend>Email</legend>
            </InputGroup>

            <InputGroup>
              <FiLock size={20} />
              <Input type="password" name="password" placeholder="Sua senha" value={password} onChange={e => setPassword(e.target.value)}/>
              <legend>Senha</legend>
            </InputGroup>
            <InputGroup>
              <FiLock size={20} />
              <Input type="password" name="ConformPassword" placeholder="Sua senha" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}/>
              <legend>Confirme sua senha</legend>
            </InputGroup>
            <TypeUser>
              Deseja cadastrar como:
              <div>
                <section>
                {type === "producer" 
                ?
                <>
                  <input type="radio" id="produto" value="producer" name="type" onChange={(e) => setType(e.target.value)}checked/>
                  <label htmlFor="produto">Produtor</label>
                </>
                :
                <>
                  <input type="radio" id="produto" value="producer" name="type" onChange={(e) => setType(e.target.value)}/>
                  <label htmlFor="produto">Produtor</label>
                </>
                }
                </section>
                <section>
                  <input type="radio" id="estudante" value="student" name="type" onChange={(e) => setType(e.target.value)} />
                  <label htmlFor="estudante">Aluno</label>
                </section>
              </div>

            </TypeUser>

            <PrimaryButton>
              {loading ? <LoadingSpin /> : 'Cadastrar'}
            </PrimaryButton>
          </StyledForm>

          <NavLink to="/login">
            <p>Já possue conta? Acesse!</p>
          </NavLink>
        </FormContainer>
      </Container>

    </MainContainer>
  )
}

export default Register