import React from "react";
// TODO: Absolute path e não relative paths
import { Redirect, Route } from "react-router-dom";
import { isAuthenticated } from "../../utils/login";

const GuestRoute = ({ component, ...rest }: any) => {
  const routeComponent = (props: any) =>
    !isAuthenticated() ? (
      React.createElement(component, props)
    ) : (
      // TODO: Aspas simples
      <Redirect to={{ pathname: "/" }} />
    );
  return <Route {...rest} render={routeComponent} />;
};

export default GuestRoute;
