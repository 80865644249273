import history from '../routes/history'

// TODO: esse arquivo é um service não um utils

export const TOKEN_KEY = '@xgrow:jwt'
export const TOKEN_USER = '@xgrow:user'

export const login = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token)
}

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.clear()
  history.push('/login')
}

export const isAuthenticated = () => {
  return !!localStorage.getItem(TOKEN_KEY)
}

export const getToken = (): string | null => localStorage.getItem(TOKEN_KEY)
