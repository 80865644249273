import styled from 'styled-components'

export const MenuCard = styled.li`
  padding: 3%;

  &:hover {
    background-color: #efefef;
  }
`

export const Menu = styled.ul`
  width: 21rem;
  min-height: 20%;
  background-color: white;
  border-radius: 5px;

  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

  list-style-type: none;
  justify-content: space-between;
  cursor: pointer;
`

interface Props {
  display: any;
}

export const OverlayEstorno = styled.div<Props>`
  position: fixed;
  display: ${props => props.display}; 
  width: 100%; 
  height: 100%; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2; 
`
export const Modal = styled.div`
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: black;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);

  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

  min-height: 30%;
  width: 50%;

  p {
    margin: 3px;
    padding: 0;
    color: #000;
    font-size: 2rem;
  }

`
export const CardEstorno = styled.div`
  border: solid;
  flex: 1;
  border-radius: 5px;
  border-color: var(--main);
  background-color: white;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

  width: 50%;
  height: 50%;

  padding: 2px;
  text-align: center;
  margin: 5px;

  p {
    font-size: 24px;
    color: #c0c0c0;
  }

  &:hover {
    background-color: #efefef;
    cursor: pointer;
  }

`

export const Button = styled.button`
    border: 1px solid var(--main);
    box-sizing: border-box;
    border-radius: 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: var(--main);
    background: transparent;
    width: 150px;
    height: 36px;
    cursor: pointer;
    transition: all 0.3s;
    outline: none;
    &:hover{
        border: 1px solid #5C5C5C;
        color: #5C5C5C; 
    }
`

interface ProductLinkProps {
  danger?: boolean
  disabled?: boolean
}

export const ProductLink = styled.a<ProductLinkProps>`
  font-size: 0.9rem;
  color: ${({ disabled }) => (disabled ? `#777` : `#292929`)};
  line-height: 1rem;
  letter-spacing: 0.025rem;
  padding: 1rem;

  &:hover {
    color: ${({ danger }) => (danger ? `#db6262` : `var(--main)`)};
  }
`

export const Subcard = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.1.5625rem;
  color: #5c5c5c;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border: 0.0625rem solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 0.625rem;
  padding: 0.5rem;

  & a:nth-child(2) {
    border-top: 0.0625rem solid #e0e0e0;
    border-bottom: 0.0625rem solid #e0e0e0;
  }

  & a:nth-child(3) {
    border-bottom: 0.0625rem solid #e0e0e0;
  }
`

export const OptionsBtn = styled.div`
  cursor: pointer;
  padding: 8px;
  align-self: flex-start;
  @media screen and (max-width: 767px){
    align-self: flex-end;
  }
`