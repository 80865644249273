import styled from 'styled-components'

export const Title = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  color: #898989;
  margin-bottom: 1.52625rem;

  span {
    color: var(--main);
  }
`