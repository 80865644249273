import React, { useEffect, useRef } from 'react'
import {
  ProductCard,
  ProductImg,
  Content,
  ContentTitle,
  ContentCenter,
  Icon,
  Value,
  Label,
  FlexContainer,
  Subcard,
  ProductLink,
  OptionsBtn,
} from './style'
import { SubmitHandler } from '@unform/core'

import MoneyIcon from 'assets/icons/money-icon.svg'
import PxpayIcon from 'assets/perfil.png'
import PeoplesIcon from 'assets/icons/peoples-icon.svg'
import ThreeDotMenu from 'assets/icons/3dotmenu-icon.svg'

import { Dropdown } from 'antd'
import api from 'services/api'
import { displayErrorNotification } from 'utils/displayNotification'

interface Productor {
  productors: {
    id: number,
    avatar: string,
    name: string,
    email: string,
    totalTransactions: any[],
    isActive: boolean
  }
  key: any
}

const notificationStyle = {
  right: '1.875rem',
  width: '16.25rem',
  borderRadius: '0.625rem'
}

const ProductorsComponent: React.FC<Productor> = ({ productors}) => {
  const { id, avatar, name, email, totalTransactions, isActive } = productors

  const formRef = useRef<SubmitHandler>(null)

  const genProductorReport = async (producer_id: number) => {
    try {
      let { data: { data } } = await api.post(`/report-generate-report`, {
        producer_id
      })

      var byteArray = new Uint8Array(data.data);
      var a = window.document.createElement('a');

      a.href = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
      a.download = `relatorio-de-produtor-${Date.now()}.xlsx`;

      document.body.appendChild(a)
      a.click();
      document.body.removeChild(a)

    } catch (error) {
      displayErrorNotification('Tente novamente')
    }
  }

  useEffect(() => {
  }, [])

  const dropdownMenu = (
    <Subcard>
      <ProductLink onClick={() => { genProductorReport(id) }}>Gerar relatório</ProductLink>
    </Subcard>
  )

  return (
    <ProductCard>
      <ProductImg src={avatar} />

      <Content>
        <ContentTitle>
          <h1>{name}</h1>
        </ContentTitle>

        <ContentCenter>
          <section>
            <FlexContainer>
              <Value>{totalTransactions[0].amount ? totalTransactions[0].amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0.00'}</Value>
            </FlexContainer>

            <Label>Total</Label>
          </section>
          <section>
            <FlexContainer>
              <Icon src={PeoplesIcon} />
              <Value>{totalTransactions[0].productor_amount ? totalTransactions[0].productor_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0.00'}</Value>
            </FlexContainer>

            <Label>Valor do Produtor</Label>
          </section>
          <section>
            <FlexContainer>
              <Icon src={PxpayIcon} />
              <Value>{totalTransactions[0].pxpay_amount ? totalTransactions[0].pxpay_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0.00'}</Value>
            </FlexContainer>

            <Label>Valor da XGrow</Label>
          </section>
          <section>
            <FlexContainer>
              <Icon src={MoneyIcon} />
              <Value>{totalTransactions[0].zoop_amount ? totalTransactions[0].zoop_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0.00'}</Value>
            </FlexContainer>

            <Label>Valor da Zoop</Label>
          </section>
          <section>
            <FlexContainer>
              <Value>{isActive ?
                (<span style={{ color: 'green' }}>Usuário ativo</span>) :
                (<span style={{ color: 'red' }}>Usuário não ativo</span>)}</Value>
            </FlexContainer>
          </section>
        </ContentCenter>

        <Dropdown overlay={dropdownMenu}>
          <OptionsBtn>
            <img className='' src={ThreeDotMenu} alt="" />
          </OptionsBtn>
        </Dropdown>

        {/* 
        <Overlay display={displayOverlay}>
          <Modal>
            <Title>
              <p>Explique o motivo:</p>

              <img src={closeIcon}
                alt='x'
                onClick={() => {
                  setDisplayOverlay('none');
                  setMotivo('');
                }}
              />

            </Title>
            <Form ref={formRef}>
              <InputTextArea
                name="motivo"
                title="Motivo"
                height={'100%'}
                valueInput={motivo}
                placeholder=''
                onChangeInput={(e: any) => setMotivo(e.target.value)}
              />
            </Form>
            <Button onClick={isToBlock ? () => handleBlock(id) : () => handleRefuse(id)}>Confirmar</Button>
          </Modal>
        </Overlay> 
        */}
      </Content>
    </ProductCard>
  )
}

export default ProductorsComponent
