import React from 'react'
import { Container, BreadcrumbItem, To } from './styles'
import { FaChevronRight } from 'react-icons/fa'

interface Props {
  items: {
    name: string
    active: boolean
    link: any
  }[]
}

const BreadCrumb: React.FC<Props> = ({ items }) => {
  return (
    <Container>
      {items.map((item, index, arr) => {
        const lastItem = index + 1 === arr.length

        return (
          <To to={item.link}>
            <BreadcrumbItem key={item.name} active={item.active}>
              {item.name}
            </BreadcrumbItem>
            {!lastItem && <FaChevronRight size={10} color="#999" />}
          </To>
        )
      })}
    </Container>
  )
}

export default BreadCrumb
