import styled from 'styled-components'

export const Main = styled.main`
  width: 100%;
  margin-top: 3.6875rem;
  position: relative;
`

export const ThanksCard = styled.main`
  width: 100%;
  display: flex;
`

export const ThanksContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  height: 13rem;
`

export const ThanksIcon = styled.img``

export const ThanksText = styled.h1`
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-align: center;
  letter-spacing: 0.03125rem;
  color: #000000;
`