import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import Forward10Icon from '@material-ui/icons/Forward10';
import Replay10Icon from '@material-ui/icons/Replay10';
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import PauseIcon from '@material-ui/icons/Pause'
import Slider from '@material-ui/core/Slider'
import Tooltip from '@material-ui/core/Tooltip'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import FullScreenIcon from '@material-ui/icons/Fullscreen'
import Popover from '@material-ui/core/Popover';
import VolumeOff from '@material-ui/icons/VolumeOff'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { VisibilityOff } from '@material-ui/icons'
import Crop75Icon from '@material-ui/icons/Crop75';
import CropDinIcon from '@material-ui/icons/CropDin';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';


interface Props {
  onPlayPause?: any
  playing?: any
  onRewind?: any
  onFastForward?: any
  muted?: any
  onMute?: any
  onVolumeChange?: any
  onVolumeSeekUp?: any
  volume?: any
  playbackRate?: any
  onPlaybackRateChange?: any
  fullscreen?: any
  onFullscreen?: any
  onToggleFullScreen?: any
  played?: any
  onSeek?: any
  onSeekMouseDown?: any
  onSeekMouseUp?: any,
  elapsedTime?: any
  totalDuration?: any
  onChangeDisplayFormat?: any
  videoName?: any
  mobile?: any
  handleControlsVisibility?: any
  view?: any
  onView?: any
  wideView?: any
  onPIP?: any
  // onBookMark?: any



  children?: any
  open?: any
  value?: any
}

const useStyles = makeStyles({
  controlsWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "100%",
    background: 'linear-gradient(0deg, rgba(0,0,0,1) 2%, rgba(255,255,255,0) 50%, rgba(0,0,0,0.5522584033613445) 100%)',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // zIndex: 1,
  },

  controlIcons: {
    color: "#fff",
    fontSize: 45,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1)"
    }
  },

  bottomIcons: {
    color: "#999",
    "&:hover": {
      color: "#fff"
    }
  },

  volumeSlider: {
    width: 100,
    color: 'var(--main)',
  }
})

function ValueLabelComponent(props: Props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const PrettoSlider = withStyles({
  root: {
    color: "var(--main)",
    height: 0,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid var(--main-shade)',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const PlayerControls: React.FC<Props> = ({ onPlayPause,
  playing,
  onRewind,
  onFastForward,
  muted,
  onMute,
  onVolumeChange,
  onVolumeSeekUp,
  volume,
  playbackRate,
  onPlaybackRateChange,
  fullscreen,
  onFullscreen,
  onToggleFullScreen,
  played,
  onSeek,
  onSeekMouseDown,
  onSeekMouseUp,
  elapsedTime,
  totalDuration,
  onChangeDisplayFormat,
  videoName,
  mobile,
  handleControlsVisibility,
  view,
  onView,
  wideView,
  onPIP,
  // onBookMark,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'playbackrate-popover' : undefined;

  return (

    <div className={classes.controlsWrapper}>
      {/* Controles da parte de cima */}
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
        style={{ padding: 16 }}
      >
        {
          fullscreen ? (
            <>
              <div style={{ width: '5%', height: '5%', borderRadius: '0 0 40px 0', backgroundColor: 'rgba(0,0,0,0.2)', zIndex: 99, top: '0', left: '0', position: 'fixed' }} onMouseOver={() => {
                handleControlsVisibility()
              }}></div>
              <div style={{ width: '5%', height: '5%', borderRadius: '0 0 0 40px', backgroundColor: 'rgba(0,0,0,0.2)', zIndex: 99, top: '0', right: '0', position: 'fixed' }} onMouseOver={() => {
                handleControlsVisibility()
              }}></div>

              <div style={{ width: '100%', height: '1.5%', backgroundColor: 'rgba(0,0,0,0.2)', zIndex: 99, bottom: '0', position: 'fixed' }} onMouseOver={() => {
                handleControlsVisibility()
              }}></div>
            </>
          ) : (<></>)
        }

        <Grid item>
          <Typography variant="h5" style={{ color: "#fff" }}>{videoName}</Typography>
        </Grid>

        {mobile ? null
          :
          <IconButton onClick={onPIP} /*className={classes.controlIcons}*/ >
            <PictureInPictureIcon fontSize="inherit" style={{ color: "#fff" }} />
          </IconButton>}

        {/* <Grid item>
          <Button onClick={onBookMark} variant="contained" color="primary" startIcon={ <BookmarkIcon/> }>
            Bookmark
          </Button>
        </Grid> */ }
      </Grid>

      {/* Controles no meio */}
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="center"
      >
        <IconButton onClick={onRewind} className={classes.controlIcons} aria-label="rewind">
          <Replay10Icon fontSize="inherit" />
        </IconButton>

        <IconButton onClick={onPlayPause} className={classes.controlIcons} aria-label="play">
          {playing ? (
            <PauseIcon fontSize="inherit" />
          ) : (
              <PlayArrowIcon fontSize="inherit" />
            )
          }
        </IconButton>

        <IconButton onClick={onFastForward} className={classes.controlIcons} aria-label="forward">
          <Forward10Icon fontSize="inherit" />
        </IconButton>
      </Grid>

      {/* Controles da parte de baixo */}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ padding: 16, width: '100%' }}
      >
        <Grid item xs={12}>
          <PrettoSlider
            min={0}
            max={100}
            value={played * 100}
            ValueLabelComponent={(props) =>
              <ValueLabelComponent {...props} value={elapsedTime} />}
            onChange={onSeek}
            onMouseDown={onSeekMouseDown}
            onChangeCommitted={onSeekMouseUp}
          />

        </Grid>

        <Grid item style={{ width: '100%' }}>


          <Grid container alignItems="center" style={{ justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {mobile ? null :
                <>
                  <IconButton onClick={onPlayPause} className={classes.bottomIcons}>
                    {playing ? (
                      <PauseIcon fontSize="large" />
                    ) : (
                        <PlayArrowIcon fontSize="large" />
                      )}
                  </IconButton>

                  <IconButton onClick={onMute} className={classes.bottomIcons}>
                    {muted ? (
                      <VolumeOff fontSize="large" />
                    ) : (
                        <VolumeUpIcon fontSize="large" />
                      )}
                  </IconButton>

                  <Slider
                    min={0}
                    max={100}
                    value={muted ? 0 : volume * 100}
                    className={classes.volumeSlider}
                    onChange={onVolumeChange}
                    onChangeCommitted={onVolumeSeekUp} />
                </>
              }

              <Button onClick={onChangeDisplayFormat} variant="text" style={{ color: "#fff", marginLeft: 16 }}>
                <Typography>{elapsedTime}/{totalDuration}</Typography>
              </Button>
            </div>


            <Grid item>
              <Button onClick={handlePopover} variant="text" className={classes.bottomIcons}>
                <Typography>{playbackRate}X</Typography>
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Grid container direction="column-reverse">
                  {[0.5, 1, 1.5, 2].map(rate => (
                    <Button onClick={() => onPlaybackRateChange(rate)} variant="text">
                      <Typography
                        color={rate === playbackRate ? "secondary" : "initial"}>
                        {rate}X
                    </Typography>
                    </Button>))}
                </Grid>
              </Popover>

              {mobile ?
                null
                :
                <IconButton onClick={() => { onView(); wideView(); }} className={classes.bottomIcons}>
                  {view ?
                    <Crop75Icon style={{ fontSize: 24 }} />
                    :
                    <CropDinIcon style={{ fontSize: 24 }} />
                  }
                </IconButton>}

              <IconButton onClick={() => { onToggleFullScreen(); onFullscreen(); }} className={classes.bottomIcons}>
                {fullscreen ?
                  <FullscreenExitIcon fontSize="large" />
                  :
                  <FullScreenIcon fontSize="large" />
                }
              </IconButton>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </div >
  )
}

export default PlayerControls