import styled from 'styled-components'
import { Card } from '../../../components/CardContainer/index'

export const MyAffiliatesCard = styled(Card)`
  width: 100%;
`

export const Header = styled.header`
  width: 100%;
  display: grid;
  grid-template-columns: 1.1fr 1fr;
`

export const SearchContainer = styled.div`
  grid-column: 1;
  margin-right: 0.8125rem;
`

export const FlexContainer = styled.div`
  grid-column: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TotalAffiliates = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  color: #898989;
  margin: 0;
  margin-left: 1.8681rem;
  width: max-content;
`