import styled from 'styled-components'
import Button from '../Button'

export const Container = styled.div`
  position: relative;
  margin-bottom: 15px;
  width: 100%;
  border: 1px solid #eee;
  padding: 20px;
  border-radius: 20px;

  > p {
    letter-spacing: 0.25px;
    color: #898989;
  }

  max-height: 400px;
  overflow: auto;

  button{
    background: transparent;
    border: none;
    color:var(--main);
    transition: all 0.2s;
  }
  button:hover{
    color:#898989;
  }
`
export const ListMore = styled.button`

    background: transparent;
    border: none;
    color:var(--main);
    transition: all 0.2s;
    cursor: pointer;
    outline: none;
  &:hover{
    color:#898989;
    outline: none
  }
`
export const ContainerForm = styled.div`
  margin-top: 30px; 
  width: 100%;
  display: flex;
  flex-direction: column;
  h3{
    text-align: center;
  }
  textarea{
    outline: none;
    padding: 20px;
    width: 100%;
    border-radius: 10px;
    min-height: 200px;
  }
  button{
    background: transparent;
    border: 1px solid var(--main);
    color: var(--main);
    border-radius: 10px;
    width: 130px;
    height: 35px;
    margin-top: 20px;
    cursor: pointer;
    transition: all 0.2s;
    align-self: flex-end;
  }
  button:hover{
    background:  var(--main);
    color: white;
  }
`

export const User = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    object-fit: cover;
    margin-right: 5px;
  }

  > h2 {
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #5c5c5c;
    margin: 0 10px 0 10px;
  }
`

export const Wrapper = styled.div`
  letter-spacing: 0.4px;
  color: #a2a2a2;
  font-size: 12px;
`

export const ReplyButton = styled(Button)`
  border: 0;
  padding: 0;
`
export const Comments = styled.div`
  width: 100%;
  margin-top: 10px;
  font-size: 18px;
  h6{
    margin-top: 10px;
    letter-spacing: 0.4px;
    color: #a2a2a2;
    font-size: 12px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 2px solid #efefef;
  }
`
