import styled from 'styled-components'

export const Container = styled.div`
  margin: auto;
  margin-top: 2.2556rem;
  width: calc(100% - 9rem);
  min-height: 100vh;
  padding: 1rem;

  @media (min-width: 1330px) {
    padding: 0;
  }
  @media screen and (max-width: 767px){
    margin: 0;
    width: 100%;
  }
`
export const Body = styled.div`
  margin-top: 40px;
  width: 100%;
  height: 100%;
`

export const Title = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgb(137, 137, 137);
  margin-bottom: 1.4481rem;
  margin-top: 1.4481rem;
`
