import React from 'react';
import { MenuLink } from './styles'
import {
    HomeOutlined,
    ShopOutlined,
  } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const StudentDesktopView = () => {

    return (
        <>
            <MenuLink
            className="menu"
            style={{ background: 'black' }}
            theme="dark"
            mode="inline"
            defaultSelectedKeys={['1']}
            >
                <MenuLink.Item key="1" icon={<HomeOutlined />}>
                    <Link to="/">Home</Link>
                </MenuLink.Item>
                <MenuLink.Item key="2" icon={<ShopOutlined />}>
                    <Link to="/products">Meus Produtos</Link>
                </MenuLink.Item>
            </MenuLink>
        </>
    )

}

export default StudentDesktopView