import styled from 'styled-components'
import {
  Card
} from '../../components/CardContainer/index';

export const Cards = styled(Card)``;

export const TitleContainer = styled.p`
width: 100%;
text-align: left;
font-size: 14px;
color: #898989;
`

export const Container = styled.div`
  width: 100%;
  height: 230px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
`
export const InfoAfiliados = styled.div`
  width: 225px;
  height: 149px;
  text-align: center;
  display: flex;
  color: 5C5C5C;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;

  
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const NewSolit = styled.div`
  height: 65px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #85be08;
  text-align: left;
  img{
    height: 35px;
  }
  strong{
    margin: 0 10px;
    font-size: 20px;
  }
  p{
    margin: 0 10px;;
  }
`

export const TopAfilliate = styled.div`
  width: 800px;
  height: 149px;
  color: 5C5C5C;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

`
export const Afilliates = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .eMZySq:first-child img{
    border: 3px solid #ebbd19;
  }
  .eMZySq:nth-child(2n) img{
    border: 3px solid #ccc;
  } 
  .eMZySq:last-child img{
    border: 3px solid #D88131;
  } 
  
`
export const FirstAfilliate = styled.div`
  width: 200px;
  height: 65px;
  display:flex;
  

  img{
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: 3px solid #ebbd19;
  }
`
export const SecondAfilliate = styled.div`
  width: 200px;
  height: 65px;
  display:flex;
  

  img{
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: 3px solid #cdcdcd;
  }
`
export const ThirdAfilliate = styled.div`
  width: 200px;
  height: 65px;
  display:flex;
  

  img{
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: 3px solid #d88131;
  }
`
export const DadosAfilliate = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin: 0 0 0 10px;

  p{
    color: #8e8e8e;
    margin: 0;
    font-weight: 400;

  }

`