import styled from 'styled-components'

export const Dot = styled.span`
  height: 50px;
  width: 50px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 9px 5px 20px #eeeeee, 0px 2px 5px #e4e4e4;
`
export const SubTitle = styled.div`
    margin-top: 10px;
    width:100%;
    text-align:left;
    color: #898989;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    p{
        margin: 0;
        cursor: pointer;
    }
`

export const Footer = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContainerSelect = styled.div`
  width: 16.0375em;
  height: 2.5rem;
  border-radius: 2.8125rem;
  display: flex;
  align-items: center;
`

export const Container = styled.div`
  margin: auto;
  margin-top: 2.2556rem;
  width: calc(100% - 9rem);
  min-height: 100vh;
  padding: 1rem;

  @media (min-width: 1330px) {
    padding: 0;
  }
  @media (max-width: 1085px) {
    margin: 0;
    width: 100%;
  }
`

export const ContainerCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 3.8%;
  margin-top: 3.125rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 767px){
    margin-top: 1.125rem;
  }
`

export const BlurComponent = styled.div<{ blur: boolean }>`
  filter: ${(props) => props.blur ? `blur(5px)` : `none`}
`

export const ContainerInputs = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;

  flex-wrap: wrap;
  justify-content: space-around;

  @media (max-width: 800px) {
  }
`

export const ContainerNotifications = styled.div`
  width: 150.6px;
  height: 40px;
  border-radius: 45px;
  background: #fff1e3;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Select = styled.div`
  width: 126.6px;
  height: 40px;
  margin: 10px;
  border-radius: 45px;
  background: #ffffff;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  align-items: center;
`

export const CardFooter = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .text {
    color: var(--main);
    font-size: 14px;
    margin: 0px;
    margin-right: 10px;
    line-height: 20px;
  }
`

export const CardHeader = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: space-around;

  .first {
    width: 80%;
    background-color: white;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 20px;

    .text {
      color: #5c5c5c;
      font-size: 14px;
      margin: 0px;
      margin-right: 5px;
      line-height: 20px;
    }
  }

  .second {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .text {
      color: #898989;
      font-size: 16px;
      font-weight: bold;
      margin: 0px;
      margin-right: 5px;
      line-height: 20px;
    }
  }
`