import styled from 'styled-components'
import { Card } from '../../../../components/CardContainer'


export const OverviewCard = styled(Card)`
  width: 100%;
`

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
`
export const Content = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  :last-of-type{
    border-right: none;
  }
`

export const Info = styled.section`
  margin: 8px 16px;
  width: 100px;
`
