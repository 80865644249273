import styled from 'styled-components'
import Form from 'components/new/Form'

export const MainContainer = styled.main`
  display: flex;
`

export const FormContainer = styled(Form)`
  width: 85%;
  max-width: 55rem;
  display: grid;
  grid-gap: 2.5rem 0rem;
  margin: 2.440624rem 0;
`

export const Flex = styled.section`
  display: flex;
  align-items: center;
  
  @media screen and (max-width: 767px){
    align-items: baseline;
    flex-wrap: wrap;
  }
  
  & > * { margin-left: 8px; }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`

export const NoCoProducer = styled.h2`
  text-align: center;
  margin: 2rem 0;
  font-size: 0.9rem;
  color: #777;
`

export const DonateForm = styled(Form)`
  display: grid;
  grid-template-columns: 17.425rem 64%;
  grid-template-rows: auto;
  grid-column-gap: 2.7rem;
  margin-bottom: 2rem;
`

export const Donate = styled.section`
  grid-row: 1;
  grid-column: 1 / 3;
  display: flex;
  align-items: center;
  width: 18.75rem;
  margin: 3.125rem 0 1.125rem;

  input {
    margin-right: 0.7rem;
    width: 0.9375rem;
    height: 1.25rem;
  }
  
  p {
    line-height: 1.25rem;
    margin: 0;
    letter-spacing: 0.015625rem;
    color: #5C5C5C;
    width: 8.25rem;
  }
`
export const ActionBtn = styled.button`
  border: none;
  color: var(--main);
  outline: none;
  background: none;
  font-weight: 500;  
  display: inline-flex;
  justify-content: space-between;
  cursor: pointer;

  &.next-btn,
  &.done-btn {
    position: absolute;
  }

  &.next-btn {
    bottom: 0;
    right: 3.5625rem;
  }

  &.done-btn {
    bottom: 0.4rem;
    right: 5.25rem;
  }
`

export const ContentContainer = styled.main`
  width: 100%;
  
  padding: 1.85875rem 2.269375rem;
  position: relative;
  box-shadow: 0.5625rem 0.3125rem 1.25rem #EEEEEE, 0 0.125rem 0.3125rem #E4E4E4;
  border-radius: 10px;
`

export const ContentFooter = styled.footer`
  width: 100%;
  height: 1.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3rem 0 0;
  position: absolute;
  bottom: 0.625rem;
`

export const InputContainer = styled.div`
  h1 {
    font-size: 0.9rem;
    letter-spacing: 0.03125rem;
    color: #292929;
  }
  
  .content > div:first-child {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(24%,1fr));
    grid-gap: 0.85rem;
    @media screen and (max-width: 815px){
      display: flex;
      flex-wrap: wrap;
    }
  }
`

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.3rem 0 1.3rem;

  p {
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.03125rem;
    color: #292929;
    margin: 0;
  }
`