import React from 'react'
import MenuIcon from 'assets/icons/3dotmenu-icon.svg'
import ArrowIcon from 'components/ArrowIcon'
import { Dropdown } from 'antd'
import { ImgContainer } from 'components/DataTable/style'
import {
  DataTableRow,
  DataTableCell,
} from 'components/DataTable'
import { MenuCard } from './style'
import Perfil from 'assets/perfil.png'

interface Student {
  student: {
    img: any
    name: string
    email: string
    product_name: string
    cell_phone: string
    porcentage_complete: number
    last_interation: number
  }
}

const Student: React.FC<Student> = ({ student }) => {
  const {
    img,
    name,
    email,
    cell_phone,
    porcentage_complete,
    last_interation,
    product_name
  } = student

  const dropdownMenu = (
    <MenuCard onClick={() => {
      window.open(`https://api.whatsapp.com/send?phone=+55${cell_phone.replace(/[^0-9.]/g, '')}`, '_blank')
    }}>
      <p>Enviar mensagem pelo Whatsapp</p>
      <ArrowIcon />
    </MenuCard>
  )

  return (
    <DataTableRow>
      <DataTableCell component="th" scope="row">
        <ImgContainer>
          <img src={img ? img : Perfil} alt='' />
        </ImgContainer>
      </DataTableCell>
      <DataTableCell>{name}</DataTableCell>
      <DataTableCell>{email}</DataTableCell>
      <DataTableCell>{product_name}</DataTableCell>
      <DataTableCell>
        {porcentage_complete ? porcentage_complete.toFixed(0) : 0}%
      </DataTableCell>
      <DataTableCell>
        {last_interation ? last_interation : 0}
      </DataTableCell>
      <Dropdown overlay={dropdownMenu}>
        <DataTableCell style={{ cursor: 'pointer' }}>
          <img src={MenuIcon} alt="" />
        </DataTableCell>
      </Dropdown>
    </DataTableRow>
  )
}

export default Student