import { notification } from 'antd'
import { ValidationError } from 'yup'

const notificationStyle = {
  right: '1.875rem',
  width: '16.25rem',
  borderRadius: '0.625rem',
}

export default function displayYupErrorNotification(err: any) {
  if (err instanceof ValidationError) {
    notification.error({
      message: err.errors[0],
      placement: 'bottomRight',
      style: notificationStyle,
    })
  } else {
    notification.error({
      message: err.response.data.message,
      placement: 'bottomRight',
      style: notificationStyle,
    })
  }
}
