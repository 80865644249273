import React from 'react';
import {
    ContainerNotifications
} from './style';

interface Props{
    not?: any
}

const Notification: React.FC<Props> =({not}) =>{

    return(
        <ContainerNotifications>
            <p>Notificações</p>
            <ul>
                {not.map((item: any) => (
                    <li>{item.name}</li>
                ))}
            </ul>
        </ContainerNotifications>
    )
}

export default Notification