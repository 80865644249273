import styled from 'styled-components'

interface CheckContainerProps {
  noBorder?: boolean
}

export const CheckContainer = styled.div<CheckContainerProps>`
  display: grid;
  grid-template-columns: 1.2rem 1fr;
  grid-column-gap: 0.6rem;
  cursor: pointer;
  border: ${({noBorder}) => noBorder ? 'none' : '0.0625rem solid #DCDCDC' };
  border-radius: 0.3125rem;
  padding: ${({noBorder}) => noBorder ? '0.625rem 1.25rem 0.625rem 0' : '0.625rem 1.25rem'};
  width: max-content;
  min-width: 11rem;
  margin-bottom: 0.5rem;
  position: relative;  

  p {
    margin: 0;
  }
`

export const CheckboxCustom = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  margin: auto;

  & input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
  }

  .checkbox-custom {
    position: absolute;
    top: -0.6875rem;
    left: -0.75rem;
    height: 1.375rem;
    width: 1.375rem;
    background: transparent;
    border-radius: 0.3125rem;
    border: 2px solid #dcdcdc;
    transition: all 0.1s ease-out;

    &:after {
      position: absolute;
      content: "";
      left: 0.3125rem;
      top: 0.625rem;
      height: 0px;
      width: 0px;
      border-radius: 0.3125rem;
      border: solid #FFF;
      border-width: 0 3px 3px 0;
      opacity: 1;
      transition: all 0.1s ease-out;
    }
  }

  & input:checked ~ .checkbox-custom {
    background-color: var(--main);
    border-radius: 0.3125rem;
    transform: rotate(0deg) scale(1);
    opacity: 1;
    border: 0.125rem solid var(--main);

    &:disabled {
      background-color: lightgray;
    }
  }

  & input:disabled ~ .checkbox-custom {
    background-color: transparent;
    border-radius: 0.3125rem;
    transform: rotate(0deg) scale(1);
    opacity: 1;
    border: 0.125rem solid lightgray;
  }

  & input:checked ~ .checkbox-custom::after {
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 0.375rem;
    top: 0px;
    width: 0.4375rem;
    height: 0.875rem;
    border: solid #FFF;
    border-width: 0px 0.09375rem 0.09375rem 0;
    background-color: transparent;
    border-radius: 0;
  }
`

export const StyledGroup = styled.div<any>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;    
`