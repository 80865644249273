import styled from 'styled-components';

export const Dot = styled.span<{background: boolean}>`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: inline-block;
  background-color:${(props)=>{
    
    switch (props.background) {
      case true:
        return "#FFF";
      case false:
        return "transparent";
    }
  }};
  box-shadow:${(props)=>{
    switch (props.background) {
      case true:
        return "9px 5px 20px #eeeeee,0px 2px 5px #e4e4e4;";
      case false:
        return "none";
    }
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 32px;
  cursor: pointer;
  @media screen and (max-width: 1125px){
    margin-left: 16px;
    margin-bottom: 16px;

  }
`
