import styled from 'styled-components'
import { Button } from '../shared-styles'
import Data from 'components/new/Input/Date'

export const ModuleList = styled.section``

export const DateInput = styled(Data)`
  border: none;
  outline: none;
  padding-left: 20px;
  width: 100%;
  cursor: pointer;
`

export const CompleteEdit = styled(Button)`
  position: absolute;
  bottom: 0.4rem;
  right: 5.25rem;
  padding: 0.425rem 1.25rem;
  background: var(--main);
  color: white;
  border-radius: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
  }
`

export const NoModulesMsg = styled.h1`
  font-size: 1.25rem;
  color: #777;
  text-align: center;
  margin: 10% 0;
`
