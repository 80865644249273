import styled from 'styled-components'
import { Card } from '../../../../components/CardContainer'
import { Button } from '../../style'

export const Main = styled(Card)`
  width: 100%;
  margin-top: 3.6875rem;
  position: relative;
  padding: 0;
`

export const OrganicHeader = styled.header`
  width: 100%;
  background-color: #EFF3F6;
  padding: 1.25rem 0 0.80rem;
  border-radius: 0.625rem 0.625rem 0 0;

  h1 {
    text-align: center;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.4375rem;
    text-align: center;
    letter-spacing: 0.009375rem;
    color: #292929;
  }
`

export const Content = styled.div`
  margin-top: 1.5rem;
  padding: 1.0912rem 1.875rem 1.0912rem 1.6018rem;
`

export const Title = styled.h1`
  text-align: center;
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.625rem;
  letter-spacing: 0.009375rem;
  color: #DB6262;
`

export const Text = styled.p`
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.009375rem;
  color: #898989;
`

export const ContentFooter = styled.footer`
  width: 100%;
  margin-top: 2.3125rem;
  heigth: 3.5rem;
  display: flex;
  justify-content: space-around;
`

export const Product = styled.div`
  height: inherit;
  display: flex;
`
export const ProductImg = styled.div`
  height: inherit;
  width: 7.3125rem;
  background-color: #E6E6E6;
  margin-right: 0.5rem;

  img {
    width: 100%;
    height: 100%;
  }
`

export const ProductInfos = styled.div`  
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  height: inherit;

  .title {
    letter-spacing: 0.015625rem;
    color: #292929;
  }

  .price {
    font-size: 1.375rem;
    line-height: 1.625rem;
    letter-spacing: 0.009375rem;
    color: #C0C0C0;
  }

  .description {
    font-weight: normal;
    letter-spacing: 0.015625rem;
    color: #C0C0C0;
    margin-bottom: 0;
  }
`

export const CheckContainer = styled.form`
  border: 0.391934px solid #A2A2A2;
  border-radius: 3.91934px;
  width: 40%;
  height: inherit;
  padding: 1.2343rem;
  display: flex;

  & > input[type="checkbox"] {
    width: 1.125rem;
    height: 1.5625rem;
    margin-right: 0.5rem;
    background-color: transparent;
  }

  & > input[type="checkbox"]:checked {
    background-color: var(--main);
  }
  
  p {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.009375rem;
    color: #292929;
  }
`

export const BuyNowButton = styled(Button)`
  margin: 2.5rem auto 1.3425rem;
  width: 20.1369rem;
  height: 3.5rem;
  font-size: 1rem;
  position: relative;
  left: 33%;
`