import styled from 'styled-components'

export const ContainerCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 1.4rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 767px){
    margin-top: 1.125rem;
  }
`
export const ContainerAfiliados = styled.div`
  display: flex;
`

export const BlurComponent = styled.div<{ blur: boolean }>`
  filter: ${(props) => props.blur ? `blur(5px)` : `none`};
  -webkit-touch-callout: ${(props) => props.blur ? `none` : null}; /* iOS Safari */
  -webkit-user-select: ${(props) => props.blur ? `none` : null}; /* Safari */
  -khtml-user-select: ${(props) => props.blur ? `none` : null}; /* Konqueror HTML */
  -moz-user-select: ${(props) => props.blur ? `none` : null}; /* Old versions of Firefox */
  -ms-user-select: ${(props) => props.blur ? `none` : null}; /* Internet Explorer/Edge */
  user-select: ${(props) => props.blur ? `none` : null}; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
`
export const CardBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
`
export const RelatoriosCard = styled.div`
  display: flex;
  flex-direction: column;

`
export const RelContent = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  line-height: 26px;
  color: var(--main);
  font-size: 16px;
  margin-bottom: 4px;
  p{
    margin: 0 0 0 16px;

  }
`
export const EmBreve = styled.h3`
  width: 100%;
  text-align: center;
  color: rgb(181, 181, 181);
  font-weight: 900;
  font-size: 27px;
  margin: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 16px;
`
export const ContainerContent = styled.div`
  padding: 4px; 
  p:first-child{
    margin: 0;
    font-weight: bold;
    line-height: 26px;
    color: #5c5c5c;
    font-size: 20px;
    margin-bottom: 4px;
    
  }
  p:last-child{
    margin: 0 0 20px 0;
    font-weight: bold;
    line-height: 12px;
    color: #a2a2a2;
    font-size: 16px;
  }
`