/*
Essa página é o header da criação de produto
*/

import React, { useState } from 'react'
import { Container } from 'GlobalStyles'
import Steps from './Steps'
import { Link, Breadcrumb } from '../style'
import Footer from 'containers/Footer'
import HeaderPromotor from 'components/HeaderPromotor'
import { NewProductContext } from './NewProductContext'

interface NewProductData {
  name: string
  category: number
  subcategory: number
  description: string

  currency: number
  repayment: number
  payments: number[]
  installments: number[]
  value: number
  co_producers?: any[]
  interestToClient?: boolean
  hasInstallment?: boolean

  activeOrderBump?: boolean
  order_bump?: number
  discountBump?: number
  descriptionBump?: string
  activeOrderSell?: boolean
  order_sell?: number
  discountSell?: number
  descriptionSell?: string
}

const initialState: NewProductData = {
  name: '',
  category: 0,
  subcategory: 0,
  description: '',
  currency: 0,
  repayment: 0,
  payments: [],
  installments: [],
  value: 0,
  co_producers: [],
  interestToClient: false,
  activeOrderBump: false,
  activeOrderSell: false,
}

window.onbeforeunload = function (e: any) {
  var message = "Você deseja descartar as alterações?"
  e = e || window.event

  // For IE and Firefox prior to version 4
  if (e) {
    e.returnValue = message;
  }

  // For Safari
  return message;
}

const NewProduct: React.FC = () => {
  const [newProductData, setNewProductData] = useState<NewProductData>(
    initialState,
  )

  return (
    <>
      <Container style={{ marginBottom: '2rem' }}>
        <HeaderPromotor SearchHeader />

        <Breadcrumb separator="→" aria-label="breadcrumb">
          <Link to="/products">Produtos</Link>
          <Link color="#BCBCBC" to="/products/new">
            Novo produto
          </Link>
        </Breadcrumb>

        <NewProductContext.Provider value={[newProductData, setNewProductData]}>
          <Steps />
        </NewProductContext.Provider>
      </Container>
      <Footer />
    </>
  )
}

export default NewProduct
