import React, { useState, useEffect } from 'react'
import { Container } from 'GlobalStyles'
import ProductorsComponent from 'components/BackOffice/Productors'
import {
  Subheader,
  Title,
  NoProductMsg,
  SearchContainer,
  ProductList,
  ErrorScreen,
  Button,
  CardContainer,
  Content,
  ContentCenter,
  Value,
  Label,
  FlexContainer,
  Icon
} from './style'
import Search from 'components/new/Search'

import MoneyIcon from 'assets/icons/money-icon.svg'
import PxpayIcon from 'assets/perfil.png'
import PeoplesIcon from 'assets/icons/peoples-icon.svg'

import { displayErrorNotification } from 'utils/displayNotification'

import api from 'services/api'
import HeaderPromotor from 'components/HeaderPromotor'
import { CircularProgress } from '@material-ui/core/'

interface ResumeFinancial {
  amount: number
  zoop_amount: number
  xgrow_amount: number
  productor_amount: number
}

const Productors = () => {
  const [productors, setProductors] = useState<Object[]>([])
  const [productorsId, setProductorsId] = useState<number[]>([])

  const [resumeFinancial, setResumeFinancial] = useState<any[]>([])

  const [, setTypeUser] = useState<any>('student')
  const [loading, setLoading] = useState<boolean>(false)

  const [profileValid,] = useState<boolean>(true)
  const [err, setErr] = useState<string>()

  const search = async (v: any) => {
    if (v.length < 1) {
      getProductors()
    }
    if (v.length >= 2) {
      setLoading(true)
      try {
        let { data } = await api.get(`/report-list-productors?name=${v}`)
        setLoading(false)
        setProductors(data.users)
        setErr('Você não tem nenhum produto cadastrado com esse nome.')
      } catch (error) {
        setErr('Tente novamente')
        setLoading(false)
      }
    }
  }

  const getProductors = async () => {
    let { data } = await api.get(`/report-list-productors`)

    setProductors(data.users)
    const list = data.users.map((item: any) => item.id)
    setProductorsId(list)
    let response = await api.get('profile')
    setTypeUser(response.data.user.type_user)

  }

  const getResumeFinancial = async () => {
    let { data } = await api.get(`/resume-financial-productors`)
    setResumeFinancial(data.currentArray)
  }

  const genProductorsReport = async (producers_id: number[]) => {
    try {
      let { data: { data } } = await api.post(`/report-generate-multiple-report`, {
        producers_id
      })

      var byteArray = new Uint8Array(data.data);
      var a = window.document.createElement('a');

      a.href = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
      a.download = `relatorio-de-produtor-${Date.now()}.xlsx`;

      document.body.appendChild(a)
      a.click();
      document.body.removeChild(a)

    } catch (error) {
      displayErrorNotification('Tente novamente')
    }
  }

  useEffect(() => {
    getProductors()
    getResumeFinancial()
    sessionStorage.clear()
  }, [])

  return (
    <Container>
      <HeaderPromotor SearchHeader />
      <CardContainer>
        <Content>
          <ContentCenter>
            <section>
              <FlexContainer>
                <Value>
                  {resumeFinancial.length ? resumeFinancial[0].amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0.00'}
                </Value>
              </FlexContainer>
              <Label>Total</Label>
            </section>
            <section>
              <FlexContainer>
                <Icon src={PeoplesIcon} />

                <Value>
                  {resumeFinancial.length ? resumeFinancial[0].productor_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0.00'}
                </Value>
              </FlexContainer>
              <Label>Total Para Produtores</Label>
            </section>
            <section>
              <FlexContainer>
                <Icon src={PxpayIcon} />

                <Value>
                  {resumeFinancial.length ? resumeFinancial[0].xgrow_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0.00'}
                </Value>
              </FlexContainer>
              <Label>Total Para XGrow</Label>
            </section>
            <section>
              <FlexContainer>
                <Icon src={MoneyIcon} />

                <Value>
                  {resumeFinancial.length ? resumeFinancial[0].zoop_amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0.00'}
                </Value>
              </FlexContainer>
              <Label>Total Para Zoop</Label>
            </section>
          </ContentCenter>
        </Content>
      </CardContainer>
      <Button onClick={() => genProductorsReport(productorsId)}>Gerar Relatório Total</Button>

      <Title>BackOffice</Title>
      <SearchContainer>
        <Search
          placeholder="Busque pelo nome do produto"
          handleSearch={(e: any) => search(e.target.value)}
        />
      </SearchContainer>
      { profileValid ? (
        <>

          <Subheader>
            <h1>{productors ? productors.length : '0'} Produto(s) Cadastrado(s)</h1>
          </Subheader>
          <ProductList>
            {loading ? (
              <CircularProgress color="inherit" style={{ margin: '0 50%' }} />
            ) : productors && productors.length === 0 ? (
              <NoProductMsg>Não há produtor cadastrado :/</NoProductMsg>
            ) : (
                  productors &&
                  productors.map((productor: any) => (
                    <ProductorsComponent productors={productor} key={productor.id} />
                  ))
                )}
          </ProductList>
        </>
      ) : (
          <ErrorScreen>
            {
              err ? (
                <p>{err}</p>
              ) : (
                  <CircularProgress color="inherit" />
                )
            }
          </ErrorScreen>
        )
      }
    </Container>
  )
}

export default Productors
