/*
Após cadastrado o teste, ao clicar nele, você verá essa página de visualização aqui.
*/

import React from 'react'
import { Modal } from 'antd'
import { Container, DownloadBtn, Time, Description } from './style'

interface Test {
  name: string
  time: number
  description: string
  pdf_link: File
}

interface Props {
  test: any
  visible: boolean
  onCancel: () => void
}

const TestPreviewModal: React.FC<Props> = ({ test, visible, onCancel }) => {
  return (
    <Modal
      title={test.name}
      visible={visible}
      onCancel={onCancel}
      closable={true}
      footer={null}
    >
      <Container>
        <Description>{test.description}</Description>
        <Time>
          Tempo do teste: <strong>{test.time} minutos</strong>
        </Time>
        <form method="get" action={test.pdf_link} target="_blank">
          <DownloadBtn type="submit">Visualizar PDF</DownloadBtn>
        </form>
      </Container>
    </Modal>
  )
}

export default TestPreviewModal
