import React from 'react'
import ImageInput from 'components/new/Input/Image'
import { Modal } from 'antd'
import { ModalButton } from '../../style'

interface Props {
  visible: boolean
  handleOk: any
  handleCancel: any
}

const ImgModal: React.FC<Props> = ({
  visible = false,
  handleOk,
  handleCancel,
  ...rest
}) => {
  return (
    <Modal
      title="Inserir Imagem"
      visible={visible}
      closable={true}
      onCancel={handleCancel}
      footer={[
        <ModalButton type="button" onClick={handleCancel}>
          Cancelar
        </ModalButton>,
        <ModalButton className="submit" type="submit" onClick={handleOk}>
          Adicionar imagem
        </ModalButton>,
      ]}
      {...rest}
    >
      <ImageInput name="photo" />
    </Modal>
  )
}

export default ImgModal
