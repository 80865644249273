import styled from 'styled-components'

export const ContainerNotifications = styled.div`
    width: 300px;
    border-radius: 8px;
    color: #222;
    font-weight: 300;
    background: #fff;
    padding: 0 20px;
    position: absolute;
    z-index: 10;
    left: -50px;
    top: 55px;
    box-shadow:9px 5px 20px #eeeeee,0px 2px 5px #e4e4e4;
    p{ 
      color: #5c5c5c;
      font-size: 11px;
      margin-bottom: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 700;
      line-height: 20px;
    
    }
    
    li{
        list-style:none;
        font-size: 16px;
        padding-bottom: 12px;
        margin-bottom: 4px;
        border-bottom: solid 1px rgba(0,0,0,0.1);
        
        font-weight: bold;
        color: #a2a2a2;
        font-size: 16px;
        :last-of-type{
            border-bottom: none
        }
    }
`
