import { Dropdown, Pagination } from 'antd'
import { Card } from 'components/CardContainer'
import styled from 'styled-components'


export const MyStudentsCard = styled.div`
  border: 1px solid #c1c1c1;
  border-radius: 0.625rem;
  padding: 16px;
  margin-top: 16px;
  padding: 16px;
  width: 100%;
  min-height: 70vh;
`

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  @media screen and (max-width:992px){

  }
`

export const SearchContainer = styled.div`
  width: 75%;
  min-width: 260px;
`

export const FlexContainer = styled.div`
  display: flex;
  margin: 8px 0;
  justify-content: flex-end;
  align-items: center;

`
export const TableContainer = styled.div`
  border: 1px solid #888;
  border-radius: 16px;

`

export const TotalStudents = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  color: #898989;
  margin: 0;
  margin-left: 1.8681rem;
  width: max-content;
`
export const Subcard = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.1.5625rem;
  color: #5C5C5C;
  display: flex;
  background: #FFFFFF;
  border: 0.0625rem solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 0.625rem;
  padding: 12px;
`

export const DownloadCard = styled(Subcard)`  
  width: auto;
  height: 50px;
  cursor: pointer;
  justify-content: space-between;
`

export const FilterDropdown = styled<any>(Dropdown)`
  button {
    position: absolute;
    right: 15px;
    top: 5px;
  }
`

export const FilterCard = styled(Subcard)`
  width: 300px;
  height: 150px;
  flex-wrap: wrap;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.625rem 0 1.18125rem;
    
    p {
      width: max-content;
      margin: 0;
    }

    &:first-child {
      border-bottom: 1px solid #E6E6E6;
    }
  }
  
`

export const NoStudentMsg = styled.h1`
  font-size: 1.25rem;
  margin: 3rem 100%;
  width: max-content;
  color: #292929;
`

export const ButtonInative = styled.button`
  width: max-content;
  padding: 0.4375rem 1.18875rem;
  color: var(--main);
  box-sizing: border-box;
  border-radius: 3rem;
  background-color: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  border: 1px solid var(--main);
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  span {
    margin-right: 0.41375rem;
    font-size: 1.34375rem;
  }
`

export const ButtonActive = styled.button`
  width: max-content;
  padding: 0.4375rem 1.18875rem;
  color: black;
  box-sizing: border-box;
  border-radius: 3rem;
  background-color: var(--main);
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  border: 1px solid var(--main);
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  span {
    margin-right: 0.41375rem;
    font-size: 1.34375rem;
  }
`
export const StyledPagination = styled(Pagination)`
  margin: 1rem auto 2.5rem;

  > .ant-pagination-item-active {
    border-color: var(--main);

    > a {
      color: var(--main);
    }

    &&&:hover {
      border-color: var(--main);
      color: var(--main);
    }
  }
`
export const FooterPagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`