/*
3º parte da criação de produto:
Aqui você cuidará de colocar produtos adicionais que o cliente poderá pagar
junto ao checkout (junto com algum desconto).
*/

import React, { useState, useEffect, useRef, useContext } from 'react'
import { SubmitHandler } from '@unform/core'
import {
  Checkbox,
  Flex,
  ActionBtn,
  MainContainer,
  ContentContainer,
  ContentFooter,
  ContentForm,
  DivOrder,
  Register,
} from './style'
import HeaderNewProduct from 'components/new/HeaderNewProduct'
import history from 'routes/history'
import Input from 'components/new/Input'
import Textarea from 'components/new/Input/Textarea'
import api from 'services/api'
import Text from 'components/new/Input/Text'
import Select from 'components/new/Input/Select'
import StyledSwitch from 'components/new/Switch'
import ArrowIcon from 'components/ArrowIcon'
import { StepContext } from '../../../StepContext'
import { CircularProgress } from '@material-ui/core/'
import { displaySucessNotification } from 'utils/displayNotification'
import { NewProductContext } from '../../NewProductContext'
import * as Yup from 'yup'
import displayYupErrorNotification from 'utils/displayYupErrorNotification'

const textAreaHeight = '6.7375rem'

interface FormData {
  descriptionBump: string
  descriptionSell: string
  discountBump: string
  discountSell: string
  orderBump: string
  orderSell: string
}

const CheckoutConfig: React.FC = () => {
  const { handlePreview } = useContext(StepContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [newProductData, setNewProductData] = useContext(NewProductContext)

  const [optionsBump, setOptionsBump] = useState<any>(null)
  const [sellUp, setSellUp] = useState<any>(null)
  const [doExistBump, setDoExistBump] = useState(true)
  const [doExistUpSell, setDoExistUpSell] = useState(true)
  const [activeOrderBump, setActiveOrderBump] = useState<boolean>(false)
  const [activeOrderSell, setActiveOrderSell] = useState<boolean>(false)

  const getBumps = async () => {
    let { data } = await api.get('/order-bump')
    data.length === 0 ? setDoExistBump(true) : setDoExistBump(false)
    setOptionsBump(data)
  }

  const getUpSell = async () => {
    let { data } = await api.get('/up-sell')
    data.length === 0 ? setDoExistUpSell(true) : setDoExistUpSell(false)
    setSellUp(data)
  }

  const handleTextValue = (evt: any) => {
    setNewProductData({
      ...newProductData,
      [evt.target.id]: capitalize(evt.target.value),
    })
  }

  const handleOrderBumpActive = () => {
    setActiveOrderBump(!activeOrderBump)

    setNewProductData({
      ...newProductData,
      activeOrderBump: !newProductData.activeOrderBump,
    })
  }

  const handleOrderSellActive = () => {
    setActiveOrderSell(!activeOrderSell)

    setNewProductData({
      ...newProductData,
      activeOrderSell: !newProductData.activeOrderSell,
    })
  }

  const handleBump = ({ value, label }: any) => {
    let bump = { value, label }

    setNewProductData({
      ...newProductData,
      order_bump: bump,
    })
  }

  const handleOrderSell = ({ value, label }: any) => {
    let sell = { value, label }

    setNewProductData({
      ...newProductData,
      order_sell: sell,
    })
  }

  const [discountBumpValue, setDiscountBumpValue] = useState<any>()
  const [discountSellValue, setDiscountSellValue] = useState<any>()
  const handleDiscountValue = (evt: any) => {
    let property = evt.target.id
    let stringValue = evt.target.value.toString().replace(',', '.')
    let value = parseFloat(stringValue)

    if (value > 100) {
      value = 100
    }

    setNewProductData({
      ...newProductData,
      [property]: value,
    })

    switch (property) {
      case 'discountBump':
        setDiscountBumpValue(value)
        return
      case 'discountSell':
        setDiscountSellValue(value)
        return
      default:
        return
    }
  }

  const setData = () => {
    if (newProductData.activeOrderBump) {
      setActiveOrderBump(true)
    }

    if (newProductData.activeOrderSell) {
      setActiveOrderSell(true)
    }

    if (newProductData.discountBump) {
      setDiscountBumpValue(newProductData.discountBump.toString())
    }

    if (newProductData.discountSell) {
      setDiscountSellValue(newProductData.discountSell.toString())
    }
  }

  useEffect(() => {
    setData()
    getBumps()
    getUpSell()
  }, [])

  const formRef = useRef<SubmitHandler>(null)

  const handleSubmit: SubmitHandler<FormData> = async data => {
    try {
      const schema = Yup.object().shape({
        tenant_id: Yup.number().required('Escolha o produto.'),
        description: Yup.string()
          .required('Adicione uma descrição.')
          .typeError('Adicione uma descrição.'),
        discount: Yup.number()
          .required('Adicione o desconto para o produto.')
          .typeError('Adicione o desconto para o produto.'),
      })

      let bumpsConvert: any = {
        tenant_id: activeOrderBump ? data.orderBump : null,
        description: activeOrderBump ? data.descriptionBump : null,
        discount: activeOrderBump ? parseFloat(discountBumpValue) : {},
      }

      let upsellsConvert: any = {
        tenant_id: activeOrderSell ? data.orderSell : null,
        description: activeOrderSell ? data.descriptionSell : null,
        discount: activeOrderSell ? parseFloat(discountSellValue) : {},
      }

      if (activeOrderBump) {
        await schema.validate(bumpsConvert)
      }

      if (activeOrderSell) {
        await schema.validate(upsellsConvert)
      }

      let ConvertBasicInfo: any = sessionStorage.getItem('BasicInfo')
      let ValueBasicInfo: any = JSON.parse(ConvertBasicInfo)

      let ConvertPhoto: any = sessionStorage.getItem('photo')
      let ValuePhoto: any = {
        photo: ConvertPhoto,
      }

      let ContentRegister = {
        bumps: [bumpsConvert],
        upsells: [upsellsConvert],
      }

      let ConvertPriceInfo: any = sessionStorage.getItem('PriceInfo')
      let ValuePrinceInfo: any = JSON.parse(ConvertPriceInfo)

      let newProductData = Object.assign(
        ValueBasicInfo,
        ContentRegister,
        ValuePhoto,
        ValuePrinceInfo,
      )

      setLoading(true)
      await api.post('/create-product', newProductData)

      displaySucessNotification('Produto cadastrado com sucesso!')

      sessionStorage.clear()
      history.push('/products')
    } catch (err) {
      displayYupErrorNotification(err)
      setLoading(false)
    }

    setLoading(false)
  }

  const capitalize = (e: any) => {
    return e.charAt(0).toUpperCase() + e.slice(1)
  }

  return (
    <MainContainer>
      <ContentContainer>
        <HeaderNewProduct
          title="3 - Configuração de Checkout"
          subtitle="Habilite e configure as opções de Order bump e Upsell no checkout deste produto."
        />
        <ContentForm ref={formRef} onSubmit={handleSubmit}>
          <Checkbox>
            <StyledSwitch
              onChange={handleOrderBumpActive}
              checked={activeOrderBump}
              disabled={doExistBump}
            />
            {doExistBump ? <p>Order Bump desabilitado (você não cadastrou nenhum produto).</p> : <p>Order bump Habilitado</p>}
          </Checkbox>

          <Textarea
            name="descriptionBump"
            title="Breve descrição"
            placeholder="Essa descrição ajudará a vender o seu produto!"
            height={textAreaHeight}
            disabled={!activeOrderBump}
            onChangeInput={handleTextValue}
            valueInput={newProductData.descriptionBump}
          />

          <Flex>
            <DivOrder>
              <Input title="Order Bump">
                <Select
                  isDisabled={!activeOrderBump}
                  options={optionsBump}
                  name="orderBump"
                  placeholder="Selecione o produto"
                  onChange={handleBump}
                  defaultValue={newProductData.order_bump}
                />
              </Input>
            </DivOrder>

            <Input title="Desconto neste produto" width="200px">
              <Text
                name="discountBump"
                type="number"
                placeholder="0,00 % OFF"
                disabled={!activeOrderBump}
                onChange={handleDiscountValue}
                onBlur={handleDiscountValue}
                value={discountBumpValue}
              />
            </Input>
          </Flex>

          <Checkbox>
            <StyledSwitch
              onChange={handleOrderSellActive}
              checked={activeOrderSell}
              disabled={doExistUpSell}
            />
            {doExistUpSell ? <p>Upsell desabilitado (você não cadastrou nenhuma oferta após a venda).</p> : <p>Upsell Habilitado</p>}
          </Checkbox>

          <Textarea
            name="descriptionSell"
            title="Breve descrição"
            placeholder="Essa descrição ajudará a vender o seu produto!"
            height={textAreaHeight}
            disabled={!activeOrderSell}
            onChangeInput={handleTextValue}
            valueInput={newProductData.descriptionSell}
          />

          <Flex style={{ marginBottom: '0' }}>
            <DivOrder>
              <Input title="Order Sell">
                <Select
                  isDisabled={!activeOrderSell}
                  options={sellUp}
                  name="orderSell"
                  placeholder="Selecione o produto"
                  onChange={handleOrderSell}
                  defaultValue={newProductData.order_sell}
                />
              </Input>
            </DivOrder>

            <Input title="Desconto neste produto" width="200px">
              <Text
                name="discountSell"
                type="number"
                placeholder="0,00 % OFF"
                disabled={!activeOrderSell}
                onChange={handleDiscountValue}
                onBlur={handleDiscountValue}
                value={discountSellValue}
              />
            </Input>
          </Flex>

          <ContentFooter>
            <ActionBtn onClick={handlePreview}>
              <ArrowIcon rotateDeg={90} color="var(--main)" />
              <p>Voltar</p>
            </ActionBtn>
            <Register type="submit">
              {loading ? (
                <CircularProgress color="inherit" size={'1.1rem'} />
              ) : (
                  <p>Cadastrar</p>
                )}
            </Register>
          </ContentFooter>
        </ContentForm>
      </ContentContainer>
    </MainContainer>
  )
}

export default CheckoutConfig
