import axios from 'axios'

const api = axios.create({
  // TODO: BaseURL vindo de um config file
  baseURL: 'https://api.skycode.com.br/',
  //baseURL: 'http://localhost:3331/',
})

api.interceptors.request.use(config => {
  const token = localStorage.getItem('@xgrow:jwt')
  const headers = { ...config.headers }

  if (token) {
    headers.Authorization = `Basic ${token}`
  }

  return { ...config, headers }
})

export default api
