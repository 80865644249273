import React from 'react'
import {
  Container,
  CoProducerTable,
  CoProducerTableHeader,
  CoProducerTableContent,
  Label,
  Value,
  DeleteButton,
} from './style'
import DeleteIcon from 'assets/icons/delete-icon.svg'

interface CoProducer {
  id: number
  co_producer_email: string
  contract: any
  comission: string
  comission_percentage: number
}

interface Props {
  co_producers: CoProducer[]
  onDelete: (id: number) => void
}

const CoProducerList: React.FC<Props> = ({ co_producers, onDelete }) => {
  return (
    <Container>
      {co_producers?.map((co_producer: CoProducer) => (
        <>
          <CoProducerTable key={co_producer.id}>
            <CoProducerTableHeader>
              <Label>Email</Label>
              <Label>Tempo de contrato</Label>
              <Label>Comissões</Label>
              <Label>Porcentagem da comissão</Label>
            </CoProducerTableHeader>
            <CoProducerTableContent>
              <section>
                <Value>{co_producer?.co_producer_email}</Value>
              </section>

              <section>
                <Value>{co_producer?.contract?.name}</Value>
              </section>

              <section>
                <Value>Vendas do produtor</Value>
              </section>

              <section>
                <Value>{co_producer?.comission_percentage}%</Value>
              </section>
            </CoProducerTableContent>
          </CoProducerTable>

          <DeleteButton
            onClick={() => onDelete(co_producer?.id)}
            src={DeleteIcon}
          />
        </>
      ))}
    </Container>
  )
}

export default CoProducerList
