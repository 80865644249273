/*
Essa página é o header da edição de produto
*/

import React, { useState, useEffect } from 'react'
import { Container } from 'GlobalStyles'
import Steps from './Steps'
import { Link, Breadcrumb } from '../style'
import HeaderPromotor from 'components/HeaderPromotor'
import { EditProductContext } from './EditProductContext'
import api from 'services/api'
import { useLocation } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core/'
import {
  createValueLabelForSelectInput,
  createBumpSellsObject,
  createFinalJSON,
} from './utils'
import Footer from 'containers/Footer'

interface EditProductData {
  id: number

  name: string
  photo?: string
  updated_photo: boolean
  category: { value: number; label: string }
  sub_category: { value: number; label: string }
  description: string

  value: number
  currency: { id: number }
  currencySymbol: string
  repayment: { id: number }
  payments: { payment_method: number }[]
  hasInstallment: boolean
  installments: []
  interest_to_client: boolean
  co_producers?: []

  activeOrderBump?: boolean
  order_bump?: { value: number; label: string }
  discountBump?: number
  descriptionBump?: string
  activeOrderSell?: boolean
  order_sell?: { value: number; label: string }
  discountSell?: number
  descriptionSell?: string

  modules?: []
  release_date?: string

  finalJSON: {}
}

const EditProduct: React.FC = () => {
  const [editProductData, setEditProductData] = useState<EditProductData>()
  const { state } = useLocation()
  const { product_id }: any = state

  const getProductData = async () => {
    let { data } = await api.get(`/get-product/${product_id}`)
    let bumps = data.bumps[0] ? data.bumps[0] : createBumpSellsObject()
    let upsells = data.upsells[0] ? data.upsells[0] : createBumpSellsObject()

    let formatedData = {
      ...data,
      category: createValueLabelForSelectInput(
        data.category.id,
        data.category.name,
      ),
      sub_category: createValueLabelForSelectInput(
        data.sub_category.id,
        data.sub_category.name,
      ),
      payments: data.payments.map((payment: any) => ({
        payment_method: payment.payment_method.id,
      })),
      currencySymbol: 'BRL',
      order_bump: createValueLabelForSelectInput(
        bumps.product_tenant.id,
        bumps.product_tenant.name,
      ),
      descriptionBump: bumps.description,
      discountBump: bumps.discount,
      order_sell: createValueLabelForSelectInput(
        upsells.product_tenant.id,
        upsells.product_tenant.name,
      ),
      descriptionSell: upsells.description,
      discountSell: upsells.discount,
      release_date: data.release_date ? new Date(data.release_date) : null,
      activeOrderBump: bumps.id !== undefined ? true : false,
      activeOrderSell: upsells.id !== undefined ? true : false,

      finalJSON: createFinalJSON(data),
    }
    console.log(formatedData)
    setEditProductData(formatedData)
  }

  window.onbeforeunload = function (e: any) {
    var message = "Você deseja descartar as alterações?"
    e = e || window.event

    // For IE and Firefox prior to version 4
    if (e) {
      e.returnValue = message;
    }

    // For Safari
    return message;
  }

  useEffect(() => {
    getProductData()
  }, [])

  return (
    <>
      <Container style={{ marginBottom: '2rem' }}>
        <HeaderPromotor SearchHeader />

        <Breadcrumb separator="→" aria-label="breadcrumb">
          <Link to="/products">Produtos</Link>
          <Link color="#BCBCBC" to="/products/new">
            Editar produto
          </Link>
        </Breadcrumb>

        {!editProductData ? (
          <CircularProgress color="inherit" style={{ margin: '10% 50%' }} />
        ) : (
            <EditProductContext.Provider
              value={[editProductData, setEditProductData]}
            >
              <Steps />
            </EditProductContext.Provider>
          )}
      </Container>

      <Footer />
    </>
  )
}

export default EditProduct