import styled from 'styled-components'

export const StyledNav = styled.nav`
  margin: 0 auto 3rem;

  a {
    padding: 1rem;
    border-bottom: 0.125rem solid lightgray;

    &:hover {
      color: var(--main);
    }
  }

  a.active {
    border-bottom: 0.125rem solid var(--main);
    color: var(--main);
  }
`
export const TypeUser = styled.div`
  color: #C0C0C0;
  font-size: 16px;
  padding-left: 10px;
  width: 100%;
  margin-top: 8px;
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
  }
  div section:first-child{
    margin-right: 100px;
  }

`

export const Flex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & fieldset:first-child {
    margin-bottom: 1rem;
  }

  @media (min-width: 760px) {
    margin-bottom: 0;

    fieldset {
      width: 49%;

      &:first-child {
        margin-bottom: 0;
      }
    }
  }
`