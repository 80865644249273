import React from 'react'
import { FormContainer, PaymentOptionForm } from './style'

import Input from 'components/Input'
import Text from 'components/Input/Text'

const Payment = () => {
  return (
    <>
      <FormContainer>
        <PaymentOptionForm>
          <div>
            <Input title="Cartão de crédito">
              <Text placeholder="Número do Cartão" />
            </Input>

            <Input title="Titular">
              <Text placeholder="Nome do titular como" />
            </Input>
          </div>

          <div>
            <Input title="MM">
              <Text />
            </Input>
            <Input title="AA">
              <Text />
            </Input>
            <Input title="Código de segurança">
              <Text />
            </Input>
          </div>
        </PaymentOptionForm>
      </FormContainer>
    </>
  )
}

export default Payment
