import React, { useState } from 'react'
import {
  MainContainer,
  StepsContainer,
  StepsContainerTitle,
  EditProductSteps,
  ContentContainer,
} from './style'
import ArrowIcon from 'components/ArrowIcon'
import BasicInfo from './BasicInfo'
import PriceInfo from './PriceInfo'
import CheckoutConfig from './CheckoutConfig'
import { StepContext } from '../../StepContext'
const { Step } = EditProductSteps

const steps = [
  {
    title: 'Informações Básicas',
    content: <BasicInfo />,
  },
  {
    title: 'Informações de Preço',
    content: <PriceInfo />,
  },
  {
    title: 'Configuração de Checkout',
    content: <CheckoutConfig />,
  },
]

interface Step {
  title: string
}

const Steps: React.FC = () => {
  const [current, setCurrent] = useState<number>(0)

  const handleNext = () => {
    setCurrent(current + 1)
  }

  const handlePreview = () => {
    setCurrent(current - 1)
  }

  const onChange = (id: any) => {
    setCurrent(id)
  }

  return (
    <StepContext.Provider value={{ handlePreview, handleNext }}>
      <MainContainer>
        <StepsContainer>
          <StepsContainerTitle>Etapas de Visualização</StepsContainerTitle>

          <EditProductSteps
            current={current}
            onChange={onChange}
            direction="vertical"
          >
            {steps.map((item: Step) => (
              <Step key={item.title} title={item.title} />
            ))}
          </EditProductSteps>
        </StepsContainer>

        <ContentContainer>{steps[current].content}</ContentContainer>
      </MainContainer>
    </StepContext.Provider>
  )
}

export default Steps
