import { Button, Card, Dropdown, Menu } from 'antd';
import styled from 'styled-components';

export const Main = styled.main`
  height: 100vh;
  width: 100%;
  background-color: #000;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  
`
export const Frame = styled.section<{ background: string }>`
  height: 100%;
  width: 100%;
  display: flex;
  background: ${(props) => props.background ? props.background : '#000'};
  background-position-y: 90%;
  background-repeat: no-repeat;
  background-size: cover;
  scroll-snap-align: start;
  .poly{
    clip-path: polygon(0 0, 50% 0, 80% 100%, 0% 100%);
    background: rgb(38,38,38);
    background: linear-gradient(111deg, rgba(38,38,38,1) 0%, rgba(0,0,0,1) 100%);
    
    @media screen and (max-width: 912px){
      clip-path: polygon(0 100%, 70% 100%, 70% 0, 0 0);
    }
  }
  .greenPoly{
    clip-path: polygon(0 0, 55% 0, 85% 100%, 0% 100%);
    background: var(--main);
    @media screen and (max-width: 912px){
      clip-path: polygon(0 100%, 75% 100%, 75% 0, 0 0);
    }
  }
  .poly2{
    clip-path: polygon(0 0, 80% 0, 50% 100%, 0% 100%);
    background: rgb(38,38,38);
    
    background: linear-gradient(45deg, rgba(38,38,38,1) 0%, rgba(0,0,0,1) 100%);
    
    @media screen and (max-width: 912px){
      clip-path: polygon(35% 100%,100% 100%,100% 0,35% 0);
      justify-content: flex-end;
    }
  }
  .greenPoly2{
    clip-path: polygon(0 0, 85% 0, 55% 100%, 0% 100%);
    background: var(--main);
    @media screen and (max-width: 912px){
      clip-path: polygon(30% 100%,100% 100%,100% 0,30% 0);
    }
  }
  .cards{
    overflow-x:scroll;
  }
  
`
export const CardContainer = styled.div`
  display: flex;
  align-self: baseline;
  margin: 0 auto;
  overflow-x: scroll;
  
`
export const StyledCard = styled(Card)`
  margin: 0px 32px;
  width: 350px;
  height: 514px;
  .ant-card-cover{
    height: 180px;
  }
  .ant-card-meta-description{
    color: #222;
  }
  @media screen and (max-width: 475px){
    margin: 0px 4px;
    width: 285px; 
  }
`

export const Content = styled.section`
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  background: rgba(0,0,0,0.95);
`
export const TopNav = styled.section`
  padding: 32px 24px;
  height: 10vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  display: flex;
  justify-content: space-between;
  z-index: 999;
  background-color: rgba(0,0,0,0.8);
  img{
    margin: 8px;
  }
 
`
export const DropdownResponsivo = styled(Dropdown)`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 0;
  background-color: #FFF;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  color: #000;
  display: none;
  padding: 4px;


  @media screen and (max-width: 912px){
    display: block;
  }
  img{
    height: 32px;
    width: 32px;
  }
`
export const StyledMenu = styled(Menu)`
  background-color: #111;
  ul{
    padding: 12px;
  }
  &>li>a{
    color: #FFF;
  }
  
`
export const Title = styled.h1`
  color: #fff;
  font-size: 32px;
  margin-top: 32px;
  
`
export const TextoApresenta = styled.div`
  width: 50vw;
  height: 100vh;
  margin: 0 32px;
  align-self: baseline;
  .texto2{
    float: inline-end !important;
  }
  p{
    color: #fff;
    padding-top: 32vh;
    font-size: 18px;
    @media screen and (max-width: 684px){
      font-size: 12px;
    }
  }
  
`
export const TextoCard = styled.div`
  text-align: justify;
  &::first-letter{
    margin-left: 32px;
  }
  
`
export const ListOptions = styled.ul`
  align-items: center;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  height: 100%;
  padding: 0 32px;
  li{
    padding: 16px 32px;
    font-weight: 700;
    cursor: pointer;
  }
  a{
    color: #FFF !important;
  }
  @media screen and (max-width: 912px){
    display: none;
  }
`
export const EntrarBtn = styled.button`
  width: 150px;
  height: 51px;
  border-radius: 4px;
  border: solid 1px #fff;
  background-color: #000;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  color: #FFF;
  outline: none;
  transition: 0.3s;
  &:hover{
    background-color: #fff;
    color: #000;
  }
  &:focus{
    color: #000;
    background-color: #eee;
  }
`
export const ResponsiveDropdown = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 4px;
  border: 0;
  background-color: #FFF;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  color: #000;
  display: none;

  @media screen and (max-width: 912px){
    display: block;
  }
`

export const Heading = styled.section`
  width: 100%;
  text-align: center;
  margin: 16px;
  padding: 16px;
  h1,h2{
    margin: 0
  }

  h1{
    color: #FFF;
    font-size: 3rem;
    
  }
  h2{
    color: #FFF;
    font-size: 1.4rem;
    margin-top: -24px;
    max-width: 75%;
    margin: -8px auto 16px auto;
    @media screen and (max-width: 684px){
      display: none;
    }
  }
`

export const SubscribeBtn = styled.button`
  padding: 12px 72px;
  border-radius: 64px;
  border: 0;
  background-color: #36c224;
  font-size: 24px;
  font-weight: 700;
  color: #FFF;
  cursor: pointer;
  transition: 0.3s;
  &:hover{
    opacity: 0.7;
  }
  &:focus{
    opacity: 1;
    background-color: #54b048;
  }
`
export const Image = styled.img`
  height: 100%;
  object-fit: cover;
  padding: 8px;
  border-radius: 10px;
`
export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 12px;
  background-color: #36c224;
  border: none;
  transition: 0.3s;
  &:hover{
    background-color: #36c224;
    opacity: 0.8;
  }
`