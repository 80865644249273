import { Card } from 'components/CardContainer'
import styled from 'styled-components'

export const InputBtn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const BodyContainer = styled.main`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0 5%;
    &&&>div{
      margin-bottom: 24px;
    }

`

export const CardContainer = styled(Card)`
    width: 400px;
    height: 400px;
    padding: 40px;
    margin-left: 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`
export const BtnYear = styled.div`
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: var(--main);
    display: flex;
    p{
        margin-left: 40px;
        margin-right: 40px;
    }
    svg{
        margin-top: 5px;
        cursor: pointer;
    }

    svg:first-child{
        transform: rotate(180deg);
    }
`
export const TableContainer = styled(Card)`
    height: 100%;
    width: 100%;
    .title{
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #5C5C5C;
    }

    .dBoHet{
        height: 70%;
    }
`
export const Button = styled.button`
    border: 1px solid var(--main);
    box-sizing: border-box;
    border-radius: 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: var(--main);
    background: transparent;
    width: 150px;
    height: 36px;
    cursor: pointer;
    transition: all 0.3s;
    outline: none;
    &:hover{
        border: 1px solid #5C5C5C;
        color: #5C5C5C; 
    }
`
export const QtdSells = styled.div`
    width: 100%;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #5C5C5C;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
`
export const FooterCard = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.15px;
    color: #5C5C5C;

    p{
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        letter-spacing: 0.15px;
        color: #5C5C5C;
        margin-left: 7px;
    }
    div{
        display: flex;
        margin-left: 80px;
    }
`
export const SubTitle = styled.div`
    margin-top: 10px;
    width:100%;
    text-align:left;
    color: #898989;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 40px;

    p{
        margin: 0;
        cursor: pointer;
    }
`

export const FilterContainer = styled.div`
  grid-column: 2;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
`