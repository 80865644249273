import { CircularProgress } from '@material-ui/core/'
import { Dropdown } from 'antd'
import Button from 'components/Button'
import { CardTitle } from 'components/CardContainer'
import {
  DataTable,



  DataTableBody, DataTableCell, DataTableHead,
  DataTableRow
} from 'components/DataTable'
import RangeSlider from 'components/RangeSlider'
import Search from 'components/Search'
import React, { useCallback, useEffect, useState } from 'react'
import { AiOutlineDownload } from 'react-icons/ai'
import api from 'services/api'
import Transfer from './Transfer'
import {
  ButtonActive, ButtonInative, DownloadCard,
  FilterCard, FlexContainer, FooterPagination, Header, MyStudentsCard,



  NoStudentMsg, SearchContainer, StyledPagination,TableContainer
} from './style'

interface MyStudentResponse {
  students: []
  total_of_students: number
}

const MyStudents: React.FC = () => {

  const [students, setStudents] = useState<any>([])
  
  const [totalStudents, setTotalStudents] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const [pageNumber, setPageNumber] = useState<number>(0)

  const [transfers, setTransfers] = useState<any>([])

  
  const getTransfers = useCallback(async()=>{
    let { data } = await api.get('/profile')
    const sellerId = data.user.seller_id
    //sellerid para testes - 0e1726380c9a4c67ab676347b42ec865
    await api.get(`/transfer-history/${sellerId}`)
    .then((res)=>{
      if (res.data.data.items === undefined) {
        setStudents({})
      } else {
        setTransfers(res.data.data.items)
      }
    })
  },[])

  const getStudents = useCallback(async () => {

    let { data } = await api.get('/my-students', {
      params: { page_index: pageNumber, page_size: pageSize },
    })


    if (data.records === undefined) {
      setStudents([])
    } else {
      setStudents(data.records)
    }
    setPageNumber(data.page_index)
    setPageSize(parseInt(data.page_size, 10))
    setTotalStudents(data.total_records)
  },[pageNumber, pageSize])

  const handlePageNumber = (page: number, size?: number) => {
    setPageNumber(page - 1)
    setPageSize(size || pageSize)
    window.scrollTo(0, 100)
  }

  useEffect(() => {
    getStudents()
    getTransfers()
  }, [getStudents, getTransfers, pageNumber])

  return (
    <>
      <MyStudentsCard>
        <CardTitle>Transferências</CardTitle>

          <DataTable>
            <DataTableHead>
              <DataTableRow>
                <DataTableCell>Valor</DataTableCell>
                <DataTableCell>Descrição</DataTableCell>
                <DataTableCell>Moeda</DataTableCell>
                <DataTableCell>Status</DataTableCell>
                <DataTableCell>Data de transferência</DataTableCell>
                <DataTableCell>Número da transferência</DataTableCell>
                <DataTableCell>Tipo</DataTableCell>
              </DataTableRow>
            </DataTableHead>
          
            <DataTableBody>
              {!transfers ? (
                <CircularProgress color="inherit" style={{ margin: '0 50%' }} />
              ) : transfers.length === 0 ? (
                <NoStudentMsg>Você não tem nenhuma transferência :/</NoStudentMsg>
              ) : (
                    transfers.map((transfer: any, index: number) => (
                      <Transfer transfer={transfer} key={index} />
                    ))
                  )}

              
            </DataTableBody>
          
          </DataTable>
       
        {students && totalStudents > pageSize && (
              <FooterPagination>
                <StyledPagination
                  defaultCurrent={pageNumber + 1}
                  total={totalStudents}
                  onChange={handlePageNumber}
                />
              </FooterPagination>
            )}
      </MyStudentsCard>
    </>
  )
}

export default MyStudents
