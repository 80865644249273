import React, { useState } from 'react'
import ImageInput from 'components/new/Input/Image'
import { Modal } from 'antd'
import { ModalButton } from '../../shared-styles'

interface Props {
  visible: boolean
  handleOk: any
  handleCancel: any
}

const ImgModal: React.FC<Props> = ({
  visible = false,
  handleOk,
  handleCancel,
  ...rest
}) => {
  return (
    <>
      <Modal
        title="Inseir Imagem"
        visible={visible}
        closable={true}
        footer={[
          <ModalButton type="button" onClick={handleCancel} value="Cancelar" />,
          <ModalButton
            className="submit"
            onClick={handleOk}
            value="Adicionar imagem"
          />,
        ]}
        {...rest}
      >
        <ImageInput name="photo" />
      </Modal>
    </>
  )
}

export default ImgModal
