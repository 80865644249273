import React, { useState, useEffect } from 'react'

import api from '../../services/api'
import {
  CardContainer,
  ContainerForm,
  FormEnd,
  FicharActive,
  FicharInative,
  FormEdit,
  Tabs,
  StyledLink
} from './style'

import ProfileBasic from 'containers/StudentBasicInfo'

import FormIndividual from './FormIndividual'
import FormBusiness from './FormBusiness'
import { Link } from 'react-router-dom'

const notificationStyle = {
  right: '1.875rem',
  width: '16.25rem',
  borderRadius: '0.625rem'
}
interface propsUser {
  picture: any
  nivel: string
  description: string
}
const ContainerPersonal = () => {
  const [studentBasic, setStudentBasic] = useState<Boolean>(false)

  const [confirmPersona, setConfirmPersona] = useState<any>('cnpj')

  const [user, setUser] = useState<any>({})

  const handleStudent = () => {
    setStudentBasic(true)
  }

  const handleValues = async () => {
    try {
      let response = await api.get("profile")
      console.log(response.data.user)
      await setUser(response.data.user)

      if (response.data.user.cpf) {
        setConfirmPersona('cpf')
      }
      if (response.data.user.cnpj) {
        setConfirmPersona('cnpj')
      }
    } catch (e) { console.log(e) }
  }

  useEffect(() => {
    handleValues()
  }, [])

  const handlePersona = (value: any) => {
    setConfirmPersona(value)
  }

  return (
    <>
      {
        studentBasic === true
          ?
          <ProfileBasic />
          :
          <main>
            <CardContainer>
              {
                <div>
                  <Tabs>
                    <StyledLink to='/student'>
                      <FicharInative>
                        <p>Perfil</p>
                      </FicharInative>
                    </StyledLink>
                    <FicharActive >
                      Dados pessoais
                    </FicharActive>
                  </Tabs>
                  <ContainerForm>
                    <FormEnd>
                      Deseja cadastrar como:
                          <FormEdit>
                        {confirmPersona === "cnpj"
                          ?
                          <div>
                            <input type="radio" id="confirmPersona" value="cnpj" name="confirmPersona" onClickCapture={(e: any) => handlePersona('cnpj')} checked />
                            <label htmlFor="confirmPersona">CNPJ</label>
                          </div>
                          :
                          <div>
                            <input type="radio" id="confirmPersona" value="cnpj" name="confirmPersona" onClickCapture={(e: any) => handlePersona('cnpj')} />
                            <label htmlFor="confirmPersona">CNPJ</label>
                          </div>
                        }

                        {confirmPersona === "cpf"
                          ?
                          <div>
                            <input type="radio" id="cpf" value="cpf" name="confirmPersona" onClickCapture={(e: any) => handlePersona('cpf')} checked />
                            <label htmlFor="cpf">CPF</label>
                          </div>
                          :
                          <div>
                            <input type="radio" id="cpf" value="cpf" name="confirmPersona" onClickCapture={(e: any) => handlePersona('cpf')} />
                            <label htmlFor="cpf">CPF</label>
                          </div>
                        }

                      </FormEdit>

                      {
                        confirmPersona === "cpf" ? (
                          <FormIndividual user={user} />
                        ) : confirmPersona === "cnpj" ?
                            (
                              <FormBusiness user={user} />
                            ) : (<></>)
                      }
                    </FormEnd>
                  </ContainerForm>
                </div>
              }
            </CardContainer>
          </main>
      }
    </>
  )
}

export default ContainerPersonal;