import styled from 'styled-components'

export const SearchContainer = styled.div`
  grid-column: 1;
  margin-right: 0.8125rem;
`

export const TitleContainer = styled.p`
  width: 100%;
  text-align: left;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #5C5C5C;
`

export const Container = styled.div`
  width: 100%;
  height: auto;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`
export const SearchAffiliates = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export const ListTypes = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: end;
  margin: 0;
  cursor: pointer;

  li{
    text-decoration: none;
    list-style: none;
    text-align: center;
    border: 2px solid var(--main);
    color: var(--main);
    padding: 0 10px;
    margin-left: 13px;
    border-radius: 27px;
    transition: all 0.3s;
  }

  li:hover{
    border: 2px solid #a2a2a2;
    color: #a2a2a2;
  }

`

export const Results = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
export const ListCard = styled.ul`
    margin-top: 40px;
    margin-left: 40px;

    &:first-child{
      margin-left: 0;
    }
    &:nth-child(6n){
      margin-left: 0;
    }
`
