import { Breadcrumbs } from '@material-ui/core/'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Title = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  color: #898989;
  margin: 1.52625rem 0;

  span {
    color: var(--main);
  }
`

export const Breadcrumb = styled(Breadcrumbs)`
  &&& {
    margin: 2.345rem 0;
  }
`

export const Link = styled(NavLink)`
  color: ${({ color }) => color ? color : 'var(--main)'};

  &:hover {
    color: var(--main);
  }
`

export const Header = styled.header`
  display: grid;
  grid-template-columns: 0.15fr 1fr;
  grid-column-gap: 2vw;
`

export const Subheader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 1.77625rem auto 1.043125rem;

  h1 {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.015625rem;
    color: #898989;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    flex: 2.86;
  }

  section {
    color: #A2A2A2;
    display: flex;
    flex: 2;

    &:not(:first-child),
    &:not(:last-child) {
      margin-left: 3rem;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.025rem;
      margin-left: 0.35rem;
    }
  }
`

export const NewProductBtn = styled(Link)`
  grid-column: 1;
  width: max-content;
  padding: 0.4375rem 1.18875rem;
  color: #FFFFFF;
  box-sizing: border-box;
  border-radius: 3rem;
  background-color: var(--main);
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  border: none;
  outline: none;
  display: flex;
  align-items: center;

  span {
    margin-right: 0.41375rem;
    font-size: 1.34375rem;
  }

  &:hover {
    color: #FFFFFF;
    background-color: #e6d627;
  }
`

export const SearchContainer = styled.div`
  grid-column: 2;
`

export const ProductList = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SmallIcon = styled.img`
  width: 0.875rem;
  height: 0.875rem;
`

export const NoProductMsg = styled.h1`
  text-align: center;
  font-size: 1.25rem;
  margin-top: 3rem;
  color: #292929;
`

export const ErrorScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 1rem;
    color: red;
  }
`