import styled from 'styled-components'
import { Pagination } from 'antd'
import { Card } from 'components/CardContainer'
import Form from 'components/new/Form'

export const ContainerDown = styled(Card)`
  width: 500px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Roboto', verdana, sans-serif;


  h3{
    text-transform: uppercase;
    color: #5C5C5C;
    font-size: 25px;
    margin-bottom: 25px;
  }

  button{
    margin-top: 20px;
    background-color: var(--main);
    border-radius: 10px;
    width: 130px;
    height: 40px;
    color: white;
    border: none;
    cursor: pointer;
    transition: all 0.3s;
  }

  button:hover{
    background-color: white;
    color: black;
    border: 1px solid var(--main);
  }
`
export const Container = styled.div`
  width: 100%;
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`
export const FlexContainer = styled.div`
  display: flex;
  @media screen and (max-width: 1253px){
    flex-direction: column;
  }
`

export const TableContainer = styled(Card)`
    
    width: 650px;
    margin-left: 16px;
    margin-bottom: 16px;
    padding: 45px;
    text-align-last: center;
    .title{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #5C5C5C;
    }

    .dBoHet{
        height: 70%;
    }
    @media screen and (max-width:900px){
      width: 100%;
      margin-left: 0;
      margin-top: 12px;
    }
`
export const ReportInfo = styled.div`
  
    font-family: 'Roboto';
    color: #A2A2A2;
    justify-content: center;
    align-items: center;

    .productName {
      font-weight: bold;
      font-size: 1.5rem;
      color: #5C5C5C;
      
    }

    .totalNumber {
      font-weight: bold;
      font-size: 1.3rem;
      color: #84A846;
    }

    .boxSells {
      border-radius: 1rem;
      border-width: thin;
      border-style: solid;
      border-color: #A2A2A2;
    }
    
    .boxSells section{
      display: flex;
      justify-content: center;
      flex-direction: row;
    }

    .boxSells section p{
      margin: 5px;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 0.8rem;
      color: #A2A2A2;
    }
    
    .subTotalNumber{
      font-weight: bold;
      font-size: 1rem;
      color: #A2A2A2;
    }

    section{
      display: flex;
      justify-content: space-between;
      padding: 0 15px;
    }
    h1 {
      text-transform: none;
      font-weight: medium;
      font-size: 0.8rem;
      color: #A2A2A2;
    }

    h3{
      text-transform: none;
      font-weight: medium;
      font-size: 1rem;
      color: #5C5C5C;
    }


`

export const StyledPagination = styled(Pagination)`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto 2.5rem;

  > .ant-pagination-item-active {
    border-color: var(--main);

    > a {
      color: var(--main);
    }

    &&&:hover {
      border-color: var(--main);
      color: var(--main);
    }
  }
`
export const Msg = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ContentForm = styled(Form)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

`

export const Register = styled.button`
  margin-top: 12px;
  bottom: 0.4rem;
  right: 5.25rem;
  padding: 0.425rem 1.25rem;
  outline: none;
  background: #82bd02;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  align-self: end;  

  p {
    margin: 0;
  }
`