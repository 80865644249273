import React from "react";
import {
  DataTable,
  DataTableHead,
  DataTableRow,
  DataTableCell,
  DataTableBody,
} from 'components/DataTable'
import {
  ContainerTable
} from './style'
import Market from './Market'
import { CircularProgress } from '@material-ui/core/'

interface MarketPlace {
  marketPlace: any
}

const SkeletonTable: React.FC<MarketPlace> = ({ marketPlace }) => {

  return (
    <ContainerTable>
      <DataTable>
        <DataTableHead>
          <DataTableRow>
            <DataTableCell>Market Places</DataTableCell>
          </DataTableRow>
        </DataTableHead>

        <DataTableBody>
          {
            !marketPlace ? (
              <CircularProgress color="inherit" />
            ) : (
                marketPlace && marketPlace.map((product: any) => (
                  <Market marketPlace={product} key={product.id} />
                ))
              )
          }
        </DataTableBody>
      </DataTable>
    </ContainerTable>
  );
};

export default SkeletonTable;
