import styled from 'styled-components'

export const Complement = styled.div`
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center; 
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #A2A2A2;
    align-self: flex-end;
    div{
        width: 150px;
        padding: 12px;
    }
    @media screen and (max-width:887px){
        flex-direction: row;
    }
`
export const TxtComplement = styled.div`
    width:100%;
    display: flex;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #5C5C5C;
    justify-content: center;
    align-items: center;
`
