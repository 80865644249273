import styled from 'styled-components'
import { Subcard } from '../../../shared-style'

export const MenuCard = styled(Subcard)`
  width: 21.925625rem;
  height: 4.945625rem;
  justify-content: space-between;
  cursor: pointer;

  img {
    transform: rotate(-90deg);
  }
`