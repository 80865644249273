/*
Aqui fica a página de quando o usuário esquece a senha e deseja recuperar a conta
*/

import React, { useRef, useState } from 'react'
import { FormHandles, SubmitHandler } from '@unform/core'
import { FiAtSign } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import * as Yup from 'yup'
import api from '../../services/api'
import {
  Container,
  FormContainer,
  MainContainer,
  PrimaryButton,
  StyledForm,
} from '../../components/Form/Container'
import Input from '../../components/Form/Input'
import Logo from '../../components/Logo'
import { LoadingSpin } from '../../components/Loading'
import { InputGroup } from '../../components/Form/Input/style'
import { displayErrorNotification } from 'utils/displayNotification'
import { notification } from 'antd'

const notificationStyle = {
  right: '1.875rem',
  width: '16.25rem',
  borderRadius: '0.625rem',
}

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false)
  const formRef = useRef<FormHandles>(null)

  const handleSubmit: SubmitHandler<FormData> = async (body: object) => {
    setLoading(true)

    try {
      const schema = Yup.object().shape({
        email: Yup.string().email().required('O email é obrigatório'),
      })

      await schema.validate(body, { abortEarly: false })
      await api.post('/forgot-password', body)

      notification.success({
        message: 'Geramos uma nova senha para você!',
        description:
          'Verifique o seu email, caso não esteja na caixa de entrada verifique o spam',
        placement: 'bottomRight',
        style: notificationStyle
      })

      setLoading(false)
    } catch (err) {
      const validationErrors: any = {}

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message
        })

        if (formRef && formRef.current)
          formRef.current.setErrors(validationErrors)
          
          setLoading(false)
          return
      }

      displayErrorNotification(err.response.data.message)
      setLoading(false)
    }
  }

  return (
    <MainContainer>
      <Container>
        <Logo />

        <FormContainer style={{ marginTop: '3rem' }}>
          <StyledForm ref={formRef} onSubmit={handleSubmit}>
            <InputGroup>
              <FiAtSign size={20} />
              <Input type="email" name="email" placeholder="Seu email" />
              <legend>Email</legend>
            </InputGroup>

            <PrimaryButton>
              {loading ? <LoadingSpin /> : 'Enviar solicitação'}
            </PrimaryButton>
          </StyledForm>

          <NavLink to="/login">
            <p>Voltar para Login</p>
          </NavLink>
        </FormContainer>
      </Container>
    </MainContainer>
  )
}

export default ForgotPassword
