import React from "react";

import { Container } from "./style";

interface ButtonProps {
  color?: string
  width?: string
  btnStyle?: string
  arrowIcon?: boolean
  onClick?: any
  style?: object
  onMouseOver?: any
}

const Button: React.FC<ButtonProps> = ({
  color = "var(--main)",
  width = "max-content",
  btnStyle = "bordered",
  arrowIcon = false,
  onClick,
  children,
  onMouseOver,
  style,
  ...rest
}) => {
  return (
    <Container
      style={style}
      onClick={onClick}
      onMouseOver={onMouseOver}
      icon={arrowIcon}
      btnStyle={btnStyle}
      width={width}
      color={color}
      {...rest}
    >
      {children}
    </Container>
  );
};
// TODO: Definir e padronizar, ou usa ; ou não
export default Button;
