import styled from "styled-components";


interface ButtonProps {
  color?: string;
  width?: string;
  btnStyle?: string;
  icon?: boolean;
  height?: string;
}

export const Container = styled.button<ButtonProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding: 0.4375rem 1.18875rem;
  color: ${({ color }) => color};
  box-sizing: border-box;
  border-radius: 3rem;
  background-color: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  span {
    margin-right: 0.41375rem;
    font-size: 1.34375rem;
  }

  ${(props) => {
    switch (props.btnStyle) {
      case "bordered":
        return `
          border: 0.0625rem solid ${props.color};
        `;
      case "filled":
        return `
          background-color: ${props.color};
          color: white;
        `;
      default:
        return;
    }
  }}

  ${(props) =>
    props.icon ? `
    padding: 0.4375rem 0.78875rem;
    padding-right: 1.875rem;
    &:before {
      content: '';
      position: absolute;
      top: 0.65625rem;
      right: 0.8125rem;
      border: solid ${props.color};
      border-width: 0 2.3px 2.3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
    }
  `
    : ``}
`;
