import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  margin: 1rem 0;
  display: flex;
`

export const CoProducerTable = styled.div`
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 0.625rem;
  margin-bottom: 1.5rem;
`

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1.25fr repeat(3, 1fr);
  grid-gap: 0.5rem;
  padding: 0.725rem;
`

export const CoProducerTableHeader = styled(TableRow)`
  border-radius: 0.625rem 0.625rem 0 0;
  background: #f0f0f0;
  border-bottom: 1px solid #e6e6e6;
  text-align: center;

  & > *:not(:last-child) {
    border-right: 1px solid #bfbfbf;
  }
`

export const CoProducerTableContent = styled(TableRow)`
  & > *:not(:last-child) {
    border-right: 1px solid #bfbfbf;
  }

  section {
    width: 100%;
    text-align: center;

    &:last-child {
      border-radius: 0 0 0.625rem 0.625rem;
    }
  }
`

export const Label = styled.span`
  color: #292929;
  font-weight: bold;
`

export const Value = styled.span`
  color: gray;
`

export const DeleteButton = styled.input.attrs({
  type: 'image',
})`
  margin: 0.625rem;
  align-self: self-start;
  outline: none;
`
