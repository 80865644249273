// import { Classes } from 'pages/CourseView'
import Fade from '@material-ui/core/Fade'
import { makeStyles } from '@material-ui/core/styles'
import PlayerControls from 'components/CourseVideo/PlayerControls'
import Comment from 'components/new/Comment'
import Button from 'components/new_components/button'
import React, { useEffect, useRef, useState } from 'react'
import { IoMdDownload } from 'react-icons/io'
import ReactPlayer from 'react-player'
import * as screenfull from 'screenfull'
import {
  CommentsWrapper, ComplementaryMaterial, Container,
  ContainerButtons, DivImg, DownloadButton
} from './styles'
import Reactpip from 'react-picture-in-picture'
// import Grid from '@material-ui/core/Grid'
// import Paper from '@material-ui/core/Paper'
// import Typography from '@material-ui/core/Typography'

interface Props {
  videoLink?: any
  name?: any
  description?: any
  conclusion?: any
  idVideo?: any
  linkArchive?: any
  children?: any
  open?: any
  value?: any
  view?: any
}

const useStyles = makeStyles({
  playerWrapper: {
    position: "sticky",
    width: '100%',
    height: '100%',
  },

})

const format = (seconds: number) => {
  if (isNaN(seconds)) {
    return '00:00'
  }

  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = date.getUTCSeconds().toString().padStart(2, "0")
  if (hh) {
    return `:${mm.toString().padStart(2, "0")}:${ss}`
  }

  return `${mm}:${ss}`
}

const CourseVideo: React.FC<Props> = ({ videoLink, name, description, conclusion, idVideo, linkArchive, view }) => {
  const classes = useStyles()
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [state, setState] = useState({
    playing: true,
    muted: false,
    fullscreen: false,
    volume: isMobile ? 1 : 0.5,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
    onClickButton: false,
    pip: false,
    wideView: true,
  })

  const [timeDisplayFormat, setTimeDisplayFormat] = useState('normal')

  // const [bookmarks, setBookmarks] = useState<any>([])

  const { playing, muted, volume, fullscreen, playbackRate, played, seeking, onClickButton, pip, wideView } = state

  const playerRef = useRef<any>(null)
  const playerContainerRef = useRef<any>(null)
  // const canvasRef = useRef<any>(null)
  const controlsRef = useRef<any>(null)

  const handlePip = () => {
    setState({ ...state, pip: !state.pip })
  }

  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing })
  }

  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10)
  }

  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10)
  }

  const handleMute = () => {
    setState({ ...state, muted: !state.muted })
  }

  const handleFullscreen = () => {
    setState({ ...state, fullscreen: !state.fullscreen })
  }

  const handleView = () => {
    setState({ ...state, wideView: !state.wideView })
  }

  const handleVolumeChange = (e: any, newValue: any) => {
    setState({
      ...state,
      volume: parseFloat(newValue) / 100,
      muted: newValue === 0 ? true : false
    })
  }

  const handleVolumeSeekUp = (e: any, newValue: any) => {
    setState({
      ...state,
      volume: parseFloat(newValue) / 100,
      muted: newValue === 0 ? true : false
    })
  }

  const handlePlaybackRateChange = (rate: any) => {
    setState({ ...state, playbackRate: rate })
  }

  const toggleFullScreen = () => {
    let sf = screenfull;
    if (sf.isEnabled) {
      sf.toggle(playerContainerRef.current)
    }
  }

  const handleProgress = (changeState: any) => {
    if (!state.seeking) {
      setState({ ...state, ...changeState })
    }
  }

  const handleSeekChange = (e: any, newValue: any) => {
    setState({ ...state, played: parseFloat(newValue) / 100 })
  }

  const handleSeekMouseDown = (e: any) => {
    setState({ ...state, seeking: true })
  }

  const handleSeekMouseUp = (e: any, newValue: any) => {
    setState({ ...state, seeking: false })
    playerRef.current.seekTo(newValue / 100)
  }

  const handleChangeDisplayFormat = () => {
    setTimeDisplayFormat(
      timeDisplayFormat === 'normal' ? 'remaining' : 'normal'
    )
  }

  const [controlsVisibility, setControlsVisibility] = useState(false)
  const handleControlsVisibility = () => {
    setControlsVisibility(!controlsVisibility)
  }

  const handleControlsVisibilityToTrue = () => {
    setControlsVisibility(true)
  }

  const handleControlsVisibilityWithDelay = async () => {
    await sleep(1200)
    setControlsVisibility(!controlsVisibility)
  }

  const handlePIPMode = () => {
    setActive(!active)
  }

  const sleep = (milliseconds?: any) =>
    new Promise((resolve) => setTimeout(() => {
      resolve(true)
    }, milliseconds))

  const handleKeyboardKeys = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.persist()

    if (e.keyCode === 13) {
      e.preventDefault()
      handlePlayPause()
    }

    if (e.keyCode === 32) {
      e.preventDefault()
      handlePlayPause()
    }

    if (e.keyCode === 37) {
      e.preventDefault()
      handleRewind()
    }

    if (e.keyCode === 38) {
      e.preventDefault()
      handleVolumeChange(0, volume * 100 + 10)
    }

    if (e.keyCode === 39) {
      e.preventDefault()
      handleFastForward()
    }

    if (e.keyCode === 40) {
      e.preventDefault()
      handleVolumeChange(0, volume * 100 - 10)
    }
  }

  // const addBookmark = () => {
  //   const canvas: any = canvasRef.current
  //   canvas.width = 160
  //   canvas.height = 90


  //   const canvasContext = canvas.getContext('2d')
  //   let img = new Image()
  //   img.src = playerRef.current.getInternalPlayer()

  //   img.onload = function(){
  //     canvasContext.drawImage(
  //         img,
  //         0,
  //         0,
  //         canvas.width,
  //         canvas.height
  //     )
  //   }
  //     // canvasContext.drawImage(
  //     //   playerRef.current.getInternalPlayer(),
  //     //   0,
  //     //   0,
  //     //   canvas.width,
  //     //   canvas.height,
  //     // )

  //   const imageUrl = canvas.toDataURL()
  //   canvas.width = 0
  //   canvas.height = 0

  //   setBookmarks([...bookmarks, 
  //     {time:currentTime, display:elapsedTime, image:imageUrl },
  //   ])
  // }

  const [active, setActive] = useState(false)

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    isMobile ? setIsMobile(true) : setIsMobile(false)

    // playerContainerRef.current.focus()
  }, [isMobile])

  const currentTime = playerRef.current ? playerRef.current.getCurrentTime() : '00:00'
  const duration = playerRef.current ? playerRef.current.getDuration() : '00:00'
  const elapsedTime = timeDisplayFormat === 'normal' ? format(currentTime) : `-${format(duration - currentTime)}`
  const totalDuration = format(duration)

  return (
    <Container>
      { isMobile ?
        <DivImg>
          <div
            ref={playerContainerRef}
            className={classes.playerWrapper}
            onDoubleClick={() => { toggleFullScreen(); handleFullscreen(); }}
            onClick={handleControlsVisibilityWithDelay}
            style={{ height: '100%', width: '100%' }}
          >
            <div onClick={handleControlsVisibility} style={{ height: '100%', width: '100%' }}>
              <ReactPlayer
                ref={playerRef}
                width="100%"
                height="100%"
                muted={muted}
                playing={playing}
                volume={volume}
                url={videoLink}
                style={{ position: 'relative', zIndex: -1 }}
                onProgress={(e: any) => { conclusion(e); handleProgress(e) }}
                playbackRate={playbackRate}
                config={{
                  file: {
                    attributes: {
                      crossOrigin: "anonymous",
                    }
                  }
                }}
              />
            </div>

            {controlsVisibility ?
              <Fade in={controlsVisibility} timeout={250}>
                <PlayerControls
                  onPlayPause={handlePlayPause}
                  playing={playing}
                  onRewind={handleRewind}
                  onFastForward={handleFastForward}
                  muted={muted}
                  onMute={handleMute}
                  onVolumeChange={handleVolumeChange}
                  onVolumeSeekUp={handleVolumeSeekUp}
                  volume={volume}
                  playbackRate={playbackRate}
                  onPlaybackRateChange={handlePlaybackRateChange}
                  onToggleFullScreen={toggleFullScreen}
                  onFullscreen={handleFullscreen}
                  fullscreen={fullscreen}
                  played={played}
                  onSeek={handleSeekChange}
                  onSeekMouseDown={handleSeekMouseDown}
                  onSeekMouseUp={handleSeekMouseUp}
                  elapsedTime={elapsedTime}
                  totalDuration={totalDuration}
                  onChangeDisplayFormat={handleChangeDisplayFormat}
                  videoName={name}
                  mobile={isMobile}
                /*onBookMark={addBookmark}*/
                />
              </Fade>
              : null
            }

            {/* <Grid container style={{ marginTop: 20 }} spacing={3}>
    {bookmarks.map((bookmark: any, index: any) => (
      <Grid item key={index}>
        <Paper onClick={() => playerRef.current.seekTo(bookmark.time)}>
          <img crossOrigin="anonymous" src={bookmark.image} alt=""/>
          <Typography>Bookmark salvo em {bookmark.display}</Typography>
        </Paper>
      </Grid>
    ))}
  </Grid> */}

            {/* <canvas ref={canvasRef} /> */}
          </div>
        </DivImg>
        :
        <DivImg>
          <div
            ref={playerContainerRef}
            className={classes.playerWrapper}
            onMouseLeave={handleControlsVisibility}
            onMouseEnter={handleControlsVisibilityToTrue}
            onDoubleClick={() => { toggleFullScreen(); handleFullscreen(); }}
            onKeyDown={handleKeyboardKeys}
            tabIndex={0}
            style={{ height: '100%', width: '100%', padding: '3px' }}
          >
            <div onClick={handleControlsVisibility} onMouseMove={handleControlsVisibilityToTrue} style={{ height: '100%', width: '100%' }}>
              <ReactPlayer
                ref={playerRef}
                width="100%"
                height="100%"
                muted={muted}
                playing={playing}
                volume={volume}
                url={videoLink}
                style={{ position: 'relative', zIndex: -1 }}
                onProgress={(e: any) => { conclusion(e); handleProgress(e) }}
                playbackRate={playbackRate}
                pip={active}
                stopOnUnmount={false}
                config={{
                  file: {
                    attributes: {
                      crossOrigin: "anonymous",
                    }
                  }
                }}
              />

              {null === null ?
                null :
                <Reactpip isActive={active} >
                  <source src={videoLink} style={{ width: 0, height: 0 }} />
                </Reactpip>
              }
            </div>

            {controlsVisibility ?
              <>
                <Fade in={controlsVisibility} timeout={250}>
                  <PlayerControls
                    onPlayPause={handlePlayPause}
                    playing={playing}
                    handleControlsVisibility={handleControlsVisibility}
                    onRewind={handleRewind}
                    onFastForward={handleFastForward}
                    muted={muted}
                    onMute={handleMute}
                    onVolumeChange={handleVolumeChange}
                    onVolumeSeekUp={handleVolumeSeekUp}
                    volume={volume}
                    playbackRate={playbackRate}
                    onPlaybackRateChange={handlePlaybackRateChange}
                    onToggleFullScreen={toggleFullScreen}
                    onFullscreen={handleFullscreen}
                    fullscreen={fullscreen}
                    played={played}
                    onSeek={handleSeekChange}
                    onSeekMouseDown={handleSeekMouseDown}
                    onSeekMouseUp={handleSeekMouseUp}
                    elapsedTime={elapsedTime}
                    totalDuration={totalDuration}
                    onChangeDisplayFormat={handleChangeDisplayFormat}
                    videoName={name}
                    view={wideView}
                    onView={view}
                    wideView={handleView}
                    onPIP={handlePIPMode}
                  /*onBookMark={addBookmark}*/
                  />
                </Fade>
              </>
              : null
            }

            {/* <Grid container style={{ marginTop: 20 }} spacing={3}>
      {bookmarks.map((bookmark: any, index: any) => (
        <Grid item key={index}>
          <Paper onClick={() => playerRef.current.seekTo(bookmark.time)}>
            <img crossOrigin="anonymous" src={bookmark.image} alt=""/>
            <Typography>Bookmark salvo em {bookmark.display}</Typography>
          </Paper>
        </Grid>
      ))}
    </Grid> */}

            {/* <canvas ref={canvasRef} /> */}
          </div>
        </DivImg>}

      <p>{description}</p>
      {
        !linkArchive
          ?
          null
          :
          <ContainerButtons>
            <ComplementaryMaterial>
              <h3>Material complementar</h3>

              <DownloadButton href={linkArchive} target="_blank">
                Download
                <IoMdDownload />
              </DownloadButton>
            </ComplementaryMaterial>
          </ContainerButtons>
      }
      <CommentsWrapper>
        <h3 id='playerGoToPIP'>Comentários </h3>
        <Comment idClass={idVideo} />
      </CommentsWrapper>
    </Container >

  )
}

export default CourseVideo
