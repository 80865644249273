import styled from 'styled-components'
import { Collapse } from 'antd'

const { Panel } = Collapse

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1rem 0 2rem;
`

export const Title = styled.h1`
  font-size: 0.95rem;
`

export const CollapseContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 2rem;

  
`

export const StyledCollapse = styled(Collapse)`
  border-radius: 0.3125rem !important;
  width: 80%;

  .style-header > .ant-collapse-header {
    background-color: #292929;
    color: white;
    border-radius: 0.3125rem !important;
  }
  & input[type='image'] {
    margin: 0 1.2rem;
    outline: none;
    margin-top: 0.2rem;
  }
`

export const StyledPanel = styled(Panel)`
  border-radius: 0px 0px 0.3125rem 0.3125rem !important;
`

export const InsertModuleMenu = styled.div`
  width: 13.765625rem;
  height: max-content;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.1.5625rem;
  color: #5c5c5c;
  display: flex;
  background: #ffffff;
  border: 0.0625rem solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 0.625rem;
  padding: 5px 20px;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
    cursor: pointer;

    p {
      width: max-content;
      margin: 0;
    }

    &:first-child {
      border-bottom: 1px solid #e6e6e6;
    }

    &:hover {
      color: var(--main);
    }
  }
`

export const ClassTestContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 0.5rem;
  margin: 2rem auto 0;
  overflow-x: auto;

  h2 {
    color: #4a4a4a;
    background: #e8e8e8;
    text-align: center;
    padding: 0 0.1rem;
    font-size: 1rem;
  }
`

const ClassTestItem = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 5px;

  section {
    padding: 0 10px;
    font-size: 0.95rem;

    div {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin: 0.5rem 0;
    }

    h1 {
      font-size: inherit;
      cursor: pointer;
      margin: 0;
      width: 100%;

      &:hover {
        color: var(--main);
      }
    }
  }
`

export const Classes = styled(ClassTestItem)`
  grid-column: 1;
`

export const Tests = styled(ClassTestItem)`
  grid-column: 2;
`
