import styled from 'styled-components'

export const ImgContainer = styled.div`
  width: 1.8556rem;
  height: 1.8556rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: lightgray;

  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
`
