import React from 'react'
import { Container } from './style'

interface Props {
  placeholder?: string
  disabled?: boolean
  type?: string
}

const Text: React.FC<Props> = ({
  placeholder = "",
  disabled = false,
  type = "text"
}) => {
  return <Container type={type} disabled={disabled} placeholder={placeholder} />
}

export default Text
